import React from 'react'
import DefaultLayout from '../templates/DefaultTemplate'
import MyProfileComponent from '../components/MyProfileComponent/'

class MyProfile extends React.Component {

    render() {
        return (
            <DefaultLayout {...this.props}>
                <MyProfileComponent {...this.props} history={this.props.history}/>
            </DefaultLayout>
        )
    }
}

export default MyProfile
