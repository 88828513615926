import React from 'react'
import Table from '../Table'

class TemplatesList extends React.Component {
    constructor(props) {
        super(props)

        this.state = {}
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.forceRefresh) this.table.forceRefresh()
    }

    render() {
        return (
            <div style={{ flex: 1, flexDirection: 'column', overflow: 'auto' }}>
                <Table
                    filter={[]}
                    ref={table => this.table = table}
                    uid={'templatesList'}
                    defaultConfig={this.props.configuration}
                    endPoint={this.props.endPoint}
                    csvFilename="templates"
                    getTdProps={(state, row, column, instance) => {
                        return {
                            onClick: () => {
                                this.props.showDetails(row.original)
                            }
                        }
                    }}
                />
            </div>
        )
    }
}

export default TemplatesList
