import React from 'react'

import './TernaryBox.css'

const values = [true, false, undefined]

class TernaryBox extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            value: props.checked
        }

        this.toggleValue = this.toggleValue.bind(this)
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            value: nextProps.checked
        })
    }

    toggleValue(e) {
        if (this.props.readOnly) {
            return
        }
        const nextPos = values.indexOf(this.state.value) + (e.shiftKey ? -1 : 1)
        const newvalue = values[nextPos < values.length ? nextPos : (e.shiftKey ? values.length : 0)]
        this.setState({
            value: newvalue
        }, () => {
            if (this.props.onChange) {
                this.props.onChange({
                    target: {
                        checked: newvalue,
                        name: this.props.name
                    }
                })
            }
        })
        this.forceUpdate()
    }

    render() {
        return (
            <button readOnly={this.props.readOnly} className={`field TernaryBox ${this.state.value}`}
                    title={this.props.title} onClick={this.toggleValue}></button>
        )
    }
}

export default TernaryBox
