import React from 'react'
import './dateTime.css'

function IntegerWidget() {
    return {
        Cell: (cell) => {
            const style = {
                textAlign: 'right'
            }
            return (
                <div style={style}>{cell.value}</div>
            )
        },

        Filter: ({ filter, onChange }) => {
            return (
                <div>
                    <input type="text"
                           style={{ width: '100%' }}
                           onChange={(event) => {
                               onChange(event.target.value)
                           }}
                           value={(filter ? filter.value : '')}
                    />
                </div>
            )
        }
    }
}

export default IntegerWidget