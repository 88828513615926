import React from 'react'
import { Button } from '../UI/Toolkit'
import Helpers from '../../core/Helpers'
import Alfred from '../../core/Alfred'
import ApiService from '../../core/ApiService'
import Table from '../Table'
import 'react-table/react-table.css'
import routes from '../../routes'
import ForbiddenComponent from '../ForbiddenComponent'
import BoxUpdateComponent from './BoxUpdate.jsx'

class BoxUpdateContainer extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            componentReady: false,
            endPoint: {},
            showModale: false,
            configuration: {
                columns: []
            },
            boxes: [],
            communities: []
        }

        this.populatePackagesList = this.populatePackagesList.bind(this)
        this.populateBoxes = this.populateBoxes.bind(this)
    }

    componentDidMount() {
        if (Helpers.getCurrentUser().admin >= 1 && Alfred.askPermission('BOX_UPDATES', Alfred.R)) {

            this.generateConfiguration()
            this.populatePackagesList()
            this.populateBoxes()
            this.props.updateBreadcrumb('Box updates', <div style={{ display: 'flex' }} >
                <div style={{ flex: 1 }}>Deploy update packages to the MediaBox network </div>
                {Alfred.askPermission('BOX_UPDATES', Alfred.W) &&
                    <Button bsStyle="primary"
                        bsSize="small"
                        onClick={
                            () => {
                                this.setState({
                                    showModale: true
                                })
                            }
                        }> New update </Button>
                }
            </div>
            )
        }
    }

    populatePackagesList() {
        ApiService.getUpdatePackagesList().then((json) => {
            this.setState({
                packages: (json || []).sort((a, b) => {
                    return a.version_code > b.version_code ? -1 : 1
                })
            })
        })
    }

    populateBoxes() {
        ApiService.getBoxes().then((json) => {
            this.setState({
                boxes: json
            })
        })
        ApiService.getCommunities().then((json) => {
            this.setState({
                communities: json
            })
        })
    }

    generateConfiguration() {
        this.setState({
            componentReady: false
        })
        let endPoint = routes.rollingUpdatesList
        const defaultConfiguration = {
            sort: {
                id: 'creation_timestamp',
                desc: true
            },
            rowsPerPage: 10,
            columns: [{
                id: 'uid',
                title: 'UID',
                type: Table.types.STRING,
                accessor: (row) => {
                    return row.uid
                },
                show: true,
                width: 150
            },
            {
                id: 'version_code',
                title: 'Version',
                type: Table.types.STRING,
                accessor: (row) => {
                    return row.version_code
                },
                show: true
            },
            {
                id: 'status',
                title: 'Status',
                type: Table.types.STRING,
                accessor: (row) => {
                    return row.status
                },
                show: true
            },
            {
                id: 'nb_boxes',
                title: 'Boxes',
                type: Table.types.STRING,
                accessor: (row) => {
                    return row.nb_boxes
                },
                show: true
            },
            {
                id: 'creation_timestamp',
                title: 'Creation',
                type: Table.types.DATETIME,
                accessor: (row) => {
                    return row.creation_timestamp
                },
                show: true
            }
            ]
        }

        this.setState({
            endPoint: endPoint,
            configuration: defaultConfiguration,
            componentReady: true
        })
    }

    render() {
        if (Helpers.getCurrentUser().admin < 1) {
            return <ForbiddenComponent />
        }
        if (!this.state.componentReady) return null
        return (<div style={
            {
                display: 'flex',
                flex: 1,
                padding: '1rem'
            }
        }>
            <BoxUpdateComponent {...this.props} {...this.state} publish={this.onPublish} cancelPublish={this.onCancelPublish} />
        </div>
        )
    }
}

export default BoxUpdateContainer