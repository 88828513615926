import React from 'react'
import Transfer from './Transfer'
import ApiService from '../../core/ApiService'
import Logger from '../../core/Logger'
import { withRouter } from 'react-router-dom'

import moment from 'moment-timezone'
import WebSocketClient from '../../core/WebSocketClient';

const logger = new Logger('FileTransferMonitor/Transfer')

class TransferContainer extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            destinations: [],
            files: [],
            item_id: null,
            lastUpdate: Date.now(),
            origin: {},
            workflow_id: null,
            statusMessages: []
        }

        this.update = this.update.bind(this)
        this.uploadUpdate = this.uploadUpdate.bind(this)
        this.updateBreadcrumb = this.updateBreadcrumb.bind(this)
    }

    componentWillReceiveProps(nextProps) {
        const message = nextProps.message
        if (message) {
            switch (message.type) {
                case 'file_upload_update':
                    this.uploadUpdate(message.file_id, message.progress, message.status, message.last_update, message.transfer_start, message.transfer_end)
                    break
                case 'file_download_update':
                    this.downloadUpdate(message.file_id, message.box_id, message.progress, message.status, message.last_update, message.transfer_start, message.transfer_end)
                    break
                case 'item_status_update':
                    this.setState({
                        status: message.status
                    })
                    break
                default:
                    break
            }
        }
    }

    componentDidMount() {
        this.update()
            .then(() => {
                this.updateBreadcrumb()
            })
    }

    uploadUpdate(fileId, progress, status, last_update, transfer_start, transfer_end) {
        const state = this.state
        const file = state.files.find((file) => {
            return file.id === fileId
        })
        if (!file) return
        file.upload = Object.assign(file.upload, {
            progress, status, last_update, transfer_start, transfer_end
        })
        state.lastUpdate = Date.now()
        this.setState(state)
    }

    downloadUpdate(fileId, boxId, progress, status, last_update, transfer_start, transfer_end) {
        const state = this.state
        const file = state.files.find((file) => {
            return file.id === fileId
        })
        if (!file) return
        const download = file.downloads.find((download) => {
            return download.destination_id === boxId
        })
        download.progress = progress
        download.status = status
        download.last_update = last_update
        download.transfer_start = transfer_start
        download.transfer_end = transfer_end
        state.lastUpdate = Date.now()
        this.setState(state)
    }

    updateBreadcrumb() {
        this.props.updateBreadcrumb(
            <span>{[this.state.workflow_name, this.state.item_name].join(' - ') || 'Transfer details'}</span>,
            <div style={{ display: 'flex' }}>
                <div style={{ flex: 1 }}>
                    Workflow ID {this.state.workflow_id}, Item ID {this.state.item_id}. Last page
                    update: {moment(this.state.lastUpdate).format('HH:mm:ss')}
                </div>
                {

                }
            </div>
        )
    }

    async update() {
        this.setState({ isActionRunning: true })
        try {
            const transfer = await ApiService.getTransfer(this.props.itemId)
            return new Promise((resolve) => {
                this.setState(Object.assign(transfer, {
                    isActionRunning: false,
                    lastUpdate: Date.now()
                }), () => {
                    this.updateBreadcrumb()
                    resolve()
                })
            })
        } catch (err) {
            logger.error(err)
        }
    }

    render() {
        return <div>
            {this.interval && <div title="Auto-refresh timer" style={{
                WebkitTransitionTimingFunction: 'linear',
                background: '#D44',
                borderRadius: '2pt',
                height: '2.5pt',
                position: 'fixed',
                right: '1.5rem',
                top: '1rem',
                transitionDuration: this.state.isActionRunning ? '0ms' : (this.duration + 'ms'),
                transitionTimingFunction: 'linear',
                width: this.state.isActionRunning ? '32px' : '0%',
                zIndex: 9999
            }} />}
            <Transfer {...this.state} {...this.props} />
        </div>
    }
}

export default withRouter(WebSocketClient.withWebSocket(TransferContainer, (props) => [props.itemId]))
