import React, { Component } from 'react'
import 'react-table/react-table.css'
import Helpers from '../../core/Helpers'
import Alfred from '../../core/Alfred'
import routes from '../../routes'
import './index.css'
import Traces from './Traces'
import Table from '../Table'
import ForbiddenComponent from '../ForbiddenComponent'

class TracesComponent extends Component {

    constructor(props) {
        super(props)
        this.state = {
            currentUser: {},
            showModal: false,
            userLogged: Helpers.getCurrentUser(),
            users: []
        }
    }

    componentDidMount() {
        if (Helpers.getCurrentUser().admin >= 1 && Alfred.askPermission('TRACES', Alfred.R)) {
            this.generateConfiguration()
            this.props.updateBreadcrumb('Traces',
                <div style={{ display: 'flex' }}>
                    <div style={{ flex: 1 }}>
                        Because quality matters !
                    </div>
                </div>
            )
        }
    }

    generateConfiguration() {
        this.setState({
            componentReady: false
        })
        let endPoint = routes.getTraces
        const defaultConfiguration = {
            sort: {
                id: 'timestamp',
                desc: true
            },
            rowsPerPage: 10,
            columns: [
                {
                    id: 'vw_traces.timestamp',
                    title: 'Time',
                    accessor: (row) => {
                        return row.timestamp
                    },
                    type: Table.types.DATETIME,
                    params: { format: 'DD/MM/YYYY HH:mm:ss' },
                    show: true,
                    resizable: true,
                    width: 120
                },
                {
                    id: 'vw_traces.user',
                    title: 'User',
                    type: Table.types.STRING,
                    accessor: (row) => {
                        return row.user
                    },
                    show: true,
                    width: 150
                },
                {
                    id: 'vw_traces.category_name',
                    title: 'Category',
                    type: Table.types.STRING,
                    accessor: (row) => {
                        return row.category_name
                    },
                    show: true,
                    width: 150
                },
                {
                    id: 'vw_traces.type_name',
                    title: 'Action',
                    accessor: (row) => {
                        return row.type_name
                    },
                    show: true,
                    width: 150
                },
                {
                    id: 'vw_traces.message',
                    title: 'Message',
                    type: Table.types.STRING,
                    accessor: (row) => {
                        return row.message
                    },
                    show: true
                }
            ]
        }

        this.setState({
            endPoint: endPoint,
            configuration: defaultConfiguration,
            componentReady: true
        })
    }

    render() {
        if (Helpers.getCurrentUser().admin < 1 || !Alfred.askPermission('TRACES', Alfred.R)) { return <ForbiddenComponent /> }
        if (!this.state.componentReady) return null
        return (
            <div style={{ display: 'flex', flex: 1 }}>
                <Traces
                    configuration={this.state.configuration}
                    endPoint={this.state.endPoint}
                    chooseUser={this.chooseUser}
                    forceRefresh={this.forceRefresh}
                />
            </div>
        )
    }
}

export default TracesComponent
