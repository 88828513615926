import React, { Component } from 'react'
import moment from 'moment-timezone'
import ApiService from '../../../../core/ApiService'
import BoxMonitorItem from './BoxMonitorItem'
import WebSocketClient from '../../../../core/WebSocketClient'
import Tag from '../../../UI/Tag'
import { Button } from '../../../UI/Toolkit'

import ic_eye from '../../../../img/ic_eye.svg'
import ic_trash from '../../../../img/ic_close.svg'

import ic_template from '../../../../img/ic_template.svg'
import ic_box from '../../../../img/ic_box_white.svg'
import ic_community from '../../../../img/ic_community.svg'
import ic_empty from '../../../../img/ic_empty.svg'

import './index.css'

class DashboardByTag extends Component {

    constructor(props) {
        super(props)
        this.state = {
            box: [],
            tags: [],
            shown_tags: [],
            filterIsOpen: localStorage.getItem('pf_dashboardFilterIsOpen') === null ? true : localStorage.getItem('pf_dashboardFilterIsOpen') === 'true'
        }
    }

    toggleFilterPane = () => {
        this.setState({
            filterIsOpen: !this.state.filterIsOpen
        }, () => {
            localStorage.setItem('pf_dashboardFilterIsOpen', !!this.state.filterIsOpen)
        })
    }

    componentWillReceiveProps(nextProps) {
        const message = nextProps.message
        const state = this.state
        if (message) {
            let box = state.box.find((box) => box.id === message.id)
            if (box) {
                box = Object.assign(box, message)
                this.setState(state)
            } else {
                state.box.push(message)
                this.setState(state)
            }
        }
    }

    componentDidMount() {
        Promise.all([
            ApiService.getBoxMonitoring(),
            ApiService.getTags()
        ])
            .then(([box, tags]) => {
                this.setState({
                    box,
                    tags: tags.rows,
                    includeTemplates: true
                }, () => {
                    if (this.props.data) {
                        this.setState({
                            shown_tags: (this.state.tags || []).filter(c => this.props.data.data.shown_tags.indexOf(c.uid) > -1),
                            includeTemplates: this.props.data.data.includeTemplates
                        })
                    }

                    if (!this.props.data && this.state.shown_tags.length === 0) {
                        this.setState({ shown_tags: this.state.tags || [] })
                    }

                    setTimeout(() => {
                        this.setState({ fullyLoaded: true })
                    }, 500)
                })

                this.props.updateBreadcrumb(
                    <div style={{ clear: 'both', display: 'flex' }}>
                        <h1>Monitoring (by tag)</h1>
                    </div>,
                    <div style={{ clear: 'both', display: 'flex' }}>
                        <div style={{ flex: 1 }}>
                            Overview of the MediaBox network
                            <div style={{ fontSize: '.95rem', opaciy: .5 }}>{moment().format('YYYY-MM-DD HH:mm:ss')}</div>
                        </div>
                    </div>
                )
            })
    }

    onSavePreset = () => {
        const name = prompt('Name your preset :')
        if (!name) {
            return
        }
        this.props.createCustomView({
            name,
            parent: 'by_tag',
            data: {
                shown_tags: (this.state.shown_tags || []).map(c => c.uid),
                includeTemplates: this.state.includeTemplates
            }
        })
    }

    onRemovePreset = () => {
        if (window.confirm('Remove this preset ?')) {
            this.props.removeCustomView(this.props.data)
        }
    }

    render() {
        return (
            <div id="DashboardTagViewComponent" className={this.state.fullyLoaded ? `animated` : ``}>
                <div style={{ display: 'flex', flexDirection: 'row', flex: 1, overflow: 'hidden' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', flex: 1, overflow: 'auto' }}>
                        {(this.state.shown_tags || []).filter((t) => this.state.includeTemplates ? true : t.domain_type !== 'template').map((st, scIdx) => {
                            return <div className="tagContainer" style={{ background: st.color }} key={scIdx}>
                                <div className="tagTitleBar" style={{ color: Tag.getContrastYIQ(st.color) }}>
                                    <div className="tagName">
                                        <b>{st.name}</b>
                                        &nbsp;
                                        <small>{st.description}</small>
                                    </div>
                                    <div style={{ flex: 1 }}></div>
                                    {st.domain_type === 'template' ? <small>{st.domain_name}</small> : ''}
                                    {st.domain_type === 'template' &&
                                        <img alt="Template" title="Template tag" src={ic_template} style={{ height: '1.25rem', width: '1.25rem', filter: `invert(${Tag.getContrastYIQ(st.color) === 'black' ? 100 : 0}%)`, pointerEvents: 'all', marginLeft: '.33rem' }} />
                                    }
                                </div>
                                {this.state.box.filter(bx => bx.tags.map(bxt => bxt.uid).indexOf(st.uid) > -1).length
                                    ? <div className="tagBoxWrapper">
                                        {
                                            this.state.box.filter(bx => bx.tags.map(bxt => bxt.uid).indexOf(st.uid) > -1).map((bx, bxIdx) => {
                                                return <BoxMonitorItem data={bx} key={bx.uid || bx.id} index={bxIdx} />
                                            })
                                        }
                                    </div>
                                    : ''
                                }
                            </div>
                        })}

                    </div>
                    <div id="dashboardTagFilterBar" className={this.state.filterIsOpen ? 'open' : 'collapsed'}>
                        <Button title={'Open / close filters'} className="filterPaneToggler" onClick={this.toggleFilterPane}>
                            {
                                this.state.filterIsOpen
                                    ? <div>&raquo;</div>
                                    : <div>&laquo;</div>
                            }
                        </Button>
                        <label style={{ display: 'flex', alignItems: 'center' }}>
                            <input type="checkbox" data-ui-toggle checked={this.state.includeTemplates} onChange={(e) => { this.setState({ includeTemplates: e.target.checked }) }} /> &nbsp; Include template tags
                        </label>
                        <div style={{ flex: 1, overflow: 'auto', flexDirection: 'column' }}>
                            <label style={{ display: 'flex', alignItems: 'center' }}>
                                <div>Shown tags</div>
                                <div style={{ flex: 1 }}></div>
                                <Button title="Clear all" onClick={() => { this.setState({ shown_tags: [] }) }}>
                                    <img alt="" src={ic_trash} style={{ filter: 'invert(100%)', margin: 0 }} />
                                </Button>
                            </label>

                            {this.state.shown_tags.filter((t) => this.state.includeTemplates || t.domain_type !== 'template').map((t, idx) => {
                                return <Button title="Hide this tag" key={idx} onClick={() => {
                                    this.setState({
                                        shown_tags: this.state.shown_tags.filter(_t => _t.uid !== t.uid)
                                    })
                                }} className={t.domain_name ? `compact` : ``}>
                                    <img alt="" src={ic_trash} style={{ filter: 'invert(100%)' }} />
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <img title={t.level} src={t.level === 'box' ? ic_box : t.level === 'community' ? ic_community : ic_empty} alt="" style={{ filter: 'invert(100%)', marginRight: '.5rem', marginLeft: 0 }} />
                                        <Tag {...t} name={
                                            <div>
                                                {t.domain_name
                                                    ? <small>{t.domain_name}</small>
                                                    : ''}
                                                <div>
                                                    {t.name}
                                                </div>
                                            </div>
                                        } />
                                    </div>
                                </Button>
                            })}

                            <label style={{ display: 'flex', alignItems: 'center' }}>
                                <div>Hidden tags</div>
                                <div style={{ flex: 1 }}></div>
                                <Button title="Show all" onClick={() => { this.setState({ shown_tags: this.state.tags }) }}>
                                    <img alt="" src={ic_eye} style={{ filter: 'invert(100%)', margin: 0 }} />
                                </Button>
                            </label>

                            {this.state.tags
                                .filter((t) => this.state.includeTemplates || t.domain_type !== 'template')
                                .filter((t) => !this.state.shown_tags.find(st => st.uid === t.uid))
                                .map((t, idx) => {
                                    return <Button title="Show this tag" key={idx} onClick={() => {
                                        this.setState({
                                            shown_tags: [...this.state.shown_tags, t]
                                        })
                                    }} className={t.domain_name ? `compact` : ``}>
                                        <img alt="" src={ic_eye} style={{ filter: 'invert(100%)' }} />
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <img title={t.level} src={t.level === 'box' ? ic_box : t.level === 'community' ? ic_community : ic_empty} alt="" style={{ filter: 'invert(100%)', marginRight: '.5rem', marginLeft: 0 }} />
                                            <Tag {...t} name={
                                                <div>
                                                    {t.domain_name
                                                        ? <small>{t.domain_name}</small>
                                                        : ''}
                                                    <div>
                                                        {t.name}
                                                    </div>
                                                </div>
                                            } />
                                        </div>
                                    </Button>
                                })}
                        </div>
                        {this.props.data
                            ? <label>
                                <Button onClick={this.onRemovePreset} style={{ margin: 'auto' }} bsStyle="danger">Delete custom filter</Button>
                            </label>
                            : <label>
                                <Button onClick={this.onSavePreset} style={{ margin: 'auto' }} bsStyle="primary">Save as custom filter</Button>
                            </label>
                        }
                    </div>
                </div>
            </div>
        )
    }
}

export default WebSocketClient.withWebSocket(DashboardByTag, () => [WebSocketClient.messageTypes.box_status])
