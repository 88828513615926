import React from 'react'
import { Button } from '../UI/Toolkit'
import Modale from '../UI/ModaleComponent'
import ApiService from '../../core/ApiService'

import ic_win from '../../img/ic_os_windows-white.svg'
import ic_tux from '../../img/ic_os_linux-white.svg'

import Icon from '../UI/FileIcon'

const Windows = 'windows'
const Linux = 'linux'


class BoxUploadPackage extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            ...props,
            showModal: false,
            upload_progress: 0,
            fileList: {},
            buttonDisabled: true
        }

        this.folderuploader = {}
    }

    componentDidMount() {
        this.initFolderUpload()
    }

    componentWillReceiveProps(newProps) {
        this.setState({
        })
    }

    onPublish = () => {
        this.setState({ buttonDisabled: true, isLoading: true, upload_progress: 0 })
        let versions = this.state.updatePackage
        const version = String(((versions[Windows] || versions[Linux] || {}).MediaBox || {}).version).trim()
        const flavour = (Object.assign((versions[Windows] || {}), (versions[Linux] || {})).MediaBox || {}).flavour
        const versionFlavour = [version, flavour].filter(x => x).join('-')
        ApiService.checkVersionCanbBePublished(versionFlavour).then((response) => {
            if (response) {
                alert(`ERROR!\nVersion ${versionFlavour} already exists. Please import another update package.`)
                this.setState({ updatePackage: null, buttonDisabled: false, isLoading: false, fileList: {} })
                return

            } else {
                let fd = new FormData()
                fd.set('version_code', versionFlavour)
                fd.set('json', JSON.stringify(versions));

                [Windows, Linux].forEach((OS) => {
                    if (this.state.fileList[OS]) {
                        this.state.fileList[OS].forEach(file => {
                            fd.append(`${OS}_${file.name}`, file)
                        })
                    }
                })

                const onProgress = (progressEvent) => {
                    const percent = (progressEvent.loaded * 100) / progressEvent.total
                    document.dispatchEvent(new CustomEvent('fileTransferInProgress'))
                    this.setState({ upload_progress: percent })
                }

                ApiService.publishVersionPackage(fd, onProgress).then(response => {
                    if (response.success) {
                        this.setState({ updatePackage: null, buttonDisabled: false, isLoading: false, showModal: false, fileList: {}, upload_progress: 0 }, () => {
                            this.props.reload()
                        })
                    } else {
                        alert(response.err)
                    }
                })
            }
        })
    }

    onCancelPublish = () => {
        this.setState({ updatePackage: null, buttonDisabled: false, isLoading: false, showModal: false, fileList: {}, upload_progress: 0 })
    }

    open = () => { this.setState({ showModal: true }) }

    handleFolderInput = (event, OS) => {
        /* eslint-disable */
        let nbVersions = 0
        let versionsIniFile = undefined
        for (const file of event.target.files) {
            if (file.name === 'versions.ini') {
                versionsIniFile = file
                nbVersions++
            }
        }

        if (!nbVersions) {
            alert('The file versions.ini is missing, aborting.')
            this.folderuploader[OS].value = ''
            return
        } else if (nbVersions > 1) {
            alert('Multiple versions.ini files, aborting.')
            this.folderuploader[OS].value = ''
            return
        } else {
            this.readIniFile(versionsIniFile, OS, Array.from(event.target.files))
        }
    }

    readIniFile = (versionsIniFile, OS, files) => {
        let reader = new FileReader();

        reader.onload = ((theFile) => {
            return (e) => {
                let pattern = /\[([^\]]+)\]$([^\[]+)/gm
                let match;
                let versions = {}
                while ((match = pattern.exec(e.target.result)) !== null) {
                    versions[match[1]] = {}
                    match[2].split('\n').forEach(x => {
                        let row = x.split('=')
                        if (row.length === 2) {
                            versions[match[1]][row[0].replace(/\\r/gmi, '')] = row[1].replace(/\\r/gmi, '').trim()
                        }
                    })
                }

                if (this.state.updatePackage) {
                    if (!this.validateIniMatch(OS, versions)) {
                        this.folderuploader[OS].value = ''
                        this.folderuploader[OS].value = ''
                        let fileList = this.state.fileList || {}
                        fileList[OS] = null
                        this.setState({ fileList })
                        alert('Versions.ini does not match. Aborting.')
                        return false
                    }
                }

                if (!versions.MediaBox || !versions.MediaBox.version) {
                    alert('MediaBox project version not found, aborting.')
                    this.setState({ updatePackage: null })
                    return
                } else {
                    let fileList = this.state.fileList || {}
                    fileList[OS] = files
                    this.setState({ fileList })
                    this.folderuploader[OS].value = ''
                    const updatePackage = this.state.updatePackage || {}
                    updatePackage[OS] = versions
                    this.setState({ updatePackage })
                }

                this.validatePackage()

            };
        })(versionsIniFile);

        // Read in the image file as a data URL.
        reader.readAsText(versionsIniFile);
    }

    validateIniMatch = (OS, newIni) => {
        return Object.keys(this.state.updatePackage).filter(k => {
            const p = this.state.updatePackage[k]
            return k !== OS && p.MediaBox && p.MediaBox.version !== newIni.MediaBox.version
        }).length == 0
    }

    validatePackage = () => {
        let success = (
            this.state.updatePackage &&
            (this.state.fileList[Windows] || this.state.fileList[Linux])
        ) || (
                !this.state.updatePackage && !this.state.fileList[Windows] && !this.state.fileList[Linux]
            )

        this.setState({ buttonDisabled: !success })
    }

    initFolderUpload = () => {
        setTimeout(() => {
            if (this.folderuploader[Windows]) {
                this.folderuploader[Windows].directory = true
                this.folderuploader[Windows].webkitdirectory = true
                this.folderuploader[Windows].mozdirectory = true
            }
            if (this.folderuploader[Linux]) {
                this.folderuploader[Linux].directory = true
                this.folderuploader[Linux].webkitdirectory = true
                this.folderuploader[Linux].mozdirectory = true
            }
        }, 200)
    }

    render() {
        let versions = (this.state.updatePackage || {})
        const version = ((versions[Windows] || versions[Linux] || {}).MediaBox || {}).version
        const flavour = (Object.assign((versions[Windows] || {}), (versions[Linux] || {})).MediaBox || {}).flavour
        const versionFlavour = [version, flavour].filter(x => x).join('-')
        return (
            <div>
                <input type="file" ref={(input) => { this.folderuploader[Windows] = input }} style={{ display: 'none' }} onChange={(e) => { this.handleFolderInput(e, Windows) }} />
                <input type="file" ref={(input) => { this.folderuploader[Linux] = input }} style={{ display: 'none' }} onChange={(e) => { this.handleFolderInput(e, Linux) }} />

                <Modale show={this.state.showModal}>
                    <Modale.Header>
                        <div>
                            {this.state.updatePackage ?
                                <p>Version from INI file : <b>{versionFlavour}</b></p>
                                : <p>Please import at least one update package below</p>
                            }
                        </div>
                    </Modale.Header>
                    <Modale.Body style={{ width: '720px' }}>

                        <div style={{ display: 'flex', flexDirection: 'row', marginBottom: '2rem' }}>
                            <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
                                <div style={{ height: '300px', overflow: 'auto', width: '100%', borderRadius: '2px', border: '1px solid rgba(0,0,0,.15)' }}>
                                    {this.state.fileList && this.state.fileList[Windows]
                                        ? this.state.fileList[Windows].map((item, index) => {
                                            return <div style={{ padding: '.33rem .66rem' }} key={index}>
                                                <Icon for={item.name} key={index} /> {item.name}
                                            </div>
                                        })
                                        : <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', width: '100%', height: '100%', opacity: .5, padding: '2rem', textAlign: 'center', background: 'rgba(0,0,0,0.1)' }}>
                                            <img src={ic_win} alt="" style={{ height: '5rem', width: '5rem', opacity: .5, filter: 'invert(100%)' }} />
                                            <br />
                                            For Windows packages, please import the Patch folder containing versions.ini and the updater
                                        </div>
                                    }
                                </div>
                                <Button disabled={this.state.isLoading} onClick={() => { this.folderuploader[Windows].click() }}>
                                    Browse for Windows Package...
                                </Button>
                            </div>
                            &nbsp;
                            &nbsp;
                            <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
                                <div style={{ height: '300px', overflow: 'auto', width: '100%', borderRadius: '2px', border: '1px solid rgba(0,0,0,.15)' }}>
                                    {this.state.fileList && this.state.fileList[Linux]
                                        ? this.state.fileList[Linux].map((item, index) => {
                                            return <div style={{ padding: '.33rem .66rem' }} key={index}>
                                                <Icon for={item.name} key={index} /> {item.name}
                                            </div>
                                        })
                                        : <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', width: '100%', height: '100%', opacity: .5, padding: '2rem', textAlign: 'center', background: 'rgba(0,0,0,0.1)' }}>
                                            <img src={ic_tux} alt="" style={{ height: '5rem', width: '5rem', opacity: .5, filter: 'invert(100%)' }} />
                                            <br />
                                            For Linux packages, please import the Patch folder containing versions.ini and docker images
                                        </div>
                                    }
                                </div>
                                <Button disabled={this.state.isLoading} onClick={() => { this.folderuploader[Linux].click() }}>
                                    Browse for Linux Package...
                                </Button>
                            </div>
                        </div>
                        {!this.state.isLoading && this.state.fileList && ((this.state.fileList[Linux] && !this.state.fileList[Windows]) || (!this.state.fileList[Linux] && this.state.fileList[Windows])) &&
                            <div style={{ background: '#ffc12c', padding: '1rem .5rem', textAlign: 'center' }}>
                                <b>Be careful : </b> You have not (yet ?) selected an update folder for <b><i>each</i></b> target operating systems.
                                <br />
                                <small>This is allowed and will work, but you won't be able to upload the same version for the other platform without removing this package first.</small>
                            </div>
                        }
                        {!this.state.isLoading && this.state.fileList && this.state.fileList[Linux] && this.state.fileList[Windows] && JSON.stringify(this.state.fileList[Linux]) !== JSON.stringify(this.state.fileList[Windows]) &&
                            <div style={{ background: '#adf68d', padding: '1rem .5rem', textAlign: 'center' }}>
                                <b>You're ready to go !</b> Windows and Linux update packages match each other.
                            </div>
                        }
                        {!this.state.isLoading && this.state.fileList && this.state.fileList[Linux] && this.state.fileList[Windows] && JSON.stringify(this.state.fileList[Linux]) === JSON.stringify(this.state.fileList[Windows]) &&
                            <div style={{ background: '#ff8167', padding: '1rem .5rem', textAlign: 'center' }}>
                                <b>Something seems wrong...</b> You have selected the <u>exact same files</u> for each OS. Please double check.
                            </div>
                        }
                    </Modale.Body>
                    <Modale.Footer>
                        <Button onClick={() => { this.onCancelPublish() }}>Cancel</Button>
                        <div style={{ flex: 1, display: 'flex', alignItems: 'center', padding: '0 2rem' }}>
                            {this.state.isLoading &&
                                <div className="package-upload-bar-wrapper">
                                    <div className="package-upload-bar" style={{ width: `${this.state.upload_progress}%` }}></div>
                                </div>
                            }
                        </div>
                        <Button disabled={this.state.buttonDisabled} onClick={() => { this.onPublish() }} bsStyle="primary">Publish package</Button>
                    </Modale.Footer>
                </Modale>
            </div>
        )
    }
}

export default BoxUploadPackage
