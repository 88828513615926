import React from 'react'
import TernaryBox from '../../Fields/TernaryBox'

function ListCounter(params) {
    params = JSON.parse(params || '{}')

    function handleChange(e) {
        if (typeof e.target.checked === 'undefined') {
            return ({
                operator: 'isNull',
                value: undefined
            })
        }
        if (e.target.checked) {
            return ({
                operator: 'isNull',
                value: false
            })
        } else {
            return ({
                operator: 'isNull',
                value: true
            })
        }
    }

    function createCheckbox(caption, onChange, index, value) {
        return (
            <TernaryBox key={index} name={index} title={caption} checked={value}
                onChange={(e) => onChange(handleChange(e))} />
        )
    }

    function setWidgetColorFromStyle(data, style) {
        switch (style) {
            case 'nocolor':
                return ['rgba(0,0,0,0.03)', '#555']
            default:
                return data > 0 ? ['#D44', '#FFF'] : ['#4A4', '#FFF']
        }
    }

    return {
        Cell: (cell) => {
            let data = []
            if (cell.value) {
                data = JSON.parse(cell.value)
            }

            const nbItems = data.length
            const [backcolor, frontcolor] = setWidgetColorFromStyle(nbItems, params.style)
            const title = data.join('\r')
            return (
                <div style={{
                    cursor: 'help',
                    fontWeight: 'bold',
                    backgroundColor: backcolor,
                    textAlign: 'center',
                    height: '1.5rem',
                    lineHeight: '1.5rem',
                    borderRadius: '3px',
                    width: (nbItems.toString().length + 1) + 'ch',
                    minWidth: '1.5rem',
                    display: 'block',
                    margin: 'auto',
                    fontSize: '1.3rem',
                    color: frontcolor
                }} title={title}>
                    {nbItems}
                </div>
            )
        },

        Filter: ({ filter, onChange }) => {
            return createCheckbox('', onChange, 0, filter && filter.value.value)
        }
    }
}

export default ListCounter