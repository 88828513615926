import React from 'react'

import './index.css'

class Tag extends React.Component {

    static getContrastYIQ = (hexcolor) => {
        if (!hexcolor) { return 'black' }
        hexcolor = (hexcolor).replace("#", "");
        var r = parseInt(hexcolor.substr(0, 2), 16);
        var g = parseInt(hexcolor.substr(2, 2), 16);
        var b = parseInt(hexcolor.substr(4, 2), 16);
        var yiq = ((r * 299) + (g * 587) + (b * 114)) / 1000;
        return (yiq >= 128) ? 'black' : 'white';
    }

    render() {
        const textColor = this.props.textcolor || (this.props.color ? Tag.getContrastYIQ(this.props.color) : 'inherit')
        return <span className='tagComponent' title={this.props.description} style={{ color: textColor, background: this.props.background || this.props.color }}>
            {this.props.name}
        </span>
    }
}

export default Tag