const routes = {
    auth: {
        method: 'post',
        path: '/auth'
    },
    boxEvents: {
        method: 'get',
        path: '/boxes/{id}/events/{nbDays}'
    },
    boxMonitoring: {
        method: 'get',
        path: '/monitoring/boxes'
    },
    boxPosition: {
        method: 'get',
        path: '/monitoring/boxes/position'
    },
    boxUpdate: {
        method: 'put',
        path: '/boxes/{uid}'
    },
    boxes: {
        method: 'get',
        path: '/boxes'
    },
    boxDetails: {
        method: 'get',
        path: '/boxes/{id}'
    },
    boxServices: {
        method: 'get',
        path: '/boxes/{id}/services'
    },
    boxServiceRestart: {
        method: 'put',
        path: '/boxes/{id}/services/{name}'
    },
    boxStatus: {
        method: 'get',
        path: '/boxes/{id}/status'
    },
    createCommunity: {
        method: 'post',
        path: '/communities'
    },
    communityBoxes: {
        method: 'get',
        path: '/communities/{uid}/boxes'
    },
    communityTemplates: {
        method: 'get',
        path: '/communities/{uid}/templates'
    },
    getUserPreferences: {
        path: '/users/preferences/{component}',
        method: 'get'
    },
    setUserPreferences: {
        path: '/users/preferences/{component}',
        method: 'put'
    },
    clearUserPreferences: {
        path: '/users/preferences/{component}',
        method: 'delete'
    },
    createUser: {
        method: 'post',
        path: '/users/'
    },
    getBoxEventsList: {
        method: 'get',
        path: '/boxEventsList'
    },
    getCommunities: {
        method: 'get',
        path: '/communities'
    },
    getCommunitiesList: {
        method: 'get',
        path: '/communitiesList'
    },
    getVersion: {
        method: 'get',
        path: '/version'
    },
    getModifications: {
        method: 'get',
        path: '/admin/modifications'
    },
    getUsersList: {
        method: 'get',
        path: '/usersList'
    },
    getTeamsList: {
        method: 'get',
        path: '/teamsList'
    },
    getUsers: {
        method: 'get',
        path: '/users'
    },
    lastTransfers: {
        method: 'get',
        path: '/boxes/{id}/lastTransfers'
    },
    logger: {
        method: 'post',
        path: '/log/{level}'
    },
    getConfig: {
        method: 'get',
        path: '/config/{key}'
    },
    pfmReloadConfig: {
        method: 'post',
        path: '/admin/export_configuration'
    },
    removeCommunity: {
        method: 'delete',
        path: '/communities/{uid}'
    },
    removeUser: {
        method: 'delete',
        path: '/users/{uid}'
    },
    renewToken: {
        method: 'post',
        path: '/auth/renew'
    },
    serviceRestart: {
        method: 'post',
        path: '/serviceMgmt'
    },
    serviceStatus: {
        method: 'get',
        path: '/serviceMgmt'
    },
    templates: {
        method: 'get',
        path: '/templates'
    },
    templateTypes: {
        method: 'get',
        path: '/templateTypes'
    },
    templateUpload: {
        method: 'post',
        path: '/templates'
    },
    transfer: {
        method: 'get',
        path: '/transfers/{itemId}'
    },
    transferUpdateFile: {
        method: 'put',
        path: '/transfers/{itemId}/files/{fileId}/{action}'
    },
    transferUpdateItem: {
        method: 'put',
        path: '/transfers/{itemId}/{action}'
    },
    transfers: {
        method: 'get',
        path: '/transfers'
    },
    updateCommunity: {
        method: 'put',
        path: '/communities/{uid}'
    },
    updateUser: {
        method: 'put',
        path: '/users/{uid}'
    },
    getU2FKeystore: {
        method: 'get',
        path: '/users/{uid}/u2fkeys'
    },
    getUserPermissions: {
        method: 'get',
        path: '/users/{uid}/permissions'
    },
    delU2FKeystore: {
        method: 'delete',
        path: '/users/{uid}/u2fkeys/{keyId}'
    },
    updateU2FKeystore: {
        method: 'post',
        path: '/users/{uid}/u2fkeys/{keyId}'
    },
    u2fRegistrationChallenge: {
        method: 'get',
        path: '/users/{uid}/u2f/registration'
    },
    u2fRegistration: {
        method: 'post',
        path: '/users/{uid}/u2f/registration'
    },
    u2fAuthenticationChallenge: {
        method: 'get',
        path: '/users/{uid}/u2f/auth'
    },
    u2fAuthenticationChallengeMail: {
        method: 'get',
        path: '/users/{uid}/u2f/auth?method=email'
    },
    u2fAuthenticationVerificationMail: {
        method: 'post',
        path: '/users/{uid}/u2f/auth?method=email'
    },
    u2fAuthentication: {
        method: 'post',
        path: '/users/{uid}/u2f/auth'
    },
    getTraces: {
        method: 'get',
        path: '/traces'
    },
    getTrace: {
        method: 'get',
        path: '/traces/{trace_uid}'
    },
    createVersionPackage: {
        method: 'post',
        path: '/updatepackages'
    },
    getUpdatePackagesList: {
        method: 'get',
        path: '/updatepackages/list'
    },
    checkVersionCanbBePublished: {
        method: 'get',
        path: '/updatepackages/{version_code}'
    },
    updatePackages: {
        method: 'get',
        path: '/updatepackages'
    },
    postAdminUpdate: {
        method: 'post',
        path: '/admin/updates'
    },
    postAdminUpdateCommand: {
        method: 'post',
        path: '/admin/updates/{uid}'
    },
    rollingUpdatesList: {
        method: 'get',
        path: '/updates/list'
    },
    rollingUpdate: {
        method: 'get',
        path: '/updates/{uid}'
    },
    disconnectBox: {
        method: 'put',
        path: '/boxes/{uid}/disconnect'
    },
    createTeam: {
        method: 'post',
        path: '/teams/'
    },
    updateTeam: {
        method: 'put',
        path: '/teams/{id}'
    },
    removeTeam: {
        method: 'delete',
        path: '/teams/{id}'
    },
    getCommunitiesForTeam: {
        method: 'get',
        path: '/teams/{id}/communities'
    },
    getUsersForTeam: {
        method: 'get',
        path: '/teams/{id}/users'
    },
    getPreferencesForTeam: {
        method: 'get',
        path: '/teams/{id}/preferences'
    },
    getPermissionsForTeam: {
        method: 'get',
        path: '/teams/{id}/permissions'
    },
    deletePackage: {
        method: 'delete',
        path: '/updatepackages/{uid}'
    },
    getPermissions: {
        method: 'get',
        path: '/teams/permissions'
    },
    getTunnelService: {
        method: 'get',
        path: '/boxes/{uid}/tunnels/services/{service}'
    },
    openTunnelService: {
        method: 'post',
        path: '/boxes/{uid}/tunnels/services/{service}'
    },
    closeTunnelService: {
        method: 'delete',
        path: '/boxes/{uid}/tunnels/services/{service}'
    },
    saveBoxNotes: {
        method: 'post',
        path: '/boxes/{uid}/notes'
    },
    getDockerContainers: {
        method: 'get',
        path: '/docker/containers'
    },
    restartDockerContainerById: {
        method: 'get',
        path: '/docker/containers/{id}/restart'
    },
    getDockerLogById: {
        method: 'get',
        path: '/docker/containers/{id}/logs'
    },
    setPFMConfig: {
        method: 'post',
        path: '/admin/PFMConfig'
    },
    getPFMConfig: {
        method: 'get',
        path: '/admin/PFMConfig'
    },
    pfmSendTestNotification: {
        method: 'post',
        path: '/admin/test_notifications'
    },
    pfmSendMail: {
        method: 'post',
        path: '/admin/send_mail'
    },
    pfmSendSMS: {
        method: 'post',
        path: '/admin/send_sms'
    },
    getTags: {
        method: 'GET',
        path: '/admin/tags'
    },
    createTag: {
        method: 'POST',
        path: '/admin/tags'
    },
    updateTag: {
        method: 'POST',
        path: '/admin/tags/{uid}'
    },
    removeTag: {
        method: 'DELETE',
        path: '/admin/tags/{uid}'
    },
    getCommunityTags: {
        method: 'GET',
        path: '/communities/{uid}/tags'
    },
    getTemplateCommunities: {
        method: 'GET',
        path: '/templates/{uid}/communities'
    },
    getTagsForTemplateType: {
        method: 'GET',
        path: '/templateTypes/{uid}/tags'
    },
    updateTemplateData: {
        method: 'POST',
        path: '/templates/{uid}'
    },
    requestStats: {
        method: 'POST',
        path: '/admin/stats'
    },
    setConfig: {
        method: 'POST',
        path: '/admin/config'
    },
    getBoxPasswords: {
        method: 'GET',
        path: '/boxes/{box_uid}/passwords'
    },
    putBoxPassword: {
        method: 'POST',
        path: '/boxes/{box_uid}/passwords'
    },
    removeBoxPassword: {
        method: 'DELETE',
        path: '/boxes/{box_uid}/passwords/{password_id}'
    },
    getSharedFiles: {
        method: 'GET',
        path: '/shared_files'
    },
    uploadSharedFiles: {
        method: 'POST',
        path: '/shared_files'
    },
    deleteSharedFile: {
        method: 'DELETE',
        path: '/shared_files/{file_uid}'
    },
    modifySharedFile: {
        method: 'PATCH',
        path: '/shared_files/{file_uid}'
    },
    downloadSharedFile: {
        method: 'GET',
        path: '/shared_files/{file_uid}/load'
    },
    transferTest: {
        method: 'POST',
        path: '/boxes/{box_id}/transfer_test'
    },
    uploadSpeeds: {
        method: 'get',
        path: '/boxes/{id}/upload_speeds/{nbDays}'
    },
    downloadSpeeds: {
        method: 'get',
        path: '/boxes/{id}/download_speeds/{nbDays}'
    },
}
module.exports = routes
