import './index.css'

import ReactTable, { ReactTableDefaults } from 'react-table'

import ApiService from '../../core/ApiService'
import BitSet from './widgets/BitSet'
import { Button } from '../UI/Toolkit'
import ColorList from './widgets/ColorList'
import DateTime from './widgets/DateTime'
import DropDown from '../Dropdown'
import ExportCsv from './ExportCsv'
import Helpers from '../../core/Helpers'
import IntegerWidget from './widgets/Integer'
import ListCounter from './widgets/ListCounter'
import Logger from '../../core/Logger'
import ModalColumnChoice from './ModalColumnChoice'
import Modality from './widgets/Modality'
import PropTypes from 'prop-types'
import React from 'react'
import StringList from './widgets/StringList'
import StringWidget from './widgets/StringWidget'
import WorkflowStatus from './widgets/WorkflowStatus'
import checkboxHOC from 'react-table/lib/hoc/selectTable'
import moment from 'moment-timezone'

import ic_filter from '../../img/ic_filter.svg'
import ic_cog from '../../img/ic_cog.svg'

let TableComponent = ReactTable

const logger = new Logger('components/Table')

export default class Table extends React.PureComponent {
    constructor(props) {
        super(props)
        this.state = {
            userPreferences: null,
            data: [],
            selection: this.props.selected || new Set(),
            selectAll: false,
            showFilters: this.props.forceDisplayFilters || this.props.showFilters || false
        }
        this.table_state = {}
        this.reactTable = null

        if (this.props.allowSelection) TableComponent = checkboxHOC(ReactTable)

        this.save = this.save.bind(this)
        this.initUserPreferences = this.initUserPreferences.bind(this)
        this.updateUserPreferences = this.updateUserPreferences.bind(this)
        this.getUserPreferences = this.getUserPreferences.bind(this)
        this.updateColumnFilter = this.updateColumnFilter.bind(this)
        this.overrides = this.overrides.bind(this)
        this.sortHandle = this.sortHandle.bind(this)
        this.resizedHandle = this.resizedHandle.bind(this)
        this.pageChangeHandle = this.pageChangeHandle.bind(this)
        this.pageSizeChangeHandle = this.pageSizeChangeHandle.bind(this)
        this.toggleSelection = this.toggleSelection.bind(this)
        this.toggleAll = this.toggleAll.bind(this)
        this.isSelected = this.isSelected.bind(this)
        this.getCSV = this.getCSV.bind(this)
        this.getData = this.getData.bind(this)
        this.forceRefresh = this.forceRefresh.bind(this)
        this.fetchHandle = this.fetchHandle.bind(this)
        this.dropFilters = this.dropFilters.bind(this)
        this.toggleFilters = this.toggleFilters.bind(this)
        this.onColumnChange = this.onColumnChange.bind(this)
    }

    async componentDidMount() {
        await this.getUserPreferences(this.props.defaultConfig)
        Object.assign(ReactTableDefaults, {
            previousText: 'prev',
            nextText: 'next',
            loadingText: 'loading',
            noDataText: 'no data',
            pageText: 'page',
            ofText: 'of',
            rowsText: 'rows'
        })

        const tbody = this.refs.tableWrapper.querySelector('.rt-tbody')
        tbody.addEventListener('scroll', this.onColumnChange, true)
    }

    onColumnChange() {
        const tbody = this.refs.tableWrapper.querySelector('.rt-tbody')
        const theads = this.refs.tableWrapper.querySelectorAll('.rt-thead')
        const rows = this.refs.tableWrapper.querySelectorAll('.rt-tr-group')

        Array.from(theads).forEach((thead) => {
            thead.style.minWidth = 'auto'
            thead.style.overflow = 'hidden'
            thead.scrollLeft = tbody.scrollLeft
        })
        Array.from(rows).forEach((row) => {
            row.style = `width: ${theads[0].scrollWidth}px!important;`
        })
    }

    async componentWillReceiveProps(nextProps) {
        if (nextProps.selected && nextProps.selected !== this.state.selection) {
            this.setState({
                selection: nextProps.selected
            })
        }
        if (this.props.uid === nextProps.uid) {
            return
        }
        await this.getUserPreferences(nextProps.defaultConfig)
    }

    save() {
        clearTimeout(this.saveTimeout)
        this.saveTimeout = setTimeout(() => {
            ApiService.setUserPreferences(this.props.uid, { preferences: this.state.userPreferences })
                .catch((err) => {
                    logger.error(err)
                })
        }, 500)
    }

    initUserPreferences(config) {
        const userPreferences = {}
        userPreferences.sort = config.sort
        userPreferences.pageSize = config.pageSize
        userPreferences.page = 0
        userPreferences.columns = config.columns.map((column, index) => {
            return {
                id: column.id,
                show: column.show,
                width: column.width,
                position: column.position || index
            }
        })
        userPreferences.filters = []
        return userPreferences
    }

    updateUserPreferences(userPreferences) {
        this.setState({
            userPreferences: userPreferences
        }, () => {
            this.save()
            this.onColumnChange()
        })
    }

    async getUserPreferences(defaultConfig) {
        const urlSearchParams = new URLSearchParams(window.location.search)
        //console.log(urlSearchParams.get(this.props.urlPagination))
        return ApiService.getUserPreferences(this.props.uid)
            .then((json) => {
                const userPreferences = this.overrides(defaultConfig, json || {})

                this.setState({
                    defaultConfig: defaultConfig,
                    userPreferences: {
                        sort: userPreferences.sort,
                        pageSize: userPreferences.pageSize,
                        page: this.props.urlPagination ? parseInt((urlSearchParams.get(this.props.urlPagination) || 1), 10) - 1 : userPreferences.page,
                        columns: userPreferences.columns.map((column, index) => {
                            return {
                                id: column.id,
                                show: column.show,
                                width: column.width,
                                position: column.position || index
                            }
                        }),
                        filters: (Array.isArray(this.props.overrideFilters) && this.props.overrideFilters.length)
                            ? this.props.overrideFilters
                            : userPreferences.filters,
                    },
                    showFilters: this.props.forceDisplayFilters || this.state.showFilters || (userPreferences.filters || []).length,
                    data: []
                })
            })
            .catch((err) => {
                logger.error(err)
            })
    }

    updateColumnFilter(columns) {
        const userPreferences = Helpers.clone(this.state.userPreferences)
        userPreferences.filters = []
        columns.forEach((column) => {
            if (column.value) {
                userPreferences.filters.push({
                    id: column.id,
                    value: column.value
                })
            }
        })
        this.updateUserPreferences(userPreferences)
    }

    overrides(defaultConfig, userPreferences) {
        const config = {}
        config.columns = defaultConfig.columns.map((column, index) => {
            let spec = {}
            switch (column.type) {
                case Table.types.WORKFLOW_STATUS:
                    spec = WorkflowStatus
                    break
                case Table.types.DATETIME:
                    spec = DateTime(column.params)
                    break
                case Table.types.INTEGER:
                    spec = IntegerWidget()
                    break
                case Table.types.BIT_SET:
                    spec = new BitSet(column.params)
                    break
                case Table.types.LIST_COUNTER:
                    spec = new ListCounter(column.params)
                    break
                case Table.types.STRING:
                    spec = new StringWidget(column.params)
                    break
                case Table.types.STRING_LIST:
                    spec = new StringList(column.params)
                    break
                case Table.types.MODALITY:
                    spec = Modality
                    break
                case Table.types.COLOR_LIST:
                    spec = ColorList
                    break
                default:
                    spec = {}
                    break
            }
            try {
                return Object.assign(
                    spec,
                    {
                        accessor: column.id,
                        Header: column.title
                    },
                    column,
                    {
                        show: userPreferences.columns[index].show,
                        width: userPreferences.columns[index].width
                    })
            } catch (ex) {
                return Object.assign(
                    spec,
                    {
                        accessor: column.id,
                        Header: column.title
                    },
                    column
                )
            }
        })
        return Object.assign({}, defaultConfig, userPreferences, config)
    }

    sortHandle(newSorted, column, shiftKey) {
        const preferences = Helpers.clone(this.state.userPreferences)
        preferences.sort = {
            id: newSorted[0].id,
            desc: newSorted[0].desc
        }

        this.updateUserPreferences(preferences)
    }

    resizedHandle(newResized, e) {
        const preferences = Helpers.clone(this.state.userPreferences)
        newResized.forEach((resize) => {
            const columnIndex = preferences.columns.findIndex((col) => {
                return col.id === resize.id
            })
            preferences.columns[columnIndex].width = resize.value
        })

        this.updateUserPreferences(preferences)
    }

    pageChangeHandle(pageIndex) {
        const userPreferences = Helpers.clone(this.state.userPreferences)
        userPreferences.page = pageIndex
        this.updateUserPreferences(userPreferences)
        if (this.props.onPageChanged) {
            this.props.onPageChanged(pageIndex)
        }
    }

    pageSizeChangeHandle(pageSize, pageIndex) {
        const userPreferences = Helpers.clone(this.state.userPreferences)
        userPreferences.pageSize = pageSize
        this.updateUserPreferences(userPreferences)
    }

    _toggleSelection(key, row, selected) {
        const currentSelection = this.state.selection
        if (selected) {
            currentSelection.add(key)
        } else {
            currentSelection.delete(key)
        }
        this.setState({
            selection: currentSelection,
            selectAll: !!!this.state.data.find((row) => !this.isSelected(row[this.props.keyField], currentSelection))
        }, () => {
            this.props.onSelection(row, selected)
        })
    }

    toggleSelection(key, shift, row) {
        if (this.isSelected(key)) {
            this._toggleSelection(key, row, false)
        } else {
            this._toggleSelection(key, row, true)
        }
    }

    toggleAll() {
        const selectAll = this.state.selectAll ? false : true
        const wrappedInstance = this.reactTable.getWrappedInstance()
        const currentRecords = wrappedInstance.getResolvedState().sortedData
        currentRecords.forEach((item) => {
            this._toggleSelection(item[this.props.keyField], item._original, selectAll)
        })
        this.setState({ selectAll })
    }

    isSelected(key, selection = this.state.selection) {
        return selection.has(key)
    }

    dropFilters() {
        const currentUserPreferences = Helpers.clone(this.state.userPreferences)
        const defaultUserPreferences = this.initUserPreferences(Helpers.clone(this.props.defaultConfig))
        currentUserPreferences.filters = defaultUserPreferences.filters
        currentUserPreferences.sort = defaultUserPreferences.sort
        this.setState({
            userPreferences: currentUserPreferences
        })
        this.updateUserPreferences(currentUserPreferences)
    }

    toggleFilters() {
        this.setState({
            showFilters: !!!this.state.showFilters
        })
    }

    getCSV(state) {
        const options = {
            sort: ((state.sorted[0] || {}).desc ? '-' : '') + (state.sorted[0] ? state.sorted[0].id : ''),
            pageSize: 5000
        }
        if (state.defaultFiltered) options.filters = encodeURI(JSON.stringify(state.defaultFiltered))
        return ApiService.fetch(this.props.endPoint, options)
            .then((res) => {
                return res
            })
            .catch((err) => {
                logger.error(err)
                this.setState({
                    //userPreferences: this.initUserPreferences(Helpers.clone(this.props.defaultConfig))
                })
            })
    }

    getData(state) {
        return ApiService.fetch(this.props.endPoint, {
            sort: ((state.sorted[0] || {}).desc ? '-' : '') + (state.sorted[0] ? state.sorted[0].id : ''),
            page: state.page,
            pageSize: state.pageSize,
            filters: this.props.filtered || encodeURI(JSON.stringify(state.defaultFiltered)) || []
        })
            .then((res) => {
                const preferences = Helpers.clone(this.state.userPreferences)
                if (res.pages < preferences.page) {
                    preferences.page = Math.max(res.pages - 1, 0)
                    this.updateUserPreferences(preferences)
                }
                this.setState({
                    data: res.rows,
                    pages: res.pages
                }, () => {
                    if (this.props.onRows) this.props.onRows(res.rows)
                })
                return res
            })
            .catch((err) => {
                logger.error(err)
                this.setState({
                    //userPreferences: this.initUserPreferences(Helpers.clone(this.props.defaultConfig))
                })
            })
    }

    forceRefresh() {
        this.fetchHandle(this.table_state, this.reactTable, 0, true)
    }

    fetchHandle(state, instance, refreshTime = 0, force = false) {
        if (!this.state.userPreferences) return
        const stateDefaultFiltered = JSON.stringify(state.defaultFiltered)
        const tablestateDefaultFiltered = JSON.stringify(this.table_state.defaultFiltered || [])
        let resetColorMap = false
        if (!force) {
            const stateSorted = JSON.stringify(state.sorted)
            const tableStateSorted = JSON.stringify(this.table_state.sorted)
            if (JSON.stringify(state.columns) === JSON.stringify(this.table_state.columns)
                && stateSorted === tableStateSorted
                && stateDefaultFiltered === tablestateDefaultFiltered
                && state.pageSize === this.table_state.pageSize
                && state.page === this.table_state.page
            ) return
            if (stateSorted !== tableStateSorted
                || stateDefaultFiltered !== tablestateDefaultFiltered) {
                resetColorMap = true
            }
        }
        if (stateDefaultFiltered !== tablestateDefaultFiltered) refreshTime = 250
        this.table_state = {
            columns: state.columns,
            sorted: state.sorted,
            defaultFiltered: state.defaultFiltered,
            pageSize: state.pageSize,
            page: state.page
        }
        clearTimeout(this.fetchTimeout)
        this.fetchTimeout = setTimeout(() => {
            if (resetColorMap) ColorList.reset()
            this.getData(state)
                .catch((err) => {
                    logger.error(err)
                })
        }, refreshTime)
    }

    render() {
        const config = this.overrides(this.props.defaultConfig, this.state.userPreferences)

        let title = null
        if (this.props.title) {
            title = (<h3>{this.props.title}</h3>)
        }

        return (
            <div className="ReactTableWrapper" key={this.state.key} ref="tableWrapper">
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <div style={{ flex: 1 }}>
                        {title}
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Button onClick={this.dropFilters}
                            bsStyle="link">Empty filters</Button>
                        {!this.props.forceDisplayFilters
                            ? <Button onClick={this.toggleFilters}
                                bsStyle="link">
                                <img alt='Filter' title='Show/Hide filters' src={ic_filter} />
                            </Button>
                            : ''
                        }
                        <DropDown label={
                            <img alt='Tools' title='Tools' src={ic_cog} />
                        } bsStyle="link">
                            <ModalColumnChoice
                                update={this.updateUserPreferences}
                                columns={this.props.defaultConfig.columns}
                                userPreferences={config}
                            />
                            <ExportCsv
                                columns={config.columns}
                                getData={() => this.getCSV(this.table_state)}
                                filename={this.props.csvFilename + ' ' + moment().format('lll') + '.csv'}
                            />
                        </DropDown>
                    </div>
                </div>
                <TableComponent
                    manual
                    filterable
                    className={`-striped -highlight ${this.state.showFilters ? 'showFilters' : 'hideFilters'}`}
                    ref={(elem) => this.reactTable = elem}
                    data={this.state.data}
                    columns={config.columns}
                    sorted={[config.sort]}
                    minRows={1}
                    pageSize={config.pageSize}
                    page={config.page}
                    pages={this.state.pages}
                    defaultFiltered={config.filters}
                    onResizedChange={this.resizedHandle}
                    onSortedChange={this.sortHandle}
                    onFilteredChange={this.updateColumnFilter}
                    onPageSizeChange={this.pageSizeChangeHandle}
                    onPageChange={this.pageChangeHandle}
                    onFetchData={this.fetchHandle}
                    keyField={this.props.keyField}
                    selectType={'checkbox'}
                    toggleSelection={this.toggleSelection}
                    toggleAll={this.toggleAll}
                    isSelected={this.isSelected}
                    selectAll={this.state.selectAll}
                    getTdProps={(state, row, column, instance) => {
                        return this.props.getTdProps(state, row, column, instance)
                    }}
                    getTrProps={(state, rowInfo, column) => {
                        if (this.props.getTrProps) {
                            return this.props.getTrProps(state, rowInfo, column)
                        }
                        return () => {
                        }
                    }}
                    pageSizeOptions={[5, 10, 15, 20, 25, 50, 100]}
                />
            </div>
        )
    }
}

Table.propTypes = {
    filter: PropTypes.array,
    uid: PropTypes.string.isRequired,
    defaultConfig: PropTypes.object.isRequired,
    endPoint: PropTypes.object.isRequired,
    csvFilename: PropTypes.string,
    onClick: PropTypes.func,
    allowSelection: PropTypes.bool,
    title: PropTypes.string,
    onSelection: PropTypes.func,
    onRows: PropTypes.func
}

Table.types = {
    WORKFLOW_FOLLOW: 'WORKFLOW_FOLLOW',
    WORKFLOW_STATUS: 'WORKFLOW_STATUS',
    DATETIME: 'DATETIME',
    INTEGER: 'Integer',
    BIT_SET: 'BitSet',
    LIST_COUNTER: 'ListCounter',
    STRING_LIST: 'StringList',
    STRING: 'String',
    MODALITY: 'MODALITY',
    COLOR_LIST: 'ColorList'
}