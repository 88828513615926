import Helpers from '../core/Helpers'
import { Link } from 'react-router-dom'
import { Button } from '../components/UI/Toolkit'
import React, { PureComponent } from 'react'

import './userarea.css'

import ic_logout from '../img/ic_logout-white.svg'

class UserArea extends PureComponent {

    constructor(props) {
        super(props)
        this.state = {
            showModal: false
        }
    }

    render() {
        const background = Helpers.stringToColor(String(Helpers.getCurrentUser().displayName)) + '88'
        const to = '/myprofile'
        const active = (document.location.pathname && document.location.pathname.indexOf(to) === 0 ? 'active' : null)
        return (
            <div id="UserArea">
                <div style={{
                    background: background,
                    borderRadius: '50%',
                    height: '86px',
                    width: '86px',
                    margin: '10px auto 10px auto',
                    minWidth: '86px',
                    boxShadow: 'inset 0 -1pt 2pt rgba(0,0,0,.175)',
                    userSelect: 'none',
                    overflow: 'hidden',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}>
                    <div style={{
                        color: 'rgba(255,255,255,.75)',
                        fontSize: '46px',
                        textShadow: '1px 1px 1px rgba(0,0,0,.33)'
                    }}>

                        {String(Helpers.getCurrentUser().displayName).split(/\s/, 2).map(item => {
                            return item[0]
                        })}
                    </div>
                </div>

                <div id="UserActions" style={{ display: 'flex', width: '100%', position: 'relative' }}>
                    <Link to={to} title="My profile" className={`btn btn-primary ${active}`} style={{ flex: 1, textAlign: 'left', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
                        {String(Helpers.getCurrentUser().displayName)}
                    </Link>
                    <Button type="button" title="Log out" className={`btn btn-primary ${active}`} onClick={() => { this.props.logout() }} style={{ padding: '0!important' }}>
                        <img alt="" src={ic_logout} style={{ pointerEvents: 'none' }} />
                    </Button>
                </div>
            </div>
        )
    }
}

export default UserArea