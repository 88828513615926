import React from 'react'
import Table from '../Table'
import ic_warning from '../../img/ic_warning.svg'

const tablePageKey = 'page'
const supportedParams = [
    {
        name: 'origin.name',
        operator: 'like'
    },
    {
        name: 'destination.name',
        operator: 'like'
    },
    {
        name: 'workflow_data.name',
        operator: 'like'
    },
    {
        name: 'workflow_uid',
        operator: 'like'
    },
    {
        name: 'transfer_item.creation_timestamp',
        operator: 'between'
    },
    {
        name: 'transfer_item.update_timestamp',
        operator: 'between'
    }
]

class TransferList extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            overrideFilters: []
        }

        this.forceRefresh = this.forceRefresh.bind(this)
    }

    forceRefresh() {
        this.table.forceRefresh()
    }

    componentDidMount() {
        // Parse filters in the url
        const urlSearchParams = new URLSearchParams(window.location.search)
        var overrideFilters = []
        supportedParams.forEach(param => {
            const val = urlSearchParams.get(param.name)
            if (val) {
                var filter = {
                    id: param.name,
                    value: {}
                }

                if (param.operator === 'like') {
                    filter.value = {
                        operator: 'like',
                        value: `${val}`
                    }
                } else if (param.operator === 'between') {
                    const dates = val.split('_')
                    filter.value = {
                        operator: 'between',
                        from: dates[0],
                        to: dates[1]
                    }
                }

                overrideFilters.push(filter)
            }
        })

        // Remove all filters from the url
        const page = parseInt(urlSearchParams.get(tablePageKey), 10) || 1
        window.history.replaceState('', document.title, `?${tablePageKey}=${page}`)
        this.setState({ overrideFilters })
    }

    render() {
        return (
            <div style={{ flex: 1, flexDirection: 'column', overflow: 'auto', padding: '1rem' }} className="noCellPadding">
                <Table
                    overrideFilters={this.state.overrideFilters}
                    filter={this.props.filter}
                    ref={table => this.table = table}
                    uid={'transferList'}
                    defaultConfig={this.props.configuration}
                    endPoint={this.props.endPoint}
                    csvFilename="file_transfer"
                    getTdProps={(state, row, column, instance) => {
                        return {
                            onClick: () => {
                                if (row && row.original)
                                    this.props.chooseTransfer(row.original)
                            }
                        }
                    }}
                    getTrProps={(state, rowInfo, column) => {
                        return { className: 'clickable_row' }
                    }}
                    onPageChanged={(page) => {
                        window.history.replaceState('', document.title, `?${tablePageKey}=${(page || 0) + 1}`)
                    }}
                    onRows={this.onRows}
                    forceDisplayFilters={true}
                    urlPagination={tablePageKey}
                    title={<div style={{ display: 'flex', alignItems: 'center', fontSize: '1.25rem' }}><img src={ic_warning} alt="" style={{ width: '1rem', height: '1rem', marginRight: '.5rem' }} /> The dataset will be truncated to the last month if no filter is selected</div>}
                />
            </div>
        )
    }
}

export default TransferList
