import React from 'react'
import DefaultLayout from '../templates/DefaultTemplate'
import TemplatesListContainer from '../components/TemplatesComponent/TemplatesListContainer'

class TemplatesRoute extends React.Component {

    render() {
        return (
            <DefaultLayout {...this.props}>
                <TemplatesListContainer {...this.props} history={this.props.history}/>
            </DefaultLayout>
        )
    }
}

export default TemplatesRoute
