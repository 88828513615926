import React from 'react'

class WorkflowStatusIcon extends React.Component {
    /* i18n OK */

    render() {
        let statusIcon = null
        let title = this.props.status

        switch (this.props.status) {
            case 'Creating':
                statusIcon = 'play-circle'
                title = 'Creating'
                break
            case 'Ready':
                statusIcon = 'ok'
                title = 'Ready'
                break
            case 'Processing':
                statusIcon = 'cog'
                title = 'Processing'
                break
            case 'WaitingUser':
                statusIcon = 'exclamation-sign'
                title = 'Waiting user'
                break
            case 'Ended':
                statusIcon = 'lock'
                title = 'Ended'
                break
            case 'Failed':
                statusIcon = 'remove'
                title = 'Failed'
                break
            case 'Discontinued':
                statusIcon = 'remove'
                title = 'Discontinued'
                break
            default:
                statusIcon = 'times'
                title = 'Undefined'
                break
        }

        return (<img src="" alt={statusIcon} title={title} />)
    }
}

export default WorkflowStatusIcon
