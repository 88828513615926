import React from 'react'
import DefaultLayout from '../templates/DefaultTemplate'
import PFMConfigurationContainer from '../components/PFMConfiguration/PFMConfigurationContainer'

class PFMConfigurationRoute extends React.Component {

    render() {
        return (
            <DefaultLayout {...this.props}>
                <PFMConfigurationContainer {...this.props} history={this.props.history}/>
            </DefaultLayout>
        )
    }
}

export default PFMConfigurationRoute
