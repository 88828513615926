import React from 'react'
import DefaultLayout from '../templates/DefaultTemplate'
import PfStatsComponent from '../components/PfStatsComponent'

class PfStatsRoute extends React.Component {

    render() {
        return (
            <DefaultLayout {...this.props}>
                <PfStatsComponent {...this.props} history={this.props.history} />
            </DefaultLayout>
        )
    }
}

export default PfStatsRoute
