import React, { Component } from 'react'

import { Button } from '../UI/Toolkit'
import Modale from '../UI/ModaleComponent/'
import Version from '../VersionInfo/'
import './index.css'
import logo_emedia from '../../img/logo_emedia-white.svg'
import ApiService from '../../core/ApiService'
import Logger from '../../core/Logger'
import Helpers from '../../core/Helpers'
import logo_yubikey from '../../img/logo_yubikey.svg'
import logo_yubikey_error from '../../img/logo_yubikey_error.svg'
import logo_platform_banner from '../../img/logo_mediavision-color.svg'
import EasterEgg from '../EasterEgg'
import ic_yubikey from '../../img/ic_yubikey.svg'
import ic_close_white from '../../img/ic_close_white.svg'
import ic_edit from '../../img/ic_switch.svg'
import { startAuthentication } from '@simplewebauthn/browser'

const logger = new Logger('routes/Login/index.js')

class LoginComponent extends Component {

    constructor(props) {
        super(props)
        this.state = {
            showModalU2F: false,
            showModalU2FEmailCode: false,
            showModalU2FNotSupported: false,
            u2fErrorMessage: false,
            tmpUser: {},
            tmp2faCode: null,
            tmp2faSession: null,
            lastUserLoggedIn: localStorage.getItem('pf_lastUserLoggedIn') ? JSON.parse(localStorage.getItem('pf_lastUserLoggedIn')) : null,
            security: undefined
        }
    }

    componentDidMount() {
        ApiService.getConfig('security').then((security) => {
            this.setState({
                security
            })
            if (window.localStorage.getItem('pf_session')) {
                this.doRedirect()
            }
            if (this.state.lastUserLoggedIn) {
                document.querySelector('#inputPassword').focus()
            }
        })
    }

    doRedirect = (uid) => {
        let path = '/monitor/boxes'
        let uuid = null
        let query = null
        try {
            query = /returnTo=([^&]+)/.exec(window.location.search)
            uuid = /uuid=([^&]+)/.exec(window.location.search)

            path = query && query[1]
        } catch (ex) {
            console.error(ex)
        }
        if (!path || path === '%2F' || path === '/' || path === 'undefined') path = '/monitor/boxes'

        console.log(`redirectiong to ${path}`)

        if (query && uuid && uuid[1] && uuid[1] === (uid || Helpers.getCurrentUser().uid)) {
            this.props.history.push(decodeURIComponent(path))
        } else if ((!uuid || (uuid && !uuid[1]) || (uuid && uuid[1] === 'undefined')) && (path)) {
            this.props.history.push(decodeURIComponent(path))
        } else {
            this.props.history.push('/monitor/boxes')
        }
    }

    submitHandler = (e) => {
        e.preventDefault()
        this.setState({
            errorMessage: undefined,
            loggingIn: true
        })

        const form = document.querySelector('form')
        const payload = new FormData(form)

        ApiService.auth(payload)
            .then((json) => {
                this.setState({ tmpUser: json })
                if ((this.state.security || {}).u2f && json.uid !== '0') {
                    ApiService.u2fAuthenticationChallenge(json.uid, json.token).then(req => {
                        this.setState({ showModalU2F: true, loggingIn: false })
                        startAuthentication(req.options)
                            .then((signResponse) => {
                                ApiService.u2fAuthentication(json.uid, req.sessionId, signResponse, json.token).then(res => {
                                    if (res.success === true) {
                                        this.setState({ loggedInUser: json })
                                        json.id += ''
                                        window.localStorage.setItem('pf_session', JSON.stringify(json))
                                        window.localStorage.setItem('pf_lastToken', Date.now())
                                        window.localStorage.setItem('pf_tokenExpire', Date.now() + (json.exp || 0))
                                        window.localStorage.setItem('pf_lang', json.lang)
                                        window.localStorage.setItem('pf_lastUserLoggedIn', JSON.stringify({
                                            displayName: json.displayName,
                                            login: json.login
                                        }))
                                        setTimeout(() => {
                                            this.doRedirect(json.uid)
                                        }, 300)
                                    } else {
                                        Helpers.pushNotification({
                                            title: 'Login refused',
                                            message: res.errorMessage,
                                            level: 'success'
                                        })
                                    }
                                })
                            })
                            .catch((err) => {
                                console.error(err)
                                this.setState({ showModalU2FNotSupported: true, u2fErrorMessage: err.toString(), loggingIn: false })
                            })
                            .finally(() => {
                                this.setState({ showModalU2F: false, loggingIn: false })
                            })
                    })
                } else {
                    this.permitLogin()
                }
            })
            .catch((err) => {
                logger.error(err)
                this.setState({
                    errorMessage: err.message,
                    loggingIn: false
                })
                setTimeout(() => {
                    setTimeout(() => {
                        this.refs.loginForm.style.animation = 'none'
                    }, 666)
                    this.refs.loginForm.style.animation = 'accessDenied .666s ease-out'
                }, 100)
            })
    }

    getEmailCodeForU2F = () => {
        ApiService.u2fAuthenticationChallengeMail(this.state.tmpUser.uid, this.state.tmpUser.token).then((data) => {
            this.setState({
                showModalU2FNotSupported: false,
                showModalU2F: false,
                showModalU2FEmailCode: true,
                tmp2faSession: data.sessionId
            })
        })
    }

    permitLogin = () => {
        const json = this.state.tmpUser
        this.setState({ loggedInUser: json })
        json.id += ''
        window.localStorage.setItem('pf_session', JSON.stringify(json))
        window.localStorage.setItem('pf_lastToken', Date.now())
        window.localStorage.setItem('pf_tokenExpire', Date.now() + (json.exp || 0))
        window.localStorage.setItem('pf_lang', json.lang)
        window.localStorage.setItem('pf_lastUserLoggedIn', JSON.stringify({
            displayName: json.displayName,
            login: json.login
        }))
        setTimeout(() => {
            this.doRedirect(json.uid)
        }, 300)
    }

    validateEmailCodeForU2F = () => {
        const code = this.state.tmp2faCode
        const sessionId = this.state.tmp2faSession
        ApiService.u2fAuthenticationVerificationMail(this.state.tmpUser.uid, { login: this.state.tmpUser.login, sessionId, code }).then(() => {
            this.permitLogin()
        }).catch((ex) => {
            Helpers.pushNotification({ message: 'Please double-check your input. If the problems persists, try logging in again after reloading the page.', title: 'Code refused', level: 'error' })
        })
    }

    render() {

        const lastUserLoggedIn = this.state.lastUserLoggedIn
        const background = lastUserLoggedIn
            ? Helpers.stringToColor(String(lastUserLoggedIn.displayName)) + '88'
            : 'var(--color-sidebar) url("/img/padlock.png") center center no-repeat'

        if (!this.state.security) {
            return 'Loading...'
        }

        return (
            <div id="loginWrapper">
                <EasterEgg />
                <a href="https://www.e-media.fr" target="_blank" rel="noopener noreferrer" id="logo_emedia"><img src={logo_emedia} style={{ width: '100%' }} alt="" /></a>
                <div id="loginFormWrapper">
                    <img src={logo_platform_banner} alt="platform_banner" id="platform_logo" />
                    <div style={{ margin: 'auto 0' }}>
                        <div style={{ position: 'relative' }}>
                            <div id="loginForm" ref="loginForm" style={{ zIndex: 2 }}>

                                {this.state.security.u2f &&
                                    <div id="u2f_message">
                                        <img src={ic_yubikey} alt="" style={{ height: '1.5rem', filter: 'grayscale(100%) brightness(0) invert(100%)', marginLeft: '-1rem' }} />
                                        <small style={{ color: 'white' }}>2 Factor Authentication is required</small>
                                    </div>
                                }

                                <div key={this.state.lastUserLoggedIn}>
                                    <div style={{
                                        background: background,
                                        backgroundSize: '75px',
                                        borderRadius: '50%',
                                        height: '86px',
                                        width: '86px',
                                        margin: '10px auto 10px auto',
                                        minWidth: '86px',
                                        boxShadow: 'inset 0 -1pt 2pt rgba(0,0,0,.175)',
                                        userSelect: 'none',
                                        overflow: 'hidden',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center'
                                    }}>
                                        <div style={{
                                            color: 'rgba(255,255,255,.75)',
                                            fontSize: '46px',
                                            textShadow: '1px 1px 1px rgba(0,0,0,.33)'
                                        }}>

                                            {lastUserLoggedIn &&
                                                String(lastUserLoggedIn.displayName).split(/\s/, 2).map(item => {
                                                    return item[0]
                                                })
                                            }
                                        </div>
                                    </div>

                                </div>

                                <form className="m-t" onSubmit={this.submitHandler} style={{ padding: '1rem', position: 'relative' }}>
                                    {lastUserLoggedIn &&
                                        < div id='inputLogin' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', borderColor: 'transparent', textAlign: 'center' }} className="form-control">
                                            <b style={{ position: 'relative', transform: 'translateY(-.75rem)' }}>
                                                <Button style={{ display: 'flex', padding: 0, textDecoration: 'none', alignItems: 'center', fontSize: '.85rem', position: 'absolute', bottom: '-1.5rem', opacity: .66, whiteSpace: 'nowrap', left: '50%', transform: 'translateX(-50%)' }} onClick={() => {
                                                    this.setState({ lastUserLoggedIn: null, errorMessage: null }, () => {
                                                        document.querySelector('#inputLogin').value = null
                                                        document.querySelector('#inputLogin').focus()
                                                    })
                                                }} title='Change' bsStyle="link">&nbsp;<img alt='' src={ic_edit} /> switch user ?</Button>
                                                <div style={{ flex: 1, maxWidth: '160pt', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{lastUserLoggedIn.displayName}</div>
                                            </b>
                                        </div>
                                    }
                                    <input type="text" name="login" className="form-control"
                                        id="inputLogin"
                                        placeholder="Login"
                                        autoComplete="username"
                                        autoCorrect="false"
                                        style={{ display: lastUserLoggedIn ? `none` : `block` }}
                                        defaultValue={lastUserLoggedIn ? lastUserLoggedIn.login : ''}
                                        required="" />
                                    <input type="password" id="inputPassword" name="password" className="form-control"
                                        autoComplete="current-password" placeholder={"Password" + (lastUserLoggedIn ? ` for ${lastUserLoggedIn.displayName}` : ``)}
                                        required={true} />
                                    <Button disabled={this.state.loggingIn} type="submit" bsStyle="primary" style={{ transition: '.3s' }}>
                                        {this.state.loggingIn
                                            ? 'Logging in...'
                                            : this.state.errorMessage ? 'Try again' : 'Log in'
                                        }
                                    </Button>

                                </form>

                            </div>
                            {this.state.errorMessage &&
                                <div className={'loginErrorMessage'}>
                                    <img src={ic_close_white} alt='' />
                                    {this.state.errorMessage}
                                </div>
                            }
                        </div>
                    </div>
                    <div id="LoginVersionNumber">
                        <Version />
                    </div>
                </div>

                <Modale show={this.state.showModalU2FEmailCode}>
                    <Modale.Body style={{ width: '480px' }}>
                        <form onSubmit={(e) => { e.preventDefault(); return false }}>
                            <img src={'/img/padlock.png'} alt="Code" style={{ display: 'block', margin: '2rem auto', height: '150px', width: '150px', borderRadius: '50%', background: 'var(--color-sidebar)' }} />
                            <p style={{ textAlign: 'center' }}>Please input the code that has just been sent to you by e-mail.</p>
                            <p>
                                <input type="text" placeholder="XXXXX" maxLength={5} required
                                    style={{ margin: 'auto', fontFamily: 'monospace', textAlign: 'center', width: 'calc(5ch + 30px + 2rem)', fontSize: '2rem', padding: '.75rem', letterSpacing: '3px' }}
                                    onInput={(e) => {
                                        this.setState({ tmp2faCode: e.target.value })
                                    }} />
                            </p>
                            <p style={{ textAlign: 'center' }}>
                                <small style={{ opacity: .75 }}>
                                    <b>Important: </b> do NOT close this popup nor this tab/window or you will have to do the whole authentication process again.
                                </small>
                            </p>
                        </form>
                    </Modale.Body>
                    <Modale.Footer>
                        <Button onClick={() => { this.setState({ showModalU2FEmailCode: false }) }}>Cancel</Button>
                        <div style={{ flex: 1 }}></div>
                        <Button bsStyle='primary' onClick={() => { this.validateEmailCodeForU2F() }}>Send</Button>
                    </Modale.Footer>
                </Modale>

                <Modale show={this.state.showModalU2F}>
                    <Modale.Body style={{ width: '480px' }}>
                        <img src={logo_yubikey} alt="Yubikey" style={{ display: 'block', margin: '2rem auto', height: '170px' }} />
                        <p style={{ textAlign: 'center' }}><b>Please touch the flashing U2F device now.</b></p>
                        <p style={{ textAlign: 'center' }}>You may be prompted to allow the site permission to access your security keys. After granting permission, the device will start to blink.</p>
                    </Modale.Body>
                    <Modale.Footer>
                        {this.state.security.u2fmail
                            ? <Button onClick={() => { this.getEmailCodeForU2F() }}>Receive code by e-mail instead</Button>
                            : ''
                        }
                        <div style={{ flex: 1 }}></div>
                        <Button onClick={() => { this.setState({ showModalU2F: false }) }}>Abort</Button>
                    </Modale.Footer>
                </Modale>

                <Modale show={this.state.showModalU2FNotSupported}>
                    <Modale.Body style={{ width: '480px' }}>
                        <img src={logo_yubikey_error} alt="Yubikey" style={{ display: 'block', margin: '2rem auto', height: '120px' }} />
                        <p style={{ textAlign: 'center' }}><b>WebAuthn Authentication failed</b></p>
                        <p style={{ textAlign: 'center' }}>There was an issue while trying to verify your 2nd authentication factor :
                            <br />{this.state.u2fErrorMessage}.
                        </p>
                        {this.state.security.u2fmail && this.state.tmpUser.email
                            ? <p style={{ textAlign: 'center', padding: '.75rem', borderRadius: '.5rem', margin: '1rem 2rem', background: 'rgba(50,200,180,.15)', color: 'rgb(10,160,140)' }}>
                                <b>Don't panic!</b><br />You can still try to log in using your e-mail address below, or in the worst case, ask an administrator for help.
                            </p>
                            : ''}
                    </Modale.Body>
                    <Modale.Footer>
                        {this.state.security.u2fmail && this.state.tmpUser.email
                            ? <Button onClick={() => { this.getEmailCodeForU2F() }}>Receive code by e-mail</Button>
                            : ''
                        }
                        <div style={{ flex: 1 }}></div>
                        <Button onClick={() => { this.setState({ showModalU2FNotSupported: false }) }}>Close</Button>
                    </Modale.Footer>
                </Modale>
            </div >
        )
    }
}

export default LoginComponent
