import React from 'react'
import TransferList from './TransferList'
import routes from '../../routes'
import Helpers from '../../core/Helpers'
import Table from '../Table'
import { withRouter } from 'react-router-dom'

import WebSocketClient from '../../core/WebSocketClient'

const messageRegistered = [
    WebSocketClient.messageTypes.file_transfer_creation,
    WebSocketClient.messageTypes.item_status_update
]
class TransferListContainer extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            componentReady: false,
            endPoint: {},
            configuration: {
                columns: []
            },
            err: null,
            key: Date.now()
        }

        this.chooseTransfer = this.chooseTransfer.bind(this)
        this.onRefresh = this.onRefresh.bind(this)
    }

    onRefresh() {
        if (this.table) this.table.forceRefresh()
    }

    async componentDidMount() {
        await this.generateConfiguration()
        this.props.updateBreadcrumb('File transfers',
            <div style={{ display: 'flex' }}>
                <div style={{ flex: 1 }}>

                </div>
            </div>
        )
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.message) {
            setTimeout(() => {
                this.onRefresh()
            }, 250)
        }
    }

    async generateConfiguration() {
        this.setState({
            componentReady: false
        })
        let endPoint = routes.transfers
        const defaultConfiguration = {
            sort: {
                id: 'transfer_item.creation_timestamp',
                desc: true
            },
            rowsPerPage: 10,
            columns: [
                {
                    id: 'transfer_status.name',
                    title: 'Status',
                    type: Table.types.STRING_LIST,
                    accessor: (row) => {
                        return row.status
                    },
                    show: true,
                    resizable: false,
                    width: 82,
                    params: `{
                        "captions": [
                            {
                                "color": "#00007f",
                                "long": "The transfer is waiting for a slot in the transfer queue",
                                "short": "Waiting"
                            },
                            {
                                "backgroundColor": "#4394d1",
                                "color": "#d6ebfc",
                                "long": "The transfer is in progress",
                                "short": "InProgress"
                            },
                            {
                                "backgroundColor": "#00007f",
                                "color": "#ffffff",
                                "long": "The transfer is paused",
                                "short": "Paused"
                            },
                            {
                                "backgroundColor": "#449944",
                                "color": "#fff",
                                "long": "The transfer has finished successfully",
                                "short": "Finished"
                            },
                            {
                                "backgroundColor": "#d83333",
                                "color": "#ffdddd",
                                "long": "The transfer has failed",
                                "short": "Failed"
                            },
                            {
                                "color": "#550000",
                                "long": "The transfer has been canceled",
                                "short": "Canceled"
                            }
                        ]
                    }`
                },
                {
                    id: 'origin.name',
                    title: 'Origin',
                    type: Table.types.STRING,
                    accessor: (row) => {
                        return row.origin.name
                    },
                    show: true
                },
                {
                    id: 'destination.name',
                    title: 'Destinations',
                    type: Table.types.STRING,
                    accessor: (row) => {
                        return row.destinations.map(d => d.name).join(', ')
                    },
                    show: true,
                    sortable: false
                },
                {
                    id: 'workflow_data.name',
                    title: 'workflow',
                    type: Table.types.STRING,
                    accessor: (row) => {
                        return row.workflow_name
                    },
                    show: true,
                    sortable: true
                },
                {
                    id: 'transfer_item.name',
                    title: 'name',
                    type: Table.types.STRING,
                    accessor: (row) => {
                        return row.name
                    },
                    show: true,
                    sortable: true
                },
                {
                    id: 'transfer_item.nb_files',
                    title: 'Files',
                    accessor: (row) => {
                        return row.nb_files
                    },
                    type: Table.types.INTEGER,
                    show: true,
                    width: 70
                },
                {
                    id: 'transfer_item.total_size',
                    title: 'Size',
                    accessor: (row) => {
                        return Helpers.humanFileSize(row.total_size || 0)
                    },
                    type: Table.types.INTEGER,
                    show: true,
                    width: 70
                },
                {
                    id: 'workflow_uid',
                    title: 'Workflow ID',
                    accessor: (row) => {
                        return row.workflow_id
                    },
                    type: Table.types.COLOR_LIST,
                    show: false,
                    width: 125
                },
                {
                    id: 'transfer_item.item_uid',
                    title: 'Item',
                    accessor: (row) => {
                        return row.item_id
                    },
                    type: Table.types.INTEGER,
                    show: false,
                    width: 125
                },
                {
                    id: 'transfer_item.creation_timestamp',
                    title: 'Creation',
                    accessor: (row) => {
                        return row.creation_time
                    },
                    type: Table.types.DATETIME,
                    show: true,
                    width: 125
                },
                {
                    id: 'transfer_item.update_timestamp',
                    title: 'Last update',
                    accessor: (row) => {
                        return row.last_update
                    },
                    type: Table.types.DATETIME,
                    show: true,
                    width: 125
                }
            ]
        }

        this.setState({
            endPoint: endPoint,
            configuration: defaultConfiguration,
            componentReady: true
        })
    }

    chooseTransfer(transfer) {
        this.props.history.push('/monitor/transfers/' + transfer.item_id)
    }

    render() {
        if (!this.state.componentReady) return null
        return (
            <TransferList
                ref={table => this.table = table}
                configuration={this.state.configuration}
                endPoint={this.state.endPoint}
                chooseTransfer={this.chooseTransfer}
            />
        )
    }
}

export default withRouter(WebSocketClient.withWebSocket(TransferListContainer, (props) => messageRegistered))
