import React from 'react'
import DefaultLayout from '../templates/DefaultTemplate'
import BoxesComponent from '../components/BoxesMonitor/'

class Home extends React.Component {

    render() {
        return (
            <DefaultLayout {...this.props}>
                <BoxesComponent {...this.props} history={this.props.history}/>
            </DefaultLayout>
        )
    }
}

export default Home
