import React from 'react'
import ph_empty from '../../img/placeholder_no_update.svg'
import * as moment from 'moment-timezone'

class PFMConfigurationComponent extends React.Component {

    constructor(props) {
        super(props)

        this.state = {}
    }

    render() {
        return (
            <div style={{ flex: 1, display: 'flex' }}>
                {JSON.stringify(this.details)}
                {!!!this.props.structure.length &&
                <div style={{ flex: 1, margin: 'auto', textAlign: 'center' }}>
                    <img src={ph_empty} alt="" style={{ display: 'block', margin: 'auto' }}/>
                </div>
                }
                {!!this.props.structure.length &&
                <div className="cards_container" style={{ width: '100%' }}>
                    {Array.from(this.props.structure).map((table, index) => {
                        return (<div key={index} style={{
                            background: '#fdfdfd',
                            borderRadius: '2pt',
                            boxShadow: '0 .2rem .5rem .5pt rgba(0,0,0,0.2)',
                            margin: '.75rem',
                            padding: '1rem'
                        }}>
                            <div style={{ display: 'flex', cursor: this.state[table] ? 'zoom-out' : 'zoom-in' }}
                                 tabIndex="-1" onClick={() => {
                                this.setState({ [table]: !this.state[table] })
                            }}>
                                <h4 style={{ textTransform: 'capitalize', flex: 1, margin: 'auto' }}>{table}</h4>
                                <div style={{ margin: '.5rem' }}>
                                    <i className={'fa fa-circle text-navy'}></i>
                                    {this.props[table].created && this.props[table].created.length} new
                                </div>
                                <div style={{ margin: '.5rem' }}>
                                    <i className={'fa fa-circle text-danger'}></i>
                                    {this.props[table].modified && this.props[table].modified.length} modified
                                </div>
                                <div style={{ margin: '.5rem' }}>
                                    <i className={'fa fa-circle text-danger'}></i>
                                    {this.props[table].deleted && this.props[table].deleted.length} deleted
                                </div>
                            </div>
                            {!!this.state[table] && Object.keys(this.props[table]).map((t2, id2) => {
                                if (this.props[table][t2].length) {
                                    return <div key={id2}>
                                        <h5>{t2}</h5>
                                        <table style={{ width: '100%', borderCollapse: 'collapse' }} border="1">
                                            <thead>
                                            <tr>
                                                {Object.keys(this.props[table][t2][0]).map((t3, id3) => {
                                                    return <th key={id3} style={{
                                                        background: '#ddd',
                                                        fontSize: '.75rem',
                                                        padding: '.35rem',
                                                        textAlign: 'center',
                                                        textTransform: 'uppercase'
                                                    }}>{t3}</th>
                                                })
                                                }
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {this.props[table][t2].map((t3, id3) => {
                                                return <tr key={id3}>
                                                    {
                                                        Object.keys(t3).map((t4, id4) => {
                                                            let display = t3[t4]
                                                            if (['creation_timestamp', 'update_timestamp'].includes(t4)) {
                                                                display = display ? moment(display).format('lll') : ''
                                                            }
                                                            return <td key={id4} style={{ padding: '.5rem' }}>
                                                                {display}
                                                            </td>
                                                        })
                                                    }
                                                </tr>
                                            })
                                            }
                                            </tbody>
                                        </table>
                                    </div>
                                } else {
                                    return ''
                                }
                            })
                            }
                        </div>)
                    })}
                </div>
                }
            </div>
        )
    }
}

export default PFMConfigurationComponent
