import React, { Component } from 'react'
import { Tabs, Tab, Button } from '../UI/Toolkit'
import Alfred from '../../core/Alfred'
import ApiService from '../../core/ApiService'
import Helpers from '../../core/Helpers'
import InputDate from '../../components/Fields/InputDate'
import moment from 'moment-timezone'
import ForbiddenComponent from '../ForbiddenComponent'
import { Link } from 'react-router-dom'
import Picker from '../UI/Picker'
import BoxPickerElement from '../UI/PickerTransformedComponents/Box'
import TagPickerElement from '../UI/PickerTransformedComponents/Tag'

import './index.css'

class PfStatsComponent extends Component {
    constructor(props) {
        super(props)

        this.state = {
            dateStart: moment().startOf('month'),
            dateEnd: moment().add(1, 'month').startOf('month'),
            types: ['file_transfers'],
            boxes: [],
            communities: [],
            tags: [],
        }

    }

    componentDidMount = () => {
        if (Alfred.askPermission('PF_STATS', Alfred.R)) {
            this.props.updateBreadcrumb('Platform Statistics',
                <div style={{ display: 'flex' }}>
                    <div style={{ flex: 1 }}>
                        Export MediaBox Network activity as slices
                    </div>
                </div>
            )

            Promise.all([
                ApiService.getBoxes().then((boxes) => {
                    this.setState({ boxes })
                }),
                ApiService.getCommunities().then((communities) => {
                    this.setState({ communities })
                }),
                ApiService.getTags().then((tags) => {
                    this.setState({ tags: tags.rows })
                })
            ]).then(() => {
                this.forceUpdate()
            })
        }
    }

    onRequestStats = (ev) => {
        ev.target.setAttribute("disabled", true)
        setTimeout((btn) => {
            btn.removeAttribute("disabled")
            document.querySelector('#generating').style.opacity = 0;
            document.querySelector('#generating').style.bottom = '-1rem';
        }, 7500, ev.target)
        ApiService.requestStats({
            ...this.state,
            boxes: this.state.boxes.filter(x => x._selected).map(x => x.uid),
            communities: this.state.communities.filter(x => x._selected).map(x => x.uid),
            tags: this.state.tags.filter(x => x._selected).map(x => x.uid),
        }).then(() => {
            document.querySelector('#generating').style.opacity = 1;
            document.querySelector('#generating').style.bottom = '0';
        })
    }

    handleDataTypeChange = (ev) => {
        const types = Array.prototype.map.call(ev.target.selectedOptions, function (x) { return x.value })
        this.setState({
            types
        })
    }

    render = () => {
        if (Helpers.getCurrentUser().admin < 1 || !Alfred.askPermission('BOX_UPDATE_PACKAGES', Alfred.R)) {
            return <ForbiddenComponent />
        }
        if (!Helpers.getCurrentUser().email) {
            return (
                <div id="StatsComponent">
                    <div data-ui-card style={{ color: 'red' }}>
                        <h1>Oops...</h1>
                        <p >
                            You must provide an e-mail address to generate stats.
                        <br />
                        Please set it in your <Link to='/myprofile'>user profile</Link> before you come back.
                    </p>
                    </div>
                </div>
            )
        }
        return (
            <div>
                <div id="StatsComponent">
                    <div data-ui-card>
                        <table>
                            <tbody>
                                <tr>
                                    <td>
                                        Data types
                                    <small>
                                            CTRL + Click to select multiple entries, or SHIFT + Click for a range selection
                                    </small>
                                    </td>
                                    <td>
                                        <select multiple onChange={this.handleDataTypeChange} value={this.state.types}>
                                            <option value="file_transfers">File transfers</option>
                                        </select>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Time range
                                    <small>
                                            Shortcuts :&nbsp;
                                        <button onClick={() => {
                                                this.setState({
                                                    dateStart: moment().add(-1, 'month').startOf('month'),
                                                    dateEnd: moment().startOf('month')
                                                })
                                            }} className="link">last month</button>,&nbsp;
                                        <button onClick={() => {
                                                this.setState({
                                                    dateStart: moment().startOf('month'),
                                                    dateEnd: moment().add(1, 'month').startOf('month')
                                                })
                                            }} className="link">this month</button>,&nbsp;
                                        <button onClick={() => {
                                                this.setState({
                                                    dateStart: moment().add(-1, 'year').startOf('year'),
                                                    dateEnd: moment().startOf('year')
                                                })
                                            }} className="link">last year</button>,&nbsp;
                                        <button onClick={() => {
                                                this.setState({
                                                    dateStart: moment().startOf('year'),
                                                    dateEnd: moment().add(1, 'year').startOf('year')
                                                })
                                            }} className="link">this year</button>
                                        </small>
                                    </td>
                                    <td>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <InputDate time={true} value={this.state.dateStart} onChange={(val) => { this.setState({ dateStart: val }) }} />
                                        &nbsp; <span>to</span> &nbsp;
                                        <InputDate time={true} value={this.state.dateEnd} onChange={(val) => { this.setState({ dateEnd: val }) }} />
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Filter boxes
                                    <small>by Box, Community, Tag... (filters are cumulative)</small>
                                        <small>&nbsp;&rarr;&nbsp;{this.state.boxes.filter(x => x._selected).length} box{this.state.boxes.filter(x => x._selected).length > 1 ? 'es' : ''}</small>
                                        <small>&nbsp;&rarr;&nbsp;{this.state.communities.filter(x => x._selected).length} communit{this.state.communities.filter(x => x._selected).length > 1 ? 'ies' : 'y'}</small>
                                        <small>&nbsp;&rarr;&nbsp;{this.state.tags.filter(x => x._selected).length} tag{this.state.tags.filter(x => x._selected).length > 1 ? 's' : ''}</small>
                                    </td>
                                    <td>
                                        <Tabs>
                                            <Tab label="Boxes">
                                                <Picker ref="boxPicker" style={{ flex: 1 }} title="Individual boxes" filterkey={['name', 'uid', 'box_version', 'system']}
                                                    items={this.state.boxes}
                                                    onChange={(boxes) => {
                                                        this.setState({ boxes })
                                                    }}
                                                    ukey="uid"
                                                    renderItem={(item) => {
                                                        return <BoxPickerElement {...item} />
                                                    }}
                                                    sortBy="name" />
                                            </Tab>
                                            <Tab label="Communities">
                                                <Picker ref="boxPicker" style={{ flex: 1 }} title="Boxes in communities" filterkey={['name', 'uid', 'box_version', 'system']}
                                                    items={this.state.communities} ukey="uid"
                                                    onChange={(communities) => {
                                                        this.setState({ communities })
                                                    }}
                                                    sortBy="name" />
                                            </Tab>
                                            <Tab label="Tags">
                                                <Picker ref="boxPicker" style={{ flex: 1 }} title="Box having tags" filterkey={['name', 'uid', 'box_version', 'system']}
                                                    items={this.state.tags} ukey="uid"
                                                    renderItem={(item) => {
                                                        return <div style={{ padding: '.5rem', color: 'black' }}><TagPickerElement  {...item} /></div>
                                                    }}
                                                    onChange={(tags) => {
                                                        this.setState({ tags })
                                                    }}
                                                    sortBy="name" />
                                            </Tab>
                                        </Tabs>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <div>
                            <p>
                                <b>Important notes :</b>
                            </p>
                            <ul>
                                <li>Exports are processed on server-side in the background, and then made available to download through a limited time validity link that will be send by e-mail to <b>{Helpers.getCurrentUser().email}</b>&nbsp;
                                    <small>(You can change your e-mail address in your <Link to='/myprofile'>user profile</Link>)</small>.
                                </li>
                                <li>Treatment might be resource-intensive for the backend, consider it could slow down the web server of the Platform while extracting data.</li>
                                <li>Any export request will be logged, mentioning your name, the date/time you requested the data, and the type of data.</li>
                            </ul>
                        </div>
                        <div style={{ position: 'relative' }}>
                            <Button bsStyle="primary" onClick={this.onRequestStats}>
                                Generate
                            </Button>
                            <span id="generating" data-ui-card
                                style={{
                                    color: 'var(--color-text-on-success)',
                                    backgroundColor: 'var(--color-success)',
                                    position: 'absolute',
                                    width: '100%',
                                    left: 0,
                                    bottom: '-1rem',
                                    textAlign: 'center',
                                    opacity: 0,
                                    pointerEvents: 'none',
                                    transition: '.5s'
                                }}>
                                Export generation is in progress, keep an eye to your mail inbox!
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default PfStatsComponent
