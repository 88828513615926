import Helpers from './Helpers'
import Logger from './Logger'

const logger = new Logger('Alfred')

class Alfred {
    static R = 1
    static W = 10
    static X = 100

    static askPermission(permission, minLevel) {
        let user = undefined
        let answer = false
        if (!Alfred.isLogged()) {
            return false
        } else {
            user = Alfred.getUser()
        }
        if (!user) {
            logger.error('Alfred opened the door but nobody was there...')
            return false
        }
        if (user && user.permissions) {
            answer = (
                user.admin >= 2
                || (user.permissions._ADMIN && user.permissions._ADMIN >= (minLevel || Alfred.X))
                || (user.permissions[permission] && user.permissions[permission] >= (minLevel || Alfred.X))
            )
            if (typeof user.permissions[permission] === 'undefined'
                && !user.admin >= 2
                && !user.permissions._ADMIN >= (minLevel || Alfred.X)) {
                return false
            }
        }
        return answer
    }

    static getUser() {
        return Helpers.getCurrentUser()
    }

    static isLogged() {
        return Helpers.checkUserSession()
    }
}

export default Alfred
