import React, { Component } from 'react'
import BoxMonitor from './routes/Boxes'
import BoxDetails from './routes/BoxDetails'
import BoxEvents from './routes/BoxEvents'
import Communities from './routes/Communities'
import Templates from './routes/Templates'
import Users from './routes/Users'
import Teams from './routes/Teams'
import Tags from './routes/Tags'
import LoginComponent from './components/LoginComponent/'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import TransferListMonitor from './routes/TransferListMonitor'
import TransferMonitor from './routes/TransferMonitor'
import MyProfile from './routes/MyProfile'
import moment from 'moment-timezone'
import 'moment/locale/en-gb'
import PFMConfigurationRoute from './routes/PFMConfigurationRoute'
import BoxUpdatePackages from './routes/BoxUpdatePackages'
import BoxUpdates from './routes/BoxUpdates'
import BoxUpdate from './routes/BoxUpdate'
import Traces from './routes/Traces'
import NotFound from './routes/404'
import BoxVNC from './routes/BoxVNC'
import DockerControl from './routes/DockerControl'
import PfSettings from './routes/PfSettings'
import PfStats from './routes/PfStats'
import DownloadFile from './routes/DownloadFile'
import Security from './routes/Security'
import SharedFiles from './routes/SharedFiles'
import NotificationsManager from './components/UI/NotificationsManager'

moment.locale('EN')

class App extends Component {

    constructor(props) {
        super(props)
        this.state = {
            ready: undefined
        }
    }

    sessionIsAlive = () => {
        return localStorage.getItem('pf_session') && localStorage.getItem('pf_tokenExpire') > Date.now()
    }

    componentDidMount() {
        if (document.location.pathname !== '/' && !this.sessionIsAlive()) {
            this.setState({ ready: false }, () => {
                document.location.href = '/?returnTo=' + encodeURIComponent(window.location.pathname)
                    + '&uuid=' + JSON.parse(localStorage.getItem('pf_session') || '{}').uid || '';
            })
        } else {
            this.setState({ ready: true })
        }
    }

    render() {
        if (this.state.ready === false) {
            return (<div
                style={{
                    display: 'flex',
                    flex: 1,
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexDirection: 'column',
                }}>
                <h1>Invalid session</h1>
                <p>Redirecting to login page...</p>
            </div>)
        }
        if (this.state.ready !== true) {
            return '' // checking
        }
        return (
            <div style={{ display: 'contents' }}>
                <BrowserRouter>
                    <Switch>
                        <Route path="/" exact component={LoginComponent} />
                        <Route path="/users" component={Users} />
                        <Route path="/security" component={Security} />
                        <Route path="/teams" component={Teams} />
                        <Route path="/tags" component={Tags} />
                        <Route exact path="/myprofile" component={MyProfile} />
                        <Route exact path="/monitor/boxes" component={BoxMonitor} />
                        <Route exact path="/monitor/boxes/:boxId" component={BoxDetails} />
                        <Route path="/monitor/boxes/:boxId/vnc" component={BoxVNC} />
                        <Route exact path="/monitor/transfers" component={TransferListMonitor} />
                        <Route path="/monitor/transfers/:itemId" component={TransferMonitor} />
                        <Route path="/communities" component={Communities} />
                        <Route path="/templates" component={Templates} />
                        <Route path="/pfmConfiguration" component={PFMConfigurationRoute} />
                        <Route exact path="/BoxesUpdate" component={BoxUpdates} />
                        <Route path="/BoxesUpdate/:uid" component={BoxUpdate} />
                        <Route path="/BoxUpdatePackages" component={BoxUpdatePackages} />
                        <Route path="/traces" component={Traces} />
                        <Route path="/dockercontrol" component={DockerControl} />
                        <Route path="/pfsettings" component={PfSettings} />
                        <Route path="/pfstats" component={PfStats} />
                        <Route path="/downloadTmp/:filename" component={DownloadFile} />
                        <Route path="/boxEvents" component={BoxEvents} />
                        <Route path="/sharedFiles" component={SharedFiles} />
                        <Route component={NotFound} />
                    </Switch>
                </BrowserRouter>
                <NotificationsManager />
            </div>
        )
    }
}

export default App
