import Alfred from '../../core/Alfred'
import ApiService from '../../core/ApiService'
import { Button } from '../UI/Toolkit'
import BoxConfigCube from './BoxConfigCube'
import ForbiddenComponent from '../ForbiddenComponent'
import Helpers from '../../core/Helpers'
import Modale from '../UI/ModaleComponent';
import PFMConfigurationComponent from './PFMConfiguration'
import React from 'react'
import WebSocketClient from '../../core/WebSocketClient';

class PFMConfigurationContainer extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            box_to_community: {},
            boxes: {},
            community_to_template: {},
            modified: null
        }

        this.update = this.update.bind(this)
        this.refresh = this.refresh.bind(this)
    }

    componentDidMount() {
        if (Helpers.getCurrentUser().admin >= 1 && Alfred.askPermission('BOX_CONFIG_DEPLOYMENT', Alfred.R)) {
            this.refresh()
            this.props.updateBreadcrumb(
                'Update boxes',
                <div style={{ display: 'flex' }}>
                    <div style={{ flex: 1 }}>Please review carefully before hitting Save !</div>
                    {Alfred.askPermission('BOX_CONFIG_DEPLOYMENT', Alfred.X) &&
                        <Button bsStyle="primary" bsSize="small" onClick={() => this.update()}>Save</Button>
                    }
                </div>
            )
        }
    }

    componentWillReceiveProps(nextProps) {
        const message = nextProps.message
        if (this.state.modified && message && message.type === 'configuration_version') {
            let modified = this.state.modified
            let box = modified[message.box_id]
            modified[message.box_id] = {
                box_id: box.box_id,
                name: box.name,
                connected: box.connected,
                current_version: message.version,
                desired_version: box.desired_version,
                success: message.success,
                error_message: message.error_message
            }
            this.setState({ modified })
        }
    }

    refresh() {
        ApiService.getModifications()
            .then((modifications) => {
                this.setState(modifications)
            })
    }

    update() {
        ApiService.pfmReloadConfig()
            .then((modified) => {
                this.setState({ modified },
                    this.refresh())
            })
    }

    render() {
        if (Helpers.getCurrentUser().admin < 1 || !Alfred.askPermission('BOX_CONFIG_DEPLOYMENT', Alfred.R)) { return <ForbiddenComponent /> }
        return (<div>
            <Modale show={this.state.modified != null} onHide={() => {
                this.setState({ modified: null })
            }}>
                <Modale.Header>
                    Updating boxes' configuration
                </Modale.Header>
                <Modale.Body>
                    <div className='BoxConfigCubesContainer'
                        style={{ display: 'flex', flexFlow: 'row wrap', overflowX: 'hidden' }}>
                        {
                            Object.keys(this.state.modified || {}).map((id) => {
                                return <BoxConfigCube key={id} data={this.state.modified[id]} />
                            })
                        }
                        <div style={{ height: '150px' }}></div>
                    </div>
                </Modale.Body>
                <Modale.Footer>
                    <div style={{ flex: 1 }} />
                    <Button style={{ float: 'left' }} onClick={() => { this.setState({ modified: null }) }}>
                        Close
                    </Button>
                </Modale.Footer>
            </Modale>

            <PFMConfigurationComponent
                {...this.state}
                update={this.update}
                structure={Object.keys(this.state).filter((s) => {
                    if (s === 'modified') return false
                    return (this.state[s].created && this.state[s].created.length)
                        || (this.state[s].modified && this.state[s].modified.length)
                        || (this.state[s].deleted && this.state[s].deleted.length)
                })} />
        </div>)
    }
}

export default WebSocketClient.withWebSocket(PFMConfigurationContainer, () => [WebSocketClient.messageTypes.configuration_version])