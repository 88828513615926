import React from 'react'
import { Button } from '../UI/Toolkit'

import './index.css'

export default class Dropdown extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            visible: false
        }

        this.hide = this.hide.bind(this)
        this.show = this.show.bind(this)
    }

    hide(e) {
        if (e && e.target.closest('.DropdownComponent')) {
            return
        }
        if (Date.now() - this.state.visible < 500) return
        this.setState({ visible: false })
    }

    show(e) {
        if (e) {
            e.preventDefault()
            e.stopPropagation()
        }
        this.setState({ visible: Date.now() })
    }

    componentWillMount() {
        document.addEventListener('click', this.hide)
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.hide)
    }

    render() {
        return (
            <div className="DropdownComponent" ref="comp">
                <Button title={this.props.title} bsStyle={this.props.bsStyle || 'primary'} className="DropdownComponent"
                    onClick={this.show}>
                    {this.props.glyphicon &&
                        <i className={`glyphicon glyphicon-${this.props.glyphicon}`}></i>
                    }
                    {this.props.glyphicon && this.props.label &&
                        <span>&nbsp;</span>
                    }
                    {this.props.label}
                </Button>
                <div className="DropdownComponentWrapper" ref="wrapper"
                    style={{ display: this.state.visible ? 'inline-block' : 'none' }}>
                    {React.Children.toArray(this.props.children)}
                </div>
            </div>
        )
    }
}