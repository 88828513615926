import React from 'react'
import ApiService from '../../core/ApiService';
import moment from 'moment-timezone'
import { withRouter } from 'react-router-dom'

import './uptimechart.css'

class BoxEventsView extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            boxEvents: [],
            nbDays: 60,
            uptime: 100
        }
    }

    componentWillMount() {
        const boxCreationDate = moment(this.props.boxCreationDate)
        ApiService.getBoxEvents(this.props.boxId, this.state.nbDays)
            .then(inputs => {
                let boxEvents = []
                let nb = inputs.length
                let d = 0 // cursor in the inputs
                let decoDuration = 0

                let firstDay = moment().add(1 - this.state.nbDays, 'day').format('YYYY-MM-DD')
                while (d < nb && inputs[d].date < firstDay) {
                    ++d
                }

                for (let i = 0; i < this.state.nbDays; ++i) {
                    let delta = this.state.nbDays - 1 - i
                    let date = moment().add(-delta, 'day').format('YYYY-MM-DD')
                    if (d < nb && inputs[d].date === date) {
                        decoDuration += (inputs[d].boxDeconnectedDuration || 0)
                        boxEvents.push(inputs[d])
                        ++d
                    } else {
                        boxEvents.push({
                            date: date,
                            severity: 0
                        })
                    }

                    if (boxCreationDate.isAfter(moment().add(-this.state.nbDays, 'days'))) {
                        boxEvents = boxEvents.map(ev => {
                            if (boxCreationDate.isAfter(moment(ev.date), 'day')) {
                                ev.severity = 999
                            }
                            return ev
                        })
                    }
                }

                let totalDuration = this.state.nbDays * 86400;
                let uptime = 100 * (totalDuration - decoDuration) / totalDuration

                this.setState({ boxEvents, uptime })
            })
            .catch((err) => {
                this.setState({
                    boxEvents: [{ error: 'Could not process the box events' }]
                })
            })
    }

    computeUptime(value) {
        if (value === 100)
            return '100.00'
        // Fix issues with rounding
        if (value > 99.98) {
            // Try different precisions until we found a number that is not 100% and does not end with a 9
            for (let i = 3; i < 8; ++i) {
                const p = Math.pow(10, i)
                const res = (Math.round(value * p) / p).toFixed(i)
                if (!res.startsWith('100') && !res.endsWith('9')) {
                    return res
                }
            }
            return value.toString()
        }
        return (Math.round(value * 100) / 100).toFixed(2)
    }

    render() {
        if (this.state.boxEvents.length === 0) { return null }
        return (
            <div
                className="ui-card ReactTable -highlight"
                style={{ display: 'flex', flexDirection: 'column', padding: '1rem', width: '100%', overflow: 'visible' }}>
                <div style={{ flex: 1, opacity: .5 }}>Box events</div>

                <div className="uptime-chart">
                    <ul className="uptime-chart">
                        {this.state.boxEvents.map((day, index) => {
                            let tooltip;
                            const tX = index * 100 / (this.state.nbDays - 1)
                            if (day.severity === 999) {
                                tooltip = <div className="tooltip" style={{ transform: `translateX(-${tX}%)` }}>
                                    {day.date}
                                    <br />No data
                                </div>
                            } else if (day.severity === 0) {
                                tooltip = <div className="tooltip" style={{ transform: `translateX(-${tX}%)` }}>
                                    {day.date}
                                    <br />No incidents
                                </div>
                            } else {
                                let downtime = day.boxDeconnectedDuration > 0 ?
                                    <span><br />{moment.duration(day.boxDeconnectedDuration, 'seconds').humanize()} of downtime</span>
                                    : <span />
                                let nbStart = day.nbBoxStart > 0 ? <span><br />{day.nbBoxStart} starts</span> : <span />
                                let nbReco = day.nbBoxConnections > 0 ? <span><br />{day.nbBoxConnections} connections</span> : <span />
                                tooltip = <div className="tooltip" style={{ transform: `translateX(-${tX}%)` }}>
                                    {day.date}{downtime}{nbStart}{nbReco}
                                </div>
                            }

                            const timestampFilter = day.date + '_' + moment(day.date).add(1, 'day').format('YYYY-MM-DD')
                            const url = `/boxEvents?box_uid=${this.props.boxId}&timestamp=${timestampFilter}`

                            return (
                                <li key={day.date} className={`uptime-bar severity-${day.severity}`}
                                    onClick={() => {
                                        this.props.history.push(url)
                                    }}>
                                    {tooltip}
                                </li>
                            )
                        })}
                    </ul>

                    <div className="legend">
                        <div className="item">{this.state.nbDays} days ago</div>
                        <div className="spacer"></div>
                        <div className="item">{this.computeUptime(this.state.uptime)} % uptime</div>
                        <div className="spacer"></div>
                        <div className="item">Today</div>
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(BoxEventsView)