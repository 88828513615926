import React, { Component } from 'react'
import './index.css'

class Modale extends Component {

    constructor(props) {
        super(props)
        this.key = Date.now()
        this.state = {
            show: !!props.show,
            visible: !!props.show
        }
        this.onKeyDown = this.onKeyDown.bind(this)
    }

    componentWillReceiveProps = (newProps) => {

        if (this.props.show === true && newProps.show === false) { // closing modale
            this.onHide()
        } else if (newProps.show === true) {
            this.onShow()
        }

    }

    onShow() {
        this.setState({
            show: true
        })
        const modale = document.querySelector(`#modale-${this.key} .modaleWrapper`)
        const backdrop = document.querySelector(`#modale-${this.key} .modaleOverlay`)
        this.setState({
            visible: true
        }, () => {
            if (backdrop)
                backdrop.style.animation = 'backdropAppear .2s ease-out forwards';
            if (modale)
                modale.style.animation = 'modaleAppear .3s ease-out forwards';
        })
    }

    onHide() {
        this.setState({
            show: false
        })
        const modale = document.querySelector(`#modale-${this.key} .modaleWrapper`)
        const backdrop = document.querySelector(`#modale-${this.key} .modaleOverlay`)
        if (modale)
            modale.style.animation = 'modaleDisappear .2s ease-out forwards';
        if (backdrop)
            backdrop.style.animation = 'backdropDisappear .2s ease-out forwards';
        setTimeout(() => {
            this.setState({
                visible: false,
                show: false
            }, () => {
                if (this.props.onHide) {
                    this.props.onHide()
                }
            })
        }, 300)
    }

    onKeyDown = (e) => {
        if (this.props.closeOnEsc)
            if (e.keyCode === 27) {
                this.onHide()
            }
    }

    componentDidMount() {
        window.addEventListener('keydown', this.onKeyDown)
    }

    componentWillUnmount() {
        window.removeEventListener('keydown', this.onKeyDown)
    }

    render() {
        return (
            <div className='modaleComponent' id={`modale-${this.key}`}>
                {this.state.visible &&
                    <div className="modaleOverlay">
                        <div className="modaleWrapper">
                            {React.Children.toArray(this.props.children)}
                        </div>
                    </div>
                }
            </div>
        )
    }
}

Modale.Header = class Header extends Component {
    render() {
        return (
            <div className="header">
                {React.Children.toArray(this.props.children)}
            </div>
        )
    }
}

Modale.Body = class Body extends Component {
    render() {
        return (
            <div className="content" style={this.props.style}>
                {React.Children.toArray(this.props.children)}
            </div>
        )
    }
}

Modale.Footer = class Footer extends Component {
    render() {
        return (
            <div className="buttons" style={{ display: 'flex', textAlign: 'right' }}>
                {React.Children.toArray(this.props.children)}
            </div>
        )
    }
}

export default Modale
