import Helpers from './Helpers'
import Logger from './Logger'
import routes from '../routes'
import axios from 'axios'

const logger = new Logger('core/Helpers.js')

class ApiService {
    static fileTransfers = {
        action(itemId, fileId, action) {
            return ApiService.fetch(routes.transferUpdateFile, { itemId, fileId, action })
        },
        actionAll(itemId, action) {
            return ApiService.fetch(routes.transferUpdateItem, { itemId, action })
        }
    }
    static serviceCtrl = {
        restart() {
            return ApiService.fetch(routes.serviceRestart, {}, { body: 'restart' })
        },
        getStatus() {
            return ApiService.fetch(routes.serviceStatus)
        }
    }

    static getApiHost() {
        return '/api'
    }

    static prepareRoute(route, params) {
        const preparedRoute = Helpers.clone(route)
        preparedRoute.path = ApiService.replaceInPath(preparedRoute.path, params)
        return preparedRoute
    }

    static replaceInPath(path, params) {
        let pathReplaced = path
        let queryParams = ''
        Object.keys(params).forEach(key => {
            if (pathReplaced.indexOf('{' + key + '}') !== -1) {
                pathReplaced = pathReplaced.replace('{' + key + '}', params[key])
            } else {
                if (!queryParams) {
                    queryParams = '?'
                    queryParams += key + '=' + params[key]
                } else {
                    queryParams += '&' + key + '=' + params[key]
                }
            }
        })
        return pathReplaced + queryParams
    }

    static async fetch(route, params = {}, optionsSupp = {}, extraHeaders = {}) {
        let routeReplaced = Object.assign({}, route)
        Object.assign(routeReplaced, {
            path: ApiService.replaceInPath(route.path, params)
        })
        const session = JSON.parse(window.localStorage.getItem('pf_session'))

        const token = (session && session.token) || null
        const options = {
            headers: {},
            method: route.method,
            url: ApiService.getApiHost() + routeReplaced.path,
            data: optionsSupp.body
        }

        try {
            if (optionsSupp.body && JSON.parse(optionsSupp.body)) {
                options.headers = { 'Content-Type': 'application/json' }
            }
        } catch (ex) {
            options.headers = {}
        }

        options.headers.Authorization = ('Bearer ' + (options.token || token))
        options.headers['Accept-Language'] = localStorage.getItem('pf_lang') || 'FR'
        Object.assign(options, optionsSupp)
        options.headers = { ...options.headers, ...extraHeaders }
        const response = await axios.request(options)
        if (response.status === 401 || response.status === 403) {
            window.localStorage.removeItem('pf_session')
            window.localStorage.removeItem('pf_lastUserAction')
            window.localStorage.removeItem('pf_lastToken')
            window.localStorage.removeItem('pf_tokenExpire')
            document.location.href = '/?returnTo=' + encodeURIComponent(window.location.pathname)
                + '&uuid=' + JSON.parse(localStorage.getItem('pf_session') || '{}').uid
        }
        if (!response.statusText === 'OK') {
            if (route === routes.logger || [202].indexOf(response.status) > -1) {
                return
            }
            let errorMessage = ''
            try {
                const json = await response.data
                errorMessage = json.message || 'An error occured'
            } catch (err) {
                logger.error('Can\'t parse response.json()')
            }
            const error = new Error(errorMessage)
            error.name = response.status
            throw error
        }
        try {
            return await response.data
        } catch (ex) {
            return {}
        }
    }

    static download(link) {
        const xhr = new XMLHttpRequest()
        xhr.open('GET', link, true)
        xhr.setRequestHeader('Authorization', 'Bearer ' + JSON.parse(localStorage.getItem('pf_session')).token)
        xhr.responseType = 'blob'

        xhr.onload = (e) => {
            if (this.status === 200) {
                // Note: .response instead of .responseText
                var a = document.createElement('a')
                a.href = window.URL.createObjectURL(xhr.response) // xhr.response is a blob
                var filename = xhr.getResponseHeader('Content-Disposition').match(/filename="(.*)"/)[1]
                a.download = filename // Set the file name.
                a.style.display = 'none'
                document.body.appendChild(a)
                a.click()
            }
        }

        xhr.send()
    }

    static log(level, module, params) {
        return ApiService.fetch(routes.logger, {
            level: level
        }, {
            body: JSON.stringify({
                module: module,
                params: params
            })
        })
            .catch((err) => {
                logger.error(err)
            })
    }

    static getConfig(key) {
        return ApiService.fetch(routes.getConfig, { key })
    }

    static getVersion() {
        return ApiService.fetch(routes.getVersion)
    }

    static updateConfig(formData) {
        return ApiService.fetch(routes.updateConfig, {}, { body: formData })
    }

    static renewToken() {
        return ApiService.fetch(routes.renewToken, {}, { headers: { 'Authorization': 'Bearer ' + Helpers.getCurrentUser().renewToken } })
    }

    static auth(formData) {
        return ApiService.fetch(routes.auth, {}, {
            body: formData
        })
    }

    static getTemplateTypes() {
        return ApiService.fetch(routes.templateTypes)
    }

    static templateUpload(files) {
        let fd = new FormData()
        Array.from(files).forEach(file => {
            fd.append(file.name, file)
        })
        return ApiService.fetch(routes.templateUpload, {}, { body: fd })
    }

    static getCommunities() {
        return ApiService.fetch(routes.getCommunities)
    }

    static getPermissions() {
        return ApiService.fetch(routes.getPermissions)
    }

    static getUsers() {
        return ApiService.fetch(routes.getUsers)
    }

    static upsertUser(user, uid) {
        if (uid) {
            return ApiService.fetch(routes.updateUser, { uid }, { body: JSON.stringify(user) })
        } else {
            return ApiService.fetch(routes.createUser, {}, { body: JSON.stringify(user) })
        }
    }

    static upsertTeam(team, id) {
        if (id) {
            return ApiService.fetch(routes.updateTeam, { id }, { body: JSON.stringify(team) })
        } else {
            return ApiService.fetch(routes.createTeam, {}, { body: JSON.stringify(team) })
        }
    }

    static getCommunitiesForTeam(id) {
        return ApiService.fetch(routes.getCommunitiesForTeam, { id })
    }

    static removeTeam(id) {
        return ApiService.fetch(routes.removeTeam, { id })
    }

    static getUsersForTeam(id) {
        return ApiService.fetch(routes.getUsersForTeam, { id })
    }

    static getPreferencesForTeam(id) {
        return ApiService.fetch(routes.getPreferencesForTeam, { id })
    }

    static getPermissionsForTeam(id) {
        return ApiService.fetch(routes.getPermissionsForTeam, { id })
    }

    static upsertCommunity(comm, uid) {
        if (uid) {
            return ApiService.fetch(routes.updateCommunity, { uid }, { body: JSON.stringify(comm) })
        } else {
            return ApiService.fetch(routes.createCommunity, {}, { body: JSON.stringify(comm) })
        }
    }

    static removeCommunity(uid) {
        return ApiService.fetch(routes.removeCommunity, { uid }, { body: '' })
    }

    static getBoxes() {
        return ApiService.fetch(routes.boxes)
    }

    static getCommunityBoxes(uid) {
        return ApiService.fetch(routes.communityBoxes, { uid })
    }

    static getCommunityTemplates(uid) {
        return ApiService.fetch(routes.communityTemplates, { uid })
    }

    static getUserPreferences(component) {
        return ApiService.fetch(routes.getUserPreferences, {
            component: component
        })
    }

    static setUserPreferences(component, data) {
        return ApiService.fetch(routes.setUserPreferences, {
            component: component
        }, {
            body: JSON.stringify(data)
        })
    }

    static delUserPreferences(component) {
        return ApiService.fetch(routes.clearUserPreferences, {
            component: component
        })
    }

    static getBoxEvents(boxId, nbDays) {
        return ApiService.fetch(routes.boxEvents, { id: boxId, nbDays: nbDays })
    }

    static getBoxMonitoring() {
        return ApiService.fetch(routes.boxMonitoring)
    }

    static getBoxServices(boxId) {
        return ApiService.fetch(routes.boxServices, { id: boxId })
    }

    static putBoxService(boxId, serviceName) {
        return ApiService.fetch(routes.boxServiceRestart, { id: boxId, name: serviceName }, {
            body: JSON.stringify({
                action: 'restart'
            })
        })
    }

    static getTransfers() {
        return ApiService.fetch(routes.transfers)
    }

    static getTransfer(itemId) {
        return ApiService.fetch(routes.transfer, { itemId: itemId })
    }

    static getLastTransfers(boxId) {
        return ApiService.fetch(routes.lastTransfers, { id: boxId })
    }

    static pfmReloadConfig() {
        return ApiService.fetch(routes.pfmReloadConfig)
    }

    static getMonitoringBoxes() {
        return ApiService.fetch(routes.monitoringBoxes)
    }

    static getModifications() {
        return ApiService.fetch(routes.getModifications)
    }

    static getUserPermissions(userUid) {
        return ApiService.fetch(routes.getUserPermissions, { uid: userUid })
    }

    static getU2FKeys(userUid) {
        return ApiService.fetch(routes.getU2FKeystore, { uid: userUid })
    }

    static revokeYubikey(userUid, keyId) {
        return ApiService.fetch(routes.delU2FKeystore, { uid: userUid, keyId })
    }

    static updateYubikey(userUid, keyId, data) {
        return ApiService.fetch(routes.updateU2FKeystore, { uid: userUid, keyId }, { body: JSON.stringify(data) })
    }

    static u2fRegistrationChallenge(userUid) {
        return ApiService.fetch(routes.u2fRegistrationChallenge, { uid: userUid })
    }

    static u2fRegistration(userUid, sessionId, registrationResponse) {
        return ApiService.fetch(routes.u2fRegistration, { uid: userUid }, { body: JSON.stringify({ sessionId, registrationResponse }) })
    }

    static u2fAuthenticationChallenge(userUid, token) {
        return ApiService.fetch(routes.u2fAuthenticationChallenge, { uid: userUid }, { token: token })
    }

    static u2fAuthenticationChallengeMail(userUid, token) {
        return ApiService.fetch(routes.u2fAuthenticationChallengeMail, { uid: userUid }, { token: token })
    }

    static u2fAuthenticationVerificationMail(userUid, data) {
        return ApiService.fetch(routes.u2fAuthenticationVerificationMail, { uid: userUid }, { body: JSON.stringify(data) })
    }

    static u2fAuthentication(userUid, sessionId, authenticationResponse, token) {
        return ApiService.fetch(routes.u2fAuthentication, { uid: userUid }, { headers: { 'Content-Type': 'application/json', Authorization: 'Bearer ' + token }, body: JSON.stringify({ sessionId, authenticationResponse }) })
    }

    static getBoxDetails(id) {
        return ApiService.fetch(routes.boxDetails, { id })
    }

    static getBoxStatus(id) {
        return ApiService.fetch(routes.boxStatus, { id })
    }

    static updateBox(uid, body) {
        return ApiService.fetch(routes.boxUpdate, { uid }, { body: JSON.stringify(body) })
    }

    static publishVersionPackage(fd, progressCallback) {
        const options = {
            body: fd
        }
        if (progressCallback) {
            options.onUploadProgress = progressCallback
        }
        return ApiService.fetch(routes.createVersionPackage, {}, options, { 'Authorization': 'Bearer ' + Helpers.getCurrentUser().uploadToken })
    }

    static checkVersionCanbBePublished(version_code) {
        return ApiService.fetch(routes.checkVersionCanbBePublished, { version_code })
    }

    static getUpdatePackagesList() {
        return ApiService.fetch(routes.updatePackages)
    }

    static postAdminUpdate(update, uid) {
        let body = { body: JSON.stringify(update) }
        if (uid) {
            return ApiService.fetch(routes.postAdminUpdateCommand, { uid }, body)
        } else {
            return ApiService.fetch(routes.postAdminUpdate, {}, body)
        }
    }

    static getRollingUpdates() {
        return ApiService.fetch(routes.rollingUpdatesList)
    }

    static getRollingUpdateByUid(uid) {
        return ApiService.fetch(routes.rollingUpdate, { uid })
    }

    static removeUser(user) {
        return ApiService.fetch(routes.removeUser, { uid: user.uid })
    }

    static disconnectBox(uid) {
        return ApiService.fetch(routes.disconnectBox, { uid })
    }

    static deletePackage(uid) {
        return ApiService.fetch(routes.deletePackage, { uid: uid })
    }

    static postBoxTunnelService(boxId, service) {
        return ApiService.fetch(routes.openTunnelService, { uid: boxId, service: service })
    }

    static getBoxTunnelService(boxId, service) {
        return ApiService.fetch(routes.getTunnelService, { uid: boxId, service: service })
    }

    static closeTunnelService(uid, service) {
        return ApiService.fetch(routes.closeTunnelService, { uid: uid, service: service })
    }

    static saveBoxNotes(uid, notes) {
        return ApiService.fetch(routes.saveBoxNotes, { uid: uid }, { body: JSON.stringify(notes) })
    }

    static restartPFM() {
        return ApiService.fetch(routes.restartPFM)
    }

    static getDockerContainers() {
        return ApiService.fetch(routes.getDockerContainers)
    }

    static restartDockerContainerById(id) {
        return ApiService.fetch(routes.restartDockerContainerById, { id: id })
    }

    static getPFMConfiguration() {
        return ApiService.fetch(routes.getPFMConfig)
    }

    static setPFMConfiguration(data) {
        return ApiService.fetch(routes.setPFMConfig, {}, { body: JSON.stringify(data) })
    }

    static pfmSendTestNotification(json) {
        return ApiService.fetch(routes.pfmSendTestNotification, {}, { body: JSON.stringify(json) })
    }

    static pfmSendMail(json) {
        return ApiService.fetch(routes.pfmSendMail, {}, { body: JSON.stringify(json) })
    }

    static pfmSendSMS(json) {
        return ApiService.fetch(routes.pfmSendSMS, {}, { body: JSON.stringify(json) })
    }

    static getTags() {
        return ApiService.fetch(routes.getTags)
    }

    static saveTag(tag) {
        if (tag.uid) {
            return ApiService.fetch(routes.updateTag, { uid: tag.uid }, { body: JSON.stringify(tag) })
        } else {
            return ApiService.fetch(routes.createTag, {}, { body: JSON.stringify(tag) })
        }
    }

    static removeTag(tag) {
        return ApiService.fetch(routes.removeTag, { uid: tag.uid })
    }

    static getCommunityTags(uid) {
        return ApiService.fetch(routes.getCommunityTags, { uid })
    }

    static getTemplateCommunities(uid) {
        return ApiService.fetch(routes.getTemplateCommunities, { uid })
    }

    static getTagsForTemplateType(uid) {
        return ApiService.fetch(routes.getTagsForTemplateType, { uid })
    }

    static updateTemplateData(data) {
        return ApiService.fetch(routes.updateTemplateData, { uid: data.uid }, { body: JSON.stringify(data) })
    }

    static requestStats(query) {
        return ApiService.fetch(routes.requestStats, {}, { body: JSON.stringify(query) })
    }

    static setConfig(nextConfig) {
        return ApiService.fetch(routes.setConfig, {}, { body: JSON.stringify(nextConfig) })
    }

    static getBoxPasswords(box_uid) {
        return ApiService.fetch(routes.getBoxPasswords, { box_uid })
    }

    static putBoxPassword(box_uid, entry) {
        return ApiService.fetch(routes.putBoxPassword, { box_uid }, { body: JSON.stringify(entry) })
    }

    static removeBoxPassword(box_uid, password_id) {
        return ApiService.fetch(routes.removeBoxPassword, { box_uid, password_id })
    }

    static getSharedFiles() {
        return ApiService.fetch(routes.getSharedFiles)
    }

    static deleteSharedFiles(file_uid) {
        return ApiService.fetch(routes.deleteSharedFile, { file_uid })
    }

    static modifySharedFileExpiration(file_uid, obj) {
        return ApiService.fetch(routes.modifySharedFile, { file_uid }, { body: JSON.stringify(obj) })
    }

    static uploadSharedFiles(fd, progressCallback) {
        const options = {
            body: fd
        }
        if (progressCallback) {
            options.onUploadProgress = progressCallback
        }
        return ApiService.fetch(routes.uploadSharedFiles, {}, options)
    }

    static launchTransferTest(box_id, obj) {
        return ApiService.fetch(routes.transferTest, { box_id }, { body: JSON.stringify(obj) })
    }

    static getTrace(trace_uid) {
        return ApiService.fetch(routes.getTrace, { trace_uid })
    }

    static getDownloadSpeeds(boxId, nbDays) {
        return ApiService.fetch(routes.downloadSpeeds, { id: boxId, nbDays: nbDays })
    }

    static getUploadSpeeds(boxId, nbDays) {
        return ApiService.fetch(routes.uploadSpeeds, { id: boxId, nbDays: nbDays })
    }
}

export default ApiService
