import ApiService from '../../core/ApiService'
import { Button } from '../UI/Toolkit'
import Modale from '../../components/UI/ModaleComponent'
import React from 'react'
import Table from '../Table'

class TracesList extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            data: null
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.forceRefresh) this.table.forceRefresh()
    }

    showTrace(trace) {
        ApiService.getTrace(trace.id)
            .then(details => {
                this.setState({ data: details })
            })
    }

    render() {
        return (
            <div style={{ flex: 1, flexDirection: 'column', overflow: 'auto', padding: '1rem' }}>
                <Table
                    filter={this.props.filter || []}
                    ref={table => this.table = table}
                    uid={'traces'}
                    defaultConfig={this.props.configuration}
                    endPoint={this.props.endPoint}
                    csvFilename="traces"
                    getTdProps={(state, row, column, instance) => {
                        return {
                            onClick: e => {
                                if (row && row.original)
                                    this.showTrace(row.original)
                            }
                        }
                    }}
                    getTrProps={(state, rowInfo, column) => {
                        const style = { className: ['clickable_row'] }
                        if (rowInfo && !rowInfo.original.is_read) {
                            style.className.push('not-read')
                        }
                        return style
                    }}
                    onRows={this.onRows}
                />

                <Modale show={this.state.data !== null} onHide={() => {
                    this.setState({ data: null })
                }}>
                    <Modale.Header>
                        Trace details
                    </Modale.Header>
                    <Modale.Body style={{ width: '600px' }}>
                        {this.state.data &&
                            (
                                <div className="TraceDetails">
                                    <div>Message: {this.state.data.data_txt}</div>
                                    {this.state.data.data_json
                                        ? <div>Details:
                                            <pre style={{ fontSize: '.85rem', fontFamily: 'monospace' }}>{JSON.stringify(
                                                JSON.parse(this.state.data.data_json)
                                                , undefined, 2)}</pre>
                                        </div>
                                        : ''
                                    }
                                </div>
                            )
                        }
                    </Modale.Body>
                    <Modale.Footer>
                        <div style={{ flex: 1 }} />
                        <Button style={{ float: 'left' }} onClick={() => { this.setState({ data: null }) }}>
                            Close
                        </Button>
                    </Modale.Footer>
                </Modale>
            </div>
        )
    }
}

export default TracesList
