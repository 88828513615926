import React from 'react'

import ic_docker from '../../img/ic_docker.svg'
import ic_filetype_default from '../../img/ic_filetype_default.svg'
import ic_filetype_shellscript from '../../img/ic_filetype_shellscript.svg'
import ic_filetype_ini from '../../img/ic_filetype_ini.svg'
import ic_filetype_zip from '../../img/ic_filetype_zip.svg'
import ic_filetype_exe from '../../img/ic_filetype_exe.svg'

class Icon extends React.Component {
    constructor(props) {
        super(props)

        this.state = {...props}
    }

    render () {
        let icon = null
        switch(String(this.props.for).toLowerCase().split('.').slice(-1)[0]) {
            case 'gz':
                icon = ic_docker
                break
            case 'sh':
                icon = ic_filetype_shellscript
                break
            case 'ini':
                icon = ic_filetype_ini
                break
            case 'zip':
                icon = ic_filetype_zip
                break
            case 'exe':
                icon = ic_filetype_exe
                break
            default:
                icon = ic_filetype_default
        }

        return <img src={icon} alt="" style={{height: '2rem', width: '2rem'}} />
    }
}

export default Icon