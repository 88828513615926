import * as semver from 'semver'
import ApiService from '../../core/ApiService'
import BoxComponent from './index.js'
import React from 'react'
import Alfred from '../../core/Alfred'
import WebSocketClient from '../../core/WebSocketClient'
import Tag from '../UI/Tag'
import { Button } from '../UI/Toolkit'
import { withRouter } from 'react-router-dom'
import Modale from '../../components/UI/ModaleComponent'
import Services from './services'
import Picker from '../../components/UI/Picker'
import TagPickerElement from '../../components/UI/PickerTransformedComponents/Tag'
import Helpers from '../../core/Helpers'
import ic_pen from '../../img/ic_pen.svg'

import ic_conn_KO from '../../img/ic_conn_KO-white.svg'
import ic_conn_OK from '../../img/ic_conn_OK.svg'

import ic_os_linux from '../../img/ic_os_linux.svg'
import ic_os_undefined from '../../img/ic_os_undefined.svg'
import ic_os_windows from '../../img/ic_os_windows.svg'

import ic_communities from '../../img/ic_community.svg'

import ic_tunnel_closed from '../../img/ic_tunnel_closed.svg'
import ic_tunnel_open from '../../img/ic_tunnel_open.svg'

import ic_roundtrip from '../../img/ic_roundtrip.svg'

import './BoxDetailsContainer.css'

class BoxDetailsContainer extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            tunnel: undefined,
            data: undefined
        }

        this.init = this.init.bind(this)
        this.updateBox = this.updateBox.bind(this)
        this.disconnectBox = this.disconnectBox.bind(this)
        this.openVNC = this.openVNC.bind(this)
        this.openTunnel = this.openTunnel.bind(this)
        this.closeTunnel = this.closeTunnel.bind(this)
        this.getTunnelStatus = this.getTunnelsStatus.bind(this)
        this.saveBoxNotes = this.saveBoxNotes.bind(this)
    }

    componentWillMount() {
        this.init()
    }

    componentWillReceiveProps(nextProps) {
        const message = nextProps.message
        const box_uid = this.state.data ? parseInt(this.state.data.uid) : -1
        if (message && (message.id === box_uid || message.destination_id === box_uid)) {
            switch (message.type) {
                case WebSocketClient.messageTypes.box_status:
                    this.setState({
                        data: {
                            ...this.state.data,
                            connected: message.connected,
                            conductor_connected: message.conductor_connected,
                            name: message.name,
                            system: message.system,
                            version: message.version
                        }
                    })
                    break
                case WebSocketClient.messageTypes.tunnel:
                    this.setState({
                        tunnel: message
                    })
                    break
                default:
                    break
            }
        }
    }

    saveBoxNotes(uid, notes) {
        return ApiService.saveBoxNotes(uid, notes).then(response => {
            this.setState({ data: { ...this.state.data, ...response } })
        })
    }

    openTunnel() {
        ApiService.postBoxTunnelService(this.props.match.params.boxId, 'vnc')
            .then((tunnel) => {
                this.setState({ tunnel }, this.openVNC)
            })
            .catch(err => {
                alert(err.toString())
            })
    }

    closeTunnel() {
        ApiService.closeTunnelService(this.props.match.params.boxId, 'vnc')
            .then((tunnel) => {
                this.setState({ tunnel })
            })
            .catch(err => {
                alert(err.toString())
            })
    }

    openWebTunnel = () => {
        ApiService.postBoxTunnelService(this.props.match.params.boxId, 'web')
            .then((tunnel_web) => {
                this.setState({ tunnel_web }, this.openWeb)
            })
            .catch(err => {
                alert(err.toString())
            })
    }

    closeWebTunnel = () => {
        ApiService.closeTunnelService(this.props.match.params.boxId, 'web')
            .then((tunnel_web) => {
                this.setState({ tunnel_web })
            })
            .catch(err => {
                alert(err.toString())
            })
    }

    async getTunnelsStatus() {
        if (this.state.data) {
            ApiService.getBoxTunnelService(this.state.data.uid, 'vnc')
                .then((tunnel) => {
                    this.setState({ tunnel })
                }).catch(() => {
                    this.setState({ tunnel: null })
                })
            await ApiService.getBoxTunnelService(this.state.data.uid, 'web')
                .then((tunnel_web) => {
                    this.setState({ tunnel_web })
                }).catch(() => {
                    this.setState({ tunnel_web: null })
                })
        }
    }

    updateBox(box) {
        return ApiService.updateBox(box.uid, {
            name: box.name,
            active: box.active,
            token: box.token,
            position: box.position,
            tags: box.tags
        })
            .then((response) => {
                this.init()
            }).catch(err => {
                alert(err)
            })
    }

    disconnectBox(uid) {
        return ApiService.disconnectBox(uid)
            .then((response) => {
                return true
            }).catch(err => {
                alert(err)
            })
    }

    init() {
        ApiService.getBoxDetails(this.props.match.params.boxId).then((data) => {
            if (data.uid) {
                this.setState({
                    data,
                    showModaleBoxEdit: false
                }, () => {
                    this.getTunnelsStatus()
                    setTimeout(() => {
                        this.getTunnelsStatus()
                    }, this.state.data ? 5000 : 500)
                })
            }
        })

        ApiService.getBoxStatus(this.props.match.params.boxId).then((data) => {
            this.setState({
                roundtrip: data.roundtrip,
                versions: data.versions
            })
        })

        this.props.updateBreadcrumb('MediaBox Details', null)
    }

    openVNC() {
        window.open(`/monitor/boxes/${this.props.match.params.boxId}/vnc?name=${this.state.data.name}`, this.props.match.params.boxId + '-VNC', "height=800,width=1280,location=0,status=0")
    }

    openWeb() {
        window.open(`${document.location.protocol}//${document.location.host}/web/port/${this.state.tunnel_web.local_port}/`, this.props.match.params.boxId + '-WEB', "height=800,width=1280,location=0,status=0")
    }

    updateBoxTags = (tags) => {
        this.setState({
            data: { ...this.state.data, tags }
        }, this.forceUpdate)
    }

    chooseTags = (tags) => {
        this.setState({
            showModaleBoxEdit: { ...this.state.showModaleBoxEdit, tags }
        })
    }

    renderVersion() {
        if (this.state.versions === undefined) {
            return <small>version: {this.state.data.box_version}</small>
        } else {
            return <span className='foldable withactions'><small>version: {this.state.data.box_version}</small>
                <div className="fold" style={{ transform: 'translate(15px, 25px)' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                        {Object.keys(this.state.versions).map(key => {
                            return <div key={key} style={{ marginBottom: '.5rem' }}>
                                {key}:<br />
                                <small><i>{this.state.versions[key].replaceAll('+', '+\u200B')}</i></small>
                            </div>
                        })}
                    </div>
                </div>
            </span>
        }
    }

    renderTags = (nbMaxVisible) => {
        nbMaxVisible = nbMaxVisible || 4
        const selectedTags = (this.state.data.tags || []).filter((tag) => tag._selected).sort((a, b) => {
            if (a.domain_type === 'template' && b.domain_type !== 'template') return 1
            if (a.domain_type !== 'template' && b.domain_type === 'template') return -1
            return a.name.length - b.name.length
        }) || []

        const visibleTags = selectedTags.slice(0, nbMaxVisible)
        const extraTags = selectedTags.slice(nbMaxVisible)
        return <div>
            {visibleTags.map((tag, index) => {
                return <span key={index}><Tag {...tag} />&nbsp;</span>
            })}
            {extraTags.length
                ? <span className='foldable withactions' style={{ border: '1px solid rgba(0,0,0,.05)', padding: '.33rem', borderRadius: '2rem', display: 'inline-flex', alignItems: 'center', width: 'auto' }}>
                    <small>+ {extraTags.length} other{extraTags.length > 1 ? 's' : ''}</small>
                    <div className="fold" style={{ transform: 'translate(15px, 25px)' }}>
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                            <div>{extraTags.map((tag, index) => {
                                return <Tag {...tag} key={index} />
                            })}</div>
                        </div>
                    </div>
                </span>
                : ''
            }
        </div>
    }

    render() {
        const data = this.state.data
        if (!data) { return null }
        let canUseWebTunnel = (data.box_version === 'dev' || (semver.valid(data.box_version) && semver.gte(data.box_version, '1.5.0')))
        let os_icon = ic_os_undefined
        switch (data.system) {
            case 'linux':
                os_icon = ic_os_linux
                break
            case 'windows':
                os_icon = ic_os_windows
                break
            default:
                os_icon = ic_os_undefined
                break
        }

        const wfm_connected = data.connected
        const conductor_connected = data.conductor_connected
        const communities = data.communities.sort((a, b) => a.creation_timestamp > b.creation_timestamp ? 1 : -1)

        const hasDisconnectWfMgrButton = Alfred.askPermission('MAINTAINANCE_DISCONNECT_BOX', Alfred.R) && wfm_connected

        return (
            <div style={{ flex: 1, display: 'flex', flexDirection: 'column', overflow: 'hidden', minWidth: 'calc(1000px - 170pt)' }}>
                <div id="fakeBreadcrumb">
                    <div style={{ display: 'flex', flex: 1, alignItems: 'center', flexDirection: 'row' }}>
                        <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                            <h1 style={{ flex: 1, display: 'flex', alignItems: 'center', maxWidth: '190pt', fontSize: '1.5rem' }}>
                                <img src={os_icon} style={{ width: '1.5rem', verticalAlign: 'middle' }} alt="" />
                                &nbsp;
                                <div title={data.name} style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{data.name || <i style={{ opacity: .5 }}>Unnamed box</i>}</div>
                                {(Helpers.getCurrentUser().admin > 1 || Alfred.askPermission('MONITOR_BOXES', Alfred.W)) &&
                                    <Button className={'editButton'} bsStyle="link" style={{ display: 'flex', alignItems: 'center', alignSelf: 'center', padding: '0 .5rem 0 .5rem' }} onClick={() => { this.setState({ showModaleBoxEdit: JSON.parse(JSON.stringify(this.state.data)) }) }}>
                                        <img alt="" src={ic_pen} style={{ height: '2rem' }} />
                                    </Button>
                                }
                            </h1>
                            <span><small>uid: {data.uid},</small> {this.renderVersion()}</span>
                            {this.renderTags(3)}
                        </div>

                        <div className={`foldable ${communities.length ? 'withactions' : ''}`}>
                            <button onClick={(e) => { e.target.focus() }} className="unfold">
                                <img title="Communities" alt="Communities" src={ic_communities} style={{ height: '2.5rem', margin: '.25rem', background: 'var(--color-primary)', borderRadius: '50%', padding: '.5rem' }} />
                                <small>
                                    {communities.length ?
                                        <div title={communities[0].name} style={{ overflow: 'hidden', whiteSpace: 'nowrap', maxWidth: '96px', textOverflow: 'ellipsis' }}>{communities[0].name}</div>
                                        : <i>No community</i>}
                                </small>
                                <small style={{ color: 'var(--color-primary)' }}>
                                    {communities.length > 1
                                        ? `+ ${communities.length - 1} other${communities.length > 2 ? 's' : ''}`
                                        : <span>&nbsp;</span>
                                    }
                                </small>
                            </button>
                            {communities.length > 1
                                ? <div className="fold">
                                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                                        {communities.map((c, cId) => {
                                            return <div key={c.uid} style={{ marginBottom: '.25rem', opacity: cId === 0 ? .5 : 1 }}>{c.name}</div>
                                        })}
                                    </div>
                                </div>
                                : ''
                            }
                        </div>

                        <div style={{ width: '1pt', background: 'rgba(0,0,0,.1)', height: '3rem' }}></div>

                        <Services autorefresh={this.state.data.conductor_connected} box={this.state.data} />

                        <div className={`foldable connection ${wfm_connected ? '' : 'error'} ${Alfred.askPermission('MAINTAINANCE_DISCONNECT_BOX', Alfred.R) && wfm_connected ? 'withactions' : ''}`}>
                            <button onClick={(e) => { e.target.focus() }} className="unfold">
                                {
                                    wfm_connected
                                        ? <img alt="Connected" src={ic_conn_OK} style={{ height: '2rem', margin: '.5rem' }} />
                                        : <img alt="Disconnected" src={ic_conn_KO} style={{ height: '2rem', margin: '.5rem' }} />
                                }
                                {
                                    this.state.roundtrip
                                        ? <small style={{ position: 'absolute', right: '1ch', top: '2rem', fontSize: '.6rem', padding: '.15rem .4rem', borderRadius: '1.5rem', background: 'rgba(255,255,255,.75)', color: 'rgba(0,0,0,.5)', display: 'flex', alignItems: 'center' }} title={'Roundtrip'}>
                                            <img alt="" src={ic_roundtrip} style={{ height: '1.1em', width: '1.1em', opacity: .5 }} />
                                            {this.state.roundtrip} ms
                                        </small>
                                        : ''
                                }
                                <small>WFManager</small>
                                <small>{wfm_connected
                                    ? <span style={{ opacity: .6, whiteSpace: 'nowrap' }}>online</span>
                                    : <b>disconnected</b>}</small>
                            </button>

                            {
                                hasDisconnectWfMgrButton &&
                                <div className="fold">
                                    {
                                        hasDisconnectWfMgrButton
                                            ? <Button bsStyle="danger" onClick={() => { this.setState({ showModaleDisconnect: true }) }}>Disconnect</Button>
                                            : ''
                                    }
                                </div>
                            }
                        </div>

                        <div className={`foldable connection ${conductor_connected ? '' : 'error'}`}>
                            <button onClick={(e) => { e.target.focus() }} className="unfold">
                                {
                                    conductor_connected
                                        ? <img alt="Connected" src={ic_conn_OK} style={{ height: '2rem', margin: '.5rem' }} />
                                        : <img alt="Disconnected" src={ic_conn_KO} style={{ height: '2rem', margin: '.5rem' }} />
                                }
                                <small>Conductor</small>
                                <small>{conductor_connected ? <span style={{ opacity: .6 }}>online</span> : <b>disconnected</b>}</small>
                            </button>
                        </div>

                        <div style={{ width: '1pt', background: 'rgba(0,0,0,.1)', height: '3rem' }}></div>

                        {Alfred.askPermission('MAINTAINANCE_VNC_REMOTE', Alfred.R)
                            ? <div className={`foldable withactions`}>
                                <button onClick={(e) => { e.target.focus() }} className="unfold">
                                    {this.state.tunnel && this.state.tunnel.tunnel_id
                                        ? <img alt="Connected" src={ic_tunnel_open} style={{ height: '2rem', margin: '.5rem' }} />
                                        : <img alt="Disconnected" src={ic_tunnel_closed} style={{ height: '2rem', margin: '.5rem' }} />
                                    }
                                    <div className="bullets">
                                        {this.state.tunnel && this.state.tunnel.tunnel_id && this.state.tunnel.nb_connections
                                            ? <div className="bullet error" title='Connections open'>{this.state.tunnel.nb_connections}</div>
                                            : ''
                                        }
                                    </div>
                                    <small>Remote control</small>
                                    {this.state.tunnel && this.state.tunnel.tunnel_id
                                        ? <small ><b style={{ color: '#00d6a2' }}>Tunnel is open</b></small>
                                        : <small style={{ opacity: .6, display: 'block', whiteSpace: 'nowrap' }}>Tunnel is closed</small>
                                    }
                                </button>
                                <div className="fold" >
                                    {this.state.tunnel && this.state.tunnel.tunnel_id
                                        ? <div style={{ display: 'flex', flexDirection: 'column' }}>
                                            <Button bsStyle="primary" onClick={() => { this.openVNC() }}>Open VNC session</Button>
                                            <Button bsStyle="default" style={{ border: 'none', color: 'red' }} onClick={() => { this.closeTunnel() }}>
                                                Close tunnel
                                            </Button>
                                        </div>
                                        : <Button bsStyle="primary" onClick={() => { this.openTunnel() }}>Start session</Button>
                                    }
                                </div>

                            </div>
                            : ''}

                        {Alfred.askPermission('MAINTAINANCE_VNC_REMOTE', Alfred.R)
                            ? <div className={`foldable withactions`}>
                                <button onClick={(e) => { e.target.focus() }} className="unfold" disabled={!canUseWebTunnel} title={canUseWebTunnel ? '' : 'Box version must be at least 1.5.0'}>
                                    {this.state.tunnel_web && this.state.tunnel_web.tunnel_id
                                        ? <img alt="Connected" src={ic_tunnel_open} style={{ height: '2rem', margin: '.5rem' }} />
                                        : <img alt="Disconnected" src={ic_tunnel_closed} style={{ height: '2rem', margin: '.5rem', opacity: canUseWebTunnel ? 1 : .2 }} />
                                    }
                                    <small>Web Access</small>
                                    {this.state.tunnel_web && this.state.tunnel_web.tunnel_id
                                        ? <small ><b style={{ color: '#00d6a2' }}>Tunnel is open</b></small>
                                        : <small style={{ opacity: .6, display: 'block', whiteSpace: 'nowrap' }}>Tunnel is closed</small>
                                    }
                                </button>
                                {canUseWebTunnel
                                    ? <div className="fold" >
                                        {this.state.tunnel_web && this.state.tunnel_web.tunnel_id
                                            ? <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                <Button bsStyle="primary" onClick={() => { this.openWeb() }}>Open Web UI</Button>
                                                <Button bsStyle="default" style={{ border: 'none', color: 'red' }} onClick={() => { this.closeWebTunnel() }}>
                                                    Close tunnel
                                                </Button>
                                            </div>
                                            : <Button bsStyle="primary" onClick={() => { this.openWebTunnel() }}>Open tunnel</Button>
                                        }
                                    </div>
                                    : ''}

                            </div>
                            : ''}

                        <Modale show={!!this.state.showModaleBoxEdit}>
                            <Modale.Header>
                                <div>Edit MediaBox</div>
                                <div style={{ flex: 1 }}></div>
                                <div style={{ color: 'black', display: 'flex', alignItems: 'center' }}>
                                    <small>status&nbsp;:&nbsp;</small>
                                    <select disabled={Helpers.getCurrentUser().admin < 1 || !Alfred.askPermission('MONITOR_BOXES', Alfred.W)}
                                        value={this.state.showModaleBoxEdit.active}
                                        onChange={(e) => { this.setState({ data: Object.assign(this.state.showModaleBoxEdit, { active: JSON.parse(e.target.value) }) }) }}
                                    >
                                        <option value={true}>Active</option>
                                        <option value={false}>Inactive</option>
                                    </select>
                                </div>
                            </Modale.Header>
                            {this.state.data &&
                                <Modale.Body>
                                    <div style={{ display: 'flex', width: '400pt', userSelect: 'none' }}>
                                        <div style={{ flex: 1, paddingTop: '1rem' }}>
                                            <label style={{ width: '100%' }}>
                                                Name
                                                <input
                                                    placeholder="MediaBox Name"
                                                    className="form-control"
                                                    type="text"
                                                    defaultValue={this.state.showModaleBoxEdit.name}
                                                    style={{ fontWeight: 'normal' }}
                                                    readOnly={Helpers.getCurrentUser().admin < 1 || !Alfred.askPermission('MONITOR_BOXES', Alfred.W)}
                                                    onInput={(e) => {
                                                        this.setState({
                                                            data: Object.assign(this.state.showModaleBoxEdit, { name: e.target.value })
                                                        })
                                                    }}
                                                />
                                            </label>

                                            <label style={{ width: '100%' }}>
                                                Token <small>(24 chars)</small>
                                                <input
                                                    placeholder="MediaBox Token"
                                                    className="form-control"
                                                    maxLength={24}
                                                    readOnly={Helpers.getCurrentUser().admin < 1 || !Alfred.askPermission('MONITOR_BOXES', Alfred.W)}
                                                    type="text"
                                                    defaultValue={this.state.showModaleBoxEdit.token}
                                                    style={{ fontWeight: 'normal' }}
                                                    onInput={(e) => {
                                                        this.setState({
                                                            data: Object.assign(this.state.showModaleBoxEdit, { token: e.target.value })
                                                        })
                                                    }}
                                                />
                                            </label>

                                            <label style={{ width: '100%' }}>
                                                Position <small>(24 chars)</small>
                                                <input
                                                    placeholder="GPS coordinates"
                                                    className="form-control"
                                                    maxLength={24}
                                                    readOnly={Helpers.getCurrentUser().admin < 1 || !Alfred.askPermission('MONITOR_BOXES', Alfred.W)}
                                                    type="text"
                                                    defaultValue={this.state.showModaleBoxEdit.position}
                                                    style={{ fontWeight: 'normal' }}
                                                    onInput={(e) => {
                                                        this.setState({
                                                            data: Object.assign(this.state.showModaleBoxEdit, { position: e.target.value })
                                                        })
                                                    }}
                                                />
                                            </label>
                                        </div>
                                        <div style={{ flex: 1, paddingTop: 0, marginLeft: '1rem' }}>
                                            <Picker style={{ flex: 1 }} title="Tags (Box level)" filterkey={['name', 'uid']}
                                                items={(this.state.showModaleBoxEdit.tags || [])} onChange={this.chooseTags}
                                                ukey="uid" sortBy="name" renderItem={(item) => {
                                                    return <TagPickerElement {...item} />
                                                }} />
                                        </div>
                                    </div>
                                </Modale.Body>
                            }
                            <Modale.Footer>
                                <Button bsStyle="default" onClick={() => { this.setState({ showModaleBoxEdit: false }) }} >Cancel</Button>
                                <div style={{ flex: 1 }}></div>
                                <Button style={{ margin: 'auto' }} bsStyle="primary" onClick={() => { this.updateBox(this.state.showModaleBoxEdit); this.setState({ showModaleBoxEdit: false }) }}>Save</Button>
                            </Modale.Footer>
                        </Modale>

                        <Modale show={this.state.showModaleDisconnect}>
                            <Modale.Header>
                                Remote disconnect
                            </Modale.Header>
                            <Modale.Body>
                                <p>You are about to remotely disconnect this box from the platform.</p>
                                <p><small>(Please note that you should normally never have to do so)</small></p>
                                <p>Anyway, this action will be logged under your name. Do you still want to proceed ?</p>
                            </Modale.Body>
                            <Modale.Footer>
                                <Button onClick={() => { this.setState({ showModaleDisconnect: false }) }}>Cancel</Button>
                                <div style={{ flex: 1 }}></div>
                                <Button bsStyle="danger" onClick={() => { this.disconnectBox(data.uid).then(res => { this.setState({ showModaleDisconnect: false }) }) }}>Disconnect</Button>
                            </Modale.Footer>
                        </Modale>
                    </div>
                </div>

                <BoxComponent boxId={this.props.match.params.boxId}
                    {...this.state}
                    onChange={this.updateBox}
                    disconnect={this.disconnectBox}
                    openVNC={this.openVNC}
                    openTunnel={this.openTunnel}
                    closeTunnel={this.closeTunnel}
                    getTunnelStatus={this.getTunnelsStatus}
                    saveBoxNotes={this.saveBoxNotes}
                    updateBoxTags={this.updateBoxTags}
                >
                </BoxComponent>
            </div>
        )
    }
}

export default withRouter(WebSocketClient.withWebSocket(BoxDetailsContainer, (props) => [WebSocketClient.messageTypes.tunnel, WebSocketClient.messageTypes.box_status]))
