import './BoxVNC.css'

import Alfred from '../core/Alfred'
import ApiService from '../core/ApiService'
import { Button } from '../components/UI/Toolkit'
import Draggable from 'react-draggable';
import Helpers from '../core/Helpers'
import RFB from '@novnc/novnc/core/rfb'
import React from 'react'
import WebSocketClient from '../core/WebSocketClient'
import ic_CtrlAltDel from '../img/ic_ctrl_alt_del.svg'
import ic_close from '../img/ic_close.svg'
import ic_disconnect from '../img/ic_disconnect_vnc.svg'
import ic_fullscreen from '../img/ic_fullscreen.svg'
import ic_note_empty from '../img/ic_note_add-white.svg'
import ic_note_full from '../img/ic_note_full-white.svg'
import ic_pen from '../img/ic_pen.svg'
import ic_clipper from '../img/ic_clipper.svg'
import ic_reload from '../img/ic_refresh.svg'
import ic_passwords from '../img/ic_passwordmgr.svg'
import ic_typein from '../img/ic_typein.svg'
import img_loading from '../img/img_loading.svg'
import marked from 'marked'

import Keyboard from './BoxVNCVirtualKeyboard'

const XK_Shift_L = 65505    // https://docs.rs/x11-dl/1.0.1/x11_dl/keysym/constant.XK_Shift_L.html
const XK_Control_L = 65507     // https://docs.rs/x11-dl/1.0.1/x11_dl/keysym/constant.XK_Control_L.html
/* const XK_Backspace = 65288  // https://docs.rs/x11-dl/1.0.1/x11_dl/keysym/constant.XK_BackSpace.html
const XK_Return = 65293     // https://docs.rs/x11-dl/1.0.1/x11_dl/keysym/constant.XK_Return.html
const XK_Escape = 65307     // https://docs.rs/x11-dl/1.0.1/x11_dl/keysym/constant.XK_Escape.html */
class BoxVNC extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            box: {},
            showNotes: false,
            showKeyboard: false,
            keyboardOpacity: 1,
            editMode: false,
            passwords: []
        }

        this.rfb = {}

        this.onSaveNotes = this.onSaveNotes.bind(this)

        this.vncClipboardReceive = this.vncClipboardReceive.bind(this)
    }

    hasTouchScreen = () => {
        return (('ontouchstart' in window) ||
            (navigator.maxTouchPoints > 0) ||
            (navigator.msMaxTouchPoints > 0))
    }

    componentWillReceiveProps(nextProps) {
        const message = nextProps.message
        if (message) {
            if (message.destination_id !== parseInt(this.props.match.params.boxId)) return
            this.setState({
                local_port: message.vnc
            })
        }
    }

    onSaveNotes() {
        const vnc_notes = this.input_vnc_notes.value
        const uid = this.state.box.uid

        const notes = {
            vnc_notes
        }

        ApiService.saveBoxNotes(uid, notes).then(response => {
            this.setState({
                box: { ...this.state.box, ...response },
                editMode: false
            }, this.forceUpdate)
        })
    }

    vncClipboardReceive(e) {
        this.setState({
            clipboard: e.detail.text || ""
        }, async () => {
            await navigator.clipboard.writeText(e.detail.text || "").catch(err => console.error)
        })
    }

    vncClipboardSend() {
        navigator.clipboard.readText().then(text => {
            this.setState({
                clipboard: text
            },
                this.rfb.clipboardPasteFrom(text)
            )
        }).catch(err => { console.error(err) })
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextState.local_port && nextState.local_port !== this.state.local_port) {
            setTimeout(() => {
                const protocol = document.location.protocol === 'http:' ? 'ws:' : 'wss:'
                this.rfb = new RFB(document.getElementById('screen'),
                    `${protocol}//${document.location.host}/vnc?token=${Helpers.getSession().token}&boxId=${this.props.match.params.boxId}&port=${nextState.local_port}&logging=debug`
                    , {
                        resizeSession: true
                    })
                this.rfb.addEventListener("clipboard", this.vncClipboardReceive);
                this.rfb.addEventListener("credentialsrequired", () => {
                    Helpers.pushNotification({
                        title: 'Authenticating',
                        message: `The remote VNC server require authentication, attempting to send password...`,
                        level: 'info'
                    })
                    this.rfb.sendCredentials({ password: 'password' });
                });
                this.rfb.addEventListener('disconnect', (e) => {
                    console.error(e);
                });
                this.rfb.resizeSession = true
            }, 200)
        }
        return true
    }

    componentDidMount = async () => {
        const boxId = this.props.match.params.boxId
        ApiService.getBoxDetails(boxId).then((data) => {
            if (data.uid) {
                this.setState({
                    box: data
                }, () => {
                    this.forceUpdate()
                    document.title = this.state.box.name + ' - VNC Session'
                }
                )
            }
        })
        ApiService.getBoxPasswords(boxId).then((passwords) => {
            this.setState({ passwords })
        })
        await ApiService.getBoxTunnelService(boxId, 'vnc').then((tunnelState) => {
            this.setState({
                local_port: tunnelState.local_port
            })
        })
            .catch(err => {
                console.warn(err)
            })

        document.addEventListener('copy', this.vncClipboardSend);
        document.addEventListener('paste', this.vncClipboardSend);
        document.addEventListener('keydown', (e) => {
            if (e.keyCode === 86 && e.ctrlKey) {
                this.vncClipboardSend()
            }
        });
    }

    sendCtrlAltDel() {
        if (this.rfb) {
            this.rfb.sendCtrlAltDel()
        }
    }

    toggleFullScreen() {
        const elem = document.body
        if (!document.fullscreenElement && !document.mozFullScreenElement &&
            !document.webkitFullscreenElement && !document.msFullscreenElement) {
            if (elem.requestFullscreen) {
                elem.requestFullscreen();
            } else if (elem.msRequestFullscreen) {
                elem.msRequestFullscreen();
            } else if (elem.mozRequestFullScreen) {
                elem.mozRequestFullScreen();
            } else if (elem.webkitRequestFullscreen) {
                elem.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT);
            }
        } else {
            if (document.exitFullscreen) {
                document.exitFullscreen();
            } else if (document.msExitFullscreen) {
                document.msExitFullscreen();
            } else if (document.mozCancelFullScreen) {
                document.mozCancelFullScreen();
            } else if (document.webkitExitFullscreen) {
                document.webkitExitFullscreen();
            }
        }
    }

    setHighestPopup(div) {
        Array.from(document.querySelectorAll('.react-draggable')).forEach(popup => {
            popup.style.zIndex = 1;
        })
        if (div) {
            div.style.zIndex = 999
        }
    }

    renderPasswordManager = () => {
        return (
            <div id="passwordManagerWrapper" className={`foldable ${this.state.passwords.length > 0 ? 'withactions' : ''}`}>
                <button
                    id="passwordManagerButton"
                    disabled={this.state.passwords.length === 0}
                    title={this.state.passwords.length > 0 ? null : 'No password saved for this box'}
                    style={{ background: 'transparent', border: 'none', outline: 'none', cursor: this.state.passwords.length > 0 ? '' : 'not-allowed', filter: this.state.passwords.length > 0 ? '' : 'opacity(.3)' }}
                    onClick={(e) => { e.target.focus() }}>
                    <img alt="" src={ic_passwords} style={{ height: '1.75rem' }} />
                </button>
                {this.state.passwords.length > 0
                    ? <div className="fold">
                        <small>Password Manager</small>
                        {this.state.passwords.map((pwd, pwdIndex) => {
                            return (<div className="passwordManagerEntryWrapper" key={pwdIndex}>
                                <div>
                                    <label style={{ lineHeight: '90%', display: 'block', padding: 0, margin: 0 }}>{pwd.name}</label>
                                    <code className="clearPass" style={{ lineHeight: '90%', padding: 0, margin: 0 }}>{pwd.pass}</code>
                                    <code className="hiddnPass" style={{ fontWeight: 'bold', lineHeight: '90%', padding: 0, margin: 0 }}>{pwd.pass.split('').map(c => '*').join('')}</code>
                                </div>
                                <Button title="Copy to clipboard" onClick={() => { this.sendToClipboards(pwd.pass) }}>
                                    <img src={ic_clipper} alt="" style={{ filter: 'invert(80%)' }} />
                                </Button>
                                <Button title="Send key presses" onClick={() => { this.sendKeyPresses(pwd.pass, true) }}>
                                    <img src={ic_typein} alt="" style={{ filter: 'invert(80%)' }} />
                                </Button>
                            </div>)
                        })}
                    </div>
                    : ''
                }
            </div>
        )
    }

    sendToClipboards = (text) => {
        navigator.clipboard.writeText(text)
        this.rfb.clipboardPasteFrom(text)
    }

    sendKeyPresses = (str, pressOnly) => {
        // console.log(`Should sent text: ${str}`)
        const f = (t) => {
            const character = t.shift();
            const code = character.charCodeAt();
            const needs_shift = character.match(/[A-Z!@#$%^&*()_+{}:">?~|]/);
            if (needs_shift) {
                this.rfb.sendKey(XK_Shift_L, 1);
            }
            this.rfb.sendKey(code, 1);
            !pressOnly && this.rfb.sendKey(code, 0);
            if (needs_shift) {
                this.rfb.sendKey(XK_Shift_L, 0);
            }

            if (t.length > 0) {
                setTimeout(() => { f(t); }, 10);
            }
        }
        f(str.split(''));
    }

    handleVKPress = (data) => {
        if (data.shiftKey) {
            this.rfb.sendKey(XK_Shift_L, 1);
        }
        if (data.ctrlKey) {
            this.rfb.sendKey(XK_Control_L, 1);
        }
        this.rfb.sendKey(data.code, 1);
        if (data.shiftKey) {
            this.rfb.sendKey(XK_Shift_L, 0);
        }
        if (data.ctrlKey) {
            this.rfb.sendKey(XK_Control_L, 0);
        }
    }

    /* 
        Function to handle presses in fake input dedicated to virtual keyboard on touch-only devices
    */
    /* onKeyboardPopperKeyPress = (event) => {

        event.preventDefault()
        event.stopPropagation()

        if (['keyup'].indexOf(event.type) === -1) {
            return false
        }

        const currentKey = event.key
        const currentKeyCode = event.which
        const lastChar = (event.target.value || '').substr(-1)


        document.querySelector('#keyboardPopper').value = ''

        const f = (code, dontrelease) => {
            this.rfb.sendKey(code, 1)
            if (!dontrelease) {
                setTimeout(() => {
                    this.rfb.sendKey(code, 0)
                }, 10)
            }
        }

        if (currentKey === 'Backspace' || currentKeyCode === 8) {
            f(XK_Backspace, true)
        }
        else if (currentKey === 'Enter' || currentKeyCode === 13) {
            f(XK_Return, true)
        }
        else if (currentKey === 'Tab' || currentKeyCode === 9) {
        }
        else if (currentKey === 'Escape' || currentKeyCode === 27) {
            f(XK_Escape, true)
        }
        else {
            f(lastChar.charCodeAt(0), true)
        }

        event.target.focus()

        return true
    } */

    render() {
        return (
            <div style={{ display: 'flex', flexDirection: 'column', flex: 1, background: '#0a1a28', position: 'relative' }} className={this.hasTouchScreen() ? `touchscreen` : ``}>
                <div id="VNCControls">
                    <div id="VNC_tools">
                        <Button style={{ background: this.state.showClipboard ? 'rgba(255,255,255,.25)' : 'transparent', border: 'none', outline: 'none' }} title={'Clipboard'} onClick={() => { this.setState({ showClipboard: !this.state.showClipboard }, () => { this.setHighestPopup(); this.forceUpdate() }) }}><img alt="" src={ic_clipper} style={{ height: '1.75rem' }} /></Button>
                        &nbsp;
                        <Button style={{ background: this.state.showNotes ? 'rgba(255,255,255,.25)' : 'transparent', border: 'none', outline: 'none' }} title={this.state.box.vnc_notes ? 'Notes' : 'Add a note...'} onClick={() => { this.setState({ showNotes: !this.state.showNotes }, () => { this.setHighestPopup(); this.forceUpdate() }) }}><img alt="" src={this.state.box.vnc_notes ? ic_note_full : ic_note_empty} style={{ height: '1.75rem' }} /></Button>
                        &nbsp;
                        {this.renderPasswordManager()}
                        &nbsp;
                        <Button style={{ background: 'transparent', border: 'none', outline: 'none' }} title="Send Ctrl + Alt + Del" onClick={() => { this.sendCtrlAltDel() }}><img alt="" src={ic_CtrlAltDel} style={{ height: '1.75rem' }} /></Button>
                        &nbsp;
                        <Button style={{ background: 'transparent', border: 'none', outline: 'none' }} title="Reload window" onClick={() => { window.location.reload() }}><img alt="" src={ic_reload} style={{ height: '1.75rem' }} /></Button>
                        &nbsp;
                        <Button style={{ background: 'transparent', border: 'none', outline: 'none' }} title="Toggle fullscreen" onClick={() => { this.toggleFullScreen() }}><img alt="" src={ic_fullscreen} style={{ height: '1.75rem' }} /></Button>
                        {
                            this.hasTouchScreen()
                                ? <div style={{ flex: 1 }}></div>
                                : ''
                        }
                        <Button title="Pop virtual keyboard" onClick={() => { this.setState({ showKeyboard: !this.state.showKeyboard }) }}>
                            <img src={'/img/ic_touchscreen_keyboard.svg'} alt="" />
                        </Button>
                        {this.hasTouchScreen() === false
                            ? <div style={{ display: 'contents' }}>
                                &nbsp;
                                <Button style={{ background: 'transparent', border: 'none', outline: 'none' }} title="Disconnect" onClick={() => { this.rfb.disconnect(); window.close() }}><img alt="" src={ic_disconnect} style={{ height: '1.75rem' }} /></Button>
                            </div>
                            : ''
                        }
                    </div>
                </div>
                <div id="screen"></div>
                <img alt="" src={img_loading} style={{ height: '8rem', position: 'absolute', top: 'calc(50% - 4rem)', left: 'calc(50% - 4rem)', zIndex: 0, animation: 'spin-clockwise 1.6s linear infinite' }} />
                {this.state.showClipboard &&
                    <Draggable handle=".handle" bounds="body" onStart={(e) => { this.setHighestPopup(e.target.parentNode) }}>
                        <div id="VNCNotesArea" style={{ zIndex: 999 }} onClick={(e) => { this.setHighestPopup(e.target); e.stopPropagation() }}>
                            <div className="handle">
                                <div className="handleTitle">
                                    Clipboard
                                </div>
                                <Button onClick={() => { this.setState({ showClipboard: false }, this.forceUpdate) }} className="closeIcon" bsSize="small">
                                    <img alt="" src={ic_close} style={{ height: '1.5rem' }} tabIndex="-1" />
                                </Button>
                            </div>
                            <div style={{ fontSize: '1.25rem', flex: 1, display: 'flex', overflow: 'auto', padding: '.5rem', marginBottom: '.75rem' }}>
                                {this.state.clipboard || <div style={{ margin: 'auto', userSelect: 'none', opacity: .5 }}>empty</div>}
                            </div>
                        </div>
                    </Draggable>
                }
                {this.state.showKeyboard &&
                    <Draggable handle=".handle" bounds="body" onStart={(e) => { this.setHighestPopup(e.target.parentNode) }}>
                        <div id="VNCVKbdArea" onClick={(e) => { this.setHighestPopup(e.target); e.stopPropagation() }} style={{ opacity: this.state.keyboardOpacity, zIndex: 999 }}>
                            {this.hasTouchScreen()
                                ? <input type="range" min={0.1} max={1.0} step={0.05} style={{ position: 'absolute', top: '1rem', right: '3rem', width: '9rem', zIndex: 9 }} onInput={(e) => { this.setState({ keyboardOpacity: e.target.value }) }} />
                                : ''
                            }
                            <div className="handle">
                                <div className="handleTitle">
                                    <i style={{ opacity: .5, fontWeight: 'normal' }}>Virtual Keyboard</i>
                                </div>
                                <Button onClick={() => { this.setState({ showKeyboard: false }, this.forceUpdate) }} className="closeIcon" bsSize="small">
                                    <img alt="" src={ic_close} style={{ height: '1.5rem' }} tabIndex="-1" />
                                </Button>
                            </div>
                            <Keyboard onPress={(data) => { this.handleVKPress(data) }} />
                        </div>
                    </Draggable>
                }
                {this.state.showNotes &&
                    <Draggable handle=".handle" bounds="body" onStart={(e) => { this.setHighestPopup(e.target.parentNode) }}>
                        <div id="VNCNotesArea" style={{ zIndex: 999 }} onClick={(e) => { this.setHighestPopup(e.target); e.stopPropagation() }}>
                            <div className="handle">
                                <div className="handleTitle">
                                    Connection infos
                                </div>
                                {Alfred.askPermission('MAINTAINANCE_VNC_REMOTE', Alfred.W) && !this.state.editMode
                                    ? <Button onClick={() => { this.setState({ editMode: true }, this.forceUpdate) }} title="Edit note" bsSize="small">
                                        <img alt="" src={ic_pen} style={{ height: '1.5rem' }} />
                                    </Button>
                                    : null
                                }
                                &nbsp;
                                <Button onClick={() => { this.setState({ showNotes: false }, this.forceUpdate) }} className="closeIcon" bsSize="small">
                                    <img alt="" src={ic_close} style={{ height: '1.5rem' }} tabIndex="-1" />
                                </Button>
                            </div>
                            {this.state.editMode
                                ? <div style={{ flex: 1, display: 'flex', flexDirection: 'column', padding: '.25rem' }}>
                                    <textarea ref={(textarea) => this.input_vnc_notes = textarea} defaultValue={this.state.box.vnc_notes} style={{
                                        flex: 1,
                                        resize: 'none',
                                        border: '1pt solid #ddd',
                                        borderRadius: '3pt',
                                        padding: '1rem'
                                    }}></textarea>
                                    <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '.5rem', padding: '.5rem', flexShrink: 0 }}>
                                        <Button onClick={() => { this.setState({ editMode: false }, this.forceUpdate) }}>Cancel</Button>
                                        <div style={{ flex: 1 }}></div>
                                        <Button bsStyle="primary" onClick={this.onSaveNotes}>Save</Button>
                                    </div>
                                </div>
                                : <div style={{ flex: 1, display: 'flex', flexDirection: 'column', padding: '.25rem' }}>
                                    <div style={{
                                        flex: 1,
                                        padding: '.5rem',
                                        overflow: 'auto'
                                    }} dangerouslySetInnerHTML={{ __html: marked(this.state.box.vnc_notes || '') }}></div>

                                </div>
                            }
                        </div>
                    </Draggable>
                }
            </div>
        )
    }
}

export default WebSocketClient.withWebSocket(BoxVNC, (props) => [WebSocketClient.messageTypes.tunnel])
