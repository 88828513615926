import React from 'react'
import ApiService from '../../core/ApiService';
import { Tabs, Tab, Button } from '../UI/Toolkit'
import Helpers from '../../core/Helpers'
import Modale from '../UI/ModaleComponent'
import moment from 'moment-timezone'

import ic_bargraph from '../../img/ic_bargraph.svg'

import './transferSpeeds.css'

class TransferSpeeds extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            showModale: false,
            downloads: {
                values: null,
                maxTransfers: 0
            },
            uploads: {
                values: null,
                maxTransfers: 0
            },
            nbDays: 30
        }

        this.showModale = this.showModale.bind(this)
        this.createSpeedBar = this.createSpeedBar.bind(this)
    }

    fillVoids(inputs) {
        if (inputs.length === 0) {
            return {
                values: [],
                maxTransfers: 0
            }
        }

        let values = []
        let nb = inputs.length
        let d = 0 // cursor in the inputs
        let maxTransfers = 0

        let firstDay = moment().add(1 - this.state.nbDays, 'day').format('YYYY-MM-DD')
        while (d < nb && inputs[d].date < firstDay) {
            ++d
        }

        for (let i = 0; i < this.state.nbDays; ++i) {
            let delta = this.state.nbDays - 1 - i
            let date = moment().add(-delta, 'day').format('YYYY-MM-DD')
            if (d < nb && inputs[d].date === date) {
                maxTransfers = Math.max(maxTransfers, inputs[d].nbTransfers)
                values.push(inputs[d])
                ++d
            } else {
                values.push({
                    date: date,
                    nbTransfers: 0,
                    sizeTransferred: 0,
                    maxTransfers: 0
                })
            }
        }

        return {
            values,
            maxTransfers
        }
    }

    showModale() {
        this.setState({ showModale: true })
        ApiService.getDownloadSpeeds(this.props.boxId, this.state.nbDays)
            .then(downloads => {
                this.setState({ downloads: this.fillVoids(downloads) })
            })
        ApiService.getUploadSpeeds(this.props.boxId, this.state.nbDays)
            .then(uploads => {
                this.setState({ uploads: this.fillVoids(uploads) })
            })
    }

    createSpeedDetailsChart(buckets) {
        const maxHeight = 75
        const nbTransfers = buckets.map(speed => speed.nb)
        const maxTransfers = Math.max(...nbTransfers)

        // If bucket = Math.floor(2 * (Math.log10(speed) - 4))
        // Then speed = 10 ^ (bucket / 2 + 4)
        const intervals = [
            '0 to 10 kB/s',
            '10 to 32 kB/s',
            '32 to 100 kB/s',
            '100 to 320 kB/s',
            '320kB to 1 MB/s',
            '1 to 3.2 MB/s',
            '3.2 to 10 MB/s',
            '10 to 32 MB/s',
            '32 MB/s and up'
        ]

        return (
            <div className="chart speedsChart">
                Speed distribution:
                <ul>
                    {buckets.map((speed, index) => {
                        let h = maxHeight * speed.nb / maxTransfers
                        if (speed.nb) {
                            h = Math.max(1, h)
                        }
                        const m = maxHeight - h
                        const tX = index * 100 / (buckets.length - 1)
                        const tooltip = <div className="tooltip2"
                            style={{ transform: `translateX(-${tX}%)` }}>
                            {intervals[index]}<br />
                            {speed.nb} transfers<br />
                            {Helpers.humanFileSize(speed.size)} transferred
                        </div>

                        const barClass = speed.nb ? 'hasValue' : 'noValue'
                        return (
                            <li key={index} className={`bar ${barClass}`}
                                style={{ height: `${maxHeight}px` }}
                            >
                                <div className='barValue'
                                    style={{ height: `${h}px`, marginTop: `${m}px` }}>
                                    {tooltip}
                                </div>
                            </li>
                        )
                    })}
                </ul>
            </div>
        )
    }

    createSpeedBar(day, index) {
        let severity = 0;
        if (day.nbTransfers === 0) severity = 999;
        else if (day.meanSpeed < 100 * 1024) severity = 4;
        else if (day.meanSpeed < 500 * 1024) severity = 3;
        else if (day.meanSpeed < 1024 * 1024) severity = 2;
        else if (day.meanSpeed < 5 * 1024 * 1024) severity = 1;

        let tooltip
        const tX = index * 100 / (this.state.nbDays - 1)
        if (day.nbTransfers === 0) {
            tooltip = <div className="tooltip"
                style={{ transform: `translateX(-${tX}%)` }}>
                {day.date}<br />
                No transfers
            </div>
        } else {
            tooltip = <div className="tooltip"
                style={{ transform: `translateX(-${tX}%)` }}>
                <div>
                    {day.date}<br />
                    Nb transfers: {day.nbTransfers}<br />
                    Total transferred: {Helpers.humanFileSize(day.sizeTransferred)}<br />
                    Mean speed: {Helpers.humanFileSize(day.meanSpeed)}/s
                </div>
                {day.speeds && this.createSpeedDetailsChart(day.speeds)}
            </div>
        }

        return (
            <li key={day.date} className={`bar severity-${severity}`}>
                {tooltip}
            </li>
        )
    }

    createSpeedChart(data) {
        if (data.values === null) {
            return (<div>Computing data for this period</div>)
        }
        if (!data.maxTransfers) {
            return (<div>No Transfers for this period</div>)
        }

        return (
            <div className="chart">
                <ul>
                    {data.values.map(this.createSpeedBar)}
                </ul>
                <div className="legend">
                    <div className="item">{this.state.nbDays} days ago</div>
                    <div className="spacer"></div>
                    <div className="item">Today</div>
                </div>
            </div>
        )
    }

    createHoursChart(buckets) {
        const maxHeight = 75
        const nbTransfers = buckets.map(hour => hour.nb)
        const maxTransfers = Math.max(...nbTransfers)

        return (
            <div className="chart speedsChart">
                Transfers per hour:
                <ul>
                    {buckets.map((speed, index) => {
                        let h = maxHeight * speed.nb / maxTransfers
                        if (speed.nb) {
                            h = Math.max(1, h)
                        }
                        const m = maxHeight - h
                        const tX = index * 100 / 23
                        const tooltip = <div className="tooltip2"
                            style={{ transform: `translateX(-${tX}%)` }}>
                            {index}h - {index + 1}h<br />
                            {speed.nb} transfers<br />
                            {Helpers.humanFileSize(speed.size)} transferred
                        </div>

                        const barClass = speed.nb ? 'hasValue' : 'noValue'
                        return (
                            <li key={index} className={`bar ${barClass}`}
                                style={{ height: `${maxHeight}px` }}
                            >
                                <div className='barValue'
                                    style={{ height: `${h}px`, marginTop: `${m}px` }}>
                                    {tooltip}
                                </div>
                            </li>
                        )
                    })}
                </ul>
            </div>
        )
    }

    createTransferChart(data) {
        if (data.values === null) {
            return (<div>Computing data for this period</div>)
        }
        if (!data.maxTransfers) {
            return (<div>No Transfers for this period</div>)
        }

        const maxHeight = 100
        const nb = data.values.length
        return (
            <div className="chart transfers">
                <ul>
                    {data.values.map((day, index) => {
                        let h = maxHeight * day.nbTransfers / data.maxTransfers
                        if (day.nbTransfers) {
                            h = Math.max(1, h)
                        }
                        const m = maxHeight - h

                        let tooltip
                        const tX = index * 100 / (nb - 1)
                        if (day.nbTransfers === 0) {
                            tooltip = <div className="tooltip"
                                style={{ transform: `translateX(-${tX}%)` }}>
                                {day.date}<br />
                                No transfers
                            </div>
                        } else {
                            tooltip = <div className="tooltip"
                                style={{ transform: `translateX(-${tX}%)` }}>
                                <div>
                                    {day.date}<br />
                                    Nb transfers: {day.nbTransfers}<br />
                                    Total transferred: {Helpers.humanFileSize(day.sizeTransferred)}
                                </div>
                                {day.hours && this.createHoursChart(day.hours)}
                            </div>
                        }

                        const barClass = day.nbTransfers ? 'hasValue' : 'noValue'
                        return (
                            <li key={day.date} className={`bar ${barClass}`}
                                style={{ height: `${maxHeight}px` }}
                            >
                                <div className='barValue'
                                    style={{ height: `${h}px`, marginTop: `${m}px` }}>
                                </div>
                                {tooltip}
                            </li>
                        )
                    })}
                </ul>
                <div className="legend">
                    <div className="item">{this.state.nbDays} days ago</div>
                    <div className="spacer"></div>
                    <div className="item">Today</div>
                </div>
            </div>
        )
    }

    render() {
        return (
            <div>
                <Button bsStyle="primary" className='TransferStatsBtn'
                    onClick={this.showModale}>
                    <img src={ic_bargraph} alt="" style={{ height: '1rem', width: '1rem', verticalAlign: 'top' }} />
                    &nbsp;
                    Transfer stats
                </Button>

                <Modale show={this.state.showModale} onHide={() => {
                    this.setState({ showModale: false })
                }}>
                    <Modale.Header>
                        Transfer stats
                    </Modale.Header>
                    <Modale.Body style={{ width: '600px' }}>
                        <Tabs id="TransferTabs">
                            <Tab label="Speeds">
                                <div className='TransferStats'>
                                    <div className='ui-card'>
                                        <div style={{ flex: 1, opacity: .5 }}>Uploads</div>
                                        {this.createSpeedChart(this.state.uploads)}
                                    </div>
                                    <div style={{ height: '75px' }} />
                                    <div className='ui-card goUp'>
                                        <div style={{ flex: 1, opacity: .5 }}>Downloads</div>
                                        {this.createSpeedChart(this.state.downloads)}
                                    </div>
                                </div>
                            </Tab>
                            <Tab label="Transfers">
                                <div className='TransferStats'>
                                    <div className='ui-card'>
                                        <div style={{ flex: 1, opacity: .5 }}>Uploads</div>
                                        {this.createTransferChart(this.state.uploads)}
                                    </div>
                                    <div style={{ height: '75px' }} />
                                    <div className='ui-card goUp'>
                                        <div style={{ flex: 1, opacity: .5 }}>Downloads</div>
                                        {this.createTransferChart(this.state.downloads)}
                                    </div>
                                </div>
                            </Tab>
                        </Tabs>
                    </Modale.Body>
                    <Modale.Footer>
                        <div style={{ flex: 1 }} />
                        <Button onClick={() => { this.setState({ showModale: false }) }}>
                            Close
                        </Button>
                    </Modale.Footer>
                </Modale>
            </div>
        )
    }
}

export default TransferSpeeds