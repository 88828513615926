import React, { Component } from 'react';

import './index.css'

class Tab extends Component {

    onClick = () => {
        const { label, onClick } = this.props;
        onClick(label);
    }

    render() {
        const {
            onClick,
            props: {
                activeTab,
                label,
            },
        } = this;

        let className = '';

        if (activeTab === label) {
            className += 'active';
        }

        return (
            <li
                className={className}
                data-layout='tab'
                onClick={onClick}
            >
                {label}
            </li>
        );
    }
}

class Tabs extends Component {
    constructor(props) {
        super(props);

        this.state = {
            activeTab: React.Children.toArray(this.props.children)[0].props.label,
        };
    }

    onClickTabItem = (tab) => {
        this.setState({ activeTab: tab });
    }

    render() {
        const {
            onClickTabItem,
            props: {
                children,
            },
            state: {
                activeTab,
            },
        } = this;

        return (
            <div className='tabs' data-layout='tabhost' style={this.props.style}>
                <ol className='tab-list' data-layout='tab-list'>
                    {React.Children.toArray(children).filter((x) => x).map((child) => {
                        const { label } = child.props;

                        return (
                            <Tab
                                activeTab={activeTab}
                                key={label}
                                label={label}
                                onClick={onClickTabItem}
                            />
                        );
                    })}
                </ol>
                <div className='tab-content' data-layout='tab-pane'>
                    {React.Children.toArray(children).filter((x) => x).map((child) => {
                        if (child.props.label !== activeTab) { return undefined; }
                        return child.props.children;
                    })}
                </div>
            </div>
        );
    }
}

class Button extends Component {
    onClick = (e) => {
        if (this.props.onClick) {
            this.props.onClick(e)
        }
    }

    render() {
        const css = this.props.style || {}
        return <button
            style={{ ...css }}
            onClick={this.onClick}
            disabled={this.props.disabled}
            title={this.props.title}
            type={this.props.type || 'button'}
            className={`btn btn-${this.props.bsStyle || 'default'} ${this.props.className}`}>
            {React.Children.map(this.props.children, (element, idx) => {
                return element;
            })}
        </button>
    }
}



class ProgressBar extends Component {
    constructor(props) {
        super(props);

        const progress =
            props.data &&
            props.data.extra_progress &&
            props.data.extra_progress.length &&
            props.data.extra_progress.reduce((a, b) => a + b.value, 0) / props.data.extra_progress.length * 100;

        this.state = {
            progressBars: props.data ? props.data.extra_progress || [] : [],
            progress_total: progress || props.now || 100,
            showDetail: false,
        };
    }

    componentWillReceiveProps(nextProps) {

        const data = nextProps.data;

        if (data && data.extra_progress && data.extra_progress.length) {
            this.setState({
                multiple: true,
                progressBars: data.extra_progress,
                progress_total: data.extra_progress.reduce((a, b) => a + b.value, 0) / data.extra_progress.length * 100,
            });
        } else {
            this.setState({
                multiple: false,
                progress_total: nextProps.now || 100,
            });
        }
    }

    getBackgroundColor() {
        if (this.props.now) {
            switch (this.props.bsStyle) {
                case 'warning':
                    return 'var(--color-warning)';
                case 'info':
                    return 'var(--color-primary)';
                case 'success':
                    return 'var(--color-success)';
                default:
                    return 'var(--color-primary-dark)';
            }
        } else {
            return 'transparent';
        }
    }

    render() {
        return <div id='MultiProgressBarComponent' style={{ ...this.props.style }} tabIndex={-1}
            onMouseEnter={() => { this.setState({ showDetail: true }); }}
            onMouseLeave={() => { this.setState({ showDetail: false }); }}
            onClick={(e) => { e.stopPropagation(); }}>
            <div id='MultiProgressBarWrapper'>
                <div id='MultiProgressBarBar' title={this.props.label}>
                    <div id='MultiProgressBarProgress' className={typeof this.props.now === 'number' ? 'progress' : 'indeterminate'} style={{
                        backgroundColor: this.getBackgroundColor(),
                        width: `${this.state.progress_total}%`,
                    }}>
                    </div>
                </div>
                {this.state.multiple
                    ? <div id='MultiProgressBarMultiple'
                    >({this.state.progressBars.length})</div>
                    : <div></div>}
            </div>
            {this.state.progressBars.length && this.state.showDetail
                ? <div id='MultiProgressBarDetail' data-ui-card>
                    {this.state.progressBars.map((bar, index) => {
                        return <div key={index} id='MultiProgressBarMultiWrapper'>
                            <div>
                                <small>{bar.name}</small>
                                <small>{parseFloat(String(bar.value * 100)).toFixed(1)} %</small>
                            </div>
                            <ProgressBar now={bar.value * 100} />
                        </div>;
                    })}
                </div>
                : ''
            }
        </div>;
    }
}


export { Tabs, Tab, Button, ProgressBar };
