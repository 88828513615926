import moment from 'moment-timezone'
import React from 'react'
import { Button } from '../../UI/Toolkit'
import Modal from '../../UI/ModaleComponent'
import Datetime from '../../Fields/InputDate'
import './dateTime.css'

class DateTimeFilterModal extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            showModal: false,
            operator: (props.defaultFilter ? props.defaultFilter.operator : 'between'),
            fromDate: moment((props.defaultFilter ? props.defaultFilter.from : null)),
            fromTime: false,
            toDate: moment((props.defaultFilter ? props.defaultFilter.to : null)),
            toTime: false,
            filter: props.defaultFilter || null
        }

        this.open = this.open.bind(this)
        this.close = this.close.bind(this)
        this.renderCalendar = this.renderCalendar.bind(this)
        this.operatorChangeHandle = this.operatorChangeHandle.bind(this)
        this.yesterdayHandle = this.yesterdayHandle.bind(this)
        this.todayHandle = this.todayHandle.bind(this)
        this.weekHandle = this.weekHandle.bind(this)
        this.monthHandle = this.monthHandle.bind(this)
        this.yearHandle = this.yearHandle.bind(this)
        this.fromChangeHandle = this.fromChangeHandle.bind(this)
        this.toChangeHandle = this.toChangeHandle.bind(this)
        this.save = this.save.bind(this)
        this.clear = this.clear.bind(this)
    }

    componentWillReceiveProps(nextProps) {
        if (this.state.showModal) return false
        this.setState({
            operator: (nextProps.defaultFilter ? nextProps.defaultFilter.operator : '='),
            fromDate: moment((nextProps.defaultFilter ? nextProps.defaultFilter.from : undefined)),
            fromTime: nextProps.defaultFilter ? (nextProps.defaultFilter.operator !== '=' ? true : false) : true,
            toDate: moment((nextProps.defaultFilter ? nextProps.defaultFilter.to : undefined)),
            toTime: nextProps.defaultFilter ? (nextProps.defaultFilter.operator !== '=' ? true : false) : true,
            filter: nextProps.defaultFilter || null
        })
    }

    save() {
        let filter = {
            operator: 'between',
            from: moment(this.state.fromDate),
            to: moment(this.state.toDate)
        }

        this.setState({
            filter: filter
        }, () => {
            this.props.update(this.state.filter)
        })
        this.close()
    }

    close() {
        this.setState({ showModal: false })
    }

    open() {
        this.setState({ showModal: true })
    }

    operatorChangeHandle(e) {
        this.setState({
            operator: e.target.value,
            fromTime: e.target.value !== '=',
            toTime: e.target.value !== '='
        })
    }

    fromChangeHandle(datetime) {
        this.setState({
            fromDate: datetime
        }, () => {
            this.forceUpdate()
        })
    }

    toChangeHandle(datetime) {
        this.setState({
            toDate: moment(datetime)
        }, () => {
            this.forceUpdate()
        })
    }

    yesterdayHandle() {
        this.setState({
            operator: 'between',
            fromDate: moment().subtract(1, 'days').hour(0).minute(0).second(0),
            fromTime: true,
            toDate: moment().subtract(1, 'days').hour(23).minute(59).second(59),
            toTime: true
        })
    }

    todayHandle() {
        this.setState({
            operator: 'between',
            fromDate: moment().hour(0).minute(0).second(0),
            fromTime: true,
            toDate: moment().hour(23).minute(59).second(59),
            toTime: true
        })
    }

    weekHandle() {
        this.setState({
            operator: 'between',
            fromDate: moment().weekday(0).hour(0).minute(0).second(0),
            fromTime: true,
            toDate: moment().weekday(6).hour(23).minute(59).second(59),
            toTime: true
        })
    }

    monthHandle() {
        this.setState({
            operator: 'between',
            fromDate: moment().date(1).hour(0).minute(0).second(0),
            fromTime: true,
            toDate: moment().add(1, 'months').date(0).hour(23).minute(59).second(59),
            toTime: true
        })
    }

    yearHandle() {
        this.setState({
            operator: 'between',
            fromDate: moment().startOf('year'),
            fromTime: true,
            toDate: moment().endOf('year'),
            toTime: true
        })
    }

    clear(e) {
        e.stopPropagation()
        e.preventDefault()
        this.setState({
            filter: null,
            fromTime: null,
            toTime: null
        }, () => {
            this.props.update(this.state.filter)
            this.forceUpdate()
        })
    }

    renderCalendar(operator, from, to) {
        return (
            <div style={{ display: 'flex', justifyContent: 'space-between', width: '450pt' }}>
                <div style={{ width: '200pt' }}>
                    <Datetime
                        i18n={this.props.i18n}
                        open={true}
                        value={from || this.state.fromDate}
                        onChange={this.fromChangeHandle}
                        time={this.state.fromTime}
                    />
                </div>

                <div style={{ display: operator === 'between' ? 'initial' : 'none', width: '200pt' }}>
                    <Datetime
                        i18n={this.props.i18n}
                        open={true}
                        value={to || this.state.toDate}
                        onChange={this.toChangeHandle}
                        time={this.state.toTime}
                    />
                </div>

            </div>
        )
    }

    renderFilter() {
        if (!this.state.filter) {
            return (<div className="rt-filter-button">Filter</div>)
        }
        let to = null
        let operator = this.state.filter.operator + ' '
        if (this.state.filter.to) {
            operator = ''
            to = (
                <small style={{ display: 'block', lineHeight: '110%' }} className="rt-filter-value">{moment(this.state.filter.to).format('D MMM YYYY HH:mm')}</small>
            )
        }

        return (
            <div>

                <small style={{ display: 'block', lineHeight: '110%' }} className="rt-filter-value">{operator + (this.state.filter.from ? moment(this.state.filter.from).format('D MMM YYYY HH:mm') : '')}</small>
                {to}
                <Button onClick={this.clear} bsStyle="danger" className="rt-filter-remove-button" bsSize="xs">Clear</Button>
            </div>
        )
    }

    render() {
        return (
            <div>
                <div onClick={this.open}>
                    {this.renderFilter()}
                </div>
                <Modal show={this.state.showModal} onHide={this.close} className="datetime-filter-modal">
                    <Modal.Header>
                        Filter by date
                    </Modal.Header>
                    <Modal.Body>
                        <div>
                            <Button bsStyle="primary"
                                onClick={this.yesterdayHandle}>Yesterday</Button>&nbsp;
                                    <Button bsStyle="primary"
                                onClick={this.todayHandle}>Today</Button>&nbsp;
                                    <Button bsStyle="primary"
                                onClick={this.weekHandle}>This week</Button>&nbsp;
                                    <Button bsStyle="primary"
                                onClick={this.monthHandle}>This month</Button>&nbsp;
                                    <Button bsStyle="primary"
                                onClick={this.yearHandle}>This year</Button>
                        </div>
                        {this.renderCalendar('between', this.state.fromDate, this.state.toDate)}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={this.close}>Cancel</Button>
                        <div style={{ flex: 1 }}></div>
                        <Button bsStyle="primary" onClick={this.save}>OK</Button>
                    </Modal.Footer>
                </Modal>
            </div >
        )
    }
}

export default function DateTime(options = {}) {
    let format = options.format || 'D MMM YYYY HH:mm'

    return {
        Cell: (cell) => {
            if (!moment(cell.value).isValid()) return ''
            return (moment(cell.value).format(format))
        },
        Filter: ({ filter, onChange }) => {
            return (<DateTimeFilterModal
                update={(filter) => {
                    onChange(filter)
                }}
                defaultFilter={(filter ? filter.value : null)}
            />)
        },
        className: 'text-center'
    }
}