import React from 'react'
import { Button } from '../UI/Toolkit'
import ApiService from '../../core/ApiService'
import { Tabs, Tab } from '../UI/Toolkit'
import Helpers from '../../core/Helpers'
import Alfred from '../../core/Alfred'
import 'react-table/react-table.css'
import Modale from '../UI/ModaleComponent/'
import BoxPickerElement from '../UI/PickerTransformedComponents/Box'
import TagPickerElement from '../UI/PickerTransformedComponents/Tag'
import Picker from '../UI/Picker'
import Table from '../Table'
import routes from '../../routes'
import CommunityList from './CommunityList'
import ForbiddenComponent from '../ForbiddenComponent'

class CommunityListContainer extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            componentReady: false,
            endPoint: {},
            configuration: {
                columns: []
            },
            currentCommunity: {},
            err: null,
            showModal: false,
            showModalRemove: false
        }

        this.forceRefresh = false

        this.onSubmit = this.onSubmit.bind(this)
        this.onRemove = this.onRemove.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.chooseBoxes = this.chooseBoxes.bind(this)
        this.chooseTemplates = this.chooseTemplates.bind(this)
        this.chooseCommunity = this.chooseCommunity.bind(this)
    }

    handleChange(event) {
        const target = event.target
        const value = target.type === 'checkbox' ? target.checked : target.value
        const name = target.name

        var currentCommunity = this.state.currentCommunity

        currentCommunity[name] = value

        this.setState({ currentCommunity })
    }

    onSubmit() {
        ApiService.upsertCommunity(this.state.currentCommunity, this.state.currentCommunity.uid).then((response) => {
            this.forceRefresh = true
            this.setState({
                uploading: false,
                showModal: false,
                currentCommunity: {}
            }, () => {
                this.forceRefresh = false
                if (response.success === false) {
                    alert(response.message)
                }
            })
        }).catch(ex => {
            alert(ex)
        })
    }

    onRemove() {
        ApiService.removeCommunity(this.state.currentCommunity.uid).then((response) => {
            this.forceRefresh = true
            this.setState({ uploading: false, showModal: false, showModalRemove: false, currentCommunity: {} })
        }).catch(ex => {
            alert(ex)
        })
    }

    componentDidMount() {
        if (Helpers.getCurrentUser().admin >= 1) {
            this.generateConfiguration()
            this.props.updateBreadcrumb('Communities management',
                <div style={{ display: 'flex' }}>
                    <div style={{ flex: 1 }}>
                        Manage platform communities
                    </div>
                    {Alfred.askPermission('COMMUNITIES', Alfred.W) &&
                        <Button bsStyle="primary" bsSize="small" onClick={() => {
                            this.setState({ currentCommunity: {}, showModal: true })
                        }}>New community</Button>
                    }
                </div>
            )

            ApiService.getBoxes().then(response => {
                this.setState({
                    boxes: response
                })
            })
        }
    }

    generateConfiguration() {
        this.setState({
            componentReady: false
        })
        let endPoint = routes.getCommunitiesList
        const defaultConfiguration = {
            sort: {
                id: 'update_timestamp',
                desc: true
            },
            rowsPerPage: 10,
            columns: [
                {
                    id: 'communities.uid',
                    title: 'UID',
                    type: Table.types.STRING,
                    accessor: (row) => {
                        return row.uid
                    },
                    show: true,
                    width: 150
                },
                {
                    id: 'communities.name',
                    title: 'Name',
                    type: Table.types.STRING,
                    accessor: (row) => {
                        return row.name
                    },
                    show: true
                },
                {
                    id: 'communities.description',
                    title: 'Description',
                    type: Table.types.STRING,
                    accessor: (row) => {
                        return row.description
                    },
                    show: true
                },
                {
                    id: 'communities.creation_timestamp',
                    title: 'Creation date',
                    accessor: (row) => {
                        return row.creation_timestamp
                    },
                    type: Table.types.DATETIME,
                    show: true,
                    width: 125
                },
                {
                    id: 'communities.update_timestamp',
                    title: 'Last update',
                    accessor: (row) => {
                        return row.update_timestamp
                    },
                    type: Table.types.DATETIME,
                    show: true,
                    width: 125
                }
            ]
        }

        this.setState({
            endPoint: endPoint,
            configuration: defaultConfiguration,
            componentReady: true
        })
    }

    chooseCommunity(community) {
        this.setState({
            currentCommunity: community,
            showModal: true
        }, () => {
            this.populateCommunity()
        })
    }

    populateCommunity() {
        let currentCommunity = this.state.currentCommunity
        currentCommunity.boxes = []
        this.setState({ currentCommunity }, () => {
            ApiService.getCommunityBoxes(currentCommunity.uid).then(response => {
                currentCommunity.boxes = JSON.parse(JSON.stringify(response))
                this.setState({ currentCommunity })
            })
            ApiService.getCommunityTemplates(currentCommunity.uid).then(response => {
                currentCommunity.templates = JSON.parse(JSON.stringify(response))
                this.setState({ currentCommunity })
            })
            ApiService.getCommunityTags(currentCommunity.uid).then(response => {
                currentCommunity.tags = JSON.parse(JSON.stringify(response))
                this.setState({ currentCommunity })
            })
        })
    }

    chooseBoxes(boxes) {
        let currentCommunity = this.state.currentCommunity
        currentCommunity.boxes = boxes
        this.setState({ currentCommunity })
    }

    chooseTemplates(templates) {
        let currentCommunity = this.state.currentCommunity
        currentCommunity.templates = templates
        this.setState({ currentCommunity })
    }

    chooseTags = (tags) => {
        let currentCommunity = this.state.currentCommunity
        currentCommunity.tags = tags
        this.setState({ currentCommunity })
    }

    render() {
        if (Helpers.getCurrentUser().admin < 1) { return <ForbiddenComponent /> }
        if (!this.state.componentReady) return null
        return (
            <div style={{ display: 'flex', flex: 1, padding: '1rem' }}>
                <Modale show={this.state.showModal}>
                    <Modale.Header>
                        {this.state.currentCommunity.name || 'Community details'}
                        &nbsp;({this.state.currentCommunity.uid || 'no uid'})
                    </Modale.Header>
                    <Modale.Body style={{ width: '650pt' }}>
                        <Tabs id="modaleCommunityTabs" style={{ minHeight: '300px', overflow: 'hidden', maxHeight: '48rem' }}>
                            <Tab label="General" >
                                <label>
                                    Community name
                                    <input autoComplete="name" onChange={this.handleChange} type="text" name="name"
                                        defaultValue={this.state.currentCommunity.name} className="form-control"
                                        style={{ fontWeight: 'normal', width: '256px' }}
                                        placeholder="Community Name" />
                                </label>

                                <label>
                                    Community description
                                    <input autoComplete="description" onChange={this.handleChange} type="text"
                                        name="description" defaultValue={this.state.currentCommunity.description}
                                        style={{ fontWeight: 'normal' }}
                                        className="form-control" placeholder="Community Description" />
                                </label>
                            </Tab>
                            {this.state.currentCommunity.uid &&
                                <Tab label="Relationships" >
                                    <div style={{ display: 'flex', flex: 1, overflow: 'hidden', maxHeight: '45rem' }}>
                                        <Picker style={{ flex: 1 }} title="Boxes" filterkey={['name', 'uid', 'system']}
                                            items={this.state.currentCommunity.boxes} onChange={this.chooseBoxes} ukey="uid"
                                            renderItem={(item) => {
                                                return <BoxPickerElement {...item} />
                                            }}
                                            sortBy="uid" />
                                        &nbsp;
                                        <Picker style={{ flex: 1 }} title="Templates" filterkey={['name', 'version', 'uid']}
                                            items={this.state.currentCommunity.templates} onChange={this.chooseTemplates}
                                            ukey="uid" sortBy="name" />
                                            &nbsp;
                                        <Picker style={{ flex: 1 }} title="Tags" filterkey={['name', 'uid']}
                                            items={this.state.currentCommunity.tags || []} onChange={this.chooseTags}
                                            ukey="uid" sortBy="name" renderItem={(item) => {
                                                return <div style={{ padding: '.5rem', color: 'black' }}><TagPickerElement  {...item} /></div>
                                            }} />
                                    </div>
                                </Tab>
                            }
                            {this.state.currentCommunity.uid && Alfred.askPermission('COMMUNITIES', Alfred.X) &&
                                <Tab label="Removal">
                                    <div style={{ padding: '1rem' }}>
                                        <Button disabled={!this.state.currentCommunity} bsStyle="danger" onClick={() => {
                                            this.setState({ showModalRemove: true })
                                        }}>Remove</Button>
                                    </div>
                                </Tab>
                            }
                        </Tabs>
                    </Modale.Body>
                    <Modale.Footer>
                        <Button style={{ float: 'left' }} onClick={() => {
                            this.setState({ showModal: false })
                        }}>Close</Button>
                        <div style={{ flex: 1 }}></div>

                        {Alfred.askPermission('COMMUNITIES', Alfred.W) &&
                            <Button bsStyle="primary" onClick={this.onSubmit}>Save</Button>
                        }
                    </Modale.Footer>
                </Modale>
                <Modale show={this.state.showModalRemove}>
                    <Modale.Header>
                        Confirm
                    </Modale.Header>
                    <Modale.Body>
                        <p>You are about to permanently remove this community and any relationship between it and its boxes.</p>
                        <p>Please confirm that it's what you want to do.</p>
                    </Modale.Body>
                    <Modale.Footer>
                        <Button disabled={!this.state.currentCommunity || !this.state.currentCommunity.uid}
                            onClick={() => {
                                this.setState({ showModalRemove: false })
                            }}>Don't</Button>
                        <div style={{ flex: 1 }}></div>
                        {Alfred.askPermission('COMMUNITIES', Alfred.X) &&
                            <Button bsStyle="danger" onClick={this.onRemove}>Remove</Button>
                        }
                    </Modale.Footer>
                </Modale>

                <CommunityList
                    configuration={this.state.configuration}
                    endPoint={this.state.endPoint}
                    chooseCommunity={this.chooseCommunity}
                    forceRefresh={this.forceRefresh}
                />
            </div>
        )
    }
}

export default CommunityListContainer
