import React, { Component } from 'react'
import { Tabs, Tab, Button } from '../UI/Toolkit'
import 'react-table/react-table.css'
import APIService from '../../core/ApiService'
import Helpers from '../../core/Helpers'
import Modale from '../UI/ModaleComponent'
import './index.css'
import Table from '../Table'
import routes from '../../routes'
import TeamsList from './TeamsList'
import ApiService from '../../core/ApiService'
import ForbiddenComponent from '../ForbiddenComponent'
import Picker from '../UI/Picker'
import Alfred from '../../core/Alfred';

class TeamsComponent extends Component {

    constructor(props) {
        super(props)
        this.state = {
            currentTeam: { users: [], communities: [], permissions: {} },
            showModal: false,
            showModaleTestNotification: false,
            userLogged: Helpers.getCurrentUser(),
            users: [],
            timestamp: 0
        }

        this.forceRefresh = false

        this.onSubmit = this.onSubmit.bind(this)
        this.onUsersChange = this.onUsersChange.bind(this)
        this.onCommunitiesChange = this.onCommunitiesChange.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.chooseTeam = this.chooseTeam.bind(this)
        this.onRemoveTeam = this.onRemoveTeam.bind(this)
        this.onPermissionChange = this.onPermissionChange.bind(this)
    }

    handleChange(event) {
        const target = event.target
        const value = target.type === 'checkbox' ? target.checked : target.value
        const name = target.name

        var currentTeam = this.state.currentTeam

        currentTeam[name] = value

        this.setState({ currentTeam })
    }

    onUsersChange(x) {
        if (!x) return
        this.setState({ currentTeam: { ...this.state.currentTeam, users: x } })
    }

    onCommunitiesChange(x) {
        if (!x) return
        this.setState({ currentTeam: { ...this.state.currentTeam, communities: x } })
    }

    onRemoveTeam(team) {
        if (window.confirm("Please confirm you want to definitely remove this team. This action will be logged.")) {
            ApiService.removeTeam(team.id).then((response) => {
                this.setState({
                    showModal: false,
                    timestamp: Date.now()
                })
            })
        }
    }

    onSubmit() {
        let team = JSON.parse(JSON.stringify(this.state.currentTeam))
        team.users = team.users.filter(u => u._selected)
        team.communities = team.communities.filter(c => c._selected)
        APIService.upsertTeam(team, this.state.currentTeam.id).then((response) => {
            this.forceRefresh = true
            this.setState({
                showModal: false
            }, () => {
                this.forceRefresh = false
                if (response.success === false) {
                    alert(response.message)
                }
            })
            this.setState({
                timestamp: Date.now()
            })
        }).catch(ex => {
            alert(ex)
        })
    }

    componentDidMount() {
        if (Helpers.getCurrentUser().admin >= 1 && Alfred.askPermission('TEAMS', Alfred.R)) {
            this.generateConfiguration()
            this.props.updateBreadcrumb('Teams management',
                <div style={{ display: 'flex' }}>
                    <div style={{ flex: 1 }}>
                        Manage teams of platform users
                    </div>
                    {Alfred.askPermission('TEAMS', Alfred.W) &&
                        <Button bsStyle="primary" bsSize="small" onClick={() => {
                            ApiService.getPermissions().then(permissions => {
                                const team_permissions = {}
                                permissions.forEach(p => {
                                    team_permissions[p.key] = 0
                                })
                                this.setState({ currentTeam: { users: this.state.users, communities: this.state.communities, preferences: {}, permissions: team_permissions }, showModal: true })
                            })

                        }}> New team </Button>
                    }
                </div>
            )

            ApiService.getUsers().then(users => {
                this.setState({ users })
            })

            ApiService.getCommunities().then(communities => {
                this.setState({ communities })
            })

            ApiService.getPermissions().then(permissions => {
                this.setState({ permissions })
            })
        }
    }

    generateConfiguration() {
        this.setState({
            componentReady: false
        })
        let endPoint = routes.getTeamsList
        const defaultConfiguration = {
            sort: {
                id: 'teams.update_timestamp',
                desc: true
            },
            rowsPerPage: 10,
            columns: [
                {
                    id: 'teams.id',
                    title: '#',
                    type: Table.types.STRING,
                    accessor: (row) => {
                        return row.id
                    },
                    show: true,
                    resizable: false,
                    width: 60
                },
                {
                    id: 'teams.name',
                    title: 'Name',
                    type: Table.types.STRING,
                    accessor: (row) => {
                        return row.name
                    },
                    show: true
                },
                {
                    id: 'teams.creation_timestamp',
                    title: 'Creation date',
                    accessor: (row) => {
                        return row.creation_timestamp
                    },
                    type: Table.types.DATETIME,
                    show: true,
                    width: 170
                },
                {
                    id: 'teams.update_timestamp',
                    title: 'Last update',
                    accessor: (row) => {
                        return row.update_timestamp
                    },
                    type: Table.types.DATETIME,
                    show: true,
                    width: 170
                }
            ]
        }

        this.setState({
            endPoint: endPoint,
            configuration: defaultConfiguration,
            componentReady: true
        })
    }

    async chooseTeam(_team) {
        let team = JSON.parse(JSON.stringify(_team))

        await ApiService.getUsersForTeam(team.id).then(users => {
            team.users = []
            this.state.users.forEach(user => {
                let userIsSelected = !!users.find(u => u.uid === user.uid)
                team.users.push({ ...user, _selected: userIsSelected })
            })
        })

        await ApiService.getPreferencesForTeam(team.id).then(preferences => {
            team.preferences = preferences || []
        })

        await ApiService.getPermissionsForTeam(team.id).then(permissions => {
            team.permissions = {}
            this.state.permissions.forEach(permission => {
                team.permissions[permission.key] = permissions[permission.key] || 0
            })
        })

        await ApiService.getCommunitiesForTeam(team.id).then(communities => {
            team.communities = []
            this.state.communities.forEach(community => {
                let communityIsSelected = !!communities.find(c => c.uid === community.uid)
                team.communities.push({ ...community, _selected: communityIsSelected })
            })
        })

        this.setState({
            currentTeam: team,
            showModal: true
        })
    }

    onPermissionChange(key, value) {
        const team = this.state.currentTeam
        team.permissions[key] = value
        this.setState({
            currentTeam: team
        })
    }

    onSendTestNotification = (team_id, subject, content) => {
        ApiService.pfmSendTestNotification({ team_id, subject, content })
    }

    render() {
        if (Helpers.getCurrentUser().admin < 1 || !Alfred.askPermission('TEAMS', Alfred.R)) { return <ForbiddenComponent /> }
        if (!this.state.componentReady) return null
        return (
            <div style={{ display: 'flex', flex: 1, padding: '.25rem' }}>
                <Modale show={this.state.showModal}>
                    <Modale.Header>
                        Team detail
                    </Modale.Header>
                    <Modale.Body style={{ width: '720px', height: '480px', display: 'flex', flexDirection: 'column' }}>
                        <Tabs id="user-tabs" style={{ flex: 1, display: 'flex', flexDirection: 'column' }} >
                            <Tab label="Details" >
                                <div style={{ padding: '.25rem' }}>
                                    <label>
                                        Name
                                        <input autoComplete="name" onChange={this.handleChange} type="text"
                                            style={{ fontWeight: 'normal' }}
                                            name="name" defaultValue={this.state.currentTeam.name}
                                            className="form-control" placeholder="Team Name" />
                                    </label>
                                    <Picker style={{ flex: 1 }} title="Platform users" filterkey={['displayname', 'uid', 'login']}
                                        items={this.state.currentTeam.users} onChange={this.onUsersChange} ukey="uid"
                                        sortBy="displayname" />
                                </div>
                            </Tab>
                            <Tab label="Communities" >
                                <div style={{ padding: '.25rem' }}>
                                    <Picker style={{ flex: 1 }} title="Communities" filterkey={['name', 'uid', 'box_version']}
                                        items={this.state.currentTeam.communities} onChange={this.onCommunitiesChange} ukey="uid"
                                        sortBy="name" />
                                </div>
                            </Tab>
                            <Tab label="Notifications" >
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', userSelect: 'none', padding: '.25rem' }}>
                                    <input id="cbk_notifications" type="checkbox" style={{ margin: '1rem' }} defaultChecked={this.state.currentTeam.preferences && this.state.currentTeam.preferences.notifications}
                                        onChange={() => {
                                            const currentTeam = this.state.currentTeam
                                            currentTeam.preferences.notifications = !!!currentTeam.preferences.notifications
                                            this.setState({
                                                currentTeam
                                            })
                                        }} />
                                    <div style={{ flex: 1 }}>
                                        <label htmlFor="cbk_notifications" style={{ margin: 0 }}>Box status change notifications</label>
                                        <small style={{ opacity: .6 }}>Send notifications by e-mail when a box disconnects. E-mail address must exist in user accounts.</small>
                                    </div>
                                    <Button bsSize="xs" onClick={() => { this.setState({ showModaleTestNotification: true }) }}>Test now</Button>
                                </div>
                            </Tab>
                            {Helpers.getSession().admin > 1 &&
                                <Tab label="Permissions">
                                    <div style={{ padding: '.25rem', overflow: 'auto', flex: 1 }}>
                                        <p style={{ padding: '.5rem', opacity: .6, fontStyle: 'italic' }}>
                                            Permissions only applies on user with <b>Power user</b> level. When a user is in several teams, the most permissive rule is kept.
                                    </p>
                                        <table className="tableau">
                                            <tbody>
                                                {Object.keys(this.state.permissions || {}).map((p, index) => {
                                                    const key = this.state.permissions[p].key
                                                    const perm = Object.assign(this.state.permissions[p], { rwx: this.state.currentTeam.permissions[key] })

                                                    return ((this.state.currentTeam.permissions._ADMIN < Alfred.X && perm.key !== "_ADMIN") || (perm.key === "_ADMIN"))
                                                        ? <tr key={index}>
                                                            <td width="100%">{perm.description.split('|').pop()}</td>
                                                            <td align="center" style={{ padding: 0 }}><Button onClick={() => { this.onPermissionChange(perm.key, 0) }} bsSize="small" bsStyle={perm.rwx === 0 ? 'default' : 'link'}>None</Button></td>
                                                            <td align="center" style={{ padding: 0 }}>{perm.R && <Button onClick={() => { this.onPermissionChange(perm.key, Alfred.R) }} bsSize="small" bsStyle={perm.rwx === Alfred.R ? 'success' : 'link'}>{perm.R}</Button>}</td>
                                                            <td align="center" style={{ padding: 0 }}>{perm.W && <Button onClick={() => { this.onPermissionChange(perm.key, Alfred.W) }} bsSize="small" bsStyle={perm.rwx === Alfred.W ? 'warning' : 'link'}>{perm.W}</Button>}</td>
                                                            <td align="center" style={{ padding: 0 }}>{perm.X && <Button onClick={() => { this.onPermissionChange(perm.key, Alfred.X) }} bsSize="small" bsStyle={perm.rwx === Alfred.X ? 'danger' : 'link'}>{perm.X}</Button>}</td>
                                                        </tr>
                                                        : ''
                                                })}
                                            </tbody>
                                        </table>
                                    </div>
                                </Tab>
                            }
                            {this.state.currentTeam.id && Helpers.getSession().admin >= 1 && Alfred.askPermission('TEAMS', Alfred.X) &&
                                <Tab label="Removal" >
                                    <div style={{ padding: '.25rem' }}>
                                        <Button bsStyle="danger" onClick={() => { this.onRemoveTeam(this.state.currentTeam) }}>Remove team</Button>
                                    </div>
                                </Tab>
                            }

                        </Tabs>
                    </Modale.Body>
                    <Modale.Footer>
                        <Button style={{ float: 'left' }} onClick={() => {
                            this.setState({ showModal: false })
                        }}>Close</Button>
                        <div style={{ flex: 1 }}></div>
                        {Alfred.askPermission('TEAMS', Alfred.W) &&
                            <Button bsStyle="primary" onClick={this.onSubmit}>Save</Button>
                        }
                    </Modale.Footer>
                </Modale>


                <Modale show={this.state.showModaleTestNotification}>
                    <Modale.Header>
                        Notification Test
                    </Modale.Header>
                    <Modale.Body>
                        <label>
                            Subject
                        </label>
                        <input className="form-control" ref="testNotifSubject" />
                        <label>
                            Content
                        </label>
                        <textarea className="form-control" ref="testNotifContent"></textarea>
                    </Modale.Body>
                    <Modale.Footer>
                        <Button onClick={() => { this.setState({ showModaleTestNotification: false }) }}>Close</Button>
                        <div style={{ flex: 1 }}></div>
                        <Button bsStyle="primary" onClick={() => { this.onSendTestNotification(this.state.currentTeam.id, this.refs.testNotifSubject.value, this.refs.testNotifContent.value); this.setState({ showModaleTestNotification: false }) }}>Send</Button>
                    </Modale.Footer>
                </Modale>


                <TeamsList
                    key={this.state.timestamp}
                    configuration={this.state.configuration}
                    endPoint={this.state.endPoint}
                    chooseTeam={this.chooseTeam}
                    forceRefresh={this.forceRefresh}
                />
            </div>
        )
    }
}

export default TeamsComponent
