import React, { Component } from 'react'
import Navmenu from './Menu'
import Helpers from '../core/Helpers'
import { Button } from '../components/UI/Toolkit'
import UserArea from './UserArea'
import Modal from '../components/UI/ModaleComponent'
import SessionTracker from '../components/SessionTracker'

import './breadcrumb.css'
import './main.css'
class App extends Component {

    constructor(props) {
        super(props)
        this.state = {
            breadcrumb: undefined,
            cartridge: undefined
        }
        this.updateBreadcrumb = this.updateBreadcrumb.bind(this)
    }

    /**
     * This function is passed to children to allow them to update
     * breadcrumb with their information
     *
     * @param path - Breadcrumb items as {name, link}
     * @param cartridge - Header details component
     */
    updateBreadcrumb(path, cartridge) {
        this.setState({
            breadcrumb: path,
            cartridge: cartridge,
            showModal: false
        })
    }

    logOut = () => {
        window.localStorage.removeItem('pf_session')
        window.localStorage.removeItem('pf_lastUserAction')
        window.localStorage.removeItem('pf_lastToken')
        window.localStorage.removeItem('pf_tokenExpire')
        setTimeout(() => {
            document.location.href = '/'
        }, 100)
    }

    render() {
        const background = Helpers.stringToColor(String(Helpers.getCurrentUser().displayName)) + '88'
        return (
            <div id="wrapper">
                <SessionTracker {...this.props} />
                <Modal show={this.state.showModal}>
                    <Modal.Body style={{ color: 'initial' }}>

                        <div style={{
                            background: background,
                            borderRadius: '50%',
                            height: '86px',
                            width: '86px',
                            margin: '10px auto 10px auto',
                            minWidth: '86px',
                            boxShadow: 'inset 0 -1pt 2pt rgba(0,0,0,.175)',
                            userSelect: 'none',
                            overflow: 'hidden',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}>
                            <div style={{
                                color: 'rgba(255,255,255,.75)',
                                fontSize: '46px',
                                textShadow: '1px 1px 1px rgba(0,0,0,.33)'
                            }}>

                                {String(Helpers.getCurrentUser().displayName).split(/\s/, 2).map(item => {
                                    return item[0]
                                })}
                            </div>
                        </div>

                        <p>Are you sure you want to log out now, {Helpers.getCurrentUser().displayName} ?</p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={() => { this.setState({ showModal: false }) }}>Stay logged in</Button>
                        <div style={{ flex: 1 }}></div>
                        <Button bsStyle="danger" onClick={() => { this.logOut() }}>Log out</Button>
                    </Modal.Footer>
                </Modal>
                <div id="navbar" className="navbar-default">
                    <Navmenu {...this.props}>
                        <UserArea logout={() => { this.setState({ showModal: true }) }} />
                    </Navmenu>
                </div>
                <div id="content">
                    <div id="breadcrumb">
                        <div>
                            <h1>{this.state.breadcrumb}</h1>
                        </div>
                        <div id="cartridge">{this.state.cartridge}</div>
                    </div>
                    <div id="mainContentArea">
                        {
                            React.Children.map(this.props.children, (child) => {
                                return React.cloneElement(child, { updateBreadcrumb: this.updateBreadcrumb })
                            })
                        }
                    </div>
                </div>
            </div>
        )
    }
}

export default App
