import React from 'react'

import jm from '../img/easter_egg_jmineur.svg'
import jm2 from '../img/easter_egg_jmineur2.svg'
import pk from '../img/easter_egg_pickaxe.svg'
import pk2 from '../img/easter_egg_pickaxe2.svg'

const y = [38, 38, 40, 40, 37, 39, 37, 39, 66, 65];
let x = 0;

class EasterEgg extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            visible: false
        }

        this.startAnimation = this.startAnimation.bind(this)
    }

    startAnimation() {
        Object.assign(this.pickaxe.style, {
            transition: '2s',
            bottom: 'calc(50% - 15px)',
            left: 'calc(50% - 50px)'
        })
        this.pickaxe.addEventListener('transitionend', (e) => {
            this.pickaxe.src = pk2
            Object.assign(this.pickaxe.style, {
                animation: 'none'
            })
            setTimeout(() => {
                this.jmineur.src = jm2
                Object.assign(this.jmineur.style, {
                    opacity: 0
                })
            }, 700)
        })
    }

    componentWillUnmount() {
        document.removeEventListener('keydown', this.fK)
    }

    fK(e, c) { if (e.keyCode === y[x]) { if (x === y.length - 1) { if (typeof c === 'function') { c() } x = 0 } else { x++ } } else { x = 0 } }

    render() {
        const K = (c) => { document.addEventListener('keydown', (e) => { this.fK(e, c) })}
        K(() => {
            this.setState({visible: true})
            setTimeout(() => {
                this.startAnimation()
            }, 100)
        })
            return <div style={{ position: 'fixed', bottom: 0, left: 0, zIndex: 9999999, display: this.state.visible ? 'block' : 'none', pointerEvents: 'none' }}>
                <img alt="" ref={(self) => { this.pickaxe = self }} src={pk} style={{position: 'fixed', bottom: '20px', left: '-64px', height: '64px', animation: 'spin-clockwise .15s infinite linear', zIndex: 1, transitionTimingFunction: 'ease-in' }}/>
                <img alt="" ref={(self) => { this.jmineur = self }} src={jm} style={{opacity: 1, transition: '1s', position: 'fixed',bottom: '-9px', left: '-5px', height: '128px',  zIndex: 2, animation: 'enter-from-left .5s ease-out'}}/>
            </div>
    }
}

export default EasterEgg
