import React from 'react'

import "./index.css"

class BoxUpdateCube extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            box: props.data
        }
    }

    componentWillReceiveProps(newProps) {
        this.setState({
            box: newProps.data
        })
    }

    getBoxVersion() {
        return (this.state.box.update && this.state.box.update.version)
            ? this.state.box.update.version
            : this.state.box.box_version
    }

    render() {
        return (
            <div>
                <div className={`BoxUpdateCube ${(this.state.box.update || {}).status}`}>
                    {this.state.box.uid}
                    <div className="BoxUpdateCubeTooltip">
                        <div style={{ borderBottom: ',5pt solid rgba(255,255,255,.5)' }}>{this.state.box.name}</div>
                        <div style={{ fontSize: '.99rem' }}>
                            Version: {this.getBoxVersion()}
                        </div>
                        <div style={{ fontSize: '.99rem' }}>
                            Update: {(this.state.box.update || {}).status}
                        </div>
                        {
                            (this.state.box.update || {}).error_message &&
                            <div style={{ fontSize: '.99rem' }}>
                                Error: {(this.state.box.update || {}).error_message}
                            </div>
                        }
                    </div>
                </div>
                <div className={`progressbarWrapper ${(this.state.box.update || {}).status}`}>
                    <div className={`progressbar`} style={{ width: `${(this.state.box.update || {}).progress * 100}%` }}>
                    </div>
                </div>
            </div>
        )
    }
}

export default BoxUpdateCube
