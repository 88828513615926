import React from 'react'
import ServicesVue from './vue';
import ApiService from '../../../core/ApiService';
import { withRouter } from 'react-router-dom'
import Logger from '../../../core/Logger'

const logger = new Logger('BoxServices')

class Services extends React.Component {
    refreshInterval = null

    constructor(props) {
        super(props)

        this.state = {
            services: [],
        }
    }

    componentWillMount() {
        this.refresh()
    }

    componentWillUnmount() {
        clearInterval(this.refreshInterval)
    }

    restart = (name) => {
        ApiService.putBoxService(this.props.match.params.boxId, name)
            .catch((err) => {
                logger.error(err)
            })
    }

    refresh = () => {
        if (!this.props.autorefresh) {
            setTimeout(() => {
                this.refresh()
            }, 5000)
            return;
        }

        this.getBoxServices()
        this.refreshInterval = setInterval(() => {
            if (this.props.autorefresh) {
                this.getBoxServices()
            }
        }, 5000)
    }

    getBoxServices = () => {
        ApiService.getBoxServices(this.props.match.params.boxId)
            .then((services) => {
                this.setState({
                    services
                })
            })
            .catch((err) => {
                logger.error(err)
            })
    }

    render() {
        return <ServicesVue
            autorefresh={this.props.autorefresh}
            box={this.props.box}
            services={this.state.services}
            restart={this.restart}
        />
    }
}

export default withRouter(Services)