import { Button } from '../UI/Toolkit'
import React, { Component } from 'react'
import ApiService from '../../core/ApiService'
import Helpers from '../../core/Helpers'
import Alfred from '../../core/Alfred'
import Modale from '../UI/ModaleComponent'

import routes from '../../routes'

import img_docker_dead from '../../img/img_docker_dead.svg'

import ic_restart from '../../img/ic_docker_restart.svg'
import ic_docker_get_logs from '../../img/ic_docker_get_logs.svg'

class DockerControlComponent extends Component {
    constructor(props) {
        super(props)

        this.state = {
            data: []
        }

        this.restartContainer = this.restartContainer.bind(this)
        this.dockerContainerPs = this.dockerContainerPs.bind(this)
    }

    componentDidMount() {
        if (Helpers.getCurrentUser().admin >= 1) {
            this.props.updateBreadcrumb('Docker controls',
                <div style={{ display: 'flex' }}>
                    <div style={{ flex: 1 }}>
                        Control Platform Docker containers
                    </div>
                    <Button onClick={() => { this.dockerContainerPs() }}>Refresh</Button>
                </div>
            )

            this.dockerContainerPs()
        }
    }

    dockerContainerPs() {
        ApiService.getDockerContainers().then(data => {
            this.setState({
                data
            })
        }).catch(ex => {
            this.setState({
                data: null
            })
        })
    }

    restartContainer(container) {
        container.state = "Restart requested..."
        ApiService.restartDockerContainerById(container.id).then(response => {
            if (!response.success) {
                alert(response.message)
            }
            setTimeout(() => {
                this.dockerContainerPs()
            }, 3000)
        })
        this.setState({ showModale: false })
    }

    getLogs = (container) => {
        const url = ApiService.getApiHost() + ApiService.prepareRoute(routes.getDockerLogById, { id: container.id }).path + `?token=${Helpers.getCurrentUser().token}`
        window.location.href = url
    }

    render() {
        if (this.state.data === null)
            return (
                <div style={{ padding: '1rem', display: 'flex', flex: 1 }}>
                    <div style={{ margin: 'auto', opacity: .5, textAlign: 'center' }}>
                        <i style={{ display: 'block', marginBottom: '3rem' }}>It seems that something went wrong with docker...</i>
                        <img src={img_docker_dead} alt="" style={{ display: 'block', width: '512px' }} />
                    </div>
                </div>
            )
        else
            return (
                <div style={{ padding: '1rem' }}>
                    <table className="tableau" style={{ width: '100%' }}>
                        <thead>
                            <tr>
                                <th></th>
                                <th>Container</th>
                                <th nowrap>Status</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {(this.state.data || []).map((container, id) => {
                                return <tr key={id}>
                                    <td width="16">
                                        {
                                            container.state === 'running'
                                                ? <div style={{
                                                    width: '1rem',
                                                    height: '1rem',
                                                    borderRadius: '1rem',
                                                    background: 'var(--color-success)',
                                                    boxShadow: 'inset -.1rem -.2rem .5rem rgba(0,0,0,.4), 0 0 .75rem 1pt var(--color-success)'
                                                }}></div>
                                                : <div style={{
                                                    width: '1rem',
                                                    height: '1rem',
                                                    borderRadius: '1rem',
                                                    background: 'var(--color-error)',
                                                    boxShadow: 'inset -.1rem -.2rem .5rem rgba(0,0,0,.4), 0 0 .75rem 1pt var(--color-error)'
                                                }}></div>
                                        }
                                    </td>
                                    <td title={container.id} >
                                        {container.name}
                                        <div>
                                            <small style={{ opacity: .5 }}>{container.image}</small>
                                        </div>
                                    </td>
                                    <td style={{ whiteSpace: 'nowrap' }} width="128">
                                        {container.state}
                                        <div>
                                            <small style={{ opacity: .5 }}>{container.status}</small>
                                        </div>
                                    </td>
                                    <td align="right" style={{ whiteSpace: 'nowrap' }} width="72">
                                        {Alfred.askPermission('DOCKER_CONTROLS', Alfred.R) &&
                                            <Button style={{ padding: '0 .5rem' }} bsStyle="link" onClick={() => { this.getLogs(container) }} title="Get logs">
                                                <img src={ic_docker_get_logs} alt="" style={{ height: '3rem' }} />
                                            </Button>
                                        }
                                        {Alfred.askPermission('DOCKER_CONTROLS', Alfred.X) &&
                                            <Button style={{ padding: '0 .5rem' }} bsStyle="link" onClick={() => { this.setState({ showModale: container }) }} title="Restart">
                                                <img src={ic_restart} alt="" style={{ height: '3rem' }} />
                                            </Button>
                                        }
                                    </td>
                                </tr>
                            })}
                        </tbody>
                    </table>
                    <Modale show={!!this.state.showModale}>
                        <Modale.Header>
                            Restart
                        </Modale.Header>
                        <Modale.Body>
                            <p>
                                Restarting a running container might impact any other container on the platform and possibly temporarily disable some feature or even all access to the platform.
                            </p>
                            <p>Anyway, this action will be recorded with your name.</p>
                            <p>
                                Are you absolutely sure you want to request restart for container <i>{this.state.showModale && this.state.showModale.name}</i> ?
                            </p>
                        </Modale.Body>
                        <Modale.Footer>
                            <Button onClick={() => { this.setState({ showModale: false }) }}>Close</Button>
                            <div style={{ flex: 1 }}></div>
                            <Button bsStyle="danger" onClick={() => { this.restartContainer(this.state.showModale) }}>Restart</Button>
                        </Modale.Footer>
                    </Modale>
                </div>
            )
    }
}

export default DockerControlComponent
