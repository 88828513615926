import React from 'react'

export default class AntiSpamButton extends React.PureComponent {
    constructor(props) {
        super(props)

        this.state = {
            disabled: false
        }
    }

    render() {
        const childrenWithProps = React.Children.map(this.props.children, child =>
            React.cloneElement(child, {
                disabled: this.state.disabled,
                onClick: () => {
                    this.setState({
                        disabled: true
                    })
                    setTimeout(() => {
                        this.setState({
                            disabled: false
                        })
                    }, 10000)
                    child.props.onClick()
                }
            })
        );

        return <div>{childrenWithProps}</div>
    }
}