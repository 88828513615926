import React from 'react'
import { Button } from '../../UI/Toolkit'
import moment from 'moment-timezone'
import NoSpamButton from './button'
import './index.css'

export default class ServicesVue extends React.PureComponent {
    renderService(name, details) {
        const status = details.running ? <span style={{ color: 'var(--color-primary)' }}>Running for {moment.duration(details.uptime).humanize()}</span> : <span style={{ color: 'red' }}>Stopped</span>;
        return (
            <div key={name} style={{ display: 'flex', flexDirection: 'column' }} className="serviceRow">
                <div style={{ display: 'flex' }}>
                    <div style={{ flex: 1 }}>{name}</div>
                    <NoSpamButton><Button bsStyle="danger" className="serviceRestartButton" onClick={() => this.props.restart(name)}>
                        <img alt="" src="/img/ic_service_restart.svg" />
                        <span>Restart</span>
                    </Button></NoSpamButton>
                </div>
                <small style={{ opacity: .5 }}><i>{status}</i></small>
            </div>
        )
    }

    render() {
        const offlineServicesCount = (Object.values(this.props.services || {}) || []).filter((details) => !details.running).length
        const runningServicesCount = (Object.values(this.props.services || {}) || []).filter((details) => details.running).length
        return (
            <div className={`foldable ${this.props.autorefresh ? 'withactions' : ''}`}>
                <button onClick={(e) => { e.target.focus() }} className="unfold">
                    <img alt="" src={`/img/ic_services_${(this.props.box.system || '').toLowerCase()}.svg`}
                        style={{ height: '2.5rem', margin: '.25rem', background: this.props.autorefresh ? (offlineServicesCount > 0 ? 'orange' : 'var(--color-primary)') : 'rgba(0,0,0,.2)', borderRadius: '50%', padding: '.5rem' }} />
                    <div className="bullets">
                        {this.props.autorefresh ? '' : <div className="bullet warning" title='Services information wont be loaded while Conductor is not connected.'>!</div>}
                        {offlineServicesCount ? <div className="bullet error" title='Some services are not running !'>{offlineServicesCount}</div> : ''}
                    </div>
                    <small>Services</small>
                    {this.props.autorefresh
                        ? <small style={{ opacity: .6 }}>{runningServicesCount} running</small>
                        : <small style={{ opacity: .6 }}>no data</small>
                    }
                </button>
                {this.props.autorefresh
                    ? <div className="fold">
                        <div>
                            {
                                !this.props.autorefresh
                                    ? <span style={{ opacity: .5 }}>Services information wont be loaded while Conductor is not connected.</span>
                                    : ''
                            }
                        </div>
                        <div style={{ flex: 'initial', overflow: 'auto' }}>
                            {Object.keys(this.props.services).map((name) => {
                                return this.renderService(name, this.props.services[name])
                            })}
                        </div>
                    </div>
                    : ''
                }

            </div>
        )
    }
}