import React from 'react'
import DefaultLayout from '../templates/DefaultTemplate'
import TransferListContainer from '../components/FileTransferMonitor/TransferListContainer'

class TransferListMonitor extends React.Component {

    constructor(props) {
        super(props)

        this.state = {}
    }

    render() {
        return (
            <DefaultLayout {...this.props}>
                <TransferListContainer {...this.props} history={this.props.history}/>
            </DefaultLayout>
        )
    }
}

export default TransferListMonitor
