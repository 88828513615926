import React, { Component } from 'react'
import { Button } from '../UI/Toolkit'
import Alfred from '../../core/Alfred'
import ApiService from '../../core/ApiService'
import ForbiddenComponent from '../ForbiddenComponent'
import moment from 'moment-timezone'
import Helpers from '../../core/Helpers'
import Modale from '../UI/ModaleComponent'

import './index.css'

class PfSettingsComponent extends Component {
    constructor(props) {
        super(props)
        this.state = {
            config: null,
            error: null,
            message: null,
            delta: {},
            showModaleSendMail: false,
            showModaleSendSMS: false
        }

        this.saveConfiguration = this.saveConfiguration.bind(this)
        this.onParamChanged = this.onParamChanged.bind(this)
    }

    componentDidMount() {
        if (Alfred.askPermission('PF_CONFIG', Alfred.R)) {
            this.props.updateBreadcrumb('Platform Manager Configuration',
                <div style={{ display: 'flex' }}>
                    <div style={{ flex: 1 }}>
                        Customize Platform Manager Configuration
                    </div>
                    {Alfred.askPermission('PF_CONFIG', Alfred.W) &&
                        <Button bsStyle="primary" bsSize="small" onClick={() => {
                            this.saveConfiguration()
                        }}>Save</Button>
                    }
                </div>
            )

            ApiService.getPFMConfiguration()
                .then((newConfig) => {
                    this.setState({ config: newConfig });
                })
                .catch((err) => {
                    this.setState({ error: 'Could not obtain the current configuration' });
                });
        }
    }

    saveConfiguration() {
        ApiService.setPFMConfiguration({
            config: this.state.config,
            delta: this.state.delta
        })
            .then(() => {
                this.setState({
                    error: null,
                    message: `Configuration set ${moment().format('YYYY-MM-DD HH:mm:ss')}`,
                    delta: {}
                });

                document.querySelectorAll('tr.modified').forEach(field => { field.classList.remove('modified') })
            })
            .catch((err) => {
                this.setState({
                    error: 'Could not modify the configuration',
                    message: null
                });
            });
    }

    onParamChanged(p) {
        let value = null;
        switch (p.target.type) {
            case 'number':
                value = parseInt(p.target.value, 10);
                break;
            case 'checkbox':
                value = !!p.target.checked;
                break;
            default:
                value = p.target.value;
                break;
        }

        let nestedAssign = function (object, path, value) {
            let last = path.split('.').pop()
            path
                .split('.')
                .reduce((o, p) => o[p] = last === p ? value : o[p] || {}, object);
        }

        let getValue = function (object, path) {
            return path
                .split('.')
                .reduce((o, p) => o[p], object);
        }

        let path = p.target.getAttribute('data-config-path')
        let delta = this.state.delta
        let prevValue = delta[path]
            ? delta[path].prev
            : getValue(this.state.config, path)

        if (prevValue !== value) {
            p.target.closest('tr').classList.add('modified')
            delta[path] = { prev: prevValue, new: value }
        } else {
            p.target.closest('tr').classList.remove('modified')
            delete delta[path]
        }

        nestedAssign(this.state.config, path, value);
        this.setState({ config: this.state.config, delta: delta });
    }

    onSendMail = (address, subject, content) => {
        ApiService.pfmSendMail({
            recipients: [
                { email_address: address }
            ], subject, content
        })
    }

    onSendSMS = (phone, content) => {
        ApiService.pfmSendSMS({
            recipients: [
                { phone: phone }
            ], content
        })
    }

    render() {
        if (Helpers.getCurrentUser().admin < 1 || !Alfred.askPermission('PF_CONFIG', Alfred.R)) { return <ForbiddenComponent /> }
        if (!this.state.config) { return null }
        let errorObj = null
        if (this.state.error)
            errorObj = <div className='error'>{this.state.error}</div>

        let messageObj = null
        if (this.state.message)
            messageObj = <div className='message'>{this.state.message}</div>
        return (
            <div className='pfSettings'>
                {errorObj}{messageObj}
                <div className='section open'>
                    <h1>Log</h1>
                    <table className="content">
                        <tbody>
                            <tr>
                                <td width="66%">
                                    <label>Log level</label>
                                    <small><legend>Determines what to log. (trace=0, debug=1, info=2, warn=3, err=4, critical=5)</legend></small>
                                </td>
                                <td>
                                    <input type='number' placeholder='4' onChange={this.onParamChanged}
                                        min="0" max="5"
                                        data-config-path='log.level'
                                        value={this.state.config.log.level} />
                                </td>
                            </tr>
                            <tr>
                                <td width="66%">
                                    <label>Database</label>
                                    <small>
                                        <legend>Log database requests and responses.</legend>
                                    </small>
                                </td>
                                <td>
                                    <input type='checkbox' onChange={this.onParamChanged}
                                        data-config-path='log.log_db_requests'
                                        defaultChecked={this.state.config.log.log_db_requests} />
                                </td>
                            </tr>
                            <tr>
                                <td width="66%">
                                    <label>Web service</label>
                                    <small>
                                        <legend>Log web service requests and responses</legend>
                                    </small>
                                </td>
                                <td>
                                    <input type='checkbox' onChange={this.onParamChanged}
                                        data-config-path='log.log_web_service'
                                        defaultChecked={this.state.config.log.log_web_service} />
                                </td>
                            </tr>
                            <tr>
                                <td width="66%">
                                    <label>Web sockets</label>
                                    <small>
                                        <legend>Log web sockets messages.</legend>
                                    </small>
                                </td>
                                <td>
                                    <input type='checkbox' onChange={this.onParamChanged}
                                        data-config-path='log.log_web_socket'
                                        defaultChecked={this.state.config.log.log_web_socket} />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div className='section open'>
                    <h1>Alerts</h1>
                    <table className="content">
                        <tbody>
                            <tr>
                                <td width="66%">
                                    <label>Platform name</label>
                                    <small><legend>Name of the platform, for the alert message</legend></small>
                                </td>
                                <td>
                                    <input type='text' placeholder='platform' onChange={this.onParamChanged}
                                        data-config-path='monitoring.platform_name'
                                        value={this.state.config.monitoring.platform_name} />
                                </td>
                            </tr>
                            <tr>
                                <td width="66%">
                                    <label>Alert delay</label>
                                    <small><legend>Delay in seconds before triggering an alert</legend></small>
                                </td>
                                <td>
                                    <input type='number' placeholder='600' onChange={this.onParamChanged}
                                        min='10'
                                        data-config-path='monitoring.connexion_alert_delay'
                                        value={this.state.config.monitoring.connexion_alert_delay} />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div className='section open'>
                    <h1>Sender mail
                        <Button bsSize="xs" onClick={() => { this.setState({ showModaleSendMail: true }) }}>Test now</Button>
                    </h1>
                    <table className="content">
                        <tbody>
                            <tr>
                                <td width="66%">
                                    <label>From</label>
                                    <small><legend>The mail address of the sender</legend></small>
                                </td>
                                <td>
                                    <input type='text' placeholder='Notification MediaBOX <dev@e-mediatech.fr>' onChange={this.onParamChanged}
                                        data-config-path='notification.from'
                                        value={this.state.config.notification.from} />
                                </td>
                            </tr>
                            <tr>
                                <td width="66%">
                                    <label>SMTP host</label>
                                    <small><legend>Host address of the smtp server</legend></small>
                                </td>
                                <td>
                                    <input type='text' onChange={this.onParamChanged}
                                        data-config-path='notification.smtp_host'
                                        value={this.state.config.notification.smtp_host} />
                                </td>
                            </tr>
                            <tr>
                                <td width="66%">
                                    <label>SMTP port</label>
                                    <small><legend>Port of the smtp server</legend></small>
                                </td>
                                <td>
                                    <input type='text' onChange={this.onParamChanged}
                                        data-config-path='notification.smtp_port'
                                        value={this.state.config.notification.smtp_port} />
                                </td>
                            </tr>
                            <tr>
                                <td width="66%">
                                    <label>SMTP user</label>
                                    <small><legend>User of the smtp server</legend></small>
                                </td>
                                <td>
                                    <input type='text' onChange={this.onParamChanged}
                                        data-config-path='notification.smtp_user'
                                        value={this.state.config.notification.smtp_user} />
                                </td>
                            </tr>
                            <tr>
                                <td width="66%">
                                    <label>SMTP password</label>
                                    <small><legend>Password of the smtp server</legend></small>
                                </td>
                                <td>
                                    <input type='text' onChange={this.onParamChanged}
                                        data-config-path='notification.smtp_pass'
                                        value={this.state.config.notification.smtp_pass} />
                                </td>
                            </tr>
                            <tr>
                                <td width="66%">
                                    <label>SMTP is secure</label>
                                    <small><legend>Use encryption when sending the request</legend></small>
                                </td>
                                <td>
                                    <input type='checkbox' onChange={this.onParamChanged}
                                        data-config-path='notification.smtp_secure'
                                        defaultChecked={this.state.config.notification.smtp_secure} />
                                </td>
                            </tr>
                            <tr>
                                <td width="66%">
                                    <label>Timeout</label>
                                    <small><legend>Timeout (in seconds) of the script</legend></small>
                                </td>
                                <td>
                                    <input type='number' placeholder='30' onChange={this.onParamChanged}
                                        data-config-path='notification.timeout'
                                        value={this.state.config.notification.timeout} />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div className='section open'>
                    <h1>Sender SMS
                        <Button bsSize="xs" onClick={() => { this.setState({ showModaleSendSMS: true }) }}>Test now</Button>
                    </h1>
                    <table className="content">
                        <tbody>
                            <tr>
                                <td width="66%">
                                    <label>From</label>
                                    <small><legend>The name of the sender</legend></small>
                                </td>
                                <td>
                                    <input type='text' placeholder='MediaVision' onChange={this.onParamChanged}
                                        data-config-path='sms.from'
                                        value={this.state.config.sms.from} />
                                </td>
                            </tr>
                            <tr>
                                <td width="66%">
                                    <label>Provider</label>
                                    <small><legend>Which sms provider is used</legend></small>
                                </td>
                                <td>
                                    <input type='text' onChange={this.onParamChanged}
                                        data-config-path='sms.provider'
                                        value={this.state.config.sms.provider} />
                                </td>
                            </tr>
                            <tr>
                                <td width="66%">
                                    <label>Provider key</label>
                                    <small><legend>API key for the selected provider</legend></small>
                                </td>
                                <td>
                                    <input type='text' onChange={this.onParamChanged}
                                        data-config-path='sms.keyId'
                                        value={this.state.config.sms.keyId} />
                                </td>
                            </tr>
                            <tr>
                                <td width="66%">
                                    <label>HTTP proxy</label>
                                    <small><legend>Proxy used to access the provider</legend></small>
                                </td>
                                <td>
                                    <input type='text' onChange={this.onParamChanged}
                                        data-config-path='sms.http_proxy'
                                        value={this.state.config.sms.http_proxy} />
                                </td>
                            </tr>
                            <tr>
                                <td width="66%">
                                    <label>Custom host name</label>
                                    <small><legend>Custom host name or URL (if needed)</legend></small>
                                </td>
                                <td>
                                    <input type='text' onChange={this.onParamChanged}
                                        data-config-path='sms.customHostname'
                                        value={this.state.config.sms.customHostname} />
                                </td>
                            </tr>
                            <tr>
                                <td width="66%">
                                    <label>Timeout</label>
                                    <small><legend>Timeout (in seconds) of the script</legend></small>
                                </td>
                                <td>
                                    <input type='number' placeholder='10' onChange={this.onParamChanged}
                                        data-config-path='sms.timeout'
                                        value={this.state.config.sms.timeout} />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div className='section open'>
                    <h1>Advanced</h1>
                    <i>⚠ These parameters need a restart of the platform manager to be applied.</i>
                    <table className="content">
                        <tbody>
                            <tr>
                                <td width="66%">
                                    <label>File server prefix</label>
                                    <small><legend>Prefix of the file paths from point of view of the box</legend></small>
                                </td>
                                <td>
                                    <input type='text' onChange={this.onParamChanged}
                                        data-config-path='file_server.prefix'
                                        value={this.state.config.file_server.prefix} />
                                </td>
                            </tr>
                            <tr>
                                <td width="66%">
                                    <label>Interbox threads</label>
                                    <small><legend>Number of threads used for the interbox communication</legend></small>
                                </td>
                                <td>
                                    <input type='number' placeholder='1' onChange={this.onParamChanged}
                                        min='1'
                                        data-config-path='platform.network_threads'
                                        value={this.state.config.platform.network_threads} />
                                </td>
                            </tr>
                            <tr>
                                <td width="66%">
                                    <label>Web service threads</label>
                                    <small><legend>Number of threads used for the web service</legend></small>
                                </td>
                                <td>
                                    <input type='number' placeholder='4' onChange={this.onParamChanged}
                                        min='2'
                                        data-config-path='web_service.nb_threads'
                                        value={this.state.config.web_service.nb_threads} />
                                </td>
                            </tr>
                            <tr>
                                <td width="66%">
                                    <label>Tunnels timeout</label>
                                    <small><legend>Timeout (in seconds) of a tunnel that is not used</legend></small>
                                </td>
                                <td>
                                    <input type='number' placeholder='300' onChange={this.onParamChanged}
                                        data-config-path='administration.interbox_tunnels_timeout'
                                        value={this.state.config.administration.interbox_tunnels_timeout} />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <Modale show={this.state.showModaleSendMail}>
                    <Modale.Header>
                        Test sender mail
                    </Modale.Header>
                    <Modale.Body>
                        <div style={{ width: '400pt' }}>
                            <label>Address</label>
                            <input className="form-control" ref="mailAddress" defaultValue="emedia-dev@e-media.fr" />
                            <label>Subject</label>
                            <input className="form-control" ref="mailSubject" defaultValue="Test mail" />
                            <label>Content</label>
                            <textarea className="form-control" ref="mailContent"
                                defaultValue={`Sent from the platform manager settings page of the ${this.state.config.monitoring.platform_name || 'unknown'} platform.`} />
                        </div>
                    </Modale.Body>
                    <Modale.Footer>
                        <Button onClick={() => { this.setState({ showModaleSendMail: false }) }}>Close</Button>
                        <div style={{ flex: 1 }}></div>
                        <Button bsStyle="primary" onClick={() => {
                            this.onSendMail(this.refs.mailAddress.value, this.refs.mailSubject.value, this.refs.mailContent.value);
                            this.setState({ showModaleSendMail: false })
                        }}>
                            Send
                        </Button>
                    </Modale.Footer>
                </Modale>

                <Modale show={this.state.showModaleSendSMS}>
                    <Modale.Header>
                        Test sender SMS
                    </Modale.Header>
                    <Modale.Body>
                        <div style={{ width: '400pt' }}>
                            <label>Phone number</label>
                            <input className="form-control" ref="smsPhoneNumber" />
                            <label>Content</label>
                            <textarea className="form-control" ref="smsContent"
                                defaultValue={`Test from the platform manager settings page of the ${this.state.config.monitoring.platform_name || 'unknown'} platform.`} />
                        </div>
                    </Modale.Body>
                    <Modale.Footer>
                        <Button onClick={() => { this.setState({ showModaleSendSMS: false }) }}>Close</Button>
                        <div style={{ flex: 1 }}></div>
                        <Button bsStyle="primary" onClick={() => {
                            this.onSendSMS(this.refs.smsPhoneNumber.value, this.refs.smsContent.value);
                            this.setState({ showModaleSendSMS: false })
                        }}>
                            Send
                        </Button>
                    </Modale.Footer>
                </Modale>
            </div>
        )
    }
}

export default PfSettingsComponent
