import React, { Component } from 'react'
import moment from 'moment-timezone'
import { Link } from 'react-router-dom'
import Tag from '../../../../UI/Tag'

import './index.css'

import ic_os_undefined from '../../../../../img/ic_os_undefined.svg'
import ic_os_windows from '../../../../../img/ic_os_windows.svg'
import ic_os_linux from '../../../../../img/ic_os_linux.svg'

import ic_warning from '../../../../../img/ic_warning.svg'

class BoxMonitorItemComponent extends Component {

    constructor(props) {
        super(props)
        this.state = {
            box: props.data
        }

        this.clickHandle = this.clickHandle.bind(this)
    }

    renderConnected() {
        return <div className={`BoxMonitorItemComponentContent`}>
            Connected
            {this.state.box.connection_time &&
                <div>
                    <div title={moment(this.state.box.connection_time).format('YYYY-MM-DD HH:mm:ss')}>{moment(this.state.box.connection_time).fromNow()}</div>
                </div>}
        </div>
    }

    componentWillReceiveProps(nextProps) {
        this.setState({ box: { ...this.state.box, configuration_up_to_date: nextProps.data.configuration_up_to_date } })
    }

    renderDisconnected() {
        return <div className={`BoxMonitorItemComponentContent`}>
            Disconnected
            {this.state.box.disconnection_time &&
                <div>
                    <div title={moment(this.state.box.disconnection_time).format('YYYY-MM-DD HH:mm:ss')}>{moment(this.state.box.disconnection_time).fromNow()}</div>
                </div>}
        </div>
    }

    clickHandle() {
        this.props.history.push(`/monitor/boxes/${this.state.box.id}`)
    }

    renderTags = () => {
        const tags = (this.state.box.tags || []).filter((tag) => tag.domain_type === 'admin_pf')
        const tagsToShow = tags.slice(0, 3)
        const extraTags = tags.slice(3)
        return (<div className='BoxMonitorItemComponentTags'>
            {extraTags.length > 0
                ? <div className="showExtraTags">
                    +{extraTags.length}
                    <div className='extraTagsPopup ui-card'>
                        {extraTags.map((tag, index) => {
                            return <Tag {...tag} key={index} />
                        })}
                    </div>
                </div>
                : ''
            }
            {tagsToShow.map((tag, index) => {
                return <Tag {...tag} key={index} />
            })}
        </div>)
    }

    render() {
        let os_icon = ic_os_undefined

        switch (this.state.box.system) {
            case 'linux':
                os_icon = ic_os_linux
                break
            case 'windows':
                os_icon = ic_os_windows
                break
            default:
                os_icon = ic_os_undefined
                break
        }
        return (
            <Link to={`/monitor/boxes/${this.state.box.id}`} className={`BoxMonitorItemComponentWrapper ${this.state.box.active ? `active` : `inactive`} ${this.state.box.connected ? `online` : `offline`}`}>
                <div className={`BoxMonitorItemComponentTitleBar`}>
                    <img src={os_icon} alt="" style={{ width: '1.5rem', marginRight: '.1rem', verticalAlign: 'middle' }} /> &nbsp;
                    <div title={this.state.box.name || 'no name'} style={{ flex: 1, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{this.state.box.name || <i style={{ opacity: .5, color: '#666' }}>no-name</i>}</div>
                    <div className={`BoxMonitorItemComponentId`} style={{ minWidth: '1.75rem', marginRight: '.1rem', verticalAlign: 'middle' }}>
                        #{this.state.box.id}
                    </div>
                </div>
                {this.renderTags()}
                {this.state.box.connected ? this.renderConnected() : this.renderDisconnected()}
                <div className={`BoxMonitorItemComponentContent`} style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div>Version</div>
                    <div style={{ color: '#48B' }}>
                        {this.state.box.version || 'unknown'}
                    </div>
                </div>
                {!this.state.box.configuration_up_to_date &&
                    <img alt="" title="Configuration version mismatch" src={ic_warning} style={{ height: '1.25rem', position: 'absolute', bottom: '-.25rem', right: '-.25rem', pointerEvents: 'all' }} />
                }
            </Link>
        )
    }
}

export default BoxMonitorItemComponent
