import React from 'react'
import DefaultLayout from '../templates/DefaultTemplate'
import BoxEventsListContainer from '../components/BoxEventsComponent/BoxEventsListContainer'

class BoxEventsRoute extends React.Component {

    render() {
        return (
            <DefaultLayout {...this.props}>
                <BoxEventsListContainer {...this.props} history={this.props.history} />
            </DefaultLayout>
        )
    }
}

export default BoxEventsRoute
