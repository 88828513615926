import React, { Component } from 'react'
import Helpers from '../../core/Helpers'

import ic_dl from '../../img/file_dl.svg'

import './index.css'

class DownloadFileComponent extends Component {
    constructor(props) {
        super(props)

        this.state = {
        }

    }

    componentDidMount = () => {
        this.props.updateBreadcrumb(
            <div style={{ clear: 'both', display: 'flex' }}>
                <h1>File download</h1>
            </div>,
            <div style={{ clear: 'both', display: 'flex' }}>
                <div style={{ flex: 1 }}>
                    {this.props.match.params.filename}
                </div>
            </div>
        )
        this.download()
    }

    download = () => {
        window.open(`/api/downloadTmp?file=${this.props.match.params.filename}&token=${Helpers.getCurrentUser().token}`, 'download')
    }

    render = () => {
        return (
            <div id="dlWrapper">
                <img src={ic_dl} alt="" />
                <h2>{this.props.match.params.filename}</h2>
                <p>Your download should start shortly if the file is still available.
                    <br />
                    If it doesn't, <button className="link" onClick={this.download}>click here to try again</button>.
                </p>
            </div>
        )
    }
}

export default DownloadFileComponent
