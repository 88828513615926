import React from 'react'
import moment from 'moment-timezone'

import './InputDate.css'

class InputDate extends React.Component {

    /*
        Props :
        - format : date format to show on the widget
        - accept : array of date formats to accept on user input (in addition to default ones)
        - readOnly
        - required
        - placeholder
        - open : force calendar to be shown as a non-popup item
    */

    constructor(props) {
        super(props)

        this.DATE_FORMATS = ["DD/MM/YYYY", "DD MMM YYYY", "DDMMYYYY", "YYYY-MM-DD", "x", "DDMMYY", "YYYY-MM-DD HH:mm"]
        if (props.accept) {
            this.DATE_FORMATS = this.DATE_FORMATS.concat(Array.isArray(props.accept) ? props.accept : [props.accept])
        }

        this.state = {
            showCalendar: this.props.open || false
        }

        if (props.value) {
            Object.assign(this.state, {
                value: props.value && (moment(props.value, this.DATE_FORMATS).isValid())
                    ? moment(props.value, this.DATE_FORMATS).format(this.props.format || this.DATE_FORMATS[0])
                    : undefined,
                time: props.value && (moment(props.value, this.DATE_FORMATS).isValid())
                    ? moment(props.value, this.DATE_FORMATS).format('H:mm')
                    : undefined
            })
        }

        this.normalize = this.normalize.bind(this)
        this.emit = this.emit.bind(this)
        this.onClickOutside = this.onClickOutside.bind(this)
    }

    componentDidMount() {
        document.body.addEventListener('click', this.onClickOutside)
        this.refs.field.value = moment(this.state.value, this.props.format || this.DATE_FORMATS[0]).isValid() ? moment(this.state.value, this.props.format || this.DATE_FORMATS[0]).format(this.props.format || this.DATE_FORMATS[0]) : ""
        this.refs.time.value = this.state.time
        this.normalize()
    }

    onClickOutside(event) {
        if ((event.path || (event.composedPath && event.composedPath()) || []).indexOf(this.refs.wrapper) === -1) {
            this.setState({ showCalendar: false })
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.value) {
            this.setState({
                value: nextProps.value && (moment(nextProps.value, this.DATE_FORMATS).isValid())
                    ? moment(nextProps.value, this.DATE_FORMATS).format(nextProps.format || this.DATE_FORMATS[0])
                    : undefined,
                time: nextProps.value && (moment(nextProps.value, this.DATE_FORMATS).isValid())
                    ? moment(nextProps.value, this.DATE_FORMATS).format('H:mm')
                    : undefined
            })
        }

        this.refs.field.value = moment(nextProps.value, this.DATE_FORMATS).isValid() ? moment(nextProps.value, this.DATE_FORMATS).format(this.props.format || this.DATE_FORMATS[0]) : ""
        this.refs.time.value = moment(nextProps.value, this.DATE_FORMATS).isValid() ? moment(nextProps.value, this.DATE_FORMATS).format('HH:mm') : ""
        this.normalize()
    }

    getScreenCordinates(el) {
        var rect = el.getBoundingClientRect(),
            scrollLeft = window.pageXOffset || document.documentElement.scrollLeft,
            scrollTop = (window.pageYOffset || document.documentElement.scrollTop)

        return {
            y: rect.top + scrollTop,
            x: rect.left + scrollLeft
        };
    }

    emit() {
        if (!this.props.readOnly && !this.props.disabled) {
            let time = moment(this.state.time, ['HH:mm', 'HHmm', 'hmm']).isValid() ? moment(this.state.time, ['HH:mm', 'HHmm', 'hmm']) : moment('00:00', 'HH:mm')
            let date = moment(this.state.value, this.props.format || this.DATE_FORMATS[0]).hours(time.hours()).minutes(time.minutes())
            this.props.onChange(this.props.time ? date.format('YYYY-MM-DD HH:mm:ss') : date.format('YYYY-MM-DD'))
        }
    }

    positionDropdown() {
        if (this.props.open) return
        let wrapper = this.refs.wrapper;
        let dropdown = this.refs.dropdown;

        let wrapperRect = wrapper.getBoundingClientRect();

        dropdown.style = Object.assign(dropdown.style, {
            position: "absolute",
            zIndex: 999,
            left: (this.getScreenCordinates(wrapper).x + 4) + "px",
            top: (this.getScreenCordinates(wrapper).y /* - parseInt(window.scrollY, 10) */ + wrapperRect.height - 5) + "px",
            maxWidth: (wrapperRect.width - 6) + "px"
        });

        let dropdownRect = dropdown.getBoundingClientRect();

        if (dropdownRect.bottom > window.innerHeight) {
            Object.assign(dropdown.style, {
                top: (-dropdownRect.height - 2) + "px"
            });
            dropdownRect = dropdown.getBoundingClientRect();
        }

        if (dropdownRect.top < 5) {
            Object.assign(dropdown.style, {
                position: "absolute",
                top: "5px",
                bottom: "auto"
            });
        }
    }

    normalize(callback) {
        let val = moment(this.refs.field && this.refs.field.value, this.DATE_FORMATS).isValid()
            ? (this.refs.field && this.refs.field.value)
            : this.state.value
        let user_time = (this.refs.time && this.refs.time.value) || '00:00'
        let time = user_time && moment(user_time, ['H:mm', 'Hmm']).isValid() ? moment(user_time, ['H:mm', 'Hmm']) : moment('00:00', 'H:mm')

        this.setState({
            value: val,
            _value: val && moment(val, this.DATE_FORMATS).isValid()
                ? moment(val, this.DATE_FORMATS).hours(time.hours()).minutes(time.minutes())
                : moment().hours(time.hours()).minutes(time.minutes()),
            time
        }, () => {
            if (val && moment(val, this.DATE_FORMATS).isValid()) {
                this.refs.field.value = moment(val, this.DATE_FORMATS).format(this.props.format || this.DATE_FORMATS[0])
            }
            this.refs.y.value = moment(val && moment(val, this.DATE_FORMATS).isValid() ? val : this.state._value, this.DATE_FORMATS).year()
            if (moment(user_time, ['H:mm', 'hhmm']).isValid()) {
                this.refs.time.value = time.format("HH:mm")
            }
            if (callback) callback()
        })
    }

    render() {
        return (
            <div ref="wrapper" className="form-field-date-wrapper">
                <div style={{ display: 'flex' }}>
                    <input
                        type="text"
                        style={{ flex: 1 }}
                        required={this.props.required}
                        readOnly={this.props.readOnly}
                        title={moment(this.state.value, this.DATE_FORMATS).format('ll')}
                        className={`form-control form-field-date ${this.state.value && this.state.value.length && !moment(this.state.value, this.DATE_FORMATS).isValid() && 'invalid-date'}`}
                        placeholder={this.props.placeholder}
                        onKeyDown={ev => { if (ev.which === 13) { this.normalize() } if (ev.which === 27) { this.setState({ showCalendar: false }) } }}
                        onKeyUp={ev => { this.setState({ value: ev.target.value }, () => { this.refs.y.value = moment(this.state.value, this.DATE_FORMATS).isValid() ? moment(this.state.value, this.DATE_FORMATS).year() : moment().year(); this.forceUpdate() }) }}
                        onClick={ev => { if (this.props.readOnly) return; ev.stopPropagation(); this.setState({ showCalendar: true }, this.positionDropdown) }}
                        onFocus={el => { if (this.props.readOnly) return; el.target.select(); this.setState({ showCalendar: true }, this.positionDropdown) }}
                        onBlur={() => { this.normalize(() => { this.emit() }); }}
                        defaultValue={this.state.value}
                        ref="field"
                    />
                    <input
                        type="text"
                        className={`form-control form-field-hour`}
                        placeholder="  :  "
                        defaultValue={this.state.time}
                        onFocus={el => { if (this.props.readOnly) return; el.target.select(); }}
                        onBlur={() => { this.normalize(() => { this.emit() }); }}
                        ref="time"
                        style={{ display: this.props.time ? 'initial' : 'none', width: '12ch' }}
                    />
                </div>

                {
                    <div className={`form-field-date-popup ${this.props.open && 'forceOpen'}`} style={{ display: (this.state.showCalendar || this.props.open) ? 'block' : 'none' }} ref="dropdown">
                        <div style={{ display: 'flex', background: 'none' }}>
                            <button style={{ width: '3rem', textAlign: 'center', background: 'none', minWidth: 0 }} className="btn btn-link" type="button" onClick={() => { this.refs.field.value = moment(this.state._value).add(-1, 'year').format(this.props.format || this.DATE_FORMATS[0]); this.normalize() }}>&laquo;</button>
                            <input className="btn btn-link" ref="y" onKeyUp={ev => { this.refs.field.value = moment(this.state._value).year(parseInt(ev.target.value, 10)).format(this.props.format || this.DATE_FORMATS[0]); this.normalize() }} style={{ flex: 1, textAlign: 'center', minWidth: 0 }} type="number" defaultValue={String(moment(this.state.value, this.DATE_FORMATS).isValid() ? parseInt(moment(this.state._value, this.DATE_FORMATS).year(), 10) : moment().year())} />
                            <button style={{ width: '3rem', textAlign: 'center', background: 'none', minWidth: 0 }} className="btn btn-link" type="button" onClick={() => { this.refs.field.value = moment(this.state._value).add(1, 'year').format(this.props.format || this.DATE_FORMATS[0]); this.normalize() }}>&raquo;</button>
                        </div>
                        <div style={{ display: 'flex', flexWrap: 'wrap', borderBottom: '.5pt solid rgba(0,0,0,.4)', borderTop: '.5pt solid rgba(0,0,0,.4)' }}>
                            {
                                [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map(month => {
                                    return <button
                                        type="button"
                                        key={month}
                                        className={`form-field-date-month ${((this.refs.field && this.refs.field.value && moment(this.refs.field.value, this.DATE_FORMATS).month() + 1) || moment().month() + 1) === month + 1
                                            ? 'selected'
                                            : 'unselected'
                                            }`}
                                        onClick={
                                            () => {
                                                this.refs.field.value = moment(this.state._value).month(month).format(this.props.format || this.DATE_FORMATS[0])
                                                this.normalize(() => { this.emit() });
                                            }
                                        }
                                    >
                                        {moment().month(month).format('MMM')}
                                    </button>
                                })
                            }
                        </div>

                        <div>
                            <div style={{ display: 'flex', marginTop: '4px' }}>
                                {[1, 2, 3, 4, 5, 6, 7].map(col => {
                                    return <div className="form-field-date-day" key={col} style={{ flex: 1, fontSize: '1.2rem', textAlign: 'center', color: 'rgba(0,0,0,.75)' }}>
                                        {moment().isoWeekday(col).format('ddd')}
                                    </div>
                                })}
                            </div>
                            {
                                [0, 1, 2, 3, 4, 5, 6].map(row => {
                                    return <div key={row} style={{ display: 'flex' }}>
                                        {
                                            [1, 2, 3, 4, 5, 6, 7].map(col => {
                                                let firstDayOfMonthAsIndex = moment(this.state._value).startOf('month').day()
                                                let day = 7 * (row - 1) + col + 1 - firstDayOfMonthAsIndex

                                                return (
                                                    day <= (moment(this.state._value).daysInMonth()) ?
                                                        <div className="form-field-date-day-area" key={`${row}-${col}`}>
                                                            {day > 0 &&
                                                                <button type="button" onClick={() => {
                                                                    this.refs.field.value = moment(this.state._value).date(day).format(this.props.format || this.DATE_FORMATS[0])
                                                                    this.forceUpdate()
                                                                    this.normalize(() => { this.emit() });

                                                                    this.setState({
                                                                        showCalendar: false
                                                                    })
                                                                }} className={`form-field-date-day-button ${day === moment(this.state.value, this.DATE_FORMATS).date() ? 'selected' : ''}`}>{day}</button>
                                                            }
                                                        </div>
                                                        : ''
                                                )
                                            })
                                        }
                                    </div>
                                })
                            }

                        </div>

                    </div>
                }
            </div>
        )
    }
}

export default InputDate