import React from 'react'

import "./index.css"

class BoxConfigCube extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            data: props.data
        }
    }

    componentWillReceiveProps(newProps) {
        this.setState({
            data: newProps.data
        })
    }

    computeStatus(box) {
        if (box.desired_version === box.current_version) return 'SUCCESS'
        if (!box.connected) return 'DISCONNECTED'
        if (box.success === false) return 'ERROR'
        return 'UPDATING'
    }

    render() {
        return (
            <div className={`BoxConfigCube ${this.computeStatus(this.state.data)}`}
                onMouseOver={(e) => {
                    const container = document.body.querySelector('.BoxConfigCubesContainer')
                    const tooltipDiv = e.target.querySelector('.BoxConfigCubeTooltip')
                    if (!tooltipDiv || !container) return;
                    const cRect = container.getBoundingClientRect()
                    const tRect = e.target.getBoundingClientRect()
                    const tX = ((tRect.right + tRect.left) / 2 - cRect.left) * 100 / cRect.width
                    tooltipDiv.style.transform = `translateX(-${tX}%)`
                }}
            >
                {this.state.data.box_id}
                <div className="BoxConfigCubeTooltip">
                    <span>{this.state.data.name}</span>
                    <br /><span>Current version: {this.state.data.current_version}, desired: {this.state.data.desired_version}</span>
                    {this.state.data.error_message
                        && <span><br />Error: {this.state.data.error_message}</span>
                    }
                </div>
            </div>
        )
    }
}

export default BoxConfigCube
