import React from 'react'
import './BoxVNCVirtualKeyboard.css'

const XK_Shift_L = 65505    // https://docs.rs/x11-dl/1.0.1/x11_dl/keysym/constant.XK_Shift_L.html
const XK_Backspace = 65288  // https://docs.rs/x11-dl/1.0.1/x11_dl/keysym/constant.XK_BackSpace.html
const XK_Return = 65293     // https://docs.rs/x11-dl/1.0.1/x11_dl/keysym/constant.XK_Return.html
const XK_KP_Tab = 65417     // https://docs.rs/x11-dl/1.0.1/x11_dl/keysym/constant.XK_KP_Tab.html
const XK_Escape = 65307     // https://docs.rs/x11-dl/1.0.1/x11_dl/keysym/constant.XK_Escape.html
const XK_Meta_L = 65511     // https://docs.rs/x11-dl/1.0.1/x11_dl/keysym/constant.XK_Meta_L.html
const XK_Control_L = 65507  // https://docs.rs/x11-dl/1.0.1/x11_dl/keysym/constant.XK_Control_L.html
const XK_KP_Page_Up = 65434  // https://docs.rs/x11-dl/1.0.1/x11_dl/keysym/constant.XK_KP_Page_Up.html
const XK_KP_Page_Down = 65435  // https://docs.rs/x11-dl/1.0.1/x11_dl/keysym/constant.XK_KP_Page_Down.html
const XK_End = 65367  // https://docs.rs/x11-dl/1.0.1/x11_dl/keysym/constant.XK_End.html
const XK_Home = 65360  // https://docs.rs/x11-dl/1.0.1/x11_dl/keysym/constant.XK_Home.html
const XK_Delete = 65535  // https://docs.rs/x11-dl/1.0.1/x11_dl/keysym/constant.XK_Delete.html

class VirtualKeyboard extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            capsLockKey: false,
            shiftKey: false,
            ctrlKey: false,
            altKey: false,
        }
    }

    press = (event, key, key2, release) => {
        event.preventDefault()
        event.stopPropagation()

        let code = null

        const shiftKey = (this.state.shiftKey || this.state.capsLockKey)
        const ctrlKey = (this.state.ctrlKey)
        if (shiftKey) {
            key = key2 || key.toUpperCase()
        }

        switch (key.toUpperCase()) {
            case 'HOME':
                code = XK_Home
                break
            case 'END':
                code = XK_End
                break
            case 'PGUP':
                code = XK_KP_Page_Up
                break
            case 'PGDN':
                code = XK_KP_Page_Down
                break
            case 'DELETE':
                code = XK_Delete
                break
            case 'SHIFT':
                code = XK_Shift_L
                break
            case 'BACKSPACE':
                code = XK_Backspace
                break
            case 'ESCAPE':
                code = XK_Escape
                break
            case 'ENTER':
                code = XK_Return
                break
            case 'TAB':
                code = XK_KP_Tab
                break
            case 'META':
                code = XK_Meta_L
                break
            case 'CTRL':
                code = XK_Control_L
                break
            default:
                code = key.charCodeAt(0)
                break
        }


        const data = {
            key,
            code,
            shiftKey,
            ctrlKey
        }

        this.props.onPress(data)

        this.setState({ ctrlKey: false, altKey: false, shiftKey: false })
    }

    render = () =>
        <div id="KBD" className={`${(this.state.shiftKey || this.state.capsLockKey) ? 'shiftKey' : ''}`}>
            <div className="keysRow">
                <button className="key altKey" onMouseDown={(e) => { this.press(e, 'Escape') }} >Échap</button>

                <button className="key" onMouseDown={(e) => { this.press(e, '&', '1') }}>
                    <small>1</small>
                    &amp;
                </button>
                <button className="key" onMouseDown={(e) => { this.press(e, 'é', '2') }}>
                    <small>2</small>
                    é
                </button>
                <button className="key" onMouseDown={(e) => { this.press(e, '"', '3') }}>
                    <small>3</small>
                    "
                </button>
                <button className="key" onMouseDown={(e) => { this.press(e, "'", '4') }}>
                    <small>4</small>
                    '
                </button>
                <button className="key" onMouseDown={(e) => { this.press(e, '(', '5') }}>
                    <small>5</small>
                    (
                </button>
                <button className="key" onMouseDown={(e) => { this.press(e, '-', '6') }}>
                    <small>6</small>
                    -
                </button>
                <button className="key" onMouseDown={(e) => { this.press(e, 'è', '7') }}>
                    <small>7</small>
                    è
                </button>
                <button className="key" onMouseDown={(e) => { this.press(e, '_', '8') }}>
                    <small>8</small>
                    _
                </button>
                <button className="key" onMouseDown={(e) => { this.press(e, 'ç', '9') }}>
                    <small>9</small>
                    ç
                </button>
                <button className="key" onMouseDown={(e) => { this.press(e, 'à', '0') }}>
                    <small>0</small>
                    à
                </button>
                <button className="key" onMouseDown={(e) => { this.press(e, ')', '°') }}>
                    <small>°</small>
                    )
                </button>

                <button className="key" onMouseDown={(e) => { this.press(e, '=', '+') }}>
                    <small>+</small>
                    =
                </button>
                <button style={{ flex: 0, minWidth: '6rem' }} className="key altKey" onMouseDown={(e) => { this.press(e, 'Backspace') }} >
                    <img alt="" src="/img/ic_kbd_backspace.svg" style={{ height: '2rem', width: '2rem', opacity: .3 }} />
                </button>
            </div>
            <div className="keysRow" style={{ overflow: 'visible', maxHeight: '4.45rem' }}>
                <button className="key altKey" onMouseDown={(e) => { this.press(e, 'Tab') }} >
                    <img alt="" src="/img/ic_kbd_tab.svg" style={{ height: '2rem', width: '2rem', opacity: .3 }} />
                </button>
                {`azertyuiop`.split('').map((k, i) =>
                    <button key={i} className="key letterKey" onMouseDown={(e) => { this.press(e, k) }} >{k}</button>
                )}
                <button className="key letterKey" onMouseDown={(e) => { this.press(e, '{', '[') }}>
                    <small>[</small>
                    &#123;
                </button>
                <button className="key letterKey" onMouseDown={(e) => { this.press(e, '}', ']') }}>
                    <small>]</small>
                    &#125;
                </button>
                <button className="key letterKey" onMouseDown={(e) => { this.press(e, ';', '.') }}>
                    <small>€</small>
                    $
                </button>
                <button style={{ flex: 0, minWidth: '5rem', minHeight: 'calc(8.5rem)', zIndex: 4 }} className="key altKey" onMouseDown={(e) => { this.press(e, 'Enter') }} >
                    <img alt="" src="/img/ic_kbd_return.svg" style={{ height: '2rem', width: '2rem', opacity: .3 }} />
                </button>
            </div>
            <div className="keysRow">
                <button style={{ flex: 0, minWidth: '7rem' }} className={`key altKey ${this.state.capsLockKey ? 'active' : ''}`} onClick={() => { this.setState({ capsLockKey: !this.state.capsLockKey }) }} >
                    <img alt="" src="/img/ic_kbd_capslock.svg" style={{ height: '2rem', width: '2rem', opacity: .3 }} />
                </button>
                {`qsdfghjklm`.split('').map((k, i) =>
                    <button key={i} className="key letterKey" onMouseDown={(e) => { this.press(e, k) }} >{k}</button>
                )}
                <button className="key letterKey" onMouseDown={(e) => { this.press(e, '|', '@') }}>
                    <small>@</small>
                    |
                </button>
                <button className="key letterKey" onMouseDown={(e) => { this.press(e, ';', '.') }}>
                    <small>%</small>
                    *
                </button>
                <button style={{ flex: 0, minWidth: '5rem' }} className="key altKey" onMouseDown={(e) => { this.press(e, 'Enter') }} >Enter</button>
            </div>
            <div className="keysRow">
                <button style={{ flex: 1.5 }} className={`key altKey ${this.state.shiftKey ? 'active' : ''}`} onClick={() => { this.setState({ shiftKey: !this.state.shiftKey }) }} >
                    <img alt="" src="/img/ic_kbd_shift.svg" style={{ height: '2rem', width: '2rem', opacity: .3 }} />
                </button>
                <button className="key letterKey" onMouseDown={(e) => { this.press(e, '<', '>') }} >
                    <small>&gt;</small>
                    &lt;
                </button>
                {`wxcvbn`.split('').map((k, i) =>
                    <button key={i} className="key letterKey" onMouseDown={(e) => { this.press(e, k) }} >{k}</button>
                )}
                <button className="key letterKey" onMouseDown={(e) => { this.press(e, ',', '?') }}>
                    <small>?</small>
                    ,
                </button>
                <button className="key letterKey" onMouseDown={(e) => { this.press(e, ';', '.') }}>
                    <small>.</small>
                    ;
                </button>
                <button className="key letterKey" onMouseDown={(e) => { this.press(e, ':', '/') }}>
                    <small>/</small>
                    :
                </button>
                <button className="key letterKey" onMouseDown={(e) => { this.press(e, '!', '§') }}>
                    <small>§</small>
                    !
                </button>
                <button className="key letterKey" onMouseDown={(e) => { this.press(e, ';', '.') }}>
                    <small>\</small>
                    #
                </button>
                <button style={{ flex: 5 }} className={`key altKey ${this.state.shiftKey ? 'active' : ''}`} onClick={() => { this.setState({ shiftKey: !this.state.shiftKey }) }} >
                    <img alt="" src="/img/ic_kbd_shift.svg" style={{ height: '2rem', width: '2rem', opacity: .3 }} />
                </button>
            </div>
            <div className="keysRow">
                <button style={{ flex: 0 }} onClick={() => { this.setState({ ctrlKey: !this.state.ctrlKey }) }} className={`key altKey ${this.state.ctrlKey ? 'active' : ''}`}>Ctrl</button>
                <button style={{ flex: 0 }} className="key altKey" onMouseDown={(e) => { this.press(e, 'Meta') }} >
                    <img alt="" src="/img/ic_kbd_meta.svg" style={{ height: '2rem', width: '2rem', opacity: .3 }} />
                </button>
                <button style={{ flex: 0 }} className="key altKey" onMouseDown={(e) => { this.press(e, 'Alt') }} >Alt</button>
                <button style={{ flex: 1 }} className="key altKey" onMouseDown={(e) => { this.press(e, ' ') }} ></button>


                <button style={{ flex: 0 }} className="key altKey" onMouseDown={(e) => { this.press(e, 'HOME', 'PGUP') }}>
                    <small>
                        <img alt="" src="/img/ic_kbd_pgup.svg" style={{ height: '2rem', width: '2rem', opacity: .3, margin: '-.5rem -.5rem' }} />
                    </small>
                    Début
                </button>
                <button style={{ flex: 0 }} className="key altKey" onMouseDown={(e) => { this.press(e, 'END', 'PGDN') }}>
                    <small>
                        <img alt="" src="/img/ic_kbd_pgdn.svg" style={{ height: '2rem', width: '2rem', opacity: .3, margin: '-.5rem -.5rem' }} />
                    </small>
                    Fin
                </button>

                <button style={{ flex: 0 }} className="key altKey" onMouseDown={(e) => { this.press(e, 'Alt') }} >Suppr</button>
                <button style={{ flex: 0 }} onClick={() => { this.setState({ ctrlKey: !this.state.ctrlKey }) }} className={`key altKey ${this.state.ctrlKey ? 'active' : ''}`}>Ctrl</button>
            </div>
        </div>
}

export default VirtualKeyboard
