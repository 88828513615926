import React, { Component } from 'react'
import { Button, Tabs, Tab } from '../UI/Toolkit'
import 'react-table/react-table.css'
import Helpers from '../../core/Helpers'
import Modale from '../UI/ModaleComponent/'
import './index.css'
import Table from '../Table'
import routes from '../../routes'
import UsersList from './UsersList'
import ApiService from '../../core/ApiService'
import moment from 'moment-timezone'
import logo_yubikey from '../../img/logo_yubikey.svg'
import ic_yubikey from '../../img/ic_yubikey.svg'
import logo_yubikey_success from '../../img/logo_yubikey_success.svg'
import ForbiddenComponent from '../ForbiddenComponent'
import Alfred from '../../core/Alfred';
import { startRegistration } from '@simplewebauthn/browser'

class UsersComponent extends Component {

    constructor(props) {
        super(props)
        this.state = {
            currentUser: {},
            showModal: false,
            showModalRevokeU2F: false,
            userLogged: Helpers.getCurrentUser(),
            users: [],
            timestamp: 0,
            security: null
        }

        this.forceRefresh = false

        this.onSubmit = this.onSubmit.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.chooseUser = this.chooseUser.bind(this)
        this.registerYubikey = this.registerYubikey.bind(this)
        this.revokeYubikey = this.revokeYubikey.bind(this)
        this.updateYubikey = this.updateYubikey.bind(this)
        this.onRemoveUser = this.onRemoveUser.bind(this)
    }

    handleChange(event) {
        const target = event.target
        const value = target.type === 'checkbox' ? target.checked : target.value
        const name = target.name

        var currentUser = this.state.currentUser

        currentUser[name] = value

        this.setState({ currentUser })
    }

    onRemoveUser(user) {
        if (window.confirm("Please confirm you want to definitely remove this user account. This action will be logged.")) {
            ApiService.removeUser(user).then((response) => {
                this.setState({
                    showModal: false,
                    timestamp: Date.now()
                })
            })
        }
    }

    onSubmit() {
        let user = JSON.parse(JSON.stringify(this.state.currentUser))
        delete user.u2f
        delete user.password
        ApiService.upsertUser(user, this.state.currentUser.uid).then((response) => {
            this.forceRefresh = true
            this.setState({
                uploading: false,
                showModal: false
            }, () => {
                this.forceRefresh = false
                if (response.success === false) {
                    alert(response.message)
                }
            })
            this.setState({
                timestamp: Date.now()
            })
        }).catch(ex => {
            alert(ex)
        })
    }

    componentDidMount() {
        if (Helpers.getCurrentUser().admin >= 1 && Alfred.askPermission('USERS', Alfred.R)) {

            ApiService.getPermissions().then(permissions => {
                this.setState({ permissions })
            })

            ApiService.getConfig('security').then((security) => {
                this.setState({
                    security
                })
            })

            this.generateConfiguration()

            this.props.updateBreadcrumb('Users management',
                <div style={{ display: 'flex' }}>
                    <div style={{ flex: 1 }}>
                        Manage platform users
                    </div>
                    {Alfred.askPermission('USERS', Alfred.W) &&
                        <Button bsStyle="primary" bsSize="small" onClick={() => {
                            this.setState({ currentUser: {}, showModal: true })
                        }}>New user</Button>
                    }
                </div>
            )
        }
    }

    generateConfiguration() {
        this.setState({
            componentReady: false
        })
        let endPoint = routes.getUsersList
        const defaultConfiguration = {
            sort: {
                id: 'users.update_timestamp',
                desc: true
            },
            rowsPerPage: 10,
            columns: [
                {
                    id: 'users.uid',
                    title: 'UID',
                    type: Table.types.STRING,
                    accessor: (row) => {
                        return row.uid
                    },
                    show: true,
                    width: 150
                },
                {
                    id: 'users.login',
                    title: 'Login',
                    type: Table.types.STRING,
                    accessor: (row) => {
                        return row.login
                    },
                    show: true
                },
                {
                    id: 'users.displayname',
                    title: 'Display Name',
                    type: Table.types.STRING,
                    accessor: (row) => {
                        return row.displayname
                    },
                    show: true
                },
                {
                    id: 'users.email',
                    title: 'E-mail address',
                    type: Table.types.STRING,
                    accessor: (row) => {
                        return row.email
                    },
                    show: true
                },
                {
                    id: 'users.phone',
                    title: 'Phone number',
                    type: Table.types.STRING,
                    accessor: (row) => {
                        return row.phone
                    },
                    show: true
                },
                {
                    id: 'users.last_login',
                    title: 'Last login',
                    accessor: (row) => {
                        return row.last_login
                    },
                    type: Table.types.DATETIME,
                    show: true,
                    width: 125
                },
                {
                    id: 'users.creation_timestamp',
                    title: 'Creation date',
                    accessor: (row) => {
                        return row.creation_timestamp
                    },
                    type: Table.types.DATETIME,
                    show: true,
                    width: 125
                },
                {
                    id: 'users.update_timestamp',
                    title: 'Last update',
                    accessor: (row) => {
                        return row.update_timestamp
                    },
                    type: Table.types.DATETIME,
                    show: true,
                    width: 125
                }
            ]
        }

        this.setState({
            endPoint: endPoint,
            configuration: defaultConfiguration,
            componentReady: true
        })
    }

    async chooseUser(user) {
        await ApiService.getUserPermissions(user.uid)
            .then(permissions => {
                user.permissions = permissions
            })

        ApiService.getU2FKeys(user.uid)
            .then(response => {
                user.u2f = response
            })
            .finally(() => {
                this.setState({
                    currentUser: user,
                    showModal: true
                })
            })
    }

    revokeYubikey(user, key) {
        ApiService.revokeYubikey(user.uid, key.id).then(() => {
            this.chooseUser(user)
            this.setState({ showModalRevokeU2F: false, showModaleU2fKeyDetails: false })
        })
    }

    updateYubikey(user, key) {
        ApiService.updateYubikey(user.uid, key.id, key).then(() => {
            this.chooseUser(user)
            this.setState({ showModaleU2fKeyDetails: false })
        })
    }

    registerYubikey(user) {
        this.setState({ showModalU2F: true })
        ApiService.u2fRegistrationChallenge(user.uid).then(options => {
            startRegistration(options)
                .then((res) => {
                    this.setState({ showModalU2F: false })
                    ApiService.u2fRegistration(user.uid, options.user.id, res)
                        .then(response => {
                            this.setState({
                                showModaleU2fKeyDetails: (response.data || {})
                            })
                        })
                        .catch(ex => {
                            console.error('ex', ex)
                        })
                        .finally(() => {
                            this.chooseUser(user)
                        })
                })
        })
    }

    renderPermissions(user) {
        const permissions = user.permissions
        if (user.permissions._ADMIN === Alfred.X) {
            return <table className="tableau">
                <tbody>
                    <tr>
                        <td width="100%">Full Administrator</td>
                        <td align="right">
                            <b>Admin</b>
                        </td>
                    </tr>
                </tbody>
            </table>
        } else {
            return <table className="tableau">
                <tbody>
                    {Object.keys(this.state.permissions).map((p, index) => {
                        const key = this.state.permissions[p].key
                        const perm = Object.assign(this.state.permissions[p], { rwx: permissions[key] })
                        return (
                            <tr key={index}>
                                <td width="100%">{perm.description.split('|').pop()}</td>
                                <td align="right">
                                    <div>
                                        {!perm.rwx && user.admin < 2 && <span style={{ opacity: .5, fontStyle: 'italic' }}>Forbidden</span>}
                                        {perm.rwx === Alfred.R && user.admin < 2 && <span>{perm.R}</span>}
                                        {perm.rwx === Alfred.W && user.admin < 2 && <span>{perm.W}</span>}
                                        {(perm.rwx === Alfred.X || user.admin === 2) && <b>{perm.X}</b>}
                                    </div>
                                </td>
                            </tr>
                        )
                    }
                    )}
                </tbody>
            </table>
        }
    }

    render() {
        if (!this.state.security) {
            return 'Loading...'
        }
        if (Helpers.getCurrentUser().admin < 1 || !Alfred.askPermission('USERS', Alfred.R)) { return <ForbiddenComponent /> }
        if (!this.state.componentReady) return null
        return (
            <div style={{ display: 'flex', flex: 1, padding: '.5rem' }}>
                <Modale show={this.state.showModal}>
                    <Modale.Header>
                        User detail {this.state.currentUser.uid ? `(${this.state.currentUser.uid})` : ''}
                    </Modale.Header>
                    <Modale.Body style={{ width: '680px', height: '400px', display: 'flex', flexDirection: 'column' }}>
                        <Tabs id="user-tabs" style={{ flex: 1, height: '400px' }}>
                            <Tab label="User" style={{ padding: '.5rem' }}>
                                <div>
                                    <label>
                                        Login *
                                        <input autoComplete="username" onChange={this.handleChange} type="text" name="login"
                                            defaultValue={this.state.currentUser.login} className="form-control"
                                            placeholder="Login" readOnly={this.state.currentUser.uid} />
                                    </label>
                                    <label>
                                        Display Name
                                        <input autoComplete="displayname" onChange={this.handleChange} type="text"
                                            name="displayname" defaultValue={this.state.currentUser.displayname}
                                            className="form-control" placeholder="User Display Name" />
                                    </label>
                                    <label>
                                        E-mail address
                                        <input autoComplete="email" onChange={this.handleChange} type="text"
                                            name="email" defaultValue={this.state.currentUser.email}
                                            className="form-control" placeholder="E-mail address" />
                                    </label>
                                    <label>
                                        Phone number
                                        <input autoComplete="phone" onChange={this.handleChange} type="text"
                                            name="phone" defaultValue={this.state.currentUser.phone}
                                            className="form-control" placeholder="Mobile phone number" />
                                    </label>
                                    <label>
                                        Level *
                                        <select onChange={this.handleChange} defaultValue={this.state.currentUser.admin}
                                            className="form-control" name="admin">
                                            <option value={0}>User (unprivileged)</option>
                                            {this.state.userLogged.admin > 0 &&
                                                <option value={1}>Power user</option>
                                            }
                                            {this.state.userLogged.admin > 1 &&
                                                <option style={{ color: 'red' }} value={2}>E-Media Internal (full-power)</option>
                                            }
                                        </select>
                                    </label>
                                    {!this.state.currentUser.uid &&
                                        <label>
                                            New Password
                                            <input autoComplete="new-password" onChange={this.handleChange} type="password"
                                                defaultValue={this.state.currentUser.newpassword} name="newpassword"
                                                className="form-control" placeholder="Leave blank to keep unchanged" />
                                        </label>
                                    }
                                </div>
                            </Tab>
                            {this.state.currentUser.uid &&
                                <Tab label="Security" style={{ padding: '.5rem' }}>
                                    <label style={{ marginBottom: '2rem' }}>
                                        Change Password
                                        <input autoComplete="new-password" onChange={this.handleChange} type="password"
                                            defaultValue={this.state.currentUser.newpassword} name="newpassword"
                                            style={{ fontWeight: 'normal' }}
                                            className="form-control" placeholder="Leave blank to keep unchanged" />
                                    </label>
                                    {this.state.security.u2f &&
                                        <div>
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                <label style={{ flex: 1 }}>2-Factor authentication</label>
                                                {Alfred.askPermission('USERS', Alfred.W) &&
                                                    <Button bsSize="small" bsStyle="primary" onClick={() => { this.registerYubikey(this.state.currentUser) }}>Add key</Button>
                                                }
                                            </div>
                                            {this.state.currentUser.u2f && this.state.currentUser.u2f.length ?
                                                <div style={{ opacity: .6, padding: '.5rem', fontSize: '1.25rem' }}>Registered keys :</div>
                                                : null
                                            }
                                            {this.state.currentUser.u2f.map((key, index) => {
                                                return <div style={{ borderTop: index ? '1pt solid rgba(0,0,0,.07)' : '', paddingTop: '.75rem', display: 'flex', alignItems: 'center', marginBottom: '.75rem' }} key={index}>
                                                    <img src={ic_yubikey} alt="Yubikey" style={{ width: '2rem', margin: 'auto', display: 'block' }} />
                                                    <div style={{ flex: 1, margin: 'auto' }}>
                                                        <div style={{ lineHeight: '100%' }}>{key.name || <i>Unnamed key</i>}</div>
                                                        <div style={{ lineHeight: '100%', opacity: .6 }}><small>Added on {moment(key.creation_timestamp).format('YYYY-MM-DD HH:mm:ss')}</small></div>
                                                    </div>
                                                    <Button bsSize="small" onClick={() => { this.setState({ showModaleU2fKeyDetails: key }) }}>Manage</Button>
                                                </div>
                                            })
                                            }
                                        </div>
                                    }
                                </Tab>
                            }
                            {this.state.currentUser.uid && this.state.currentUser.admin && this.state.currentUser.admin < 2 &&
                                <Tab label="Permissions" style={{ padding: '.5rem' }}>
                                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                                        <div style={{ flex: 1 }}>
                                            <p style={{ padding: '.5rem', opacity: .6, fontStyle: 'italic' }}>
                                                Permissions are inherited from teams this user is member of. If several teams have different level for the same permission, only the most permissive matters.
                                            </p>
                                            {this.renderPermissions(this.state.currentUser)}
                                        </div>
                                    </div>
                                </Tab>
                            }
                            {Helpers.getSession().admin > 1 && this.state.currentUser.uid &&
                                <Tab label="Removal" style={{ padding: '.5rem' }}>
                                    <Button bsStyle="danger" onClick={() => { this.onRemoveUser(this.state.currentUser) }}>Remove user</Button>
                                </Tab>
                            }

                        </Tabs>
                    </Modale.Body>
                    <Modale.Footer>
                        <Button style={{ float: 'left' }} onClick={() => {
                            this.setState({ showModal: false })
                        }}>Close</Button>
                        <div style={{ flex: 1 }}></div>
                        {Alfred.askPermission('USERS', Alfred.W) &&
                            <Button disabled={
                                !this.state.currentUser.login
                            } bsStyle="primary" onClick={this.onSubmit}>Save</Button>
                        }
                    </Modale.Footer>
                </Modale>

                <Modale show={this.state.showModalU2F}>
                    <Modale.Header>
                        Performing U2F Action
                    </Modale.Header>
                    <Modale.Body style={{ width: '480px' }}>
                        <img src={logo_yubikey} alt="Yubikey" style={{ display: 'block', margin: '2rem auto', height: '170px' }} />
                        <p style={{ textAlign: 'center' }}><b>Please touch the flashing U2F device now.</b></p>
                        <p style={{ textAlign: 'center' }}>You may be prompted to allow the site permission to access your security keys. After granting permission, the device will start to blink.</p>
                    </Modale.Body>
                    <Modale.Footer>
                        <div style={{ flex: 1 }}></div>
                        <Button onClick={() => { this.setState({ showModalU2F: false }) }}>Abort</Button>
                    </Modale.Footer>
                </Modale>

                <Modale show={!!this.state.showModaleU2fKeyDetails}>
                    {(this.state.showModaleU2fKeyDetails || {}).creation_timestamp &&
                        <Modale.Body style={{ width: '480px', textAlign: 'center' }}>
                            <img alt="" src={logo_yubikey_success} style={{
                                height: '170px',
                                marginBottom: '3rem'
                            }} />

                            <div>
                                <small>This key has been registered on {moment(this.state.showModaleU2fKeyDetails.creation_timestamp).format('LLL')}.</small>
                            </div>

                            <input ref={input => { this.keyName = input }}
                                defaultValue={this.state.showModaleU2fKeyDetails.name}
                                style={{ width: '250px', margin: '3rem auto' }}
                                className="form-control" placeholder="Choose a name for your key" />

                            <Button bsSize="small" bsStyle="link" style={{ color: 'red' }} onClick={() => { this.setState({ showModalRevokeU2F: this.state.showModaleU2fKeyDetails }) }}>Revoke this key</Button>
                        </Modale.Body>
                    }
                    <Modale.Footer>
                        <Button onClick={() => { this.setState({ showModaleU2fKeyDetails: false }) }}>Close</Button>
                        <div style={{ flex: 1 }}></div>
                        <Button bsStyle="primary" onClick={() => { this.updateYubikey(this.state.currentUser, { ...this.state.showModaleU2fKeyDetails, name: this.keyName.value }) }}>save</Button>
                    </Modale.Footer>
                </Modale>

                <Modale show={!!this.state.showModalRevokeU2F}>
                    <Modale.Header>
                        Revoking Yubikey
                    </Modale.Header>
                    <Modale.Body style={{ width: '480px' }}>
                        <p>Revoking your key will make future login impossible unless you register a key again.</p>
                        <p>Do you really want to revoke this key ?</p>
                    </Modale.Body>
                    <Modale.Footer>
                        <Button onClick={() => { this.setState({ showModalRevokeU2F: false }) }}>Cancel</Button>
                        <div style={{ flex: 1 }}></div>
                        <Button bsStyle="danger" onClick={() => { this.revokeYubikey(this.state.currentUser, this.state.showModalRevokeU2F) }}>Revoke</Button>
                    </Modale.Footer>
                </Modale>

                <UsersList
                    key={this.state.timestamp}
                    configuration={this.state.configuration}
                    endPoint={this.state.endPoint}
                    chooseUser={this.chooseUser}
                    forceRefresh={this.forceRefresh}
                />
            </div>
        )
    }
}

export default UsersComponent
