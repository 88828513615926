import React from 'react';

import './index.css';

import ic_check from '../../../../img/ic_check_dark.svg';
import ic_empty from '../../../../img/ic_empty.svg';


class MultiSelect extends React.PureComponent {
    constructor(props) {
        super(props);

        let value = [];
        if (Array.isArray(this.props.value)) {
            value = this.props.value;
        } else if (typeof this.props.value === 'string') {
            value = [this.props.value];
        }

        this.state = {
            value,
        };
    }

    componentWillReceiveProps(newProps) {
        if (newProps.value) {
            this.setState({ value: newProps.value });
        }
    }

    getMap = () => {
        const map = new Map();
        this.props.children.forEach((child) => {
            map.set(child.props.value, child.props.children);
        });
        return map;
    }

    render() {
        return (
            <div className='multiSelectWrapper'>
                <button className='form-control text-field multiSelect'>
                    {this.state.value.length
                        ? <span className='multiSelectLabel'>{this.renderSelectionLabel()}</span>
                        : <span className='multiSelectPlaceholder'>{this.props.placeholder}</span>
                    }
                </button>
                <div className='multiSelectDropdown'>
                    {React.Children.map(this.props.children, (child) => {
                        if (child && (child).type === 'option') {
                            return (
                                <button type='button' className='btn btn-default'
                                    title={(child).props.title}
                                    onClick={() => {
                                        const existingIndex = this.state.value.findIndex((item) => item === (child).props.value);
                                        const value = JSON.parse(JSON.stringify(this.state.value));
                                        if (existingIndex > -1) {
                                            value.splice(existingIndex, 1);
                                        } else {
                                            value.push((child).props.value);
                                        }
                                        this.setState({ value }, () => { this.props.onChange(this.state.value); });
                                    }}>
                                    <div className='multiSelectButtonLabel'>
                                        {(child).props.children}
                                    </div>
                                    {this.state.value.indexOf((child).props.value) > -1
                                        ? <img src={ic_check} alt='' />
                                        : <img src={ic_empty} alt='' />
                                    }
                                </button>
                            );
                        } else if (child && (child).type === 'button') {
                            return React.cloneElement(child, {});
                        } else {
                            return null;
                        }
                    })}
                </div>
            </div>
        );
    }

    renderSelectionLabel = () => {
        let str = <span></span>;
        const firstValue = this.state.value[0];
        const map = this.getMap();
        let label = firstValue;
        if (map.has(firstValue)) {
            label = map.get(firstValue);
        }
        if (this.state.value.length === 1) {
            str = <span>{label}</span>;
        } else if (this.state.value.length > 1) {
            str = <span>{label} <small>(+{this.state.value.length - 1})</small></span>;
        }
        return str;
    }
}

export default MultiSelect;
