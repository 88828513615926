import React from 'react'

import './index.css'

import ic_os_linux from '../../../../img/ic_os_linux.svg'
import ic_os_undefined from '../../../../img/ic_os_undefined.svg'
import ic_os_windows from '../../../../img/ic_os_windows.svg'

class BoxPickerElement extends React.Component {
    constructor(props) {
        super(props)

        this.state = {}
    }

    render() {
        let os_icon = ic_os_undefined
        switch (this.props.system) {
            case 'linux':
                os_icon = ic_os_linux
                break
            case 'windows':
                os_icon = ic_os_windows
                break
            default:
                os_icon = ic_os_undefined
                break
        }
        return <div className='boxPickerElementComponent'>
            <img src={os_icon} alt='' className='os_icon' />
            <div>
                <div className="boxName">
                    {this.props.name}
                </div>
                <div className="boxDetails">
                    {[
                        this.props.uid ? `#${this.props.uid}` : null,
                        this.props.version ? `version ${this.props.version}` : null
                    ].filter((x) => !!x).join(', ')}
                </div>
            </div>
        </div>
    }
}

export default BoxPickerElement