import React from 'react'
import Table from '../Table'

class UsersList extends React.Component {
    constructor(props) {
        super(props)

        this.state = {}
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.forceRefresh) this.table.forceRefresh()
    }

    render() {
        return (
            <div style={{ flex: 1, flexDirection: 'column', overflow: 'auto' }}>
                <Table
                    filter={this.props.filter || []}
                    ref={table => this.table = table}
                    uid={'usersList'}
                    defaultConfig={this.props.configuration}
                    endPoint={this.props.endPoint}
                    csvFilename="users"
                    getTdProps={(state, row, column, instance) => {
                        return {
                            onClick: () => {
                                if (row && row.original)
                                    this.props.chooseUser(row.original)
                            }
                        }
                    }}
                    getTrProps={(state, rowInfo, column) => {
                        const style = { className: ['clickable_row'] }
                        if (rowInfo && !rowInfo.original.is_read) {
                            style.className.push('not-read')
                        }
                        return style
                    }}
                    onRows={this.onRows}
                />
            </div>
        )
    }
}

export default UsersList
