import React, { Component } from 'react'
import moment from 'moment-timezone'
import ApiService from '../../../../core/ApiService'
import BoxMonitorItem from './BoxMonitorItem'
import WebSocketClient from '../../../../core/WebSocketClient'
import Tag from '../../../UI/Tag'
import { Button } from '../../../UI/Toolkit'

import ic_eye from '../../../../img/ic_eye.svg'
import ic_trash from '../../../../img/ic_close.svg'

import './index.css'

class DashboardByCommunity extends Component {

    constructor(props) {
        super(props)
        this.state = {
            box: [],
            communities: [],
            shown_communities: [],
            filterIsOpen: localStorage.getItem('pf_dashboardFilterIsOpen') === null ? true : localStorage.getItem('pf_dashboardFilterIsOpen') === 'true'
        }
    }

    toggleFilterPane = () => {
        this.setState({
            filterIsOpen: !this.state.filterIsOpen
        }, () => {
            localStorage.setItem('pf_dashboardFilterIsOpen', !!this.state.filterIsOpen)
        })
    }

    componentWillReceiveProps(nextProps) {
        const message = nextProps.message
        const state = this.state
        if (message) {
            let box = state.box.find((box) => box.id === message.id)
            if (box) {
                box = Object.assign(box, message)
                this.setState(state)
            } else {
                state.box.push(message)
                this.setState(state)
            }
        }
    }

    componentDidMount() {
        Promise.all([
            ApiService.getBoxMonitoring(),
            ApiService.getCommunities()
        ])
            .then(([box, communities]) => {
                this.setState({
                    box,
                    communities
                }, () => {
                    if (this.props.data) {
                        this.setState({
                            shown_communities: this.state.communities.filter(c => this.props.data.data.shown_communities.indexOf(c.uid) > -1)
                        })
                    }
                    setTimeout(() => {
                        this.setState({ fullyLoaded: true })
                    }, 500)
                })

                if (!this.props.data && this.state.shown_communities.length === 0) {
                    this.setState({ shown_communities: communities })
                }
                this.props.updateBreadcrumb(
                    <div style={{ clear: 'both', display: 'flex' }}>
                        <h1>Monitoring (by community)</h1>
                    </div>,
                    <div style={{ clear: 'both', display: 'flex' }}>
                        <div style={{ flex: 1 }}>
                            Overview of the MediaBox network
                            <div style={{ fontSize: '.95rem', opaciy: .5 }}>{moment().format('YYYY-MM-DD HH:mm:ss')}</div>
                        </div>
                    </div>
                )
            })
    }

    onSavePreset = () => {
        const name = prompt('Name your preset :')
        if (!name) {
            return
        }
        this.props.createCustomView({
            name,
            parent: 'by_community',
            data: {
                shown_communities: (this.state.shown_communities || []).map(c => c.uid)
            }
        })
    }

    onRemovePreset = () => {
        if (window.confirm('Remove this preset ?')) {
            this.props.removeCustomView(this.props.data)
        }
    }

    render() {
        return (
            <div id="DashboardCommunityViewComponent" className={this.state.fullyLoaded ? `animated` : ``}>
                <div style={{ display: 'flex', flexDirection: 'row', flex: 1, overflow: 'hidden' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', flex: 1, overflow: 'auto' }}>

                        {this.state.shown_communities.map((sc, scIdx) => {
                            return <div className="communityContainer" key={scIdx}>
                                <div className="communityTitleBar">
                                    <div className="communityName">
                                        <div>
                                            <b>{sc.name}</b>
                                            <span className="boxCounter">{this.state.box.filter(bx => bx.communities.map(bxc => bxc.uid).indexOf(sc.uid) > -1).length}</span>
                                        </div>
                                        <small>{sc.uid}</small>
                                    </div>
                                    <div style={{ flex: 1 }}></div>
                                    {sc.tags.map((sct, sctIdx) => {
                                        return <Tag {...sct} key={sctIdx} />
                                    })}
                                </div>
                                {
                                    this.state.box.filter(bx => bx.communities.map(bxc => bxc.uid).indexOf(sc.uid) > -1).map((bx, bxIdx) => {
                                        return <BoxMonitorItem data={bx} key={bx.uid || bx.id} index={bxIdx} />
                                    })
                                }
                            </div>
                        })}

                    </div>
                    <div id="dashboardCommunityFilterBar" className={this.state.filterIsOpen ? 'open' : 'collapsed'}>
                        <Button title={'Open / close filters'} className="filterPaneToggler" onClick={this.toggleFilterPane}>
                            {
                                this.state.filterIsOpen
                                    ? <div>&raquo;</div>
                                    : <div>&laquo;</div>
                            }
                        </Button>
                        <div style={{ flex: 1, overflow: 'auto' }}>
                            <label style={{ display: 'flex', alignItems: 'center' }}>
                                <div>Shown communities</div>
                                <div style={{ flex: 1 }}></div>
                                <Button title="Clear all" onClick={() => { this.setState({ shown_communities: [] }) }}>
                                    <img alt="" src={ic_trash} style={{ filter: 'invert(100%)', margin: 0 }} />
                                </Button>
                            </label>

                            {this.state.shown_communities.map((c, idx) => {
                                return <Button title="Hide this community" key={idx} onClick={() => {
                                    this.setState({
                                        shown_communities: this.state.shown_communities.filter(_c => _c.uid !== c.uid)
                                    })
                                }}>
                                    <img alt="" src={ic_trash} style={{ filter: 'invert(100%)' }} /> {c.name}
                                </Button>
                            })}

                            <label style={{ display: 'flex', alignItems: 'center' }}>
                                <div>Hidden communities</div>
                                <div style={{ flex: 1 }}></div>
                                <Button title="Show all" onClick={() => { this.setState({ shown_communities: this.state.communities }) }}>
                                    <img alt="" src={ic_eye} style={{ filter: 'invert(100%)', margin: 0 }} />
                                </Button>
                            </label>

                            {this.state.communities
                                .filter((c) => !this.state.shown_communities.find(sc => sc.uid === c.uid))
                                .map((c, idx) => {
                                    return <Button title="Show this community" key={idx} onClick={() => {
                                        this.setState({
                                            shown_communities: [...this.state.shown_communities, c]
                                        })
                                    }}>
                                        <img alt="" src={ic_eye} style={{ filter: 'invert(100%)' }} /> {c.name}
                                    </Button>
                                })}
                        </div>
                        {this.props.data
                            ? <label>
                                <Button onClick={this.onRemovePreset} style={{ margin: 'auto' }} bsStyle="danger">Delete custom filter</Button>
                            </label>
                            : <label>
                                <Button onClick={this.onSavePreset} style={{ margin: 'auto' }} bsStyle="primary">Save as custom filter</Button>
                            </label>
                        }
                    </div>
                </div>
            </div>
        )
    }
}

export default WebSocketClient.withWebSocket(DashboardByCommunity, () => [WebSocketClient.messageTypes.box_status])
