import React, { Component } from 'react';
import { Button } from '../../UI/Toolkit';
import Modal from '../../UI/ModaleComponent';

import ic_add from '../../../img/ic_add.svg';
import ic_delete from '../../../img/ic_delete.svg';
import ph_empty from '../../../img/placeholder_no_pickable.svg';

import './index.css';

const DEFAULT_SELECTED = '_selected';
const DEFAULT_FILTERKEY = 'name';
const DEFAULT_SORTKEY = 'name';

class PickerComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            filter: '',
            items: JSON.parse(JSON.stringify(props.items || '[]')),
            onChange: () => {
                alert('ERROR : onChange function is not defined !');
            },
            showModal: false,
        };

        this.onSelectionChange = this.onSelectionChange.bind(this);
        this.onPublishChanges = this.onPublishChanges.bind(this);
    }

    componentDidMount() {
        this.onPublishChanges();
    }

    componentWillReceiveProps(newProps) {
        this.setState({
            filterkey: newProps.filterkey || DEFAULT_FILTERKEY,
            items: newProps.items || [],
            sortBy: newProps.sortBy || DEFAULT_SORTKEY,
        });
    }
    displayItem(item) {
        if (this.props.renderItem) {
            return <div className='item'>
                {this.props.renderItem(item)}
            </div>
        }
        return (
            <div className='item'>
                <div>{item[this.props.filterkey[0]]}</div>
                <small>
                    {
                        this.props.filterkey.slice(1).map((fk, fkindex) => {
                            return item[fk];
                        }).filter(x => x).join(', ')
                    }
                </small>
            </div>
        );
    }

    onCancel = () => {
        const items = (this.state.items || []).map((item) => {
            delete item.__tmp_selection;
            return item;
        });
        this.setState({ items, showModal: false }, this.forceUpdate);
    }

    onDeleteItem = async (item) => {
        const data = item[this.props.ukey];
        if (!this.props.onBeforeDelete || await this.props.onBeforeDelete(item, this.state.items.filter((i) => i[this.props.selectedIndicator || DEFAULT_SELECTED] === true), this.state.items) === true) {
            const items = this.state.items || [];
            const index = items.findIndex((el) => String(el[this.props.ukey]) === String(data));
            items[index][this.props.selectedIndicator || DEFAULT_SELECTED] = false;
            this.onPublishChanges();
            this.forceUpdate();
        }
    }

    onFilterInput = (data) => {
        this.setState({ filter: String(data.target.value) }, this.forceUpdate);
    }

    onPublishChanges = () => {
        const items = this.state.items.map((item) => {
            if (item.__tmp_selection) {
                item[this.props.selectedIndicator || DEFAULT_SELECTED] = true;
            }
            delete item.__tmp_selection;
            return item;
        });
        this.setState({ showModal: false, filter: '', items }, () => { this.props.onChange(items); });
    }

    onSelectionChange(data, cb) {
        const items = this.state.items || [];
        const index = items.findIndex((el) => String(el[this.props.ukey]) === String(data));
        items[index].__tmp_selection = !!!items[index].__tmp_selection;
        this.setState({ items }, this.forceUpdate);
        if (cb) { cb(); }
    }

    render() {
        let items = this.props.items || [];
        if (typeof this.props.transformItem === 'function') {
            items = items.map((item) => this.props.transformItem(item)).filter((i) => !!i);
        }
        return (
            <div id='PickerComponent'>
                <div className='form-wrapper'>
                    <div className='title-area'>
                        <label>{this.props.title} <small>({(items || []).filter((item) => !!item[this.props.selectedIndicator || DEFAULT_SELECTED]).length})</small></label>
                        <Button
                            bsSize='small'
                            bsStyle='link'
                            title='Add...'
                            onClick={() => {
                                this.setState({ showModal: true });
                            }}>
                            <img src={ic_add} alt='' />
                        </Button>
                    </div>
                    <div className='form-control'>
                        {(items || [])
                            .filter((item) => !!item[this.props.selectedIndicator || DEFAULT_SELECTED])
                            .sort((a, b) => a[(this.props.sortBy || DEFAULT_SORTKEY)] > b[(this.props.sortBy || DEFAULT_SORTKEY)] ? 1 : -1)
                            .map((item, index) => {
                                return <div key={index} className='PickerListItem'>
                                    <div>
                                        {this.displayItem(item)}
                                    </div>
                                    <Button
                                        bsSize='xs'
                                        title='Delete'
                                        bsStyle='link'
                                        type='button'
                                        onClick={() => { this.onDeleteItem(item); }}>
                                        <img src={ic_delete} alt='' />
                                    </Button>
                                </div>;
                            })}
                    </div>
                </div>

                <Modal show={!!this.state.showModal} onHide={() => { }} id='PickerModaleWrapper'>
                    <Modal.Header>{this.props.title || 'Add...'}</Modal.Header>
                    <Modal.Body>
                        <div id="PickerModaleBody">
                            <input type='search' className='form-field form-control PickerFilter' placeholder={'Filter by ' + this.props.filterkey.join(', ')} onInput={this.onFilterInput} />
                            {(items || []).filter((item) => !item[this.props.selectedIndicator || DEFAULT_SELECTED]).filter((item) => Object.keys(item).filter((k) => this.props.filterkey.indexOf(k) > -1).map((k) => item[k]).join('').toLowerCase().indexOf(this.state.filter) > -1).length ?
                                <div className='PickerSuggestions'>
                                    {(items || []).filter((item) => !item[this.props.selectedIndicator || DEFAULT_SELECTED]).filter((item) => Object.keys(item).filter((k) => this.props.filterkey.indexOf(k) > -1).map((k) => item[k]).join('').toLowerCase().indexOf(this.state.filter) > -1).map((item, index) => {
                                        return <label key={index} tabIndex={-1} className={'PickerSuggestionItem ' + (item[this.props.selectedIndicator || DEFAULT_SELECTED] ? 'selected' : 'unselected')}>
                                            <input
                                                type='checkbox'
                                                className='pickerCheckbox'
                                                checked={item.__tmp_selected}
                                                onChange={() => { this.onSelectionChange(item[this.props.ukey]); }}
                                            />
                                            {this.displayItem(item)}
                                        </label>;
                                    })
                                    }
                                </div>
                                :
                                <div className='PickerSuggestions'>
                                    <img className='imageEmpty' src={ph_empty} alt='empty' />
                                </div>}
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            style={{ float: 'left' }}
                            onClick={this.onCancel}>
                            Cancel
                        </Button>
                        <div style={{ flex: 1 }}></div>
                        <Button
                            bsStyle='primary'
                            disabled={items.filter((item) => item.__tmp_selection).length === 0}
                            onClick={this.onPublishChanges}>
                            Add selection
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
    reset() {
        const items = this.state.items;
        items.forEach((item) => {
            item[this.props.selectedIndicator || DEFAULT_SELECTED] = undefined;
        });
    }
}

export default PickerComponent;
