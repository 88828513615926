import React from 'react'
import DefaultLayout from '../templates/DefaultTemplate'
import TransferContainer from '../components/FileTransferMonitor/TransferContainer'

class TransferMonitor extends React.Component {

    constructor(props) {
        super(props)

        this.state = {}
    }

    render() {
        return (
            <DefaultLayout {...this.props}>
                <TransferContainer
                    itemId={this.props.match.params.itemId}
                />
            </DefaultLayout>
        )
    }
}

export default TransferMonitor
