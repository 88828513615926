import React, { Component } from 'react'
import ic_loading from '../../../img/ic_loading.svg'
import './index.css'

class LoadingComponent extends Component {

    constructor(props) {
        super(props)
        this.state = {
            if: !!props.if
        }
    }

    componentWillReceiveProps(newProps) {
        this.setState({
            if: !!newProps.if
        })
    }

    render() {

        if (this.state.if) {
            return <div className={`loadingWrapper ${this.props.backdrop ? 'LoadingBackdrop' : ''}`}>
                <img src={ic_loading} className="AnimateLoading" style={{ height: '2rem', width: '2rem', margin: '0 1rem' }} alt="" />
            </div>
        } else {
            return ''
        }
    }
}

export default LoadingComponent
