import Alfred from '../../core/Alfred'
import ApiService from '../../core/ApiService'
import { Button } from '../UI/Toolkit'
import Helpers from '../../core/Helpers'
import Modale from '../UI/ModaleComponent';
import moment from 'moment-timezone';
import React from 'react'
import 'react-table/react-table.css'
import routes from '../../routes'
import ForbiddenComponent from '../ForbiddenComponent'
import WebSocketClient from '../../core/WebSocketClient';
import { Link } from 'react-router-dom'
import Tag from '../UI/Tag'

import ic_open from '../../img/ic_open_external.svg'
import ic_delete from '../../img/ic_delete.svg';
import ic_download_dark from '../../img/ic_download_dark.svg';
import ic_folder from '../../img/ic_folder-white.svg';
import ic_box from '../../img/ic_box.svg'

import './index.css';
class SharedFilesContainer extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            uploading: false,
            showModal: false,
            editingExpiration: 0,
            files: null,
            uploadProgress: 0,
            sharedFiles: [],
            sortBy: null,
            sortDirection: 1
        }
    }

    componentWillReceiveProps(nextProps) {
        const message = nextProps.message
        if (message && message.type === 'shared_files') {
            switch (message.action) {
                case "add":
                    //@ts-ignore
                    console.log("add")
                    this.setState(state => {
                        const sharedFiles = [...state.sharedFiles, {
                            file_id: message.file_id,
                            name: message.name,
                            size: message.size,
                            ready: message.ready,
                            origin: message.origin,
                            creation_time: message.creation_time,
                            expiration_time: message.expiration_time
                        }];
                        return { sharedFiles };
                    }, () => { this.sortBy(this.state.sortBy) });
                    break;

                case "delete":
                    this.setState(state => {
                        const sharedFiles = state.sharedFiles.filter(file => file.file_id !== message.file_id);
                        return { sharedFiles };
                    }, () => { this.sortBy(this.state.sortBy) });
                    break;

                case "update":
                    //@ts-ignore
                    console.log("add")
                    this.setState(state => {
                        const sharedFiles = state.sharedFiles.map(file => {
                            if (file.file_id === message.file_id) {
                                return {
                                    file_id: file.file_id,
                                    name: file.name,
                                    size: file.size,
                                    ready: message.ready,
                                    origin: file.origin,
                                    creation_time: file.creation_time,
                                    expiration_time: message.expiration_time
                                }
                            } else {
                                return file;
                            }
                        });
                        return { sharedFiles };
                    }, () => { this.sortBy(this.state.sortBy) });
                    break;

                default:
                    break;
            }
        }
    }

    componentDidMount() {
        if (Helpers.getCurrentUser().admin >= 1 && Alfred.askPermission('SHARED_FILES', Alfred.R)) {
            this.props.updateBreadcrumb('Shared files',
                <div style={{ display: 'flex' }}>
                    <div style={{ flex: 1 }}>
                        Manage shared files. Drop a file in the main area to add it.
                    </div>
                    {Alfred.askPermission('SHARED_FILES', Alfred.W) &&
                        <Button bsStyle="primary" bsSize="small" onClick={() => {
                            this.showUploadModal()
                        }}>Add file</Button>
                    }
                </div>
            )

            ApiService.getSharedFiles()
                .then((files) => {
                    //@ts-ignore
                    console.log("didmount")
                    this.setState({ sharedFiles: files }, () => { this.sortBy('creation_time') });
                })
                .catch((err) => {
                    this.setState({ error: 'Could not obtain the shared files list' });
                });
        }
    }

    onUpload = () => {
        if (!this.state.files || !this.state.files.length) {
            Helpers.pushNotification({
                title: 'D\'oh...',
                message: `You must choose a file first!`,
                level: 'error'
            })
            return
        }
        this.setState({ uploading: true, uploadProgress: 0 })
        const onProgress = (progressEvent) => {
            const percent = (progressEvent.loaded * 100) / progressEvent.total
            document.dispatchEvent(new CustomEvent('fileTransferInProgress'))
            this.setState({ uploadProgress: percent })
        }

        let fd = new FormData()
        Array.from(this.state.files).forEach(file => {
            fd.append(file.name, file)
        })
        ApiService.uploadSharedFiles(fd, onProgress)
            .then((response) => {
                if (response.success === false) {
                    alert(response.message)
                } else {
                    this.setState({
                        files: null,
                        uploading: false,
                        showModal: false,
                        uploadProgress: 0
                    })
                }
            })
    }

    onCancelUpload = () => {
        this.setState({
            showModal: false,
            uploading: false,
            files: null,
            uploadProgress: 0
        })
    }

    deleteFile = (file) => {
        console.log({ file })
        ApiService.deleteSharedFiles(file.file_id).then(() => {
            Helpers.pushNotification({
                title: `File removed`,
                message: `${file.name} has just been removed`,
                level: 'warning'
            })
        })
    }

    downloadFile = (fileUid) => {
        const url = ApiService.getApiHost()
            + ApiService.prepareRoute(routes.downloadSharedFile, { file_uid: fileUid }).path
            + `?token=${Helpers.getCurrentUser().token}`
        window.location.href = url
    }

    showUploadModal = () => {
        this.setState({
            showModal: true
        });
    }

    setFiles = (files) => {
        if (files && files.length) {
            this.setState({ files });
        }
    }

    onDragEnter = (e) => {
        if (!this.state.uploading) {
            e.target.classList.add('isDragOver');
        }
    }
    onDragLeave = (e) => {
        if (!this.state.uploading) {
            e.target.classList.remove('isDragOver');
        }
    }

    onDragOver = (e) => {
        e.stopPropagation();
        e.preventDefault();
        e.dataTransfer.dropEffect = 'copy';
    }

    onDrop = (e) => {
        e.stopPropagation();
        e.preventDefault();
        const files = e.dataTransfer.files;
        if (files) {
            this.setState({
                showModal: true,
                files
            },
                this.onUpload);
        }
        e.target.classList.remove('isDragOver');
    }

    showEditExpiration = (file_uid) => {
        this.setState({
            editingExpiration: file_uid
        });
    }

    closeEditExpiration = () => {
        this.setState({
            editingExpiration: 0
        });
    }

    editExpiration = (nb, str) => {
        let obj = {
            action: 'set_expiration_date',
            expiration_time: moment().add(nb, str).valueOf()
        }

        ApiService.modifySharedFileExpiration(this.state.editingExpiration, obj)
            .then(this.closeEditExpiration)
    }

    sortBy = (nextSortBy) => {
        const sortBy = nextSortBy
        const sortDirection = this.state.sortBy === nextSortBy ? -(this.state.sortDirection) : 1
        const sharedFiles = this.state.sharedFiles.sort((a, b) => a[nextSortBy] < b[nextSortBy] ? sortDirection : -sortDirection)

        this.setState({
            sortBy,
            sortDirection,
            sharedFiles
        })
    }

    render() {
        if (Helpers.getCurrentUser().admin < 1
            || !Alfred.askPermission('SHARED_FILES', Alfred.R)) {
            return <ForbiddenComponent />
        }

        return (
            <div id='SharedFilesDrop'
                onDragOver={this.onDragOver}
                onDragLeave={this.onDragLeave}
                onDragEnter={this.onDragEnter}
                onDrop={this.onDrop}
            >
                <div id='SharedFilesComponent'>
                    {this.state.sharedFiles.length
                        ? <table className="tableau">
                            <thead>
                                <tr>
                                    <th
                                        className="sortable"
                                        onClick={() => { this.sortBy('name') }}
                                    >
                                        Filename
                                        {this.state.sortBy === 'name' ? <b className={`sortIndicator ${this.state.sortDirection > 0 ? 'desc' : 'asc'}`}>▼</b> : ''}
                                    </th>
                                    <th style={{ textAlign: 'right' }}
                                        className="sortable"
                                        onClick={() => { this.sortBy('size') }}>
                                        Size
                                        {this.state.sortBy === 'size' ? <b className={`sortIndicator ${this.state.sortDirection > 0 ? 'desc' : 'asc'}`}>▼</b> : ''}
                                    </th>
                                    <th style={{ textAlign: 'center' }}
                                        className="sortable"
                                        onClick={() => { this.sortBy('ready') }}>
                                        Status
                                        {this.state.sortBy === 'ready' ? <b className={`sortIndicator ${this.state.sortDirection > 0 ? 'desc' : 'asc'}`}>▼</b> : ''}
                                    </th>
                                    <th style={{ textAlign: 'center' }}
                                        className="sortable"
                                        onClick={() => { this.sortBy('origin') }}>
                                        Box
                                        {this.state.sortBy === 'origin' ? <b className={`sortIndicator ${this.state.sortDirection > 0 ? 'desc' : 'asc'}`}>▼</b> : ''}
                                    </th>
                                    <th className="sortable"
                                        onClick={() => { this.sortBy('creation_time') }}>
                                        Creation
                                        {this.state.sortBy === 'creation_time' ? <b className={`sortIndicator ${this.state.sortDirection > 0 ? 'desc' : 'asc'}`}>▼</b> : ''}
                                    </th>
                                    <th style={{ textAlign: 'right' }}
                                        className="sortable"
                                        onClick={() => { this.sortBy('expiration_time') }}>
                                        Expiration
                                        {this.state.sortBy === 'expiration_time' ? <b className={`sortIndicator ${this.state.sortDirection > 0 ? 'desc' : 'asc'}`}>▼</b> : ''}
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.sharedFiles.map((file) => {
                                    return (
                                        <tr key={file.file_id}>

                                            <td width="100%" style={{ paddingLeft: '.25rem' }}>
                                                <Button title='Download' bsStyle="link" style={{ marginLeft: 0 }}
                                                    onClick={() => { this.downloadFile(file.file_id) }}
                                                >
                                                    <div data-layout='horizontal'>
                                                        <img src={ic_download_dark} alt='' style={{ height: '.8rem', width: '.8rem', marginBottom: '-.1rem', opacity: .6 }} />
                                                        &nbsp;
                                                        {file.name}
                                                    </div>
                                                </Button>
                                                &nbsp;
                                                <Button title='Delete' className="deleteButton"
                                                    onClick={() => { this.deleteFile(file) }}
                                                >
                                                    <img src={ic_delete} alt='' style={{ height: '.8rem', width: '.8rem' }} />
                                                </Button>
                                            </td>
                                            <td style={{ whiteSpace: 'nowrap', textAlign: 'right' }}>{Helpers.humanFileSize(file.size)}</td>
                                            <td style={{ whiteSpace: 'nowrap', textAlign: 'center' }}>
                                                {file.ready
                                                    ? <Tag name="Ready" background="#b7ebd3" textcolor="#60967d" description="File is ready" />
                                                    : <Tag name="Uploading..." background="#d5d5d5" textcolor="#6c93ae" description="File is uploading..." />
                                                }
                                            </td>
                                            <td style={{ whiteSpace: 'nowrap', textAlign: 'center' }}>
                                                {file.origin
                                                    ? <div style={{ display: 'flex' }} className='sourceDestinationTag'>
                                                        <img src={ic_box} alt="" style={{ height: '1.2rem', width: '1.2rem', marginRight: '.5rem' }} />
                                                        <div title={file.origin} className="name" style={{
                                                            flex: 1,
                                                            opacity: .66,
                                                            overflow: 'hidden',
                                                            textOverflow: 'ellipsis',
                                                            marginRight: '.5rem',
                                                        }}>{file.origin}</div>
                                                        <Link to={`/monitor/boxes/${file.origin}`} title='Show box details'>
                                                            <img src={ic_open} alt="" style={{ height: '1rem', width: '1rem' }} />
                                                        </Link>
                                                    </div>
                                                    : '-'
                                                }
                                            </td>
                                            <td style={{ whiteSpace: 'nowrap' }}>{moment(file.creation_time).format('YYYY-MM-DD HH:mm')}</td>
                                            <td style={{ whiteSpace: 'nowrap', textAlign: 'right' }} className="expiration"
                                                onClick={() => { this.showEditExpiration(file.file_id) }}>
                                                {file.expiration_time !== 0
                                                    ? moment(file.expiration_time).format('YYYY-MM-DD HH:mm')
                                                    : 'Unknown'}
                                            </td>
                                        </tr>
                                    )
                                })
                                }
                            </tbody>
                        </table>
                        : ''
                    }

                    <Modale show={this.state.showModal} onHide={() => {
                        this.setState({ uploading: false })
                    }}>
                        <Modale.Header>
                            Add file
                        </Modale.Header>
                        <Modale.Body>
                            <div id='inputFile'>
                                <input type='text' readOnly
                                    value={(this.state.files
                                        && this.state.files.length
                                        && this.state.files[0].name)
                                        ? this.state.files[0].name
                                        : ''}
                                    placeholder='No file chosen' className='form-control inputFile' />
                                <Button bsStyle='primary' title='Browse disk' className='inputFile'
                                    disabled={this.state.uploading}
                                    onClick={() => { this.refs.file.click(); }}>
                                    <img src={ic_folder} alt='' />
                                </Button>
                                <input type='file' ref='file' name='file'
                                    onChange={(e) => { this.setFiles(e.target.files) }} />
                            </div>
                        </Modale.Body>
                        <Modale.Footer>
                            <Button style={{ float: 'left' }} onClick={this.onCancelUpload}>
                                Close
                            </Button>
                            <div style={{ flex: 1, display: 'flex', alignItems: 'center', padding: '0 2rem' }}>
                                {this.state.uploading &&
                                    <div className="package-upload-bar-wrapper">
                                        <div className="package-upload-bar"
                                            style={{ width: `${this.state.uploadProgress}%` }}></div>
                                    </div>
                                }
                            </div>
                            <Button bsStyle="primary" disabled={this.state.uploading}
                                onClick={this.onUpload}>
                                OK
                            </Button>
                        </Modale.Footer>
                    </Modale>

                    <Modale show={this.state.editingExpiration !== 0} onHide={() => {
                        this.setState({ editingExpiration: 0 })
                    }}>
                        <Modale.Header>
                            Edit expiration date
                        </Modale.Header>
                        <Modale.Body>
                            <div className="expirationButtons">
                                <Button onClick={() => { this.editExpiration(1, 'h') }}>1 hour</Button>
                                <Button onClick={() => { this.editExpiration(6, 'h') }}>6 hours</Button>
                                <Button onClick={() => { this.editExpiration(1, 'd') }}>1 day</Button>
                                <Button onClick={() => { this.editExpiration(7, 'd') }}>7 days</Button>
                                <Button onClick={() => { this.editExpiration(2, 'w') }}>2 weeks</Button>
                                <Button onClick={() => { this.editExpiration(1, 'M') }}>1 month</Button>
                                <Button onClick={() => { this.editExpiration(6, 'M') }}>6 months</Button>
                                <Button onClick={() => { this.editExpiration(1, 'y') }}>1 year</Button>
                            </div>
                        </Modale.Body>
                        <Modale.Footer>
                            <div style={{ flex: 1 }}></div>
                            <Button style={{ float: 'left' }} onClick={this.closeEditExpiration}>
                                Cancel
                            </Button>
                        </Modale.Footer>
                    </Modale>
                </div>
            </div>
        );
    }
}

export default WebSocketClient.withWebSocket(SharedFilesContainer, () => [WebSocketClient.messageTypes.shared_files])
