import React from 'react'

import icon from './forbidden.svg'

import './index.css'

class ForbiddenComponent extends React.Component {
    render() {
        return (
            <div>
                <div id="forbiddenLogo">
                    <img src={icon} height="96" alt="forbidden" />
                    <h2>Forbidden</h2>
                    <div>You have unsufficient privileges to access this content</div>
                </div>
            </div>
        )
    }
}

export default ForbiddenComponent