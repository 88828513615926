import React from 'react'
import { Button } from '../UI/Toolkit'
import Helpers from '../../core/Helpers'
import ApiService from '../../core/ApiService'
import 'react-table/react-table.css'
import ForbiddenComponent from '../ForbiddenComponent'
import BoxCube from './BoxUpdateCube'

import WebSocketClient from '../../core/WebSocketClient'
import Alfred from '../../core/Alfred';

class BoxUpdateContainer extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      update: undefined
    }

    this.updateBreadcrumb = this.updateBreadcrumb.bind(this)
    this.onStartUpdate = this.onStartUpdate.bind(this)
    this.onAbortUpdate = this.onAbortUpdate.bind(this)
    this.onRetryUpdate = this.onRetryUpdate.bind(this)
  }

  onStartUpdate() {
    this.setState({
      buttonDisabled: true
    })
    ApiService.postAdminUpdate({ action: "start" }, this.props.match.params.uid).then(() => {
      this.setState({
        buttonDisabled: false
      })
    })
  }

  onAbortUpdate() {
    if (window.confirm("Cancel update deployment ?")) {
      ApiService.postAdminUpdate({ action: "cancel" }, this.props.match.params.uid).then(() => { })
    }
  }

  onRetryUpdate() {
    this.setState({
      buttonDisabled: true
    })
    ApiService.postAdminUpdate({ action: "retry" }, this.props.match.params.uid).then(() => {
      this.setState({
        buttonDisabled: false
      })
    })
  }

  updateBreadcrumb() {
    this.props.updateBreadcrumb('Update Details', <div style={{ display: 'flex' }} >
      <div style={{ flex: 1 }}>{this.state.update && this.state.update.version_code}</div>

    </div>
    )
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.message && nextProps.message.update_id === this.props.match.params.uid) {
      this.setState({
        update: Object.assign(this.state.update, { status: nextProps.message.status })
      })
      if (nextProps.message.boxes) {
        Object.keys(nextProps.message.boxes).forEach((boxUid) => {
          let boxes = this.state.update.boxes.map(box => {
            if (box.uid === boxUid) {
              box.update = nextProps.message.boxes[boxUid]
            }
            return box
          })

          this.setState({ update: { ...this.state.update }, boxes })
        })
      }
    }
  }

  componentDidMount() {
    if (Helpers.getCurrentUser().admin >= 1 && Alfred.askPermission('BOX_UPDATES', Alfred.R)) {

      this.updateBreadcrumb()

      ApiService.getRollingUpdateByUid(this.props.match.params.uid).then(json => {
        if (json.data.message) {
          alert(json.data.message)
        } else {
          this.setState({
            update: json.data
          }, this.updateBreadcrumb)
        }
      })
    }
  }

  render() {
    if (Helpers.getCurrentUser().admin < 1 || !Alfred.askPermission('BOX_UPDATES', Alfred.R)) {
      return <ForbiddenComponent />
    }
    if (!this.state.update) return null
    return (<div style={
      {
        display: 'flex',
        flexDirection: 'column',
        padding: '1rem'
      }
    }>

      <div style={{ margin: '0 50px' }}>
        <div style={{ display: 'flex', borderRadius: '6px', border: '.5pt solid rgba(0,0,0,.1)', padding: '1rem', width: '100%', marginBottom: '2rem', background: '#fbfbfb' }}>
          <div style={{ flex: 1 }}>
            <h3 style={{ margin: 0 }}>{this.state.update.package.version_code || '!! No packge !!'} ({this.state.update.status})</h3>
            <p>{this.state.update.boxes.filter(x => (x.update || {}).status === "SUCCESS").length} out of {this.state.update.boxes.length} boxes updated</p>
          </div>
          <Button style={{ margin: '0 0 auto auto' }} disabled={this.state.buttonDisabled || this.state.update.status !== "READY"} bsStyle="success" onClick={this.onStartUpdate}>Start now</Button>
          &nbsp;
          <Button style={{ margin: '0 0 auto auto' }} disabled={this.state.buttonDisabled || this.state.update.status !== "ERROR"} bsStyle="primary" onClick={this.onRetryUpdate}>Retry</Button>
          &nbsp;
          <Button style={{ margin: '0 0 auto auto' }} disabled={["SUCCESS", "CANCELED"].indexOf(this.state.update.status) > -1} bsStyle="danger" onClick={this.onAbortUpdate}>Cancel</Button>
        </div>
        <h3>Deployment</h3>
        <div style={{ display: 'flex', flexFlow: 'row wrap' }}>
          {this.state.update.status === "READY"
            ? <div style={{ textAlign: 'center', lineHeight: '100%', margin: 'auto 1rem auto 0', padding: '0 1rem' }}>
              <b style={{ fontSize: '1.6rem' }}>{this.state.update.boxes.filter(x => (x.update || {}).status === "READY").length} / {this.state.update.boxes.length}</b>
              <div>complete</div>
            </div>
            : <div style={{ textAlign: 'center', lineHeight: '100%', margin: 'auto 1rem auto 0', padding: '0 1rem' }}>
              <b style={{ fontSize: '1.6rem' }}>{this.state.update.boxes.filter(x => (x.update || {}).status === "SUCCESS").length} / {this.state.update.boxes.length}</b>
              <div>complete</div>
            </div>
          }
          {
            this.state.update.boxes.map((box, index) => {
              return <BoxCube key={index} data={box} />
            })
          }
        </div>
      </div>
    </div>
    )
  }
}

export default WebSocketClient.withWebSocket(BoxUpdateContainer, () => [WebSocketClient.messageTypes.box_update])