import React from 'react'
import Logger from '../../../core/Logger'
import MultiSelect from './MultiSelect'

const logger = new Logger('Table/widgets/StringList')

function StringList(params) {
    try {
        if (typeof params === 'string')
            params = JSON.parse(params)
        if (!params.captions) throw new Error('No captions presents')
    } catch (ex) {
        logger.error(ex.message + ': ' + params)
        return {
            Cell: (cell) => {
                return (cell.value)
            },
            Filter: () => {
                return null
            }
        }
    }

    return {
        Cell: (cell) => {
            const caption = params.captions.find((currentCaption) => {
                return currentCaption.short === cell.value;
            }) || {};

            const style = {
                borderRadius: '3px',
                display: 'inline-block',
                padding: '2px 5px',
                margin: 'auto .5rem'
            };

            if (caption.color) { style.color = caption.color; }
            if (caption.backgroundColor) {
                style.backgroundColor = caption.backgroundColor;
                style.boxShadow = 'inset 0 0 0 1pt var(--color-control-border)';
            }

            return (
                <div style={style} title={caption.long}>
                    {caption.label ? caption.label : caption.short}
                </div>
            );
        },
        Filter: ({ filter, onChange }) => {
            return (
                <MultiSelect
                    onChange={(newValue) => {
                        onChange(newValue);
                    }}
                    value={(filter ? filter.value : [])}
                >
                    {params.captions.map((caption) => {
                        return (
                            <option key={caption.short}
                                value={caption.short}
                                title={caption.long}>{caption.short}</option>
                        );
                    })}
                </MultiSelect>
            )
        }
    }
}

export default StringList