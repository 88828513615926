import React from 'react'
import ApiService from '../../core/ApiService';
import Alfred from '../../core/Alfred'
import { Button } from '../UI/Toolkit'
import Helpers from '../../core/Helpers'
import Modale from '../UI/ModaleComponent'
import WebSocketClient from '../../core/WebSocketClient'

import ic_chrono from '../../img/ic_chrono.svg'
import ic_cloud from '../../img/ic_connection_cloud.svg'

import ani_upload from '../../img/ani_connection_uploading.svg'
import ic_upload_finished from '../../img/ic_connection_uploading_finished.svg'

import ani_download from '../../img/ani_connection_downloading.svg'
import ic_download_finished from '../../img/ic_connection_downloading_finished.svg'

import ic_updown from '../../img/ic_connection_updown.svg'
import ani_updown from '../../img/ani_connection_updown.svg'
import ic_updown_finished from '../../img/ic_connection_updown_finished.svg'

import './transferTest.css'

class TransferTest extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            showModale: false,
        }

        this.launchConnectionTest = this.launchConnectionTest.bind(this)
        this.launchUploadSpeedTest = this.launchUploadSpeedTest.bind(this)
        this.launchDownloadSpeedTest = this.launchDownloadSpeedTest.bind(this)
    }

    componentWillMount() {
    }

    componentWillReceiveProps(nextProps) {
        const message = nextProps.message
        if (message && message.type === 'transfer_test' && message.box_id.toString() === this.props.boxId) {
            const prev = this.state[message.action] || {}
            this.setState({
                [message.action]: {
                    success: message.success,
                    error: message.error_message || '',
                    current_speed: message.current_speed || prev.current_speed,
                    total_speed: message.total_speed || prev.total_speed
                }
            })
        }
    }

    launchConnectionTest(e) {
        if (document.querySelector('#updownimg')) document.querySelector('#updownimg').src = ani_updown
        this.setState({
            test_upload: {},
            test_download: {}
        })

        if (e && e.target) {
            const button = e.target
            button.setAttribute('disabled', true)
            setTimeout((button) => {
                button.removeAttribute('disabled')
            }, 5000, button)
        }

        ApiService.launchTransferTest(this.props.boxId, {
            action: 'test_upload'
        })
        ApiService.launchTransferTest(this.props.boxId, {
            action: 'test_download'
        })
    }

    launchUploadSpeedTest(e) {
        this.setState({
            speed_upload: {}
        })

        if (e && e.target) {
            const button = e.target
            button.setAttribute('disabled', true)
            setTimeout((button) => {
                button.removeAttribute('disabled')
            }, 10000, button)
        }

        ApiService.launchTransferTest(this.props.boxId, {
            action: 'speed_upload'
        })
    }

    launchDownloadSpeedTest(e) {
        this.setState({
            speed_download: {}
        })

        if (e && e.target) {
            const button = e.target
            button.setAttribute('disabled', true)
            setTimeout((button) => {
                button.removeAttribute('disabled')
            }, 10000, button)
        }

        ApiService.launchTransferTest(this.props.boxId, {
            action: 'speed_download'
        })
    }

    renderTestStatus(test) {
        if (test === undefined) return 'waiting test start'
        if (test.success === undefined) return 'test is running'
        if (test.success) {
            if (document.querySelector('#updownimg')) document.querySelector('#updownimg').src = ic_updown_finished
            return 'ok'
        }
        if (test.error) {
            if (document.querySelector('#updownimg')) document.querySelector('#updownimg').src = ic_updown_finished
            return `error (${test.error})`
        }
        if (test.success === false) {
            if (document.querySelector('#updownimg')) document.querySelector('#updownimg').src = ic_updown_finished
            return 'error'
        }
        return 'unknown'
    }

    renderSpeedTest(test, direction) {
        let img_running = ''
        let img_ready = ''
        let img_waiting = ''
        let img_finished = ''
        switch (direction) {
            case 'upload':
                img_ready = <img src={ic_cloud} alt="" style={{ height: '64px', width: '64px' }} />
                img_waiting = <img src={ani_upload} alt="" style={{ height: '64px', width: '64px' }} />
                img_running = <img src={ani_upload} alt="" style={{ height: '64px', width: '64px' }} />
                img_finished = <img src={ic_upload_finished} alt="" style={{ height: '64px', width: '64px' }} />
                break;
            case 'download':
                img_ready = <img src={ic_cloud} alt="" style={{ height: '64px', width: '64px' }} />
                img_waiting = <img src={ani_download} alt="" style={{ height: '64px', width: '64px' }} />
                img_running = <img src={ani_download} alt="" style={{ height: '64px', width: '64px' }} />
                img_finished = <img src={ic_download_finished} alt="" style={{ height: '64px', width: '64px' }} />
                break;
            default: break;
        }
        if (test === undefined) return img_ready
        if (test.current_speed === undefined) return img_waiting
        return (
            <div>
                <div>
                    {test.success === undefined
                        ? img_running
                        : img_finished}
                </div>
                <div>
                    <small style={{ opacity: .5 }}>Current speed:</small> {test.current_speed
                        ? Helpers.humanFileSize(test.current_speed) + '/s'
                        : ''}
                </div>
                <div>
                    <small style={{ opacity: .5 }}>Mean speed:</small> {test.total_speed
                        ? Helpers.humanFileSize(test.total_speed) + '/s'
                        : ''}
                </div>
            </div>)
    }

    render() {
        if (!Alfred.askPermission('TRANSFER_TEST', Alfred.R)) return '';

        return (
            <div>
                <Button bsStyle="primary" className='TransferTestBtn'
                    onClick={() => {
                        this.setState({ showModale: !this.state.showModale })
                    }}>
                    <img src={ic_chrono} alt="" style={{ height: '1rem', width: '1rem', verticalAlign: 'top' }} />
                    &nbsp;
                    Connection test
                </Button>

                <Modale show={this.state.showModale} onHide={() => {
                    this.setState({ showModale: false })
                }}>
                    <Modale.Header>
                        Connection test
                    </Modale.Header>
                    <Modale.Body style={{ width: '700px' }}>
                        <div className='TransferTestContainer'>
                            <div className='Panel'>
                                <label>Connection test</label>
                                <img alt="" src={ic_updown} style={{ height: '64px', width: '64px' }} id="updownimg" />
                                <div>
                                    <small style={{ opacity: .5 }}>Upload:</small> {this.renderTestStatus(this.state.test_upload)}
                                </div>
                                <div>
                                    <small style={{ opacity: .5 }}>Download:</small> {this.renderTestStatus(this.state.test_download)}
                                </div>
                                <div style={{ flex: 1 }}></div>
                                <Button bsStyle="primary"
                                    onClick={this.launchConnectionTest}>
                                    Begin test
                                </Button>

                            </div>

                            <div className='Panel'>
                                <label>Upload speed test</label>
                                {this.renderSpeedTest(this.state.speed_upload, 'upload')}
                                <div style={{ flex: 1 }}></div>
                                <Button bsStyle="primary"
                                    onClick={this.launchUploadSpeedTest}>
                                    Begin test
                                </Button>
                            </div>

                            <div className='Panel'>
                                <label>Download speed test</label>
                                {this.renderSpeedTest(this.state.speed_download, 'download')}
                                <div style={{ flex: 1 }}></div>
                                <Button bsStyle="primary"
                                    onClick={this.launchDownloadSpeedTest}>
                                    Begin test
                                </Button>
                            </div>
                        </div>
                    </Modale.Body>
                    <Modale.Footer>
                        <div style={{ flex: 1 }} />
                        <Button style={{ float: 'left' }} onClick={() => { this.setState({ showModale: false }) }}>
                            Close
                        </Button>
                    </Modale.Footer>
                </Modale>
            </div>
        )
    }
}

export default WebSocketClient.withWebSocket(TransferTest, (props) => [WebSocketClient.messageTypes.transfer_test])