import React from 'react'
import WorkflowStatusIcon from '../../Workflow/statusIcon.js'

// TODO : switch to a database query ?
const STATUSES = ['Creating', 'Ready', 'Processing', 'WaitingUser', 'Ended', 'Failed', 'Discontinued'].map((status) => {
    return { id: status, label: status }
})

function listStatuses(statuses) {
    return statuses.map((status, index) => {
        return (
            <option value={status.id} key={index}>{status.label}</option>
        )
    })
}

export default {
    Cell: ({ value }) => {
        return <WorkflowStatusIcon status={value}/>
    },
    Filter: ({ filter, onChange }) => {
        return (
            <select
                onChange={event => onChange(event.target.value)}
                style={{ width: '100%' }}
                value={filter ? filter.value : 'all'}
            >
                <option value="">Tous</option>
                {listStatuses(STATUSES)}
            </select>
        )
    },
    className: 'text-center'
}