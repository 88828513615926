import React from 'react'
import { Tabs, Tab, Button } from '../UI/Toolkit'
import Helpers from '../../core/Helpers'
import moment from 'moment-timezone'
import ic_yubikey from '../../img/ic_yubikey.svg'
import ApiService from '../../core/ApiService'
import Modale from '../UI/ModaleComponent/'
import logo_yubikey from '../../img/logo_yubikey.svg'
import logo_yubikey_success from '../../img/logo_yubikey_success.svg'
import { startRegistration } from '@simplewebauthn/browser'

class MyAccountComponent extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            user: Helpers.clone(Helpers.getCurrentUser()),
            notificationPreferences: {},
        }

        this.onSave = this.onSave.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.handlePreferenceChange = this.handlePreferenceChange.bind(this)
        this.fetchU2FKeys = this.fetchU2FKeys.bind(this)
        this.fetchUserNotificationPreferences = this.fetchUserNotificationPreferences.bind(this)
    }

    componentDidMount() {
        this.props.updateBreadcrumb(
            'My Profile',
            <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                <div style={{ flex: 1 }}>
                    Manage password, security key(s), e-mail...
                </div>
                <Button bsSize="small" bsStyle="primary" onClick={this.onSave}>Save</Button>
            </div>
        )

        this.fetchU2FKeys()
        this.fetchUserNotificationPreferences()
    }

    fetchUserNotificationPreferences() {
        ApiService.getUserPreferences('profile_notifications')
            .then(response => {
                this.setState({ notificationPreferences: response || {} })
            })
    }

    fetchU2FKeys() {
        const user = this.state.user
        ApiService.getU2FKeys(user.uid)
            .then(response => {
                user.u2f = response
                this.setState({ user })
            })
    }

    handleChange(e) {
        const user = this.state.user
        user[e.target.name] = e.target.value
        this.setState({ user })
    }

    handlePreferenceChange(e) {
        const notificationPreferences = this.state.notificationPreferences || {}
        switch (e.target.type) {
            case 'checkbox': notificationPreferences[e.target.name] = e.target.checked;
                break;
            default: notificationPreferences[e.target.name] = e.target.value;
                break;
        }
        this.setState({ notificationPreferences })
    }

    onSave() {
        let user = JSON.parse(JSON.stringify(this.state.user))
        delete user.id
        delete user.u2f
        delete user.token
        delete user.renewToken
        delete user.exp
        delete user.password
        if (user.newpassword) {
            if (!(user.newpassword && user.newpassword2 && user.newpassword === user.newpassword2)) {
                Helpers.pushNotification({
                    title: 'Unable to save',
                    message: 'Passwords does not match...',
                    level: 'error'
                })
                return
            }
        }
        delete user.newpassword2

        const notificationPreferences = JSON.parse(JSON.stringify(this.state.notificationPreferences))
        ApiService.setUserPreferences('profile_notifications', { preferences: notificationPreferences })
        ApiService.upsertUser(user, user.uid).then((response) => {
            this.forceRefresh = true
            this.setState({
                uploading: false,
                showModal: false
            }, () => {
                this.forceRefresh = false
                if (response.success === false) {
                    alert(response.message)
                }
            })
            this.setState({
                timestamp: Date.now()
            })
            Helpers.pushNotification({
                title: 'Your profile has been updated',
                message: 'Please log out and log back in to see changes',
                level: 'success'
            })
        }).catch(ex => {
            alert(ex)
        })
    }

    revokeYubikey(key) {
        const user = this.state.user
        ApiService.revokeYubikey(user.uid, key.id).then(() => {
            this.setState({ showModalRevokeU2F: false, showModaleU2fKeyDetails: false }, this.fetchU2FKeys())
        })
    }

    updateYubikey(key) {
        const user = this.state.user
        ApiService.updateYubikey(user.uid, key.id, key).then(() => {
            this.setState({ showModaleU2fKeyDetails: false }, this.fetchU2FKeys())
        })
    }

    registerYubikey() {
        const user = this.state.user
        this.setState({ showModalU2F: true })
        ApiService.u2fRegistrationChallenge(user.uid).then(options => {
            startRegistration(options)
                .then((res) => {
                    this.setState({ showModalU2F: false })
                    ApiService.u2fRegistration(user.uid, options.user.id, res)
                        .then(response => {
                            this.setState({
                                showModaleU2fKeyDetails: (response.data || {})
                            }, this.fetchU2FKeys())
                        })
                        .catch(ex => {
                            console.error('ex', ex)
                        })
                })
        })
    }

    render() {
        const my = this.state.user
        return (
            <div style={{ padding: '1rem' }}>
                <Tabs id="userProfileTabs">
                    <Tab label="General">
                        <div style={{ maxWidth: '256px', padding: '1rem' }}>
                            <label>
                                Login
                                <input type="text" className="form-control" defaultValue={my.login} disabled />
                            </label>
                            <label>
                                Display name
                                <input type="text" className="form-control" defaultValue={my.displayName} name="displayName" onInput={this.handleChange} />
                            </label>
                            <label>
                                E-mail address
                                <input type="text" className="form-control" defaultValue={my.email} name="email" onInput={this.handleChange} />
                            </label>
                            <label>
                                Phone number
                                <input type="text" className="form-control" defaultValue={my.phone} name="phone" onInput={this.handleChange} />
                            </label>
                        </div>
                    </Tab>
                    <Tab label="Security" style={{ padding: '1rem' }}>
                        <form style={{ display: 'flex' }}>
                            <div style={{ maxWidth: '256px', padding: '1rem' }}>
                                <label>
                                    New password
                                    <input autoComplete="username" style={{ display: 'none' }} />
                                    <input type="password" autoComplete="new-password" className="form-control" defaultValue={''} name="newpassword" onInput={this.handleChange} />
                                </label>
                                <label>
                                    Confirmation
                                    <input type="password" autoComplete="new-password-confirmation" className="form-control" defaultValue={''} name="newpassword2" onInput={this.handleChange} />
                                </label>
                            </div>
                            <div style={{ flex: 1, padding: '1rem' }}>
                                <b style={{ display: 'block', overflow: 'hidden' }}>
                                    Registered security keys
                                    <Button style={{ float: 'right', padding: 0 }} bsStyle="link" onClick={() => { this.registerYubikey() }} >Link a new key</Button>
                                </b>
                                <br />
                                {(this.state.user.u2f || []).map((key, index) => {
                                    return <div style={{ borderTop: index ? '1pt solid rgba(0,0,0,.07)' : '', paddingTop: '.75rem', display: 'flex', alignItems: 'center', marginBottom: '.75rem' }} key={index}>
                                        <img src={ic_yubikey} alt="Yubikey" style={{ width: '2rem', margin: 'auto', display: 'block' }} />
                                        <div style={{ flex: 1, margin: 'auto' }}>
                                            <div style={{ lineHeight: '100%' }}>{key.name || <i>Unnamed key</i>}</div>
                                            <div style={{ lineHeight: '100%', opacity: .6 }}><small>Added on {moment(key.creation_timestamp).format('YYYY-MM-DD HH:mm:ss')}</small></div>
                                        </div>
                                        <Button bsSize="small" onClick={() => { this.setState({ showModaleU2fKeyDetails: key }) }}>Manage</Button>
                                    </div>
                                })
                                }
                            </div>
                        </form>
                    </Tab>
                    <Tab label="Notification">
                        <form style={{ padding: '1rem' }}>
                            <p>Receive notification by...</p>
                            <div>
                                <label>
                                    <input type="checkbox" disabled={!this.state.user.email} name="email" defaultChecked={this.state.notificationPreferences.email} onChange={this.handlePreferenceChange} />
                                    &nbsp;
                                    e-mail
                                </label>
                            </div>
                            <div>
                                <label>
                                    <input type="checkbox" disabled={!this.state.user.phone} name="sms" defaultChecked={this.state.notificationPreferences.sms} onChange={this.handlePreferenceChange} />
                                    &nbsp;
                                    SMS
                                </label>
                            </div>
                        </form>
                    </Tab>
                </Tabs>

                <Modale show={this.state.showModalU2F}>
                    <Modale.Header>
                        Performing U2F Action
                    </Modale.Header>
                    <Modale.Body style={{ width: '480px' }}>
                        <img src={logo_yubikey} alt="Yubikey" style={{ display: 'block', margin: '2rem auto', height: '170px' }} />
                        <p style={{ textAlign: 'center' }}><b>Please touch the flashing U2F device now.</b></p>
                        <p style={{ textAlign: 'center' }}>You may be prompted to allow the site permission to access your security keys. After granting permission, the device will start to blink.</p>
                    </Modale.Body>
                    <Modale.Footer>
                        <div style={{ flex: 1 }}></div>
                        <Button onClick={() => { this.setState({ showModalU2F: false }) }}>Abort</Button>
                    </Modale.Footer>
                </Modale>

                <Modale show={!!this.state.showModaleU2fKeyDetails}>
                    {(this.state.showModaleU2fKeyDetails || {}).creation_timestamp &&
                        <Modale.Body style={{ width: '480px', textAlign: 'center' }}>
                            <img alt="" src={logo_yubikey_success} style={{
                                height: '170px',
                                marginBottom: '3rem'
                            }} />

                            <div>
                                <small>This key has been registered on {moment(this.state.showModaleU2fKeyDetails.creation_timestamp).format('LLL')}.</small>
                            </div>

                            <input ref={input => { this.keyName = input }}
                                defaultValue={this.state.showModaleU2fKeyDetails.name}
                                style={{ width: '250px', margin: '3rem auto' }}
                                className="form-control" placeholder="Choose a name for your key" />

                            <Button bsSize="small" bsStyle="link" style={{ color: 'red' }} onClick={() => { this.revokeYubikey(this.state.showModaleU2fKeyDetails) }}>Revoke this key</Button>
                        </Modale.Body>
                    }
                    <Modale.Footer>
                        <Button onClick={() => { this.setState({ showModaleU2fKeyDetails: false }) }}>Close</Button>
                        <div style={{ flex: 1 }}></div>
                        <Button bsStyle="primary" onClick={() => { this.updateYubikey({ ...this.state.showModaleU2fKeyDetails, name: this.keyName.value }) }}>Save</Button>
                    </Modale.Footer>
                </Modale>

                <Modale show={this.state.showModalRevokeU2F}>
                    <Modale.Header>
                        Revoking Yubikey
                    </Modale.Header>
                    <Modale.Body style={{ width: '480px' }}>
                        <p>Revoking your key will make future login impossible unless you register a key again.</p>
                        <p>Do you really want to revoke this key ?</p>
                    </Modale.Body>
                    <Modale.Footer>
                        <Button onClick={() => { this.setState({ showModalRevokeU2F: false }) }}>Cancel</Button>
                        <div style={{ flex: 1 }}></div>
                        <Button bsStyle="danger" onClick={() => { this.revokeYubikey(this.state.showModalRevokeU2F) }}>Revoke</Button>
                    </Modale.Footer>
                </Modale>
            </div>
        )
    }
}

export default MyAccountComponent