import React from 'react'
import Helpers from '../../../core/Helpers'
import BitSetField from '../../Fields/TernaryBox'

function BitSet(params) {
    let filters = {}
    params = JSON.parse(params)

    function handleChange(e) {
        if (typeof e.target.checked === 'undefined') {
            delete filters[e.target.name]
        } else {
            filters[e.target.name] = e.target.checked
        }

        return Helpers.clone({
            operator: 'bitset',
            value: filters
        })
    }

    function createCheckbox(caption, onChange, index, value) {
        return (
            <BitSetField key={index} name={index} title={caption} checked={value}
                         onChange={(e) => onChange(handleChange(e))}/>
        )
    }

    return {
        Cell: (cell) => {
            if (cell.value === null) return null
            let value = cell.value.toString(2)
            const checkboxes = (params.captions || []).map((caption, index) => {
                let checked = false
                if (value[(value.length - 1) - index] === '1') checked = true

                return (
                    <BitSetField key={index} name={index} title={caption} checked={checked} readOnly={true}/>
                )
            })

            return (
                <div className={'text-center'}>
                    {checkboxes}
                </div>
            )
        },

        Filter: ({ filter, onChange }) => {
            if (filter) filters = Helpers.clone(filter.value.value)
            const checkboxes = (params.captions || []).map((caption, index) => {
                return createCheckbox(caption, onChange, index, filters[index])
            })

            return (
                <div>
                    {checkboxes}
                </div>
            )
        }
    }
}

export default BitSet