import './index.css'

import { Tabs, Tab, Button } from '../UI/Toolkit'

import Alfred from '../../core/Alfred'
import Helpers from '../../core/Helpers'
import Modale from '../../components/UI/ModaleComponent'

import React from 'react'
import ic_pen from '../../img/ic_pen.svg'
import ic_passwords from '../../img/ic_passwordmgr.svg'
import ic_add from '../../img/ic_add.svg'
import ic_eye from '../../img/ic_eye.svg'
import ic_floppy from '../../img/ic_floppy.svg'
import ic_delete from '../../img/ic_delete.svg'
import marked from 'marked'
import LastTransfers from './lastTransfers'
import BoxEventsView from '../BoxEventsComponent/BoxEventsView'
import ApiService from '../../core/ApiService'

class BoxDetails extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            input_box_notes: (this.props.data || {}).notes,
            input_vnc_notes: (this.props.data || {}).vnc_notes,
            passwords: [],
            showModaleBoxEdit: false,
            showModaleNotesEdit: false,
            showModalePasswords: false,
            showNotes: !JSON.parse(localStorage.getItem('pf_user_prefer_notes_hidden'))
        }

        this.renderNotesBlock = this.renderNotesBlock.bind(this)
        this.onSaveNotes = this.onSaveNotes.bind(this)
    }

    componentDidMount() {
        this.loadPasswords()
    }

    componentWillReceiveProps(nextProps) {
        if (!this.state.showModalNotesEdit) {
            this.setState({
                input_box_notes: (nextProps.data || {}).notes,
                input_vnc_notes: (nextProps.data || {}).vnc_notes,
            })
        }
    }

    loadPasswords = () => {
        return ApiService.getBoxPasswords(this.props.data.uid).then((passwords) => {
            this.setState({ passwords })
        })
    }

    upsertPassword = (row) => {
        if (!row.name || !row.pass) {
            Helpers.pushNotification({
                title: 'Incomplete data',
                message: 'Please insert a name and a password before submitting',
                level: 'error'
            })
            return
        }
        ApiService.putBoxPassword(row.box_uid, row).then(() => {
            this.loadPasswords()
            Helpers.pushNotification({
                title: 'Password saved',
                message: `Password for "${row.name}" has been saved !`,
                level: 'success'
            })
        })
    }

    removePassword = (pwd, pwdIndex) => {
        if (!window.confirm(`Deletion cannot be undone!\nAre you sure you want to delete this password ?`)) {
            return
        }
        if (pwd.id) {
            ApiService.removeBoxPassword(this.props.data.uid, pwd.id).then(() => {
                this.loadPasswords()
                Helpers.pushNotification({
                    title: 'Password removed',
                    message: `Password for "${pwd.name}" has been removed`,
                    level: 'warning'
                })
            })
        } else {
            const passwords = this.state.passwords
            passwords.splice(pwdIndex, 1)
            this.setState({
                passwords
            })
        }
    }

    onSaveNotes() {
        const uid = this.props.data.uid

        const data = {
            notes: this.state.input_box_notes,
            vnc_notes: this.state.input_vnc_notes
        }

        this.props.saveBoxNotes(uid, data).then(() => {
            this.setState({ showModaleNotesEdit: false })
        })
    }

    renderNotesBlock() {
        return <div style={{ padding: '0 .5rem .5rem .5rem', borderTop: '1pt solid rgba(0,0,0,.1)', background: 'white', flex: 1, display: 'flex', flexDirection: 'column' }}>
            <div style={{ display: 'flex', alignItems: 'center', minHeight: '2.5rem' }}>
                <Button bsStyle="default" style={{ border: 'none', padding: '.25rem 0', display: 'flex', alignItems: 'center' }} title="Toggle notes" onClick={() => { this.setState({ showNotes: !this.state.showNotes }, () => { localStorage.setItem('pf_user_prefer_notes_hidden', JSON.stringify(!this.state.showNotes)) }) }}>
                    <img alt="" src="/img/ic_toggle_down.svg" style={{ transform: `rotate(${this.state.showNotes ? '0' : '180deg'})` }} />
                    &nbsp;Notes
                </Button>
                {(Helpers.getCurrentUser().admin > 1 || Alfred.askPermission('MONITOR_BOXES', Alfred.W)) && this.state.showNotes
                    ? <Button className={'editButton'} bsStyle="link" style={{ display: 'flex', alignItems: 'center', alignSelf: 'center', padding: '0 .5rem 0 .5rem' }} onClick={() => { this.setState({ showModaleNotesEdit: true }) }}>
                        <img alt="" src={ic_pen} style={{ height: '1.5rem' }} />
                    </Button>
                    : ''
                }
                <div style={{ flex: 1 }}></div>
                {Alfred.askPermission('MAINTAINANCE_VNC_REMOTE', Alfred.R)
                    ? <Button bsStyle="primary" style={{ border: 'none', padding: '.25rem .75rem .25rem .25rem', display: 'flex', alignItems: 'center' }} title="Toggle notes" onClick={() => { this.loadPasswords().then(() => { this.setState({ showModalePasswords: !this.state.showModalePasswords }) }) }}>
                        <img alt="" src={ic_passwords} />
                        &nbsp;Passwords Manager
                    </Button>
                    : ''
                }
            </div>

            <div style={{ display: 'flex', flex: 1 }}>
                <div style={{ flex: 2, position: 'relative', overflow: 'auto', display: 'flex', flexDirection: 'column', border: '1pt solid rgba(0,0,0,.1)', marginRight: '.25rem', borderRadius: '3px', padding: '.3rem', background: 'rgba(0,0,0,.03)' }}>
                    {Alfred.askPermission('MAINTAINANCE_VNC_REMOTE', Alfred.R)
                        ? <div style={{ fontSize: '.6rem', opacity: .5, position: 'absolute' }}>General</div>
                        : ''
                    }
                    <div style={{ flex: 1, overflow: 'auto' }} id="markedRendered" dangerouslySetInnerHTML={{ __html: marked(this.props.data.notes || '') }}></div>
                </div>
                {Alfred.askPermission('MAINTAINANCE_VNC_REMOTE', Alfred.R)
                    ? <div style={{ flex: 1, position: 'relative', overflow: 'auto', display: 'flex', flexDirection: 'column', border: '1pt solid rgba(0,0,0,.1)', marginLeft: '.25rem', borderRadius: '3px', padding: '.3rem', background: 'rgba(0,0,0,.03)' }}>
                        <div style={{ fontSize: '.6rem', opacity: .5, position: 'absolute' }}>Connection infos</div>
                        <div style={{ flex: 1, overflow: 'auto' }} id="markedRendered" dangerouslySetInnerHTML={{ __html: marked(this.props.data.vnc_notes || '') }}></div>
                    </div>
                    : ''
                }
            </div>
        </div>
    }

    renderGeneralInfosBlock() {

        return <div className="ui-card" style={{ minWidth: '300px', display: 'flex', flexDirection: 'column', padding: '1rem', margin: '.5rem', minHeight: '200px' }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <span style={{ flex: 1, borderBottom: '1pt solid rgba(0,0,0,.07)', padding: '.25rem' }}>{this.props.data.name || <i style={{ opacity: .5 }}>Unnamed box</i>}</span>
                {(Helpers.getCurrentUser().admin > 1 || Alfred.askPermission('MONITOR_BOXES', Alfred.W)) &&
                    <Button bsStyle="default" style={{ display: 'flex', alignItems: 'center', alignSelf: 'center', padding: '0 .5rem 0 .5rem' }} onClick={() => { this.setState({ showModaleBoxEdit: JSON.parse(JSON.stringify(this.props.data)) }) }}>
                        <img alt="" src={ic_pen} style={{ height: '2rem', marginRight: '.25rem' }} />
                        <div>Modify</div>
                    </Button>
                }
            </div>
            <div>
                <div style={{ display: 'flex', padding: '.33rem 0', alignItems: 'flex-end' }}>
                    <div>Uid</div>
                    <div style={{ background: 'linear-gradient(90deg, rgba(0, 0, 0, .5) 18%, transparent 0%) bottom/6px 1px repeat-x', flex: 1, height: '1.6rem' }}></div>
                    <div>{this.props.data ? this.props.data.uid : null}</div>
                </div>
                <div style={{ display: 'flex', padding: '.33rem 0', alignItems: 'flex-end' }}>
                    <div>Status</div>
                    <div style={{ background: 'linear-gradient(90deg, rgba(0, 0, 0, .5) 18%, transparent 0%) bottom/6px 1px repeat-x', flex: 1, height: '1.6rem' }}></div>
                    <div>{this.props.data ? this.props.data.active ? <span>Active</span> : <b style={{ color: 'red' }}>Inactive</b> : null}</div>
                </div>
                <div style={{ display: 'flex', padding: '.33rem 0', alignItems: 'flex-end' }}>
                    <div>Version</div>
                    <div style={{ background: 'linear-gradient(90deg, rgba(0, 0, 0, .5) 18%, transparent 0%) bottom/6px 1px repeat-x', flex: 1, height: '1.6rem' }}></div>
                    <div>{this.props.data ? <span style={{ color: 'darkred' }}>{this.props.data.version}</span> : null}</div>
                </div>
                <div style={{ display: 'flex', padding: '.33rem 0', alignItems: 'flex-end' }}>
                    <div>Token</div>
                    <div style={{ background: 'linear-gradient(90deg, rgba(0, 0, 0, .5) 18%, transparent 0%) bottom/6px 1px repeat-x', flex: 1, height: '1.6rem' }}></div>
                    <div>{this.props.data ? <span style={{ color: 'darkred' }}>{this.props.data.token}</span> : null}</div>
                </div>
                <div style={{ display: 'flex', padding: '.33rem 0', alignItems: 'flex-end' }}>
                    <div>Operating system</div>
                    <div style={{ background: 'linear-gradient(90deg, rgba(0, 0, 0, .5) 18%, transparent 0%) bottom/6px 1px repeat-x', flex: 1, height: '1.6rem' }}></div>
                    <div style={{ textTransform: 'capitalize' }}>
                        {this.props.data
                            ? <span>
                                {this.props.data.system}
                            </span>
                            : null}
                    </div>
                </div>
            </div>
        </div>
    }

    render() {
        if (!this.props.data) return null
        return (
            <div style={{ display: 'flex', flex: 1, height: '100%', overflow: 'hidden' }}>
                <div style={{ display: 'flex', flex: 1, flexDirection: 'column', overflow: 'hidden' }}>

                    <div style={{ flex: 1, display: 'flex', flexDirection: 'column', overflowY: 'auto', overflowX: 'hidden', padding: '.5rem' }}>
                        <div style={{ display: 'flex', padding: '.5rem 0' }}>
                            <BoxEventsView boxCreationDate={this.props.data.creation_timestamp} boxId={this.props.boxId} />
                        </div>
                        <div style={{ display: 'flex', padding: '.5rem 0' }}>
                            <LastTransfers style={{ flex: 1 }} />
                        </div>
                    </div>

                    <div style={{ display: 'flex', flex: 1, maxHeight: this.state.showNotes ? '200px' : '2.6rem', transition: '.2s' }}>
                        {this.renderNotesBlock()}
                    </div>
                </div>

                <Modale show={this.state.showModaleNotesEdit}>
                    <Modale.Header>
                        About this box
                    </Modale.Header>
                    <Modale.Body style={{ width: '640px' }}>
                        <Tabs id="boxDetailsModaleNotesTabs">
                            <Tab label="Notes">
                                <textarea style={{
                                    display: 'block',
                                    marginTop: '.5rem',
                                    width: '100%',
                                    flex: 1.5,
                                    resize: 'none',
                                    height: '240px',
                                    border: '1pt solid #ddd',
                                    borderRadius: '3pt',
                                    padding: '1rem'
                                }}
                                    id="input_box_notes"
                                    value={this.state.input_box_notes}
                                    onChange={(e) => { this.setState({ input_box_notes: e.target.value }) }}
                                ></textarea>
                            </Tab>
                            <Tab label="Remote Connection infos">
                                <textarea style={{
                                    display: Alfred.askPermission('MAINTAINANCE_VNC_REMOTE', Alfred.R) ? 'block' : 'none',
                                    marginTop: '.5rem',
                                    width: '100%',
                                    flex: 1.5,
                                    resize: 'none',
                                    height: '240px',
                                    border: '1pt solid #ddd',
                                    borderRadius: '3pt',
                                    padding: '1rem',
                                }}
                                    id="input_vnc_notes"
                                    value={this.state.input_vnc_notes}
                                    onChange={(e) => { this.setState({ input_vnc_notes: e.target.value }) }}
                                    readonly={!Alfred.askPermission('MAINTAINANCE_VNC_REMOTE', Alfred.W)}
                                ></textarea>
                            </Tab>
                        </Tabs>
                    </Modale.Body>
                    <Modale.Footer>
                        <Button onClick={() => { this.setState({ showModaleNotesEdit: false }) }}>Close</Button>
                        <div style={{ flex: 1 }}></div>
                        <Button style={{ margin: 'auto' }} bsStyle="primary" onClick={this.onSaveNotes}>Save</Button>
                    </Modale.Footer>
                </Modale>

                <Modale show={this.state.showModalePasswords}>
                    <Modale.Header>
                        Passwords for this Box
                    </Modale.Header>
                    <Modale.Body style={{ width: '640px' }}>
                        <table className="tableau" style={{ width: '100%' }}>
                            <thead>
                                <tr>
                                    <th>Type</th>
                                    <th>Name</th>
                                    <th>Password</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.passwords.map((pwd, pwdIndex) => {
                                    return (<tr key={pwdIndex}>
                                        <td>
                                            <select defaultValue={pwd.box_uid} disabled={pwd.id} onChange={(e) => { pwd.box_uid = e.target.value }}>
                                                <option value={this.props.data.uid}>This box</option>
                                                <option value={0} selected={!pwd.box_uid}>Global</option>
                                            </select>
                                        </td>
                                        <td><input type="text" defaultValue={pwd.name} onInput={(e) => { pwd.name = e.target.value }} /></td>
                                        <td><input className="passwordField" type="password" defaultValue={pwd.pass} onInput={(e) => { pwd.pass = e.target.value }} /></td>
                                        <td>
                                            <button className="btn btn-default" style={{ padding: '.33rem' }}
                                                onMouseDown={(e) => { e.target.closest('tr').querySelector('.passwordField').setAttribute('type', 'text') }}
                                                onMouseOut={(e) => { e.target.closest('tr').querySelector('.passwordField').setAttribute('type', 'password') }}
                                                onMouseUp={(e) => { e.target.closest('tr').querySelector('.passwordField').setAttribute('type', 'password') }}
                                            >
                                                <img alt="" src={ic_eye} style={{ filter: 'invert(100%)' }} />
                                            </button>
                                            &nbsp;
                                            <Button
                                                style={{ padding: '.33rem' }}
                                                onClick={() => { this.upsertPassword(pwd) }}
                                            >
                                                <img alt="" src={ic_floppy} />
                                            </Button>
                                            &nbsp;
                                            <Button
                                                style={{ padding: '.33rem' }}
                                                onClick={() => { this.removePassword(pwd, pwdIndex) }}
                                            >
                                                <img alt="" src={ic_delete} />
                                            </Button>
                                        </td>
                                    </tr>)
                                })}
                            </tbody>
                        </table>
                        <br />
                    </Modale.Body>
                    <Modale.Footer>
                        <Button bsStyle="primary" onClick={() => { this.setState({ passwords: [...this.state.passwords, { box_uid: this.props.data.uid }] }) }}>
                            <img alt="" src={ic_add} style={{ filter: 'invert(100%)', height: '1rem', width: '1rem' }} />
                            &nbsp;Add new entry
                        </Button>
                        <div style={{ flex: 1 }}></div>
                        <Button onClick={() => { this.setState({ showModalePasswords: false }) }}>Close</Button>
                    </Modale.Footer>
                </Modale>
            </div>
        )
    }
}

export default BoxDetails
