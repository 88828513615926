import React from 'react'

export default {
    Cell: (cell) => {
        return cell.value && cell.value.split('\\').filter(x => x !== '').sort().join(', ')
    },
    Filter: ({ filter, onChange }) => {
        return (
            <div>
                <input type="text"
                       style={{ width: '100%' }}
                       onChange={(event) => {
                           onChange({
                               operator: 'like',
                               value: event.target.value
                           })
                       }}
                       value={(filter ? filter.value.value : '')}
                />
            </div>
        )
    }
}