import React, { Component } from 'react'
import Dashboard from './Views/Dashboard'
import DashboardByCommunity from './Views/Community'
import DashboardByTag from './Views/Tag'
import DashboardByMap from './Views/Map'
import { Button } from '../UI/Toolkit'
import ApiService from '../../core/ApiService'

import ic_db_dashboard from '../../img/ic_dashboard_dashboard.svg'
import ic_db_community from '../../img/ic_dashboard_community.svg'
import ic_db_tag from '../../img/ic_dashboard_tag.svg'
import ic_empty from '../../img/ic_empty.svg'
import ic_location from '../../img/ic_location.svg'

import './index.css'
import Helpers from '../../core/Helpers'

export default class BoxMonitorComponent extends Component {

    constructor(props) {
        super(props)
        this.state = {
            displayMode: localStorage.getItem('pf_dashboardDisplayMode') || 'default',
            customViews: [],
            boxFilter: ''
        }
    }

    chooseDisplayMode = (mode) => {
        localStorage.setItem('pf_dashboardDisplayMode', mode)
        this.setState({
            displayMode: mode
        })
    }

    componentDidMount() {
        ApiService.getUserPreferences('CustomDashboardEntries').then((customViews) => {
            try {
                this.setState({
                    customViews: customViews || []
                })
            } catch (err) {
                //oops, not loaded
            }
        })
    }

    createCustomView = (json) => {
        const customViews = this.state.customViews
        customViews.push(json)
        ApiService.setUserPreferences('CustomDashboardEntries', { preferences: customViews })
        this.setState({ customViews, displayMode: json.name })
    }

    removeCustomView = (json) => {
        const customViews = this.state.customViews.filter(cv => cv !== json)
        ApiService.setUserPreferences('CustomDashboardEntries', { preferences: customViews })
        this.setState({ customViews, displayMode: json.name })
    }

    render() {
        let component = <Dashboard filter={this.state.boxFilter} {...this.props} />
        switch (this.state.displayMode) {
            case 'default':
                component = <Dashboard filter={this.state.boxFilter} {...this.props} />
                break;
            case 'by_community':
                component = <DashboardByCommunity filter={this.state.boxFilter} createCustomView={this.createCustomView} {...this.props} />
                break;
            case 'by_tag':
                component = <DashboardByTag filter={this.state.boxFilter} createCustomView={this.createCustomView} {...this.props} />
                break;
            case 'by_map':
                component = <DashboardByMap filter={this.state.boxFilter} createCustomView={this.createCustomView} {...this.props} />
                break;
            default:
                const matchView = this.state.customViews.find(cv => cv.name === this.state.displayMode)
                if (!matchView) {
                    component = <Dashboard filter={this.state.boxFilter} {...this.props} />
                } else {
                    switch (matchView.parent) {
                        case 'by_community':
                            component = <DashboardByCommunity filter={this.state.boxFilter} removeCustomView={this.removeCustomView} createCustomView={this.createCustomView} {...this.props} data={matchView} />
                            break;
                        case 'by_tag':
                            component = <DashboardByTag filter={this.state.boxFilter} removeCustomView={this.removeCustomView} createCustomView={this.createCustomView} {...this.props} data={matchView} />
                            break;
                        case 'by_map':
                            component = <DashboardByMap filter={this.state.boxFilter} removeCustomView={this.removeCustomView} createCustomView={this.createCustomView} {...this.props} data={matchView} />
                            break;
                        default:
                            component = <Dashboard filter={this.state.boxFilter} {...this.props} />
                            break;
                    }
                }
                break;
        }

        return (
            <div style={{ display: 'flex', flexDirection: 'column', flex: 1, overflow: 'hidden' }} key={this.state.displayMode}>
                <div id="dashboardChooserBar">
                    {
                        (['default'].indexOf(this.state.displayMode) > -1)
                            ? <input type="search" placeholder="Filter boxes..." value={this.state.boxFilter} onInput={(e) => { this.setState({ boxFilter: e.target.value }) }} style={{ width: '140pt' }} />
                            : ''
                    }
                    <div style={{ flex: 1 }}></div>
                    <Button title="Status view"
                        className={this.state.displayMode === 'default' ? 'active' : 'default'} onClick={() => { this.chooseDisplayMode('default') }}>
                        <img alt="" src={ic_db_dashboard} />
                    </Button>
                    <Button title="Community View"
                        className={this.state.displayMode === 'by_community' ? 'active' : 'default'} onClick={() => { this.chooseDisplayMode('by_community') }}>
                        <img alt="" src={ic_db_community} />
                    </Button>
                    {Helpers.getCurrentUser().admin > 1
                        ? <Button title="Tag View"
                            className={this.state.displayMode === 'by_tag' ? 'active' : 'default'} onClick={() => { this.chooseDisplayMode('by_tag') }}>
                            <img alt="" src={ic_db_tag} />
                        </Button>
                        : ''
                    }
                    <Button title="Map View"
                        className={this.state.displayMode === 'by_map' ? 'active' : 'default'} onClick={() => { this.chooseDisplayMode('by_map') }}>
                        <img alt="" src={ic_location} />
                    </Button>

                    {(this.state.customViews || []).length > 0
                        ? <div style={{ margin: '0 1rem', opacity: .25, pointerEvents: 'none' }}> | </div>
                        : ''
                    }
                    {(this.state.customViews || []).map((v, index) => {
                        let btnIcon = ic_empty
                        switch (v.parent) {
                            case 'by_community':
                                btnIcon = ic_db_community
                                break
                            case 'by_tag':
                                btnIcon = ic_db_tag
                                break
                            case 'by_map':
                                btnIcon = ic_location
                                break
                            default:
                                btnIcon = ic_empty
                                break
                        }
                        return <Button key={index} className={`${this.state.displayMode === v.name ? 'active' : 'default'} custom`} onClick={() => { this.chooseDisplayMode(v.name) }}>
                            <img alt="" src={btnIcon} style={{ marginRight: '.25rem' }} />
                            {v.name}
                        </Button>
                    })}
                </div>
                <div style={{ flex: 1, overflow: 'hidden', display: 'flex' }}>
                    {component}
                </div>
            </div>
        )
    }
}
