import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import Version from '../components/VersionInfo/'
import { withRouter } from 'react-router-dom'
import ic_monitor from '../img/ic_monitor.svg'
import ic_transfers from '../img/ic_transfers.svg'
import ic_update_box from '../img/ic_update_box.svg'
import ic_community from '../img/ic_community.svg'
import ic_template from '../img/ic_template.svg'
import ic_groups from '../img/ic_groups.svg'
import ic_update from '../img/ic_update.svg'
import ic_upgrade from '../img/ic_upgrade.svg'
import ic_users from '../img/ic_users.svg'
import ic_eye from '../img/ic_eye.svg'
import ic_docker from '../img/ic_docker-white.svg'
import ic_configure from '../img/ic_cog-white.svg'
import ic_tag from '../img/ic_tag.svg'
import ic_stats from '../img/ic_stats-white.svg'
import ic_security from '../img/ic_menu_security.svg'

import WebSocketClient from '../core/WebSocketClient';
import Helpers from '../core/Helpers'
import Alfred from '../core/Alfred'



import './navmenu.css'

const menus = [
    {
        title: 'Boxes',
        subtitle: 'Monitor MediaBox Network',
        to: '/monitor/boxes',
        icon: ic_monitor
    },
    {
        title: 'File transfers',
        subtitle: 'Monitor running file transfers',
        to: '/monitor/transfers',
        icon: ic_transfers
    },
    { separator: true },
    {
        title: 'MediaBox Network',
        children: [
            {
                title: 'Communities',
                subtitle: 'Manage communities',
                to: '/communities',
                icon: ic_community,
                permissions: {
                    'COMMUNITIES': Alfred.R
                }
            },
            {
                title: 'Templates',
                subtitle: 'Manage templates',
                to: '/templates',
                icon: ic_template,
                permissions: {
                    'TEMPLATES': Alfred.R
                }
            },
            {
                title: 'Tags',
                subtitle: 'Manage tags',
                to: '/tags',
                icon: ic_tag,
                permissions: {
                    'TAGS': Alfred.R
                }
            },
            { separator: true },
            {
                title: 'Deploy changes',
                subtitle: 'Deploy new configuration to boxes',
                to: '/PFMConfiguration',
                icon: ic_update,
                permissions: {
                    'BOX_CONFIG_DEPLOYMENT': Alfred.R
                }
            },
        ]
    },
    { separator: true },
    {
        title: 'Mediabox Updates',
        children: [
            {
                title: 'Version packages',
                subtitle: 'Upload MediaBox version packages',
                to: '/BoxUpdatePackages',
                icon: ic_upgrade,
                permissions: {
                    'BOX_UPDATE_PACKAGES': Alfred.R
                }
            },
            {
                title: 'Box updates',
                subtitle: 'Send update to boxes/communities',
                to: '/BoxesUpdate',
                icon: ic_update_box,
                permissions: {
                    'BOX_UPDATES': Alfred.R
                }
            },
        ]
    },
    { separator: true, flex: 1 },
    {
        title: 'Platform administration',
        children: [
            {
                title: 'Manager Config',
                subtitle: 'Edit Platform Manager configuration',
                to: '/pfsettings',
                icon: ic_configure,
                permissions: {
                    'PLATFORM_CONFIG': Alfred.R
                }
            },
            {
                title: 'Security',
                subtitle: 'Edit Security Policy',
                to: '/security',
                icon: ic_security,
                permissions: {
                    'PLATFORM_CONFIG': Alfred.R
                }
            },
            {
                title: 'User accounts',
                subtitle: 'Manage platform users',
                to: '/users',
                icon: ic_users,
                permissions: {
                    'USERS': Alfred.R
                }
            },
            {
                title: 'User teams',
                subtitle: 'Manage platform teams of users',
                to: '/teams',
                icon: ic_groups,
                permissions: {
                    'TEAMS': Alfred.R
                }
            },
            { separator: true },
            {
                title: 'Traces',
                subtitle: 'See who did what, and when',
                to: '/traces',
                icon: ic_eye,
                permissions: {
                    'TRACES': Alfred.R
                }
            },
            {
                title: 'Box events',
                subtitle: 'See what happened with the softwares',
                to: '/boxEvents',
                icon: ic_eye,
                permissions: {
                    'BOX_EVENTS': Alfred.R
                }
            },
            {
                title: 'Docker containers',
                subtitle: 'Manage running Docker containers',
                to: '/dockercontrol',
                icon: ic_docker,
                permissions: {
                    'DOCKER_CONTROLS': Alfred.R
                }
            },
            {
                title: 'Statistics',
                subtitle: 'Export Box network activity by slices',
                to: '/pfstats',
                icon: ic_stats,
                permissions: {
                    'PF_STATS': Alfred.R
                }
            },
            {
                title: 'Shared Files',
                subtitle: 'Share files with the boxes',
                to: '/sharedFiles',
                icon: ic_transfers,
                permissions: {
                    'SHARED_FILES': Alfred.R
                }
            },
        ]
    },
]

class MenuComponent extends Component {

    constructor(props) {
        super(props)
        const lS = JSON.parse(localStorage.getItem(`pf_menu_categories_open`) || '[]')
        const mMenus = menus.map(mC => ({
            ...mC,
            open: lS.indexOf(mC.title) > -1
        }))
        this.state = {
            menus: mMenus
        }
    }

    componentDidMount() {
        document.body.addEventListener('pushRoute', (e) => {
            if (this.props.history.location.pathname !== e.detail) {
                this.props.history.push(e.detail)
            }
        })
    }

    componentWillReceiveProps(nextProps) {
        const message = nextProps.message
        if (message && message.type === 'shared_files' && message.action === 'add') {
            Helpers.pushNotification({
                title: `New file uploaded`,
                message: `${message.name} (${Helpers.humanFileSize(message.size)}), from Box #${message.origin}`,
                level: 'info',
                button: {
                    label: 'Show list',
                    href: '/sharedFiles'
                },
                duration: 8000
            })
        }
    }

    toggleEntry(entry) {
        this.state.menus.find(m => m.title === entry.title).open = !entry.open
        const menusOpen = this.state.menus.filter(mC => mC.open).map(mC => mC.title)
        localStorage.setItem(`pf_menu_categories_open`, JSON.stringify(menusOpen))
        this.forceUpdate()
    }

    renderMenuEntry(entry, index) {
        if (entry.to) {
            let active = (this.props.location.pathname && this.props.location.pathname.indexOf(entry.to) === 0 ? 'active' : null)
            return (
                <Link to={entry.to} className={active} key={index}>
                    <img src={entry.icon} alt="" style={{ height: '2rem', width: '2rem', verticalAlign: 'middle' }} />
                    <div>
                        <div>
                            {entry.title}
                        </div>
                        <small>
                            {entry.subtitle}
                        </small>
                    </div>
                </Link>
            )
        }
        if (entry.separator) {
            return <div key={index} className='separator' style={entry.flex ? { flex: entry.flex } : {}}></div>
        }
        if (entry.children) {
            const validatedPermissions = []
            entry.children.forEach(c => {
                Object.keys(c.permissions || {}).forEach(p => {
                    validatedPermissions.push(Alfred.askPermission(p, c.permissions[p]))
                })
            })
            // If there's not any satisfied permission, don't display category at all
            if (validatedPermissions.length && !validatedPermissions.find(x => x === true)) {
                return ''
            }

            const openByLocation = entry.children.find(c => this.props.location.pathname && this.props.location.pathname.indexOf(c.to) === 0)
            return <div key={index}>
                <button className='buttonLink' onClick={(e) => { e.stopPropagation(); this.toggleEntry(entry) }}
                    style={{ background: `${openByLocation || entry.open ? 'rgba(0,0,0,.1)' : 'rgba(0,0,0,.2)'}` }}
                >
                    <div style={{ flex: 1 }}>
                        <small style={{ opacity: .75, fontWeight: 100, textTransform: 'uppercase', fontSize: '.6rem' }}>{entry.title}</small>
                    </div>
                    {!openByLocation ? <div style={{ transform: `rotate(${entry.open ? 0 : 180}deg)` }}>▴</div> : ''}
                </button>
                {openByLocation || entry.open
                    ? entry.children.map((e, i) => this.renderMenuEntry(e, i))
                    : ''
                }
            </div>
        }

    }

    render() {
        return (
            <div id="NavMenuWrapper">
                {this.props.children}
                <div id="NavMenu">
                    {this.state.menus.map((entry, index) => {
                        return this.renderMenuEntry(entry, index)
                    })}
                    <div className="separator"></div>
                </div>
                {Helpers.getCurrentUser().admin >= 1 &&
                    <div id="SideBarAdminInfos">
                        <div id="SideBarVersionInfos">
                            <Version full={true} />
                        </div>
                    </div>
                }

            </div>
        )
    }
}

export default withRouter(WebSocketClient.withWebSocket(MenuComponent, () => [WebSocketClient.messageTypes.shared_files]))
