export default class Helpers {
    static checkUserSession() {
        return localStorage.getItem('pf_session')
            && localStorage.getItem('pf_tokenExpire')
            && parseInt(localStorage.getItem('pf_tokenExpire'), 10) > Date.now()
    }

    static getCurrentUser() {
        return JSON.parse(localStorage.getItem('pf_session') || '{}')
    }

    static getConfig() {
        return JSON.parse(localStorage.getItem('pf_config') || '{}')
    }

    static setConfig(config) {
        localStorage.setItem('pf_config', JSON.stringify(config))
    }

    static getSession() {
        if (localStorage.getItem('pf_session')) {
            return JSON.parse(localStorage.getItem('pf_session'))
        }
        return null
    }

    static clone(object) {
        return JSON.parse(JSON.stringify(object))
    }

    static humanFileSize(size) {
        if (size === 0) return '0 B'
        var i = Math.floor(Math.log(size) / Math.log(1024))
        return (size / Math.pow(1024, i)).toFixed(2) * 1 + ' ' + (['B', 'kB', 'MB', 'GB', 'TB'][i] || 'B')
    }

    static isEquivalent(a, b) {
        var aProps = Object.getOwnPropertyNames(a);
        var bProps = Object.getOwnPropertyNames(b);

        if (aProps.length !== bProps.length) {
            return false;
        }

        for (var i = 0; i < aProps.length; i++) {
            var propName = aProps[i];

            if (a[propName] !== b[propName]) {
                return false;
            }
        }

        return true;
    }

    static async forEach(array, callback) {
        for (let index = 0; index < array.length; index++) {
            await callback(array[index], index, array)
        }
    }

    static pushRoute(route) {
        document.body.dispatchEvent(new CustomEvent('pushRoute', { detail: route }))
    }

    static pushNotification(n) {
        const notification = {
            title: n.title,
            message: n.message,
            level: n.level,
            button: n.button,
            duration: n.duration
        }
        document.body.dispatchEvent(new CustomEvent('pushNotification', { detail: notification }))
    }

    static stringToColor = (str) => {
        let hash = 0;
        for (let i = 0; i < str.length; i++) {
            hash = str.charCodeAt(i) + ((hash << 5) - hash);
        }
        let color = '#';
        for (let i = 0; i < 3; i++) {
            let value = (hash >> (i * 8)) & 0xFF;
            color += ('00' + value.toString(16)).substr(-2);
        }
        return color;
    }

    static msToDuration(millisec) {
        var seconds = (millisec / 1000).toFixed(0);
        var minutes = Math.floor(seconds / 60);
        var hours = "";
        if (minutes > 59) {
            hours = Math.floor(minutes / 60);
            hours = (hours >= 10) ? hours : "0" + hours;
            minutes = minutes - (hours * 60);
            minutes = (minutes >= 10) ? minutes : "0" + minutes;
        }

        seconds = Math.floor(seconds % 60);
        seconds = (seconds >= 10) ? seconds : "0" + seconds;
        return [hours || '00', minutes || '00', seconds || '00'].join(':');
    }
}
