import React from 'react'
import DefaultLayout from '../templates/DefaultTemplate'
import SecurityComponent from '../components/SecurityComponent'

class SecurityRoute extends React.Component {

    render() {
        return (
            <DefaultLayout {...this.props}>
                <SecurityComponent {...this.props} history={this.props.history} />
            </DefaultLayout>
        )
    }
}

export default SecurityRoute
