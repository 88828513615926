import React from 'react'
import { Button } from '../UI/Toolkit'
import em_logo from '../../img/logo_emedia.svg'

import './index.css'

class NotFoundComponent extends React.Component {
    render() {
        return (
            <div>
                <div id="NotFoundComponent">
                    <div id="e404">
                        <h1 style={{ fontSize: '9rem' }}>4O4</h1>
                        <h2>
                            Page not found
                        </h2>
                        <p>
                            The requested page can not be found.
                            <br />
                            <small style={{ opacity: .5 }}>{document.location.href}</small>
                        </p>
                    </div>
                    <div>
                        &nbsp;
                        {!window.localStorage.getItem('pf_session') &&
                            <Button bsStyle="link" onClick={() => { this.props.history.push('/') }}>Login page</Button>
                        }
                        &nbsp;
                        {window.localStorage.getItem('pf_session') &&
                            <Button bsStyle="link" onClick={() => { this.props.history.push('/monitor/boxes/') }}>Boxes monitor</Button>
                        }
                        &nbsp;
                    </div>
                </div>
                <div id="e404Logo">
                    <img src={em_logo} height="64" alt="e-media" />
                </div>
            </div>
        )
    }
}

export default NotFoundComponent