import React, { Component } from 'react'
import moment from 'moment-timezone'
import Helpers from '../../../../core/Helpers'
import ApiService from '../../../../core/ApiService'
import BoxMonitorItem from './BoxMonitorItem'
import Loader from '../../../UI/Loader'
import WebSocketClient from '../../../../core/WebSocketClient'

import './index.css'

class Dashboard extends Component {

    constructor(props) {
        super(props)
        this.state = {
            box: []
        }
    }

    componentWillReceiveProps(nextProps) {
        const message = nextProps.message
        const state = this.state
        if (message) {
            let box = state.box.find((box) => box.id === message.id)
            if (box) {
                box = Object.assign(box, message)
                this.setState(state)
            } else {
                state.box.push(message)
                this.setState(state)
            }
        }
    }

    componentDidMount() {
        ApiService.getBoxMonitoring()
            .then((boxes) => {
                this.setState({ box: boxes }, () => {
                    setTimeout(() => {
                        this.setState({ fullyLoaded: true })
                    }, 500)
                })
                this.props.updateBreadcrumb('Monitoring',
                    <div style={{ clear: 'both', display: 'flex' }}>
                        <div style={{ flex: 1 }}>
                            Overview of the MediaBox network
                            <div style={{ fontSize: '.95rem', opaciy: .5 }}>{moment().format('YYYY-MM-DD HH:mm:ss')}</div>
                        </div>
                    </div>
                )
            })
    }

    render() {

        const boxes_filtered = (this.state.box || []).filter(box => {
            return this.props.filter && box.name.toLowerCase().indexOf(this.props.filter.toLowerCase()) > -1
        })

        const boxes_new = (this.state.box || []).filter(box => {
            return box.new
        })

        const boxes_offline = (this.state.box || []).filter(box => {
            return !box.connected
        })

        const boxes_online = (this.state.box || []).filter(box => {
            return box.connected
        })

        return (
            <div id="DashboardComponent" className={this.state.fullyLoaded ? `animated` : ``}>
                {boxes_filtered.length
                    ? <div className="boxesContainer boxesFilteredContainer" style={{ display: boxes_filtered.length ? 'inline-block' : 'none' }}>
                        <h3 tabIndex="-1">{boxes_filtered.length} Matching your filter <Loader if={!this.state.box} /></h3>
                        <div className='boxesWrapper' ref="newbox">
                            {boxes_filtered.map((box, index) => {
                                return <BoxMonitorItem data={box} key={box.id} index={index} />
                            })
                            }
                        </div>
                    </div>
                    : ''
                }
                {Helpers.getCurrentUser().admin > 1 &&
                    <div className="boxesContainer boxesNewContainer" style={{ display: boxes_new.length ? 'inline-block' : 'none' }}>
                        <h3 tabIndex="-1">{boxes_new.length} Unconfigured <Loader if={!this.state.box} /></h3>
                        <div className='boxesWrapper' ref="newbox">
                            {boxes_new.map((box, index) => {
                                return <BoxMonitorItem data={box} key={box.id} index={index} />
                            })
                            }
                        </div>
                    </div>
                }
                <div className="boxesContainer boxesOfflineContainer" style={{ display: boxes_offline.length ? 'inline-block' : 'none' }}>
                    <h3 tabIndex="-1">{boxes_offline.length} Offline <Loader if={!this.state.box} /></h3>
                    <div className='boxesWrapper' ref="offlinebox">
                        {boxes_offline.map((box, index) => {
                            return <BoxMonitorItem data={box} key={box.id} index={index} />
                        })
                        }
                    </div>
                </div>
                <div className={`boxesContainer boxesOnlineContainer`} style={{ display: boxes_online.length ? 'inline-block' : 'none' }}>
                    <h3 tabIndex="-1">{boxes_online.length} Online <Loader if={!this.state.box} /></h3>
                    <div className='boxesWrapper' ref="onlinebox">
                        {boxes_online.map((box, index) => {
                            return <BoxMonitorItem data={box} key={box.id} index={index} />
                        })
                        }
                    </div>
                </div>
            </div>
        )
    }
}

export default WebSocketClient.withWebSocket(Dashboard, () => [WebSocketClient.messageTypes.box_status])
