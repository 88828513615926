import React from 'react'
import Table from '../Table'

const supportedParams = [
    {
        name: 'box_uid',
        operator: 'equal'
    },
    {
        name: 'box_name',
        operator: 'like'
    },
    {
        name: 'type_id',
        operator: 'equal'
    },
    {
        name: 'type_name',
        operator: 'like'
    },
    {
        name: 'timestamp',
        operator: 'between'
    }
]

class BoxEventsList extends React.Component {
    constructor(props) {
        super(props)

        this.state = {}
    }

    componentDidMount() {
        // Parse filters in the url
        const urlSearchParams = new URLSearchParams(window.location.search)
        var overrideFilters = []
        supportedParams.forEach(param => {
            const val = urlSearchParams.get(param.name)
            if (val) {
                var filter = {
                    id: param.name,
                    value: {}
                }

                if (param.operator === 'equal') {
                    filter.value = `${val}`
                } else if (param.operator === 'like') {
                    filter.value = {
                        operator: 'like',
                        value: `${val}`
                    }
                } else if (param.operator === 'between') {
                    const dates = val.split('_')
                    filter.value = {
                        operator: 'between',
                        from: dates[0],
                        to: dates[1]
                    }
                }

                overrideFilters.push(filter)
            }
        })

        this.setState({ overrideFilters })
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.forceRefresh) this.table.forceRefresh()
    }

    render() {
        return (
            <div style={{ flex: 1, flexDirection: 'column', overflow: 'auto' }}>
                <Table
                    overrideFilters={this.state.overrideFilters}
                    filter={this.props.filter}
                    ref={table => this.table = table}
                    uid={'boxEventsList'}
                    defaultConfig={this.props.configuration}
                    endPoint={this.props.endPoint}
                    csvFilename="boxEvents"
                    onRows={this.onRows}
                    getTdProps={(state, row, column, instance) => {
                        return {
                            onClick: e => {
                            }
                        }
                    }}
                    forceDisplayFilters={true}
                />
            </div>
        )
    }
}

export default BoxEventsList
