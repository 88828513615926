import React from 'react'
import { Button } from '../UI/Toolkit'
import moment from 'moment-timezone'

const FIELD_SEPARATOR = ';'

export default class ExportCsv extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            columns: props.columns
        }

        this.encodeUri = this.encodeUri.bind(this)
        this.download = this.download.bind(this)
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            columns: nextProps.columns
        })
    }

    processRow(columns, row) {
        var finalVal = ''
        columns.forEach((column) => {
            if (!column.show) {
                return
            }
            let data = column.accessor ? column.accessor(row) : row[column.id]
            var innerValue = !data ? '' : data.toString()
            switch (column.type) {
                case 'DATETIME':
                    innerValue = moment(data).format('L LT')
                    break
                case 'WORKFLOW_STATUS':
                    innerValue = data
                    break
                default:
                    break

            }
            var result = innerValue.replace(/"/g, '""')
            result = '"' + result + '"'
            finalVal += result + FIELD_SEPARATOR
        })
        return finalVal + '\n'
    }

    processHeaders(headers) {
        var finalVal = ''
        headers.forEach((col) => {
            if (!col.show) {
                return
            }
            var innerValue = col.Header ? col.Header : col.id
            var result = innerValue.replace(/"/g, '""')
            result = '"' + result + '"'
            finalVal += result + FIELD_SEPARATOR
        })
        return finalVal + '\n'
    }

    encodeUri(columns, rows) {
        var csvFile = ''
        csvFile += this.processHeaders(columns)
        for (var i = 0; i < rows.length; i++) {
            csvFile += this.processRow(columns, rows[i])
        }
        return csvFile
    }

    download(filename, columns, rows) {
        let csvFile = '\uFEFF' + this.encodeUri(columns, rows)
        var blob = new Blob([csvFile], { type: 'text/csv;charset=UTF-8;' })
        if (navigator.msSaveBlob) { // IE 10+
            navigator.msSaveBlob(blob, filename)
        } else {
            var link = document.createElement('a')
            if (link.download !== undefined) { // feature detection
                // Browsers that support HTML5 download attribute
                var url = URL.createObjectURL(blob)
                link.setAttribute('href', url)
                link.setAttribute('download', filename)
                link.style.visibility = 'hidden'
                document.body.appendChild(link)
                link.click()
            }
        }
    }

    render() {
        return (
            <div style={{ display: 'inline-block' }}>
                <Button
                    bsStyle="primary"
                    onClick={() => {
                        const self = this
                        this.props.getData()
                            .then((data) => {
                                this.download(self.props.filename, self.state.columns, data.rows)
                            })
                    }}
                >
                    CSV export
                </Button>
            </div>
        )
    }
}
