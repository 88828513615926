import React from 'react'
import './dateTime.css'

export default function (params) {
    return {
        Cell: (cell) => {
            return cell.value
        },
        Filter: ({ filter, onChange }) => {
            return (<div><input type="text"
                style={{ width: '100%' }}
                onChange={(event) => {
                    onChange({
                        operator: 'like',
                        value: event.target.value
                    })
                }}
                value={(filter ? filter.value.value : '')}
            />
            </div>)
        }
    }
}