import React from 'react'
import { Button } from '../UI/Toolkit'
import 'react-table/react-table.css'
import APIService from '../../core/ApiService'
import Helpers from '../../core/Helpers'
import Alfred from '../../core/Alfred'
import Modale from '../UI/ModaleComponent/'
import Tag from '../UI/Tag/'
//import FileInput from '../UI/FileInput/'
//import Picker from '../UI/Picker'
import './index.css'
import Table from '../Table'
import routes from '../../routes'
import ForbiddenComponent from '../ForbiddenComponent'

import ic_trash from '../../img/ic_delete.svg'

const TAG_LEVELS = [null, 'community', 'box', 'team', 'user']
const TAG_LEVELS_AVAILABLE = [null, 'community', 'box']
const TAG_DOMAINS = [null, 'template', 'admin_pf']
const TAG_DOMAINS_AVAILABLE = [null, 'admin_pf']

class TagsList extends React.Component {
    constructor(props) {
        super(props)

        this.state = {

        }
    }

    generateConfiguration = () => {
        this.setState({
            componentReady: false
        })
        let endPoint = routes.getTags
        const defaultConfiguration = {
            sort: {
                id: 'domain_type',
                desc: true
            },
            rowsPerPage: 10,
            columns: [
                {
                    id: 'uid',
                    title: 'UID',
                    type: Table.types.STRING,
                    accessor: (row) => {
                        return row.uid
                    },
                    show: true,
                    width: 150
                },
                {
                    id: 'name',
                    title: 'Tag',
                    type: Table.types.STRING,
                    accessor: (row) => {
                        return <Tag {...row} />
                    },
                    show: true,
                },
                {
                    id: 'level',
                    title: 'Level',
                    type: Table.types.STRING_LIST,
                    params: {
                        captions: TAG_LEVELS.filter((x) => x).map((level) => {
                            return {
                                long: level,
                                short: level
                            }
                        })
                    },
                    show: true,
                    width: 150
                },
                {
                    id: 'domain_type',
                    title: 'Domain type',
                    type: Table.types.STRING_LIST,
                    params: {
                        captions: TAG_DOMAINS.filter((x) => x).map((domain) => {
                            return {
                                long: domain,
                                short: domain
                            }
                        })
                    },
                    show: true,
                    width: 150
                },
                {
                    id: 'domain_uid',
                    title: 'Domain UID',
                    type: Table.types.STRING,
                    show: true,
                    width: 150
                },
                {
                    id: 'domain_name',
                    title: 'Domain Name',
                    type: Table.types.STRING,
                    show: true,
                    width: 150
                },
            ]
        }

        this.setState({
            endPoint: endPoint,
            configuration: defaultConfiguration,
            componentReady: true
        })
    }

    componentDidMount() {
        if (Helpers.getCurrentUser().admin >= 1) {
            this.generateConfiguration()
            APIService.getTags().then((json) => {
                this.setState({
                    tags: json
                })
            })
            this.props.updateBreadcrumb('Tags management',
                <div style={{ display: 'flex' }}>
                    <div style={{ flex: 1 }}>
                        Manage tags
                    </div>
                    {Alfred.askPermission('TAGS', Alfred.W) &&
                        <Button bsStyle="primary" bsSize="small" onClick={() => {
                            this.setState({ showModal: true, currentTag: { domain_type: null, level: null } })
                        }}>New tag</Button>
                    }
                </div>
            )
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.forceRefresh) this.table.forceRefresh()
    }

    handleChange = (e) => {
        const currentTag = this.state.currentTag
        currentTag[e.target.getAttribute('alt')] = e.target.value || null
        this.setState({ currentTag })
    }

    onSave(tag) {
        APIService.saveTag(tag).then(() => {
            this.setState({
                showModal: false,
            }, () => { this.table.forceRefresh() })
        }).catch(alert)
    }

    onRemoveTag = () => {
        if (window.confirm('Remove this tag ?')) {
            APIService.removeTag(this.state.currentTag).then(() => {
                this.table.forceRefresh()
                this.setState({
                    showModal: false
                })
            })
        }
    }

    render() {
        if (!Alfred.askPermission('TAGS', Alfred.R)) { return <ForbiddenComponent /> }
        if (!this.state.componentReady) return null;

        const modale = <Modale show={!!this.state.showModal} onHide={() => { this.setState({ currentTag: null, showModal: false }) }} closeOnEsc={true}>
            <Modale.Header>
                {(this.state.currentTag || {}).name || 'New tag'}
                {(this.state.currentTag || {}).uid ? ` (${(this.state.currentTag || {}).uid})` : ''}
                <div style={{ flex: 1 }}></div>
                {(this.state.currentTag || {}).domain_type === 'admin_pf' && Alfred.askPermission('TAGS', Alfred.X) && (this.state.currentTag || {}).uid &&
                    < Button bsStyle="link" style={{ padding: '.25rem' }} title='remove' onClick={this.onRemoveTag}>
                        <img src={ic_trash} alt='' style={{ height: '1.5rem', width: '1.5rem', verticalAlign: 'center' }} />
                    </Button>
                }
            </Modale.Header>
            <Modale.Body>
                {this.state.currentTag &&
                    <div style={{ display: 'flex', width: '450pt' }}>
                        <div style={{ flex: 1 }}>
                            <label>
                                Name*
                            <input disabled={this.state.currentTag.domain_type !== 'admin_pf'} type="text" className="form-control" alt="name" value={this.state.currentTag.name || ''} onChange={this.handleChange} />
                            </label>

                            <label>
                                Description
                                <textarea disabled={this.state.currentTag.domain_type !== 'admin_pf'} alt="description" value={this.state.currentTag.description || ''} onChange={this.handleChange} style={{ minHeight: '10rem' }} />
                            </label>
                        </div>
                        &nbsp;
                        <div style={{ width: '170pt' }}>
                            <label>
                                Level*
                            <select disabled={this.state.currentTag.uid} value={this.state.currentTag.level} alt="level" onChange={this.handleChange} className="form-control">
                                    {
                                        TAG_LEVELS.map((level, index) => {
                                            return <option value={level} disabled={TAG_LEVELS_AVAILABLE.indexOf(level) === -1} key={index}>{level}</option>
                                        })
                                    }
                                </select>
                            </label>

                            <label>
                                Domain*
                            <select disabled={this.state.currentTag.uid} value={this.state.currentTag.domain_type} alt="domain_type" onChange={this.handleChange} className="form-control">
                                    {
                                        TAG_DOMAINS.map((domain, index) => {
                                            return <option value={domain} disabled={TAG_DOMAINS_AVAILABLE.indexOf(domain) === -1} key={index}>{domain}</option>
                                        })
                                    }
                                </select>
                            </label>

                            <label>
                                {
                                    this.state.currentTag.uid
                                        ? <span>Domain</span>
                                        : <span>Domain UID</span>
                                }
                                {
                                    this.state.currentTag.uid
                                        ? <div className='form-control' style={{ fontWeight: 'normal', lineHeight: '100%', padding: '.25rem', minHeight: '2.5rem', opacity: .66 }}>
                                            <div>{this.state.currentTag.domain_name}</div>
                                            <small style={{ color: 'var(--color-primary)' }}>{this.state.currentTag.domain_uid}</small>
                                        </div>
                                        : <input readOnly={this.state.currentTag.uid} type="text" className="form-control" alt="domain_uid"
                                            value={this.state.currentTag.domain_uid || ''} onChange={this.handleChange} />
                                }
                            </label>
                            {this.state.currentTag.domain_type === 'admin_pf' &&
                                <label>
                                    Color
                            <input type="color" className="form-control" alt="color" value={this.state.currentTag.color || ''} onChange={this.handleChange} style={{ padding: 0 }} />
                                </label>
                            }
                        </div>
                    </div>
                }
            </Modale.Body>
            <Modale.Footer>
                <Button onClick={() => {
                    this.setState({ showModal: false })
                }}>Close</Button>
                <div style={{ flex: 1 }}></div>
                {Alfred.askPermission('TAGS', Alfred.W) &&
                    <Button bsStyle='primary'
                        disabled={
                            !this.state.currentTag ||
                            !this.state.currentTag.name ||
                            !this.state.currentTag.level ||
                            !this.state.currentTag.domain_type
                        }
                        onClick={() => {
                            this.onSave(this.state.currentTag)
                        }}>Save</Button>
                }
            </Modale.Footer>
        </Modale >

        return (
            <div style={{ flex: 1, flexDirection: 'column', overflow: 'auto', padding: '1rem' }}>
                {modale}
                <Table
                    filter={[]}
                    ref={table => this.table = table}
                    uid={'tagsList'}
                    defaultConfig={this.state.configuration}
                    endPoint={this.state.endPoint}
                    csvFilename="tags"
                    getTdProps={(state, row, column, instance) => {
                        return {
                            onClick: e => {
                                this.setState({
                                    showModal: true,
                                    currentTag: JSON.parse(JSON.stringify(row.original))
                                })
                            }
                        }
                    }}
                />
            </div>
        )
    }
}

export default TagsList
