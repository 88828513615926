import React from 'react'
import { Button } from '../UI/Toolkit'
import ApiService from '../../core/ApiService'
import Helpers from '../../core/Helpers'
import Alfred from '../../core/Alfred'
import Table from '../Table'
import 'react-table/react-table.css'
import routes from '../../routes'
import ForbiddenComponent from '../ForbiddenComponent'
import BoxUpdatePackageComponent from './BoxUpdatePackage.jsx'

import UploadPackage from './UploadPackage'

import ic_trash from '../../img/ic_delete.svg'


class BoxUpdatePackagesContainer extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            componentReady: false,
            endPoint: {},
            configuration: {
                columns: []
            },
        }
    }

    componentDidMount() {
        if (Helpers.getCurrentUser().admin >= 1 && Alfred.askPermission('BOX_UPDATE_PACKAGES', Alfred.R)) {
            this.generateConfiguration()
            this.props.updateBreadcrumb('Update packages management', <div style={{ display: 'flex' }} >
                <div style={{ flex: 1 }}>Manage MediaBox update packages </div>
                {Alfred.askPermission('BOX_UPDATE_PACKAGES', Alfred.W) &&
                    <Button onClick={() => { this.uploadPackageComponent.open() }} bsStyle={'primary'} >
                        Upload package
                    </Button>
                }
            </div>
            )
        }
    }

    deleteRow(uid) {
        ApiService.deletePackage(uid).then((response) => {
            if (response.success) {
                this.childWithTable.forceRefresh()
            } else {
                alert(response.message || 'An error occured')
            }
        })
    }



    generateConfiguration() {
        this.setState({
            componentReady: false
        })
        let endPoint = routes.getUpdatePackagesList
        const defaultConfiguration = {
            sort: {
                id: 'creation_timestamp',
                desc: true
            },
            rowsPerPage: 10,
            columns: [{
                id: 'uid',
                title: 'UID',
                type: Table.types.STRING,
                accessor: (row) => {
                    return row.uid
                },
                show: true,
                width: 150
            },
            {
                id: 'version_code',
                title: 'Version',
                type: Table.types.STRING,
                accessor: (row) => {
                    return row.version_code
                },
                show: true
            },
            {
                id: 'status',
                title: 'Status',
                type: Table.types.STRING,
                accessor: (row) => {
                    return row.status
                },
                show: true
            },
            {
                id: 'creation_timestamp',
                title: 'Creation',
                type: Table.types.DATETIME,
                accessor: (row) => {
                    return row.creation_timestamp
                },
                show: true
            },
            {
                id: 'actions',
                title: '',
                width: 48,
                accessor: (row) => {
                    if (row.status !== 'DELETED') {
                        return <div style={{ textAlign: 'center' }}>
                            {Alfred.askPermission('BOX_UPDATE_PACKAGES', Alfred.X) &&
                                <Button style={{ padding: 0 }} bsStyle="link" onClick={(e) => { e.stopPropagation(); this.deleteRow(row.uid) }} title='Request deletion'>
                                    <img alt="" src={ic_trash} style={{ height: '1.5rem' }} />
                                </Button>
                            }
                        </div>
                    }
                },
                show: true,
                filterable: false,
                sortable: false
            }
            ]
        }

        this.setState({
            endPoint: endPoint,
            configuration: defaultConfiguration,
            componentReady: true
        })
    }




    render() {
        if (Helpers.getCurrentUser().admin < 1 || !Alfred.askPermission('BOX_UPDATE_PACKAGES', Alfred.R)) {
            return <ForbiddenComponent />
        }
        if (!this.state.componentReady) return null
        return (<div style={
            {
                display: 'flex',
                flex: 1,
                padding: '1rem'
            }
        }>

            <BoxUpdatePackageComponent ref={childWithTable => this.childWithTable = childWithTable} {...this.props} {...this.state} />

            <UploadPackage ref={uploadPackageComponent => this.uploadPackageComponent = uploadPackageComponent} reload={() => { this.childWithTable.forceRefresh() }} />

        </div>
        )
    }
}

export default BoxUpdatePackagesContainer