import React from 'react'
import DefaultLayout from '../templates/DefaultTemplate'
import SharedFilesContainer from '../components/SharedFilesComponent/SharedFilesContainer'

class SharedFilesRoute extends React.Component {

    render() {
        return (
            <DefaultLayout {...this.props}>
                <SharedFilesContainer {...this.props} history={this.props.history} />
            </DefaultLayout>
        )
    }
}

export default SharedFilesRoute
