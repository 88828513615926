import React from 'react'
import DefaultLayout from '../templates/DefaultTemplate'
import NotFoundComponent from '../components/NotFound'

class NotFoundRoute extends React.Component {

    render() {
        return (
            <DefaultLayout {...this.props}>
                <NotFoundComponent {...this.props} history={this.props.history}/>
            </DefaultLayout>
        )
    }
}

export default NotFoundRoute
