import WebSocket from 'socket.io-client'
import React from 'react'
import Logger from './Logger'
import ApiService from './ApiService';

const logger = new Logger('core/WebSocketClient.js')

export default class WebSocketClient {
    static messageTypes = {
        box_status: 'box_status',
        conductor_status: 'conductor_status',
        configuration_version: 'configuration_version',
        file_transfer_creation: 'file_transfer_creation',
        file_upload_update: 'file_upload_update',
        file_download_update: 'file_download_update',
        file_status_update: 'file_status_update',
        item_status_update: 'item_status_update',
        box_update: 'box_update',
        tunnel: 'tunnel',
        shared_files: 'shared_files',
        transfer_test: 'transfer_test'
    }

    subscriptions = new Map()

    constructor(server) {
        this.io = WebSocket(server)
        this.on = this.io.on.bind(this.io)
        this.off = this.io.off.bind(this.io)

        this.io.on('connect', () => {
            if (this.subscriptions.size > 0) this.io.emit('subscribe', Array.from(this.subscriptions.keys()))
        })
    }

    /**
     * Send a subscription message to the server with all subscriptions
     *
     * @param {[String]} messageTypes
     */
    subscribe(messageTypes) {
        if (!Array.isArray(messageTypes)) {
            logger.warn(`subscribe: messageTypes should be an array, current value is: ${messageTypes}`)
            messageTypes = [messageTypes]
        }
        if (messageTypes.length <= 0) {
            logger.warn(`subscribe: messageTypes shouldn't be empty`)
            return
        }
        if (this.io.connected) this.io.emit('subscribe', messageTypes)
        messageTypes.forEach((messageType) => {
            let subscriptions = this.subscriptions.get(messageType) || 0
            subscriptions++
            this.subscriptions.set(messageType, subscriptions)
        })

    }

    /**
     * Send unsubscribe message to the server with all subscriptions to remove
     *
     * @param {[String]} messageTypes
     */
    unsubscribe(messageTypes) {
        if (!Array.isArray(messageTypes)) {
            logger.warn(`unsubscribe: messageTypes should be an array, current value is: ${messageTypes}`)
            messageTypes = [messageTypes]
        }
        this.io.emit('unsubscribe', messageTypes)
        messageTypes.forEach((messageType) => {
            let subscriptions = this.subscriptions.get(messageType) || 1
            subscriptions--
            this.subscriptions.set(messageType, subscriptions)
            if (subscriptions === 0) this.subscriptions.delete(messageType)
        })
    }

    /**
     * Inject message from websocket as a props in the wrapped component
     * setSubscriptions is a function that receive props as parameter
     * and should return an array of subscriptions
     *
     * @param {React.Component} WrappedComponent
     * @param {Function} setSubscriptions
     */
    static withWebSocket(WrappedComponent, setSubscriptions) {
        return class extends React.Component {
            constructor(props) {
                super(props)

                this.state = {
                    message: null
                }

                this.subscriptions = setSubscriptions(props)

                this.handleUpdate = this.handleUpdate.bind(this)
            }

            componentDidMount() {
                ApiService.ws.subscribe(this.subscriptions)
                ApiService.ws.on('update', this.handleUpdate)
            }

            componentWillUnmount() {
                ApiService.ws.off('update', this.handleUpdate)
                ApiService.ws.unsubscribe(this.subscriptions)
            }

            handleUpdate(message) {
                this.setState({
                    message: message
                })
            }

            render() {
                return <WrappedComponent message={this.state.message} {...this.props} />
            }
        }
    }
}