import React from 'react'
import DefaultLayout from '../templates/DefaultTemplate'
import TagsListContainer from '../components/TagsComponent'

class TagsRoute extends React.Component {

    render() {
        return (
            <DefaultLayout {...this.props}>
                <TagsListContainer {...this.props} history={this.props.history} />
            </DefaultLayout>
        )
    }
}

export default TagsRoute
