import React from 'react'
import DefaultLayout from '../templates/DefaultTemplate'
import BoxUpdatesContainer from '../components/BoxUpdatesComponent/BoxUpdatesContainer'

class BoxUpdates extends React.Component {

    render() {
        return (
            <DefaultLayout {...this.props}>
                <BoxUpdatesContainer {...this.props} history={this.props.history}/>
            </DefaultLayout>
        )
    }
}

export default BoxUpdates
