import React, { Component } from 'react'
import { Button } from '../../UI/Toolkit'

import ic_folder from '../../../img/ic_folder-white.svg'

import './index.css'

class FileInput extends Component {

    constructor(props) {
        super(props)
        this.state = {
            file: {}
        }

        this.renderFileName = this.renderFileName.bind(this)
    }

    componentDidMount() {
        if (this.props.directory) {
            this.input.directory = true
            this.input.webkitdirectory = true
            this.input.mozdirectory = true
        }
    }

    renderFileName() {
        if (this.state.file.files && this.state.file.files.length > 1) {
            return this.state.file.files.length + ' items'
        } else if (this.state.file.files) {
            return this.state.file.files[0].name
        } else {
            return null
        }
    }

    render() {
        return (
            <div className="inputFileComponentWrapper">
                <div className="inputFileComponentField">
                    {this.renderFileName()}
                </div>
                <input ref={input => this.input = input} type="file" {...this.props} onChange={(e) => { this.setState({ file: e.target }); this.props.onChange && this.props.onChange(e.target.files) }} className="inputFileComponentInput" />
                <Button disabled={this.props.disabled} bsStyle="primary" title="Browse" onClick={() => { this.input.click() }}><img alt="" src={ic_folder} style={{ height: '1.5rem' }} /></Button>
            </div>
        )
    }
}

export default FileInput
