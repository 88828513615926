import React from 'react'
import DefaultLayout from '../templates/DefaultTemplate'
import UsersComponent from '../components/UsersComponent/UsersListContainer'

class Users extends React.Component {

    render() {
        return (
            <DefaultLayout {...this.props}>
                <UsersComponent {...this.props} history={this.props.history}/>
            </DefaultLayout>
        )
    }
}

export default Users
