import React from 'react'
import ApiService from '../../core/ApiService';
import { withRouter } from 'react-router-dom'
import Helpers from '../../core/Helpers'
import moment from 'moment-timezone'
import TransferSpeeds from './transferSpeeds'
import TransferTest from './transferTest'
import WebSocketClient from '../../core/WebSocketClient'

import './lastTransfers.css'

const messageRegistered = [
    WebSocketClient.messageTypes.file_transfer_creation,
    WebSocketClient.messageTypes.item_status_update
]

class LastTransfers extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            transfers: [],
        }
    }

    componentWillMount() {
        this.onRefresh()
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.message) {
            setTimeout(() => {
                this.onRefresh(nextProps.message)
            }, 250)
        }
    }

    onRefresh = (message) => {
        ApiService.getLastTransfers(this.props.match.params.boxId)
            .then(transfers => {
                if (message) {
                    transfers = transfers.map(t => {
                        if (t.workflow_uid === message.workflow_id) {
                            t.status_name = message.status || 'Waiting'
                        }
                        return t
                    })
                }
                this.setState({ transfers })
            })
            .catch((err) => {
                this.setState({
                    transfers: []
                })
            })
    }

    renderStatus = (status) => {
        switch (status) {
            case 0: //Waiting
            case 'Waiting': //Waiting
                return <div className={`status status-${status}`}>Waiting</div>
            case 1: //InProgress
            case 'InProgress': //InProgress
                return <div className={`status status-${status}`}>InProgress</div>
            case 2: //Paused
            case 'Paused': //Paused
                return <div className={`status status-${status}`}>Paused</div>
            case 3: //finished
            case 'Finished': //finished
                return <div className={`status status-${status}`}>finished</div>
            case 4: //Failed
            case 'Failed': //Failed
                return <div className={`status status-${status}`}>Failed</div>
            case 5: // Canceled
            case 'Canceled': // Canceled
                return <div className={`status status-${status}`}>Canceled</div>
            default:
                return <div className={`status status-unknown`}>?? ({status}) ??</div>
        }
    }

    render() {
        return (
            <div id="LastTransfersComponent" className="ui-card ReactTable -highlight" style={{ display: 'flex', flexDirection: 'column', padding: '1rem', width: '100%', paddingTop: '0px' }}>
                <div style={{ display: 'flex', alignItems: 'center', minHeight: '2.5rem' }}>
                    <div style={{ flex: 1, opacity: .5 }}>Last transfers of this box</div>
                    <div style={{ flex: 1 }}></div>
                    <TransferSpeeds boxId={this.props.match.params.boxId} />
                    <TransferTest boxId={this.props.match.params.boxId} />
                </div>

                <table className="tableau" style={{ width: '100%' }}>
                    <thead>
                        <tr>
                            <th style={{ whiteSpace: 'nowrap', textAlign: 'center' }}>Status</th>
                            <th style={{ whiteSpace: 'nowrap' }}>Name</th>
                            <th style={{ whiteSpace: 'nowrap' }}>Nb files</th>
                            <th style={{ whiteSpace: 'nowrap' }}>Total size</th>
                            <th style={{ whiteSpace: 'nowrap', textAlign: 'right' }}>Creation date</th>
                            <th style={{ whiteSpace: 'nowrap', textAlign: 'right' }}>Last update</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.transfers.map(transfer => {
                            const now = moment().format('YYYY-MM-DDTHH:mm:ss')
                            const timestampFilter = moment(transfer.creation_timestamp).format('YYYY-MM-DD') + '_' + now
                            const url = `/monitor/transfers?workflow_uid=${transfer.workflow_uid}&transfer_item.creation_timestamp=${timestampFilter}`
                            return (
                                <tr key={transfer.workflow_uid} style={{ cursor: 'pointer' }} onClick={() => {
                                    console.log(url)
                                    this.props.history.push(url)
                                }}>
                                    <td style={{ whiteSpace: 'nowrap', minWidth: '80pt', textAlign: 'center' }}>{this.renderStatus(transfer.status_name)}</td>
                                    <td style={{ whiteSpace: 'nowrap' }} width="100%">{transfer.workflow_name}</td>
                                    <td style={{ whiteSpace: 'nowrap', textAlign: 'center' }}>{transfer.nb_files}</td>
                                    <td style={{ whiteSpace: 'nowrap', textAlign: 'right' }}>{Helpers.humanFileSize(transfer.total_size)}</td>
                                    <td style={{ whiteSpace: 'nowrap', textAlign: 'right' }}>{moment(transfer.creation_timestamp).format('YYYY-MM-DD HH:mm:ss')}</td>
                                    <td style={{ whiteSpace: 'nowrap', textAlign: 'right' }}>{moment(transfer.update_timestamp).format('YYYY-MM-DD HH:mm:ss')}</td>
                                </tr>)
                        })}
                    </tbody>
                </table>
            </div>
        )
    }
}

export default withRouter(WebSocketClient.withWebSocket(LastTransfers, (props) => messageRegistered))