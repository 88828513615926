import React from 'react'
import Tag from '../../Tag'

import './index.css'

class TagPickerElement extends React.Component {
    constructor(props) {
        super(props)

        this.state = {}
    }

    render() {

        return <div className='tagPickerElementComponent'>
            <Tag {...this.props} />
            &nbsp;
            <small >({this.props.domain_name ? <span>from <i title={this.props.domain_uid}>{this.props.domain_name}</i></span> : this.props.domain_type})</small>
        </div>
    }
}

export default TagPickerElement