import React from 'react'
import { Button } from '../UI/Toolkit'
import Modale from '../UI/ModaleComponent'
import Picker from '../UI/Picker'
import BoxPickerElement from '../UI/PickerTransformedComponents/Box'
import Table from '../Table'
import ApiService from '../../core/ApiService';

import ic_win from '../../img/ic_os_windows.svg'
import ic_tux from '../../img/ic_os_linux.svg'
class BoxDetails extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            ...props,
            update: {
                package: undefined,
                boxes: []
            },
            tmp: {
                boxes: [],
                communities: []
            }
        }
        this.onPackageChanged = this.onPackageChanged.bind(this)
        this.onBoxesChange = this.onBoxesChange.bind(this)
        this.onCommunityChange = this.onCommunityChange.bind(this)
        this.onSubmit = this.onSubmit.bind(this)
        this.validateUpdate = this.validateUpdate.bind(this)
        this.buildBoxesList = this.buildBoxesList.bind(this)
        this.resetUpdate = this.resetUpdate.bind(this)
    }

    resetUpdate() {
        this.refs.boxPicker.reset()
        this.refs.communityPicker.reset()
        this.setState({
            showModale: false,
            update: {
                package: undefined,
                boxes: []
            },
            tmp: {
                boxes: [],
                communities: []
            }
        }, () => {
            this.buildBoxesList()
        })
    }

    componentWillReceiveProps(newProps) {
        this.setState({
            showModale: newProps.showModale
        })
        this.table.forceRefresh()
    }

    onPackageChanged() {
        this.setState({
            update: { ...this.state.update, package: this.props.packages.find(x => x.uid === this.packageSelect.value) }
        }, () => {
            this.validateUpdate()
        })
    }

    onBoxesChange(x) {
        this.setState({ tmp: { ...this.state.tmp, boxes: x.filter(y => y._selected === true) } }, this.buildBoxesList)
    }

    onCommunityChange(x) {
        this.setState({ tmp: { ...this.state.tmp, communities: x.filter(y => y._selected === true) } }, this.buildBoxesList)
    }

    onSubmit() {
        let update = {
            package_id: String(this.state.update.package.uid),
            boxes: this.state.update.boxes.map(x => parseInt(x.uid, 10))
        }
        ApiService.postAdminUpdate(update).then((json) => {
            if (json.update_id) {
                document.location.href = "/BoxesUpdate/" + json.update_id
            } else {
                this.table.forceRefresh()
                alert("An error occured.")
            }
        })
    }

    buildBoxesList() {
        let boxes = []

        this.state.tmp.communities.forEach(community => {
            this.props.boxes.filter(b => b.communities.indexOf(community.uid) > -1)
                .forEach(box => {
                    if (!boxes.find(x => x.uid === box.uid)) {
                        boxes.push(box)
                    }
                })
        })

        this.state.tmp.boxes.forEach(box => {
            if (!boxes.find(x => x.uid === box.uid)) {
                boxes.push(box)
            }
        })

        this.setState({
            update: { ...this.state.update, boxes: boxes }
        }, this.validateUpdate)

    }

    validateUpdate() {
        if (this.state.update && this.state.update.package && this.state.update.package.json) {
            const OS = Object.keys(JSON.parse(this.state.update.package.json))
            const boxes = this.state.update.boxes
            const nb_boxes_os_not_supported = boxes.filter(b => OS.indexOf(b.system) === -1).length
            this.setState({ update_error: nb_boxes_os_not_supported }, this.forceUpdate)
        }
    }

    render() {
        return (
            <div style={{ flex: 1, flexDirection: 'column', overflow: 'auto' }}>
                <Modale show={this.state.showModale}>
                    <Modale.Header>
                        Prepare update deployment
                    </Modale.Header>
                    <Modale.Body style={{ width: '750px' }}>
                        <label>
                            Update package<br />
                            <select className="form-control" defaultValue="" ref={(input) => this.packageSelect = input} onChange={this.onPackageChanged} style={{ width: 'auto', display: 'inline', marginRight: '1rem' }}>
                                <option disabled selected value=""></option>
                                {(this.props.packages || []).filter(upackage => upackage.status !== 'DELETED').map((upackage, index) => {
                                    return <option value={upackage.uid} key={index}>{upackage.version_code}</option>
                                })

                                }
                            </select>
                            {this.state.update.package && JSON.parse(this.state.update.package.json).windows &&
                                <img alt="" src={ic_win} style={{ height: '2rem', margin: '0 .25rem' }} />
                            }
                            {this.state.update.package && JSON.parse(this.state.update.package.json).linux &&
                                <img alt="" src={ic_tux} style={{ height: '2rem', margin: '0 .25rem' }} />
                            }
                        </label>
                        <div style={{ display: 'flex' }}>
                            <Picker ref="boxPicker" style={{ flex: 1 }} title="Individual boxes" filterkey={['name', 'uid', 'box_version', 'system']}
                                items={this.props.boxes} onChange={this.onBoxesChange} ukey="uid"
                                renderItem={(item) => {
                                    return <BoxPickerElement {...item} />
                                }}
                                sortBy="uid" />
                            &nbsp;
                        <Picker ref="communityPicker" style={{ flex: 1 }} title="Boxes from community" filterkey={['name', 'uid']}
                                items={this.props.communities} onChange={this.onCommunityChange} ukey="uid"
                                sortBy="uid" />
                        </div>
                        {this.state.update.package && this.state.update.boxes &&
                            <div>
                                <label>Recap</label>

                                <div>Upgrade {this.state.update.boxes.length} boxes to version {this.state.update.package.version_code}</div>
                            </div>
                        }
                    </Modale.Body>
                    {this.state.update_error ?
                        <div style={{ background: '#ff8167', padding: '1rem .5rem', textAlign: 'center' }}>
                            <b>ERROR</b> You have selected {this.state.update_error} boxe(s) that are uneligible to the current update (OS mismatch)
                        </div>
                        : ''}
                    {!this.state.update_error && this.state.update.package && this.state.update.boxes.length ?
                        <div style={{ background: '#adf68d', padding: '1rem .5rem', textAlign: 'center' }}>
                            <b>You're ready to go !</b> Press "Go" to start deploying this update
                        </div>
                        : ''}
                    <Modale.Footer>
                        <Button onClick={() => { this.resetUpdate() }}>Cancel</Button>
                        <div style={{ flex: 1 }}></div>
                        <Button onClick={this.onSubmit} bsStyle="primary" disabled={this.state.update_error || !this.state.update.package || !this.state.update.boxes.length}>Go</Button>
                    </Modale.Footer>
                </Modale>
                <Table
                    filter={this.props.filter}
                    ref={table => this.table = table}
                    uid={'updatePackagesList'}
                    defaultConfig={this.props.configuration}
                    endPoint={this.props.endPoint}
                    csvFilename="update_packages"
                    getTdProps={(state, row, column, instance) => {
                        return {
                            onClick: e => {
                                if (row && row.original) {
                                    this.props.history.push(`/BoxesUpdate/${row.original.uid}`)
                                }
                                return null
                            }
                        }
                    }}
                    getTrProps={(state, rowInfo, column) => {
                        const style = { className: ['clickable_row'] }
                        if (rowInfo && !rowInfo.original.is_read) {
                            style.className.push('not-read')
                        }
                        return style
                    }}
                    onRows={this.onRows}
                />
            </div>
        )
    }
}

export default BoxDetails
