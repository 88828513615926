import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import ApiService from './core/ApiService'
import WebSocketClient from './core/WebSocketClient'
import marked from 'marked'
marked.setOptions({
    renderer: new marked.Renderer(),
    gfm: true,
    tables: true,
    breaks: true,
    sanitize: false,
    smartLists: true,
    smartypants: false,
    xhtml: false
});

ApiService.ws = new WebSocketClient(ApiService.getApiHost())
ReactDOM.render(<App />, document.getElementById('root'))
