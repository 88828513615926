import React from 'react'
import ApiService from '../../core/ApiService'
import Logger from '../../core/Logger'

const logger = new Logger('components/VersionInfo')
class VersionInfo extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            versions: {
                Platform: false
            }
        }

        this.positionPopup = this.positionPopup.bind(this)
    }

    positionPopup() {
        const popup = this.refs.popup
        const popupRect = popup.getBoundingClientRect()

        Object.assign(popup.style, {
            position: 'absolute',
            padding: '1rem',
            background: 'white',
            color: 'black',
            borderRadius: '3px',
            boxShadow: '0 0 1rem 1pt rgba(0,0,0,.2)',
            top: '0',
            left: '50%',
            textAlign: 'left',
            transform: 'translate(-50%, -100%)',
            width: '200px'
        })

        if (popupRect.left < 0) {
            Object.assign(popup.style, {
                transform: 'translate(0, -100%)',
                right: 'auto',
                left: 0
            })
        } else if (popupRect.right + popupRect.width / 2 > window.innerWidth) {
            Object.assign(popup.style, {
                transform: 'translate(0, -100%)',
                left: 'auto',
                right: 0
            })
        }
    }

    componentDidMount() {
        ApiService.getVersion()
            .then((versions) => {
                this.setState({ versions })
            })
            .catch((err) => {
                logger.error(err)
            })
    }

    render() {
        return (
            <div style={{ position: 'relative' }}>
                {this.state.popup &&
                    <div style={{ position: 'fixed', top: 0, left: 0, right: 0, bottom: 0, zIndex: '99999' }} tabIndex="-1" onClick={() => { this.setState({ popup: false }) }}></div>
                }
                {this.state.popup &&
                    <div ref="popup" style={{ marginTop: '-.75rem' }}>
                        {
                            Object.keys(this.state.versions)
                                .filter((component) => component !== 'Platform')
                                .map((component) => {
                                    const version = String(this.state.versions[component]).replace(/\+/gm, '+\u200B')
                                    return (<div style={{ marginBottom: '.5rem' }} key={component}><small>{component}</small>:<br /> <i style={{ opacity: .66 }}>{version}</i></div>)
                                })
                        }
                    </div>
                }
                <div onClick={() => { this.setState({ popup: true }, this.positionPopup) }} style={{ cursor: 'pointer', position: 'relative' }}>
                    {this.state.popup ? <div style={{ position: 'absolute', background: 'white', height: '1rem', width: '1rem', transform: 'rotate(45deg)', left: 'calc(50% - .7rem)', top: '-1.25rem' }}></div> : ''}
                    <span style={{ opacity: '.5' }}>Version {this.state.versions.Platform}</span>
                </div>
            </div >
        )
    }
}

export default VersionInfo