import ApiService from '../../../../core/ApiService';
import { Button } from '../../../UI/Toolkit'
import Helpers from '../../../../core/Helpers'
import moment from 'moment-timezone'
import React from 'react'
import WebSocketClient from '../../../../core/WebSocketClient'
import Tag from '../../../UI/Tag'

import marker_on from '../../../../img/marker-icon-on.png'
import marker_off from '../../../../img/marker-icon-off.png'

import './index.css'

class DashboardByMap extends React.PureComponent {
    constructor(props) {
        super(props)
        this.state = {
            box: [],
            showConnected: true,
            filterIsOpen: localStorage.getItem('pf_dashboardFilterIsOpen') === null
                ? true
                : localStorage.getItem('pf_dashboardFilterIsOpen') === 'true'
        }

        this.boxMap = null
    }

    toggleFilterPane = () => {
        this.setState({
            filterIsOpen: !this.state.filterIsOpen
        }, () => {
            localStorage.setItem('pf_dashboardFilterIsOpen', this.state.filterIsOpen)
        })
    }

    componentWillReceiveProps(nextProps) {
        const message = nextProps.message
        const state = this.state
        if (message && message.type === 'box_status') {
            let box = state.box.find((box) => box.id === message.id)
            if (box) {
                box = Object.assign(box, message)
                this.setState(state)
            } else {
                state.box.push(message)
                this.setState(state)
            }

            this.createMarkers()
        }
    }

    componentWillMount() {
        if (!document.querySelector('link#leaflet-css')) { // test no to multiply link tags for the same resource
            let stylesheet = document.createElement('link')
            stylesheet.id = "leaflet-css"
            stylesheet.href = 'https://unpkg.com/leaflet@1.7.1/dist/leaflet.css'
            stylesheet.rel = 'stylesheet'
            stylesheet.integrity = 'sha512-xodZBNTC5n17Xt2atTPuE1HxjVMSvLVW9ocqUKLsCC5CXdbqCmblAshOMAS6/keqq/sMZMZ19scR4PsZChSR7A=='
            stylesheet.crossOrigin = ''
            document.head.appendChild(stylesheet)
        }

        let script = document.createElement("script")
        script.src = 'https://unpkg.com/leaflet@1.7.1/dist/leaflet.js'
        script.integrity = 'sha512-XQoYMqMTK8LvdxXYG3nZ448hOEQiglfqkJs1NOQV44cWnUrBc8PkAOcXy20w0vlaXaVUearIOBhiXZ5V3ynxwA=='
        script.crossOrigin = ''
        script.async = true
        script.onload = this.scriptLoaded
        document.head.appendChild(script)
        script.remove() // drop after execution

        ApiService.getBoxMonitoring()
            .then((boxes) => {
                this.setState({ box: boxes }, () => {
                    if (this.props.data) {
                        this.setState({
                            showConnected: this.props.data.data.showConnected
                        })
                    }

                    this.createMarkers()
                    setTimeout(() => {
                        this.setState({ fullyLoaded: true })
                    }, 500)
                })

                this.props.updateBreadcrumb(
                    <div style={{ clear: 'both', display: 'flex' }}>
                        <h1>Monitoring (by position)</h1>
                    </div>,
                    <div style={{ clear: 'both', display: 'flex' }}>
                        <div style={{ flex: 1 }}>
                            Overview of the MediaBox network
                            <div style={{ fontSize: '.95rem', opaciy: .5 }}>{moment().format('YYYY-MM-DD HH:mm:ss')}</div>
                        </div>
                    </div>
                )
            })
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.showConnected !== undefined && prevState.showConnected !== this.state.showConnected) {
            this.createMarkers()
        }
    }

    posFromString(str) {
        return str.split(',')
    }

    scriptLoaded = () => {
        let L = window.L;
        this.boxMap = L.map('box_map')
            .setView([47, 0], 6) // centered on France

        L.tileLayer('https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token=pk.eyJ1IjoiY2d1ZWJlcnQiLCJhIjoiY2tzMGQzeTg1MWl2MjJwczd3bzJqbHBpaSJ9.7KXvRpneCLENJ4fE2icqkQ', {
            maxZoom: 18,
            attribution: 'Map data &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors, ' +
                'Imagery © <a href="https://www.mapbox.com/">Mapbox</a>',
            id: 'mapbox/light-v10',
            tileSize: 512,
            zoomOffset: -1
        }).addTo(this.boxMap);

        this.iconOff = L.icon({
            iconUrl: marker_off,
            iconSize: [25, 41],
            iconAnchor: [12, 41],
            popupAnchor: [1, -34],
            tooltipAnchor: [16, -28]
        })
        this.iconOn = L.icon({
            iconUrl: marker_on,
            iconSize: [25, 41],
            iconAnchor: [12, 41],
            popupAnchor: [1, -34],
            tooltipAnchor: [16, -28]
        })

        this.createMarkers()
    }

    createMarkers() {
        if (window.L === undefined || this.state.box.length === 0) return

        if (this.markers !== undefined)
            this.markers.removeFrom(this.boxMap)

        this.markers = window.L.layerGroup()

        Helpers.forEach(this.state.box, (box) => {
            if (!box.position) return
            if (box.connected && !this.state.showConnected) return

            var mark = window.L.marker(this.posFromString(box.position), {
                icon: box.connected ? this.iconOn : this.iconOff
            })
            mark.bindPopup(`
            <div style="display: flex; flex-direction: column; width: 200pt;">
                <div style="display: flex; align-items: center; font-size: 1.25rem;">
                    <div style="flex: 1; overflow: hidden; text-overflow: ellipsis; white-space: nowrap;" title="${box.name}"><small style="opacity: .5;">#${box.id}</small> ${box.name}</div>
                    <div style="display: ${box.configuration_up_to_date ? 'none' : 'flex'}; height: 1.25rem; width: 1rem; border-radius: 3px; border: 1px solid rgba(0,0,0,.1); margin-left: .25rem; font-size: 10pt; align-items: center; justify-content: center; background: orange;" title="Configuration version mismatch">!</div>
                    <div style="height: 1.25rem; width: 1rem; border-radius: 3px; border: 1px solid rgba(0,0,0,.1); margin-left: .25rem;display: flex; align-items: center; justify-content: center; font-size: 7pt; background: ${box.connected ? '#2C6' : '#d44'}" title="Workflow Manager ${box.connected ? 'online' : 'offline'}">w</div>
                    <div style="height: 1.25rem; width: 1rem; border-radius: 3px; border: 1px solid rgba(0,0,0,.1); margin-left: .25rem;display: flex; align-items: center; justify-content: center; font-size: 7pt; background: ${box.conductor_connected ? '#2C6' : '#d44'}" title="Conductor ${box.conductor_connected ? 'online' : 'offline'}">c</div>
                </div>
                <small style="opacity: .5;">
                    ${box.disconnection_time ? 'Offline since ' + new Date(box.disconnection_time).toLocaleString('US') : ''}
                    ${box.connection_time ? 'Online since ' + new Date(box.connection_time).toLocaleString('US') : ''}
                </small>
                ${box.communities.length ? `<div><br/><small>Communities :</small></div>` : ''}
                <div>
                    ${box.communities.map((community) => `<span class="tagComponent" title="${community.description}">${community.name}</span>`).join(' ')}
                </div>
                ${box.tags.length ? `<div><br/><small>Tags :</small></div>` : ''}
                <div>
                ${box.tags.map((tag) => `<span class="tagComponent" title="${tag.description}" style="color: ${Tag.getContrastYIQ(tag.color)}; background: ${tag.color}">${tag.name}</span>`).join(' ')}
                </div>
                <br/>
                <div style="text-align: right; margin-bottom: .5rem; margin-top: .5rem">
                    <a 
                        href="javascript:void"
                        class="btn btn-primary"
                        onClick="document.body.dispatchEvent(new CustomEvent('pushRoute', { detail: '/monitor/boxes/${box.id}' }))"
                    >Go to box page</a>
                </div>
            </div>
            `)
            this.markers.addLayer(mark)
        })

        this.markers.addTo(this.boxMap)
    }

    onSavePreset = () => {
        const name = prompt('Name your preset :')
        if (!name) {
            return
        }
        this.props.createCustomView({
            name,
            parent: 'by_map',
            data: {
                showConnected: this.state.showConnected
            }
        })
    }

    onRemovePreset = () => {
        if (window.confirm('Remove this preset ?')) {
            this.props.removeCustomView(this.props.data)
        }
    }

    render() {
        return (
            <div id="DashboardMapViewComponent" className={this.state.fullyLoaded ? `animated` : ``}>
                <div style={{ display: 'flex', flexDirection: 'row', flex: 1, overflow: 'hidden' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', flex: 1, overflow: 'auto' }}>
                        <div id='box_map'></div>
                    </div>

                    <div id="DashboardMapFilterBar" className={this.state.filterIsOpen ? 'open' : 'collapsed'}>
                        <Button title={'Open / close filters'} className="filterPaneToggler" onClick={this.toggleFilterPane}>
                            {
                                this.state.filterIsOpen
                                    ? <div>&raquo;</div>
                                    : <div>&laquo;</div>
                            }
                        </Button>
                        <div style={{ flex: 1, overflow: 'auto', flexDirection: 'column' }}>
                            <label style={{ display: 'flex', alignItems: 'center' }}>
                                <input type="checkbox" data-ui-toggle
                                    checked={this.state.showConnected}
                                    onChange={(e) => {
                                        this.setState({ showConnected: e.target.checked })
                                    }} />
                                &nbsp; Show connected
                            </label>
                        </div>
                        {this.props.data
                            ? <label>
                                <Button onClick={this.onRemovePreset} style={{ margin: 'auto' }} bsStyle="danger">Delete custom filter</Button>
                            </label>
                            : <label>
                                <Button onClick={this.onSavePreset} style={{ margin: 'auto' }} bsStyle="primary">Save as custom filter</Button>
                            </label>
                        }
                    </div>
                </div>
            </div>
        )
    }
}

export default WebSocketClient.withWebSocket(DashboardByMap, () => [WebSocketClient.messageTypes.box_status])
