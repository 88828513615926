import React from 'react'
import DefaultLayout from '../templates/DefaultTemplate'
import BoxUpdateContainer from '../components/BoxUpdatesComponent/BoxUpdateContainer'

class BoxUpdate extends React.Component {

    render() {
        return (
            <DefaultLayout {...this.props}>
                <BoxUpdateContainer {...this.props} history={this.props.history}/>
            </DefaultLayout>
        )
    }
}

export default BoxUpdate
