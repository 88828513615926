import React from 'react'
import { ProgressBar } from '../UI/Toolkit'
import * as moment from 'moment-timezone'

import './Download.css'

import ic_download from '../../img/ic_download.svg'
import ic_upload from '../../img/ic_upload.svg'

import Helpers from '../../core/Helpers'

const SECONDS_BEFORE_STUCK = 15

class Download extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            last_update: props.last_update || Date.now()
        }
        this.renderDuration = this.renderDuration.bind(this)
    }

    renderDuration() {
        const duration = moment.utc(moment(this.props.transfer_end).diff(moment(this.props.transfer_start)))
        const minutes = duration.hours() * 60 + duration.minutes()
        const seconds = duration.seconds()
        const arr = []
        if (minutes > 0) { arr.push(`${minutes}mn`) }
        if (seconds > 0) { arr.push(`${seconds}s`) }

        const totalSeconds = seconds + 60 * minutes

        const transferredSize = this.props.progress * this.props.filesize

        const speed = Helpers.humanFileSize((transferredSize / (totalSeconds || 1)) || 0) + '/s'

        return <span title={`Total duration : ${arr.join(', ')} (~${speed})`} style={{ display: 'inline-block', minWidth: '3rem', textAlign: 'right', cursor: 'help' }}>{arr.join(', ')}</span>
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            last_update: nextProps.last_update || Date.now()
        })
    }

    componentDidMount() {
        this.timer = setInterval(() => {
            if (this.state.last_update < Date.now() - 4000) {
                this.setState({
                    last_update: Date.now()
                })
            }
        }, 5000)
    }

    render() {
        const attrs = {}

        if (this.props.progress < 1) {
            attrs.active = true
        }

        const progress = Math.round(this.props.progress * 1000) / 10

        if (this.props.progress === 1) {
            attrs.bsStyle = 'success'
        }

        let icStatus = require('../../img/ic_empty.svg')
        let icTooltip = ''


        if (moment().diff(moment(this.props.last_update), 'seconds') > SECONDS_BEFORE_STUCK && progress && progress < 1 && !this.props.transfer_end) {
            attrs.bsStyle = 'danger'
            attrs.className = 'progressBarAlert'
            attrs.title = `Stuck since ${moment(this.props.last_update).format('YYYY-MM-DD HH:mm:ss')}`
            icStatus = require('../../img/ic_broken.svg')
            icTooltip = <b style={{ color: 'darkred' }}>stuck</b>
        } else {
            if (this.props.type === 'upload') {
                attrs.bsStyle = ''
            } else {
                attrs.bsStyle = ''
            }

            switch (this.props.status) {
                case 'Waiting':
                    icStatus = require('../../img/ic_filetransfer_status_waiting.svg')
                    icTooltip = 'waiting'
                    break
                case 'Paused':
                    icStatus = require('../../img/ic_pause.svg')
                    icTooltip = 'paused'
                    break
                case 'Failed':
                    icStatus = require('../../img/ic_filetransfer_status_failed.svg')
                    icTooltip = 'failed'
                    break
                case 'Canceled':
                    icStatus = require('../../img/ic_close.svg')
                    icTooltip = 'canceled'
                    break
                case 'Finished':
                    icStatus = require('../../img/ic_filetransfer_status_finished.svg')
                    icTooltip = 'done'
                    break
                default:
                    break
            }
        }

        return (
            <div className="DownloadWrapper">
                <div style={{ display: 'flex', flexDirection: 'column', marginRight: '1rem', pointerEvents: 'all', height: '3rem', minWidth: '3rem' }}>
                    <img src={icStatus} title={icTooltip} alt=""
                        style={{ margin: 'auto', transform: 'scale(1.33) translateY(20%)', height: '2rem!impotant', width: '2rem!impotant' }} />
                    <small style={{ marginTop: '.6rem', fontSize: '.9rem', textAlign: 'center' }}>{icTooltip}</small>
                </div>
                <div style={{ flex: 1, display: 'block' }}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <img src={this.props.type === 'upload' ? ic_upload : ic_download} alt=""
                            style={{ margin: '.1rem' }} />
                        <b>{this.props.name}</b>

                        <div style={{ flex: 1, textAlign: 'center' }}>
                        </div>
                        <div>
                            {this.props.transfer_start && !this.props.transfer_end ?
                                <span title={moment(this.props.transfer_start).format('YYYY-MM-DD HH:mm:ss')} style={{ opacity: .7 }}>started {moment(this.props.transfer_start).from(moment())}</span>
                                : ''
                            }
                            {this.props.transfer_end ?
                                <span title={moment(this.props.transfer_end).format('YYYY-MM-DD HH:mm:ss')} style={{ opacity: .7 }}>ended {moment(this.props.transfer_end).from(moment())}</span>
                                : ''
                            }
                                &nbsp;
                                {this.props.transfer_end
                                ? <span title="Total duration">{this.renderDuration()}</span>
                                : progress ? <span style={{ display: 'inline-block', minWidth: '3rem', textAlign: 'right' }}>{progress + '%'}</span> : 'not started'
                            }
                        </div>
                    </div>
                    <ProgressBar title={this.props.status} {...attrs} now={this.props.progress * 100} bsStyle="info" />
                </div>
            </div>
        )
    }
}

export default Download
