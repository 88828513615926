import React from 'react'
import Download from './Download'
import './File.css'
import Helpers from '../../core/Helpers'
import Alfred from '../../core/Alfred'

import { Button } from '../UI/Toolkit'

import ic_play from '../../img/ic_play.svg'
import ic_restart from '../../img/ic_restart.svg'
import ApiService from '../../core/ApiService'
import moment from 'moment-timezone';

class File extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            statusMessage: props.statusMessage
        }

        this.retry = this.retry.bind(this)
        this.restart = this.restart.bind(this)
        this.handleResponse = this.handleResponse.bind(this)
    }

    componentWillReceiveProps(props) {
        if (props.statusMessage)
            this.setState({
                statusMessage: props.statusMessage
            })
    }

    retry() {
        ApiService.fileTransfers.action(this.props.itemId, this.props.id, 'retry')
            .then(this.handleResponse)
    }

    restart() {
        ApiService.fileTransfers.action(this.props.itemId, this.props.id, 'restart')
            .then(this.handleResponse)
    }

    handleResponse(response) {
        if (!response || !Array.isArray(response)) {
            this.setState({
                isActionRunning: false
            })
            return
        }
        this.setState({
            statusMessage: response.map(m => { return Object.assign(m, { timestamp: Date.now() }) })[0]
        })
    }

    computeFileStatus() {
        const statuses = String(this.props.upload.status
            + this.props.downloads.reduce((a, b) => {
                return a + b.status
            }, ''))

        return (statuses.indexOf('Failed') > -1
            || statuses.indexOf('Canceled') > -1)
            ? 'inError'
            : 'normal'
    }

    render() {
        const isUploadFinished = ['Finished', 'Canceled', 'Failed'].includes(this.props.upload.status)
        const areDownloadsFinished = !this.props.downloads.find((download) => {
            return !['Finished', 'Canceled', 'Failed'].includes(download.status)
        })
        const isItemFinished = this.props.itemFinished
        const isFinished = isItemFinished || (isUploadFinished && areDownloadsFinished)

        return (
            <div className="File ui-card" style={{ width: '360px' }}>
                <div className={`FileTitle ${this.computeFileStatus()}`}>
                    <div style={{ flex: 1, display: 'flex' }}>
                        <span style={{ flex: 1 }}>{Helpers.humanFileSize(this.props.size || 0)} file</span>
                        <small>uid: {this.props.id}</small>
                    </div>
                </div>
                <div className="FileContent">
                    <Download type={'upload'} {...this.props.upload} name={this.props.origin.name} filesize={this.props.size} />
                    <div>
                        {this.props.downloads.map((download) => {
                            return <Download key={download.destination_id} type={'download'} {...download}
                                filesize={this.props.size}
                                name={download.destination_name} />
                        })}
                    </div>
                </div>
                {Alfred.askPermission('MAINTAINANCE_TRANSFERS_CONTROL', Alfred.R) ?
                    <div className="FileFooter">
                        <div style={{ margin: 'auto 0', padding: '0 .75rem', fontSize: '1.2rem' }}>{this.state.statusMessage ? (moment(this.state.statusMessage.timestamp).format('HH:mm:ss') + ' - ' + this.state.statusMessage.message) : ''}</div>
                        <div style={{ flex: 1 }}></div>
                        <Button style={{ padding: 0 }} bsStyle="link" title="Resume" disabled={isFinished || this.props.isActionRunning} onClick={() => {
                            this.retry()
                        }}><img src={ic_play} alt="" /></Button>
                        &nbsp;
                        <Button style={{ padding: 0 }} bsStyle="link" title="Restart" disabled={isFinished || this.props.isActionRunning} onClick={() => {
                            this.restart()
                        }}><img src={ic_restart} alt="" /></Button>
                        &nbsp;
                    </div>
                    : ''}
            </div>
        )
    }
}

export default File
