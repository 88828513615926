import React from 'react';
import { Button } from './UI/Toolkit';
import ApiService from '../core/ApiService';
import Helpers from '../core/Helpers';
import './SessionTracker.css';

const DIVIDER = 5

export class SessionTracker extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            shouldDisplayPopup: false
        }
    }

    handleUserAction = (ev) => {
        localStorage.setItem('pf_lastUserAction', String(Date.now()))
    }

    handleTick = (oneshot = false) => {
        const session = localStorage.getItem('pf_session')
        if (!session) {
            this.logOut()
        }
        const tokenExpire = parseInt(String((localStorage.getItem('pf_tokenExpire') || 0)), 10)
        const lastToken = parseInt(String((localStorage.getItem('pf_lastToken') || 0)), 10)
        const lastUserAction = parseInt(String((localStorage.getItem('pf_lastUserAction') || Date.now())), 10)
        const checkInterval = Math.floor((tokenExpire - lastToken) / DIVIDER)

        this.setState({
            shouldDisplayPopup: Date.now() + checkInterval + 1000 >= tokenExpire
        }, () => {
        })


        if (
            lastUserAction >= lastToken
        ) {
            this.renewToken()
        } else if (tokenExpire <= Date.now()) {
            this.logOut()
        }
        if (!oneshot) {
            setTimeout(this.handleTick, checkInterval)
        }
    }

    logOut = () => {
        window.localStorage.removeItem('pf_session')
        window.localStorage.removeItem('pf_lastUserAction')
        window.localStorage.removeItem('pf_lastToken')
        window.localStorage.removeItem('pf_tokenExpire')
        setTimeout(() => {
            document.location.href = '/?returnTo=' + encodeURIComponent(window.location.pathname)
                + '&uuid=' + JSON.parse(localStorage.getItem('pf_session') || '{}').uid || '';
        }, 100)
    }

    componentDidMount() {
        (['click', 'keyup', 'keydown', 'fileTransferInProgress']).forEach((e) => document.addEventListener(e, this.handleUserAction))
        this.handleTick()
    }

    componentWillUnmount() {
        ['click', 'keyup', 'keydown', 'fileTransferInProgress'].forEach((e) => document.removeEventListener(e, this.handleUserAction))
    }

    render() {
        return (
            <div>
                {Helpers.checkUserSession() && this.state.shouldDisplayPopup &&
                    <div>
                        <div className='timeoutPopupBack'></div>
                        <div className='timeoutPopup'>
                            <b>Your session is about to expire</b>
                            <p>
                                <small>For security reasons, unless you do womething on this page, you will be disconnected in a few moments.</small>
                            </p>
                            <br />
                            <Button onClick={() => { this.renewToken() }}
                                bsStyle='danger'>Continue</Button>
                        </div>
                    </div>
                }
            </div>
        );
    }

    renewToken = () => {
        if (!localStorage.getItem('pf_session')) {
            return;
        }
        ApiService.renewToken()
            .then((json) => {
                const session = JSON.parse(localStorage.getItem('pf_session') || '{}');
                localStorage.setItem('pf_session', JSON.stringify(Object.assign(session, json)));
                localStorage.setItem('pf_lastToken', String(Date.now()));
                localStorage.setItem('pf_tokenExpire', Date.now() + (json.exp || 0));
                this.setState({
                    shouldDisplayPopup: false
                })
            }).catch(ex => {
                console.error(ex)
                this.logOut()
            });
    }
}

export default SessionTracker
