import React from 'react'
import DefaultLayout from '../templates/DefaultTemplate'
import PfSettingsComponent from '../components/PfSettingsComponent'

class PfSettingsRoute extends React.Component {

    render() {
        return (
            <DefaultLayout {...this.props}>
                <PfSettingsComponent {...this.props} history={this.props.history} />
            </DefaultLayout>
        )
    }
}

export default PfSettingsRoute
