import React, { Component } from 'react'
import { Button, Tabs, Tab } from '../UI/Toolkit'
import 'react-table/react-table.css'
import APIService from '../../core/ApiService'
import Helpers from '../../core/Helpers'
import Alfred from '../../core/Alfred'
import Modale from '../UI/ModaleComponent/'
import Tag from '../UI/Tag/'
import FileInput from '../UI/FileInput/'
import Picker from '../UI/Picker'
import './index.css'
import Table from '../Table'
import routes from '../../routes'
import TemplatesList from './TemplatesList'
import ForbiddenComponent from '../ForbiddenComponent'
import BoxPickerElement from '../UI/PickerTransformedComponents/Box'

class TemplatesListContainer extends Component {

    constructor(props) {
        super(props)
        this.state = {
            componentReady: false,
            endPoint: {},
            configuration: {
                columns: []
            },
            current_template_type: undefined,
            template_types: undefined,
            uploading: false,
            communities: [],
            tmp: undefined
        }

        this.forceRefresh = false

        this.onUpload = this.onUpload.bind(this)
        this.onCommunityChange = this.onCommunityChange.bind(this)
    }

    componentDidMount() {
        if (Helpers.getCurrentUser().admin >= 1) {
            this.generateConfiguration()
            APIService.getCommunities().then((json) => {
                this.setState({
                    communities: json
                })
            })
            this.props.updateBreadcrumb('Templates management',
                <div style={{ display: 'flex' }}>
                    <div style={{ flex: 1 }}>
                        Manage workflow templates
                    </div>
                    {Alfred.askPermission('TEMPLATES', Alfred.W) &&
                        <Button bsStyle="primary" bsSize="small" onClick={() => {
                            this.setState({ showModal: true, tmp: { communities: [] } })
                        }}>New template</Button>
                    }
                </div>
            )
        }
    }

    onCommunityChange(x) {
        if (x) this.setState({ tmp: { ...this.state.tmp, communities: x } }, this.forceUpdate)
    }

    generateConfiguration() {
        this.setState({
            componentReady: false
        })
        let endPoint = routes.templates
        const defaultConfiguration = {
            sort: {
                id: 'templates.update_timestamp',
                desc: true
            },
            rowsPerPage: 10,
            columns: [
                {
                    id: 'templates.uid',
                    title: 'UID',
                    type: Table.types.STRING,
                    accessor: (row) => {
                        return row.uid
                    },
                    show: true,
                    width: 150
                },
                {
                    id: 'template_types.name',
                    title: 'Name',
                    type: Table.types.STRING,
                    accessor: (row) => {
                        return row.template_type_name
                    },
                    show: true
                },
                {
                    id: 'templates.version',
                    title: 'Version',
                    accessor: (row) => {
                        return row.version
                    },
                    type: Table.types.INTEGER,
                    show: true,
                    width: 70
                },
                {
                    id: 'template_types.active',
                    title: 'Active',
                    accessor: (row) => {
                        return row.template_types_active
                    },
                    type: Table.types.INTEGER,
                    show: true,
                    width: 70
                },
                {
                    id: 'templates.creation_timestamp',
                    title: 'Creation date',
                    accessor: (row) => {
                        return row.creation_timestamp
                    },
                    type: Table.types.DATETIME,
                    show: true,
                    width: 90
                },
                {
                    id: 'templates.update_timestamp',
                    title: 'Last update',
                    accessor: (row) => {
                        return row.update_timestamp
                    },
                    type: Table.types.DATETIME,
                    show: true,
                    width: 90
                }
            ]
        }

        this.setState({
            endPoint: endPoint,
            configuration: defaultConfiguration,
            componentReady: true
        })
    }

    onUpload() {
        if (!this.state.files || !this.state.files.length) {
            Helpers.pushNotification({
                title: 'D\'oh...',
                message: `You must choose template file(s) first!<div style="opacity: .5; font-weight: bold; font-family: monospace; font-style: italic;">#n00b</div>`,
                level: 'error'
            })
            return
        }
        this.setState({ uploading: true })
        APIService.templateUpload(this.state.files).then((response) => {
            this.forceRefresh = true
            setTimeout(() => {

                this.setState({
                    uploading: false,
                    showModal: false,
                }, () => {
                    this.forceRefresh = false
                    if (response.success === false) {
                        alert(response.message)
                    } else {
                        this.forceRefresh = true
                        if (response.data[0]) {
                            this.showDetails(response.data[0])
                        }
                    }
                })
            }, 750)
        })
    }

    showDetails = (row) => {
        Promise.all([
            APIService.getTagsForTemplateType(row.template_type_uid),
            APIService.getTemplateCommunities(row.uid)
        ]).then(([tags, communities]) => {
            this.setState({
                showModaleDetails: true,
                tmp: Object.assign(row, { communities, tags }),
            }, this.forceUpdate)
        })
    }

    onUpdateTemplateData = () => {
        APIService.updateTemplateData(this.state.tmp).then(() => {
            this.setState({ showModaleDetails: false })
        })
    }

    render() {
        if (Helpers.getCurrentUser().admin < 1) { return <ForbiddenComponent /> }
        if (!this.state.componentReady) return null
        return (
            <div style={{ display: 'flex', flex: 1, padding: '1rem' }}>
                <Modale show={this.state.showModal} onHide={() => {
                    this.setState({ uploading: false })
                }}>
                    <Modale.Header>
                        Upload template
                    </Modale.Header>
                    <Modale.Body>
                        <label>
                            Template file(s)
                            </label>
                        <FileInput
                            disabled={this.state.uploading}
                            type="file"
                            multiple={true}
                            name="input_template"
                            onChange={(files) => {
                                this.setState({ files })
                            }}
                            accept=".emtpl"
                            style={{
                                width: '100%'
                            }} />

                        {this.state.uploading &&
                            <div className="uploadprogress"></div>
                        }
                    </Modale.Body>
                    <Modale.Footer>
                        <Button style={{ float: 'left' }} onClick={() => {
                            this.setState({ showModal: false, uploading: false })
                        }}>Close</Button>
                        <div style={{ flex: 1 }}></div>
                        <Button bsStyle="primary" disabled={this.state.uploading}
                            onClick={this.onUpload}>Upload</Button>
                    </Modale.Footer>
                </Modale>


                <Modale show={this.state.showModaleDetails} onHide={() => { }}>
                    <Modale.Header>
                        {(this.state.tmp || {}).template_type_name || 'Template details'}
                        <small style={{ color: 'black' }}>&nbsp;version {(this.state.tmp || {}).version || '???'}</small>
                    </Modale.Header>
                    <Modale.Body>
                        {this.state.tmp &&
                            <Tabs id="templateDetailsTabs" style={{ height: '300pt', width: '500pt' }}>
                                <Tab label="Relationships">
                                    <Picker style={{ flex: 1 }} title="Communities" filterkey={['name', 'uid']}
                                        items={this.state.tmp.communities} onChange={this.onCommunityChange}
                                        ukey="uid" sortBy="name" />
                                </Tab>
                                <Tab label="Deploy tags">
                                    <div className='tagsPickerWrapper'>

                                        {
                                            (this.state.tmp.tags || []).sort((a, b) => {
                                                return a.level < b.level ? -1 : 1
                                            }).map((tag, index) => {
                                                return (
                                                    <div key={index} className='tagsPickers'>
                                                        {
                                                            tag.level === 'community' &&
                                                            <Picker style={{ flex: 1 }} title={<span>Communities to put <span style={{ fontWeight: 'normal' }}><Tag {...tag} /></span> on</span>} filterkey={['name', 'uid']}
                                                                items={tag.relationships.communities} onChange={(x) => {
                                                                    const tmp = this.state.tmp
                                                                    tmp.tags[index].relationships.communities = x
                                                                    this.setState({
                                                                        tmp
                                                                    })
                                                                }}
                                                                ukey="uid" sortBy="name" />
                                                        }
                                                        {
                                                            tag.level === 'box' &&
                                                            <Picker style={{ flex: 1 }} title={<span>Boxes to put <span style={{ fontWeight: 'normal' }}><Tag {...tag} /></span> on</span>} filterkey={['name', 'uid']}
                                                                items={tag.relationships.boxes} onChange={(x) => {
                                                                    const tmp = this.state.tmp
                                                                    tmp.tags[index].relationships.boxes = x
                                                                    this.setState({
                                                                        tmp
                                                                    })
                                                                }}
                                                                renderItem={(item) => {
                                                                    return <BoxPickerElement {...item} />
                                                                }}
                                                                ukey="uid" sortBy="name" />
                                                        }
                                                        {
                                                            ['box', 'community'].indexOf(tag.level) === -1 &&
                                                            <div>
                                                                <i>Tag</i> <Tag {...tag} /> <small>&nbsp;({tag.level})</small> <i>is included in template but can not (yet) be linked</i>
                                                            </div>
                                                        }
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </Tab>
                            </Tabs>
                        }
                    </Modale.Body>
                    <Modale.Footer>
                        <Button onClick={() => { this.setState({ showModaleDetails: false }) }}>Close</Button>
                        <div style={{ flex: 1 }}></div>
                        <Button bsStyle="primary" onClick={this.onUpdateTemplateData}>Save</Button>
                    </Modale.Footer>
                </Modale>

                <TemplatesList
                    configuration={this.state.configuration}
                    endPoint={this.state.endPoint}
                    forceRefresh={this.forceRefresh}
                    showDetails={this.showDetails}
                />
            </div>
        )
    }
}

export default TemplatesListContainer
