import React from 'react'
import File from './File'
import Tag from '../UI/Tag'
import './Transfer.css'

import { Tabs, Tab, Button } from '../UI/Toolkit'
import { Link } from 'react-router-dom'

import ic_open from '../../img/ic_open_external.svg'
import ic_box from '../../img/ic_box.svg'
import ApiService from '../../core/ApiService'

import ic_play from '../../img/ic_play.svg'
import ic_pause from '../../img/ic_pause.svg'
import ic_close from '../../img/ic_close.svg'
import ic_restart from '../../img/ic_restart.svg'

import Loader from '../../components/UI/Loader'
import Helpers from '../../core/Helpers'
import Alfred from '../../core/Alfred'
import moment from 'moment-timezone'

class Transfer extends React.Component {

    constructor(props) {
        super(props)

        this.state = {}
        this.getStatusMessage = this.getStatusMessage.bind(this)
        this.onPause = this.onPause.bind(this)
        this.onResume = this.onResume.bind(this)
        this.onRestart = this.onRestart.bind(this)
        this.onCancel = this.onCancel.bind(this)
        this.handleResponse = this.handleResponse.bind(this)
    }

    onPause() {
        this.setState({ isActionRunning: true }, this.updateBreadcrumb)
        ApiService.fileTransfers.actionAll(this.props.itemId, 'pause')
            .then(this.handleResponse)
            .catch(console.error)
    }

    onResume() {
        this.setState({ isActionRunning: true }, this.updateBreadcrumb)
        ApiService.fileTransfers.actionAll(this.props.itemId, 'retry')
            .then(this.handleResponse)
            .catch(console.error)
    }

    onRestart() {
        this.setState({ isActionRunning: true }, this.updateBreadcrumb)
        ApiService.fileTransfers.actionAll(this.props.itemId, 'restart')
            .then(this.handleResponse)
            .catch(console.error)
    }

    onCancel() {
        this.setState({ isActionRunning: true }, this.updateBreadcrumb)
        ApiService.fileTransfers.actionAll(this.props.itemId, 'cancel')
            .then(this.handleResponse)
            .catch(console.error)
    }

    handleResponse(response) {
        this.setState({
            isActionRunning: false
        })
        if (response && Array.isArray(response)) {
            this.setState({
                statusMessages: response.map(m => { return Object.assign(m, { timestamp: Date.now() }) })
            })
        }
    }

    getStatusMessage(id) {
        return (this.state.statusMessages || []).find(m => m.id === id)
    }

    render() {
        return (
            <div className="Transfer">
                <div className="TransferFileBlock">

                    <div style={{ display: 'flex', flex: 1 }}>
                        <div style={{ display: 'flex' }} className='sourceDestinationTag'>
                            <img src={ic_box} alt="" style={{ height: '1.2rem', width: '1.2rem', marginRight: '.5rem' }} />
                            <div title={this.props.origin.name} className="name" style={{
                                flex: 1,
                                opacity: .66,
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                marginRight: '.5rem',
                            }}>{this.props.origin.name} <small style={{ opacity: .5 }}>({this.props.origin.id})</small></div>
                            <Link to={`/monitor/boxes/${this.props.origin.id}`} title='Show box details'>
                                <img src={ic_open} alt="" style={{ height: '1rem', width: '1rem' }} />
                            </Link>
                        </div>
                        <div style={{
                            margin: 'auto 1rem',
                            textAlign: 'center',
                            color: 'var(--color-primary)',
                            letterSpacing: '-2pt'
                        }}> &rarr; </div>
                        <div style={{ flex: 1, display: 'flex', margin: 'auto', verticalAlign: 'center' }}>
                            {this.props.destinations.map((dest, index) => {
                                return (
                                    <div className='sourceDestinationTag' style={{
                                        display: 'flex',
                                        flexWrap: 'wrap',
                                        textOverflow: 'ellipsis',
                                        whiteSpace: 'nowrap',
                                        alignItems: 'center'
                                    }} key={index}>
                                        <img src={ic_box} alt="" style={{ height: '1.2rem', width: '1.2rem', marginRight: '.5rem' }} />
                                        <div title={dest.name} className="name" style={{
                                            flex: 1,
                                            opacity: .66,
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                            marginRight: '.5rem',
                                        }}>{dest.name} <small style={{ opacity: .5 }}>({dest.id})</small></div>
                                        <Link to={`/monitor/boxes/${dest.id}`} title='Show box details'>
                                            <img src={ic_open} alt="" style={{ height: '1rem', width: '1rem' }} />
                                        </Link>
                                    </div>
                                )
                            })}
                        </div>
                    </div>

                    <div style={{ display: 'flex' }}>
                        <div className="actionButtons">
                            <Loader if={this.state.isActionRunning} />
                            <div className="buttons">
                                {Helpers.getCurrentUser().admin >= 1 && Alfred.askPermission('MAINTAINANCE_TRANSFERS_CONTROL', Alfred.X) ?
                                    <Button style={{ padding: 0 }} title="Pause all" disabled={this.state.isActionRunning || this.props.finished} onClick={this.onPause}><img
                                        src={ic_pause} alt="" style={{ margin: 'auto 0', height: '1.5rem' }} /></Button>
                                    : ''}&nbsp;
                                {Helpers.getCurrentUser().admin >= 1 && Alfred.askPermission('MAINTAINANCE_TRANSFERS_CONTROL', Alfred.X) ?
                                    <Button style={{ padding: 0 }} title="Resume all" disabled={this.state.isActionRunning || this.props.finished} onClick={this.onResume}><img
                                        src={ic_play} alt="" style={{ margin: 'auto 0', height: '1.5rem' }} /></Button>
                                    : ''}&nbsp;
                                {Helpers.getCurrentUser().admin >= 0 && Alfred.askPermission('MAINTAINANCE_TRANSFERS_CONTROL', Alfred.X) ?
                                    <Button style={{ padding: 0 }} title="Restart all" disabled={this.state.isActionRunning || this.props.finished} onClick={this.onRestart}><img
                                        src={ic_restart} alt="" style={{ margin: 'auto 0', height: '1.5rem' }} /></Button>
                                    : ''}&nbsp;
                                {Helpers.getCurrentUser().admin >= 1 && Alfred.askPermission('MAINTAINANCE_TRANSFERS_CONTROL', Alfred.X) ?
                                    <Button style={{ padding: 0 }} title="Cancel all" disabled={this.state.isActionRunning || this.props.finished} onClick={this.onCancel}><img
                                        src={ic_close} alt="" style={{ margin: 'auto 0', height: '1.5rem' }} /></Button>
                                    : ''}&nbsp;
                            </div>
                        </div>
                    </div>
                </div>

                <div style={{ padding: '.5rem' }}>
                    <Tabs id="transferTabs" style={{ minHeight: '8rem' }}>
                        <Tab label="Overview">
                            <div className="TransferFiles">
                                {this.props.files.map((file) => {
                                    file.downloads.forEach(dl => {
                                        const name = this.props.destinations.find(d => d.id === dl.destination_id).name
                                        return Object.assign(dl, {
                                            destination_name: name
                                        })
                                    })
                                    return <File
                                        key={file.id}
                                        itemId={this.props.item_id}
                                        origin={this.props.origin}
                                        itemFinished={this.props.finished}
                                        {...file}
                                        isActionRunning={this.props.isActionRunning}
                                        statusMessage={this.getStatusMessage(file.id)}
                                    />
                                })}
                            </div>
                        </Tab>

                        <Tab label="Details">
                            <div className="TransferDetails">
                                <div className="ItemDetails ui-card">
                                    <b>Workflow:</b> {this.props.workflow_name} <span className="uid">({this.props.workflow_id})</span><br />
                                    <b>Item:</b> {this.props.item_name} <span className="uid">({this.props.item_id})</span><br />
                                    <b>Creation time:</b> {moment(this.props.creation_time).format('YYYY-MM-DD HH:mm:ss')}<br />
                                    <b>Last update:</b> {moment(this.props.last_update).format('YYYY-MM-DD HH:mm:ss')}
                                </div>

                                <div className="FilesDetails">
                                    {this.props.files.map((file) => {
                                        return (
                                            <div className="ui-card" key={file.id}>
                                                <div>{Helpers.humanFileSize(file.size || 0)} file <span className="uid">({file.id})</span></div>
                                                <table class="tableau">
                                                    <thead><tr>
                                                        <th>Box</th>
                                                        <th>Attempts</th>
                                                        <th>Status</th>
                                                        <th>Transfer start</th>
                                                        <th>Transfer end</th>
                                                        <th style={{ textAlign: 'right' }}>Duration</th>
                                                    </tr></thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>
                                                                <div style={{ display: 'flex' }} className='sourceDestinationTag'>
                                                                    <img src={ic_box} alt="" style={{ height: '1.2rem', width: '1.2rem', marginRight: '.5rem' }} />
                                                                    <div title={this.props.origin.id} className="name" style={{
                                                                        flex: 1,
                                                                        opacity: .66,
                                                                        overflow: 'hidden',
                                                                        textOverflow: 'ellipsis',
                                                                        marginRight: '.5rem',
                                                                    }}>{this.props.origin.id}</div>
                                                                    <Link to={`/monitor/boxes/${this.props.origin.id}`} title='Show box details'>
                                                                        <img src={ic_open} alt="" style={{ height: '1rem', width: '1rem' }} />
                                                                    </Link>
                                                                </div>
                                                            </td>
                                                            <td style={{ textAlign: 'center' }}>{file.upload.attempts}</td>
                                                            <td>
                                                                <Tag name={file.upload.status} />
                                                            </td>
                                                            <td>{file.upload.transfer_start ? moment(file.upload.transfer_start).format('YYYY-MM-DD HH:mm:ss') : 'no data'}</td>
                                                            <td>{file.upload.transfer_end ? moment(file.upload.transfer_end).format('YYYY-MM-DD HH:mm:ss') : 'no data'}</td>
                                                            <td style={{ textAlign: 'right' }}>{file.upload.transfer_start && file.upload.transfer_end ? Helpers.msToDuration(file.upload.transfer_end - file.upload.transfer_start) : 'no data'}</td>
                                                        </tr>
                                                        {file.downloads.map(dl => {
                                                            return (
                                                                <tr>
                                                                    <td>
                                                                        <div style={{ display: 'flex' }} className='sourceDestinationTag'>
                                                                            <img src={ic_box} alt="" style={{ height: '1.2rem', width: '1.2rem', marginRight: '.5rem' }} />
                                                                            <div title={dl.destination_id} className="name" style={{
                                                                                flex: 1,
                                                                                opacity: .66,
                                                                                overflow: 'hidden',
                                                                                textOverflow: 'ellipsis',
                                                                                marginRight: '.5rem',
                                                                            }}>{dl.destination_id}</div>
                                                                            <Link to={`/monitor/boxes/${dl.destination_id}`} title='Show box details'>
                                                                                <img src={ic_open} alt="" style={{ height: '1rem', width: '1rem' }} />
                                                                            </Link>
                                                                        </div>
                                                                    </td>
                                                                    <td style={{ textAlign: 'center' }}>{dl.attempts}</td>
                                                                    <td><Tag name={dl.status} /></td>
                                                                    <td>{dl.transfer_start ? moment(dl.transfer_start).format('YYYY-MM-DD HH:mm:ss') : 'no data'}</td>
                                                                    <td>{dl.transfer_end ? moment(dl.transfer_end).format('YYYY-MM-DD HH:mm:ss') : 'no data'}</td>
                                                                    <td style={{ textAlign: 'right' }}>{dl.transfer_start && dl.transfer_end ? Helpers.msToDuration(dl.transfer_end - dl.transfer_start) : 'no data'}</td>
                                                                </tr>
                                                            )
                                                        })}
                                                    </tbody>
                                                </table>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        </Tab>
                    </Tabs>
                </div>
            </div >
        )
    }
}

export default Transfer
