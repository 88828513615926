import React from 'react'
import { Button } from '../UI/Toolkit'
import Modale from '../UI/ModaleComponent'
import Table from '../Table'

import './style.css'

class BoxUpdatePackage extends React.Component {
    constructor(props) {
        super(props)

        this.state = { ...props }

        this.renderPackageDetails = this.renderPackageDetails.bind(this)
    }

    componentWillReceiveProps(newProps) {
        this.setState({
            updatePackage: newProps.updatePackage,
            showModal: newProps.showModal,
            showModalDetails: newProps.showModalDetails
        })
        this.table.forceRefresh()
    }

    forceRefresh() {
        this.table.forceRefresh()
    }

    renderPackageDetails(json) {
        json = JSON.parse(json)
        return <div style={{ display: 'flex' }}>
            {Object.keys(json).map((OS, index) => {
                return <div key={index}>
                    <h3 style={{ textTransform: 'capitalize', margin: 0 }}>{OS}</h3>
                    {json[OS] && json[OS].Projects &&
                        <ul>
                            {Object.keys(json[OS].Projects).map((project, pId) => {
                                return <li key={pId}>
                                    <b>{project}</b> : {json[OS].Projects[project]}
                                </li>
                            })
                            }
                        </ul>
                    }
                </div>
            })}
        </div>
    }

    render() {

        return (
            <div style={{ flex: 1, flexDirection: 'column', overflow: 'auto' }}>
                <Modale show={!!this.state.showModalDetails}>
                    {this.state.showModalDetails &&
                        <Modale.Header>
                            {this.state.showModalDetails.version_code} details
                        </Modale.Header>
                    }
                    <Modale.Body style={{ width: '680px' }}>
                        {this.state.showModalDetails && this.state.showModalDetails.json && this.renderPackageDetails(this.state.showModalDetails.json)}
                    </Modale.Body>
                    <Modale.Footer>
                        <Button onClick={() => { this.setState({ showModalDetails: undefined }) }}>Close</Button>
                    </Modale.Footer>
                </Modale>

                <Table
                    filter={this.props.filter}
                    ref={table => this.table = table}
                    uid={'updatePackagesList'}
                    defaultConfig={this.props.configuration}
                    endPoint={this.props.endPoint}
                    csvFilename="update_packages"
                    getTdProps={(state, row, column, instance) => {
                        return {
                            onClick: (e) => {
                                this.setState({ showModalDetails: row.original })
                            }
                        }
                    }}
                    getTrProps={(state, rowInfo, column) => {
                        const style = { className: ['clickable_row'] }
                        if (rowInfo && rowInfo.original && rowInfo.original.status === 'DELETED') {
                            style.className.push('package-deleted')
                        }
                        return style
                    }}
                    onRows={this.onRows}
                />
            </div>
        )
    }
}

export default BoxUpdatePackage
