import React from 'react'
import Helpers from '../../../core/Helpers'
import './index.css'

const DEFAULT_DURATION = 4000

class NotificationsManager extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            notifications: []
        }
    }

    componentDidMount() {
        document.body.addEventListener('pushNotification', (e) => {
            const notification = {
                title: e.detail.title,
                message: e.detail.message,
                level: e.detail.level || 'info',
                id: Math.random() * Date.now(),
                button: e.detail.button,
                duration: e.detail.duration
            }
            const notifications = this.state.notifications
            notifications.push(notification)
            this.setState({
                notifications
            }, () => {
                notification.timeout = setTimeout(() => {
                    this.cancelNotification(notification.id)
                }, notification.duration || DEFAULT_DURATION)
            })
        })
    }

    cancelNotification(whichId) {
        const notifications = this.state.notifications

        const domNotification = document.querySelector(`[data-notification-id="${whichId}"]`)
        const nIndex = notifications.findIndex(n => n.id === whichId)
        if (notifications[nIndex]) {
            clearTimeout(notifications[nIndex].timeout)
        }

        if (!domNotification) {
            notifications.splice(nIndex, 1)
            this.setState({
                notifications
            })
            return
        }

        domNotification.style.animation = `.75s notification-leave ease-in forwards`
        domNotification.addEventListener('animationend', () => {
            notifications.splice(nIndex, 1)
            this.setState({
                notifications
            })
        })
    }

    handleNotificationButtonClick = (n) => {
        if (!n.button) {
            return
        }
        if (n.button.href) {
            Helpers.pushRoute(n.button.href)
        }
    }

    render() {
        return <div id="NotificationManagerComponent">
            {this.state.notifications.map((n) => {
                return <div key={n.id} data-notification-id={n.id} className={`notification ${n.level}`}>
                    <button type="button" className="notificationCloseButton"
                        title="Close"
                        onClick={() => {
                            this.cancelNotification(n.id)
                        }}>
                        &times;
                    </button>
                    <div className="notificationTitle">
                        {n.title}
                    </div>
                    <div className="notificationMessage" dangerouslySetInnerHTML={{ __html: n.message }}>
                    </div>
                    {n.button
                        ? <button button className="notificationButton" onClick={(e) => { e.stopPropagation(); this.handleNotificationButtonClick(n) }}>{n.button.label || '???'}</button>
                        : ''
                    }
                </div>
            })}
        </div>
    }
}

export default NotificationsManager