import React from 'react'
import Helpers from '../../core/Helpers'
import Alfred from '../../core/Alfred'
import 'react-table/react-table.css'
import Table from '../Table'
import routes from '../../routes'
import BoxEventsList from './BoxEventsList'
import ForbiddenComponent from '../ForbiddenComponent'

class BoxEventsListContainer extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            componentReady: false,
            endPoint: {},
            configuration: {
                columns: []
            }
        }
    }

    componentDidMount() {
        if (Helpers.getCurrentUser().admin >= 1 && Alfred.askPermission('BOX_EVENTS', Alfred.R)) {
            this.generateConfiguration()
            this.props.updateBreadcrumb('Box events',
                <div style={{ display: 'flex' }}>
                    <div style={{ flex: 1 }}>
                        View box events
                    </div>
                </div>
            )
        }
    }

    generateConfiguration() {
        this.setState({
            componentReady: false
        })
        let endPoint = routes.getBoxEventsList
        const defaultConfiguration = {
            sort: {
                id: 'timestamp',
                desc: true
            },
            rowsPerPage: 10,
            columns: [
                {
                    id: 'box_uid',
                    title: 'Box uid',
                    type: Table.types.STRING,
                    accessor: (row) => {
                        return row.box_uid
                    },
                    show: false,
                    width: 50
                },
                {
                    id: 'box_name',
                    title: 'Box name',
                    type: Table.types.STRING,
                    accessor: (row) => {
                        return row.box_name
                    },
                    show: true
                },
                {
                    id: 'type_id',
                    title: 'Event type',
                    type: Table.types.STRING,
                    accessor: (row) => {
                        return row.type_id
                    },
                    show: false,
                    width: 50
                },
                {
                    id: 'type_name',
                    title: 'Event name',
                    type: Table.types.STRING,
                    accessor: (row) => {
                        return row.type_name
                    },
                    show: true
                },
                {
                    id: 'timestamp',
                    title: 'Date',
                    accessor: (row) => {
                        return row.timestamp
                    },
                    type: Table.types.DATETIME,
                    show: true,
                    width: 125
                }
            ]
        }

        this.setState({
            endPoint: endPoint,
            configuration: defaultConfiguration,
            componentReady: true
        })
    }

    render() {
        if (Helpers.getCurrentUser().admin < 1 || !Alfred.askPermission('BOX_EVENTS', Alfred.R)) { return <ForbiddenComponent /> }
        if (!this.state.componentReady) return null
        return (
            <div style={{ display: 'flex', flex: 1, padding: '1rem' }}>
                <BoxEventsList
                    configuration={this.state.configuration}
                    endPoint={this.state.endPoint}
                />
            </div>
        )
    }
}

export default BoxEventsListContainer
