import React from 'react'
import { Button } from '../UI/Toolkit'
import Modal from '../UI/ModaleComponent'
import Helpers from '../../core/Helpers'

export default class ModalColumnChoice extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            showModal: false,
            columns: props.columns,
            userPreferences: props.userPreferences
        }

        this.close = this.close.bind(this)
        this.open = this.open.bind(this)
        this.handleCancel = this.handleCancel.bind(this)
        this.handleSave = this.handleSave.bind(this)
    }

    close() {
        this.setState({ showModal: false })
    }

    open() {
        this.setState({ showModal: true })
    }

    componentWillReceiveProps(nextProps) {
        if (this.state.showModal) return false
        this.setState({
            columns: nextProps.columns,
            userPreferences: nextProps.userPreferences
        })
    }

    handleChange(column) {
        const userPreferences = Helpers.clone(this.state.userPreferences)
        const index = userPreferences.columns.findIndex((col) => {
            return col.id === column.id
        })
        userPreferences.columns[index].show = !userPreferences.columns[index].show
        this.setState({
            userPreferences: userPreferences
        })
    }

    handleCancel() {
        this.setState({
            userPreferences: this.props.userPreferences
        })
        this.close()
    }

    handleSave() {
        this.props.update(this.state.userPreferences)
        this.close()
    }

    renderColumns(columns, userPreferences) {
        return columns.map((column, index) => {
            return <label key={index} style={{ display: 'flex', alignItems: 'center', fontWeight: 'normal' }}>
                <input type='checkbox'
                    checked={userPreferences && userPreferences.columns[index] ? userPreferences.columns[index].show : column.show}
                    onChange={(e) => {
                        this.handleChange(column)
                    }}
                />
                &nbsp;
                {column.title || column.id}
            </label>

        })
    }

    render() {
        return (
            <div style={{ display: 'inline-block' }}>
                <Button
                    bsStyle="primary"
                    onClick={this.open}
                >
                    Columns
                </Button>
                <Modal show={this.state.showModal} onHide={this.close}>
                    <Modal.Header>
                        Columns
                    </Modal.Header>
                    <Modal.Body>
                        {this.renderColumns(this.state.columns, this.state.userPreferences)}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={this.handleCancel}>Cancel</Button>
                        <div style={{ flex: 1 }}></div>
                        <Button bsStyle="primary" onClick={this.handleSave}>Save</Button>
                    </Modal.Footer>
                </Modal>
            </div>
        )
    }
}
