import React from 'react'

const colors = [
    '#fff6a188',
    '#fbbaaa88',
    '#eec8f988',
    '#a1e0f288',
    '#bbecb688',
    '#f4cdb388',
    '#e3d2c188',
    '#fffcf488',
    '#f2a45c88'
]
const colorMap = new Map()
let index = 0

const ColorList = {
    Cell: (cell) => {
        const key = cell.column.id + cell.value
        if (!colorMap.has(key)) {
            colorMap.set(key, colors[index])
            index = index >= colors.length - 1 ? 0 : index + 1
        }

        const style = {
            padding: '.5rem',
            margin: '-.5rem',
            height: 'calc(100% + 1rem)',
            width: 'calc(100% + 1rem)',
            backgroundColor: colorMap.get(key)
        }

        return (<div style={style}>{cell.value}</div>)
    },

    Filter: ({ filter, onChange }) => {
        return (<div><input type="text"
            style={{ width: '100%' }}
            onChange={(event) => {
                onChange({
                    operator: 'like',
                    value: event.target.value
                })
            }}
            value={(filter ? filter.value.value : '')}
        />
        </div>)
    },

    reset: () => {
        colorMap.clear()
        index = 0
    }
}


export default ColorList