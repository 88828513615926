import React from 'react'
import { Button } from '../UI/Toolkit'
import ApiService from '../../core/ApiService'
import Helpers from '../../core/Helpers'
import Alfred from '../../core/Alfred'
import ForbiddenComponent from '../ForbiddenComponent'
import './index.css'

class SecurityComponent extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            config: {},
            componentIsReady: false
        }
    }

    componentDidMount() {
        if (Helpers.getCurrentUser().admin >= 1 && Alfred.askPermission('PLATFORM_CONFIG', Alfred.R)) {
            ApiService.getConfig('security').then((security) => {
                this.setState({
                    config: security,
                    componentIsReady: true
                })
            })
            this.props.updateBreadcrumb('Security', <div style={{ display: 'flex' }} >
                <div style={{ flex: 1 }}>Set security rules for web access</div>
                {Alfred.askPermission('PLATFORM_CONFIG', Alfred.W) &&
                    <Button bsStyle="primary"
                        bsSize="small"
                        onClick={this.onApplyRules}> Save </Button>
                }
            </div>
            )
        }
    }

    onApplyRules = () => {
        ApiService.setConfig({ security: this.state.config }).then(() => {
            // ok
        }).catch(() => {
            Helpers.pushNotification({
                title: 'D\'oh...',
                message: `Error while saving configuration changes`,
                level: 'error'
            })
        })
    }

    render() {
        if (Helpers.getCurrentUser().admin < 1 || !Alfred.askPermission('PLATFORM_CONFIG', Alfred.R)) {
            return <ForbiddenComponent />
        }
        if (!this.state.componentIsReady) {
            return <div></div>
        }
        return (<div style={{ padding: '1rem', display: 'flex', alignItems: 'flex-start' }}>
            <div className='ui-card' style={{ width: '160pt', marginRight: '1rem', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <img src="/img/illus_security_u2f.svg" alt="" style={{ width: '96pt', height: '96pt', margin: 'auto' }} />
                <label style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', fontWeight: 'normal', whiteSpace: 'nowrap' }}>
                    <button className={`toggle ${this.state.config.u2f ? 'on' : 'off'}`} onClick={() => { this.setState({ config: { ...this.state.config, u2f: !this.state.config.u2f } }) }}></button>
                    Enable U2F
                </label>
                <small style={{ opacity: .5, textAlign: 'center' }}>
                    Secure user's login using a hardware security key (Yubikey)
                </small>

            </div>
            <div className='ui-card' style={{ width: '160pt', marginRight: '1rem', display: 'flex', flexDirection: 'column', alignItems: 'center' }} disabled={!this.state.config.u2f}>
                <img src="/img/illus_security_u2fmail.svg" alt="" style={{ width: '96pt', height: '96pt', margin: 'auto' }} />
                <label style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', fontWeight: 'normal', whiteSpace: 'nowrap' }}>
                    <button disabled={!this.state.config.u2f} className={`toggle ${this.state.config.u2fmail ? 'on' : 'off'}`} onClick={() => { this.setState({ config: { ...this.state.config, u2fmail: !this.state.config.u2fmail } }) }}></button>
                    Enable Mail as 2nd factor
                </label>
                <small style={{ opacity: .5, textAlign: 'center' }}>
                    Allow e-mail fallback if YubiKey is not available (needs U2F enabled)
                </small>

            </div>
            <div className='ui-card' style={{ width: '160pt', marginRight: '1rem', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <img src="/img/illus_security_pfadmin.svg" alt="" style={{ width: '96pt', height: '96pt', margin: 'auto' }} />
                <label style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', fontWeight: 'normal', whiteSpace: 'nowrap' }}>
                    <button className={`toggle ${this.state.config.permitPFAdminLogin ? 'off' : 'on'}`} onClick={() => { this.setState({ config: { ...this.state.config, permitPFAdminLogin: !this.state.config.permitPFAdminLogin } }) }}></button>
                    Disable pfadmin login
                </label>
                <small style={{ opacity: .5, textAlign: 'center' }}>
                    Secure platform settings by refusing pfadmin's login
                </small>
            </div>

        </div>
        )
    }
}

export default SecurityComponent